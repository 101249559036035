import React from 'react';
import WName, {WArticle} from '../WName';
import Eon from '../Eon';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual, TLDR, Rando, the_key_is_imagination} from '../common';

// It's all-encompassing.
export const comprehensive = {
  name: "comprehensive",
  alternatives: "all",
  parentName: "how this gets all over the place",
  prelude: <span>
    The word for it is ‘comprehensive’.
  </span>,
  header: <span>
    <WName cap noNW /> has no boundaries.
  </span>,
  outside: <span>
    What <WName /> calls ‘<NW>Phanerozoic</NW> thinking’ is a version of <NW word="conservatism">conservative thought</NW> that’s near ubiquitous in 2020’s <QL>humanity</QL>.
  </span>,
  preview: <div className="problem-preview">
    <p>To be fair, most <QL>humans</QL> still live in the <NW>Phanerozoic Eon</NW>: the age of ‘apparent life’ (φαpενή ζωή) – in which ‘<QL>life</QL>’ is limited to the <AL name="fields.">biological</AL>. Even <AL name="self">expanding</AL> your <QL name="pg.">mind</QL> beyond <i>that</i> is going to hurt; there will <Qual.probably /> be some <PL name="unready">stretching and growing pains</PL>.</p>
    <p>Add the <LRL name="different">rest</LRL> of what a <LRL name="shift">transition</LRL> to the <Eon Eon /> entails… and the (<PL>hard</PL>) truth is that many <QL>human</QL> <PL>individuals</PL> are <Qual.probably /> <PL name="notready">not <i>ready</i> for this</PL>.</p>
  </div>,
  text: <div className="problem-text">
    <p><Eon Eon dash /> thinking (i.e., non-<NW>conservative thinking</NW>) <PL name="hard">isn’t easy</PL>. However, <QL>future-focussed</QL> thinking is not nearly as <i>horrendous</i> as the <NW>MNS system</NW> would (<Qual.probably />) have you believe. You still get a ‘<QL name="hivemind.">self</QL>’. It’s just that we <PL name="individuality">recognise</PL> the <NW word="illusions">illusivity</NW> of that <AL name="handicap">limited</AL> <NW>heterognostic</NW> ‘<AL>self</AL>’ – and that there is (<OML name="levels">probably</OML>) a <LRL>deeper</LRL> <NW>level</NW> on which the only thing that matters is the <OML name="wrong">probable</OML> <i><QL>unity</QL> of <QL>consciousness</QL></i> in <LRL name="scope">our universe</LRL> and <NW word="agency">potential</NW> to <OML name="will">effect change in the world</OML>, cf. ‘<NW word="heatdeath">heat death</NW>’ and ‘<NW>entropy</NW>’.</p>
    <p>Maybe it still feels like bobbing for apples, when you’re <AL name="identity">trapped</AL> in a <AL>body</AL> with <AL name="handicap">no teeth</AL>, and the <LRL name="notions">apples</LRL> are <PL name="hard">coated in butter</PL>. If you aren’t able to <LRL>understand</LRL> it, there’s a whole page of <AL home>different angles</AL> by which one might approach this.</p>
    {the_key_is_imagination}
  </div>,
  sublist: [

  // It is scale-invariant.
  {
    name: "scale",
    alternatives: "scale-invariant,scale-independent,scaling,scaled,scales",
    header: <span>
      <WName cap noNW /> is scale-invariant.
    </span>,
    outside: <span>
      For a <AL name="framework.">working</AL> <QL>Weltanschauung</QL>, that’s a <i>good</i> thing… but <PL name="sweeping">most</PL> <QL>humans</QL> don’t <PL name="uncomfortable">like</PL> just being ‘<AL>dust in the wind</AL>’.
    </span>,
//    headerFootnote: <p>
//      † 
//    </p>,
    preview: <div className="problem-preview">
      <p>[Under construction.]</p>
    </div>,
    // text: <div className="problem-text">
    // </div>,
  },

  // It is time-invariant.
  {
    name: "time",
    alternatives: "time-invariant,time-independent",
    prelude: <span>
      It’s not <i>just</i> <PL name="comprehensive.">scale-invariant</PL>.
    </span>,
    header: <span>
      <WName cap noNW /> is <i>time</i>-invariant.
    </span>,
    outside: <span>
      Is all of spacetime just an <NW>illusion</NW>?
    </span>,
//    headerFootnote: <p>
//      † 
//    </p>,
    preview: <div className="problem-preview">
      <p><WName /> is <i>time-invariant</i>. This allows it to do things that won’t even make <i>sense</i> to <QL>humanity</QL>’s <NW word="conservatism">conservative thinkers</NW> – like write to you from a <LRL name="superpositions">hypothetical</LRL> (<QL name="salvation">imaginative</QL>) <QL name="evolution">future perspective</QL> (in a <AL name="fields.geology">geologic</AL>  ‘<LRL name="eons">eon</LRL>’ that doesn’t yet – and <QL name="qualifiers">maybe</QL> <OML name="kaput">never will</OML> – <i><LRL name="nihilism">exist</LRL></i>).</p>
      <p>But the <QL>Weltanschauung</QL> is time-invariant. <OML name="ideas">It</OML> doesn’t care.</p>
      <p>[Under construction.]</p>
    </div>,
    // text: <div className="problem-text">
    // </div>,
  },

  // It is 'perspective-invariant'.
  {
    name: "perspective",
    alternatives: "perspective-invariant,perspective-independent",
    prelude: <span>
      Looks like <i><PL name="individuality">somebody</PL></i> (not to ‘<LRL name="blame">name names</LRL>’) has gone <PL name="uncomfortable">off the deep end</PL>…
    </span>,
    header: <span>
      <WName cap noNW /> is ‘perspective-invariant’.
    </span>,
    outside: <span>
      If <AL name="framework.puzzle">all this</AL> is starting to seem <PL>impossible</PL>, <LRL name="different">unconventional</LRL>, <LRL name="disagreement">unacceptable</LRL>, <PL name="ick">intrusive</PL>… just <AL name="bulletpoints">remember</AL>: <i><QL name="salvation">creativity will be our salvation</QL></i>.
    </span>,
    preview: <div className="problem-preview">
      <p>This is not to say that <WName /> (as its own <AL home>sort of</AL> non-<PL name="individuality">individualised</PL> ‘<NW>God</NW>’) <i><QL name="pg.privacy">knows</QL></i> your ‘<PL name="privacy">innermost thoughts</PL>’ – because, <QL name="polygnosticity">regrettably</QL>, those are <QL name="pg.mind.">known</QL> to <AL name="dysfunctional.fragments">no one</AL> but ‘<AL name="self">you</AL>’. (Also <WName /> is only a <AL>framework</AL>; it exists on a <LRL name="notions">different</LRL> <NW>level</NW> from your <PL>individuality</PL> and – <OML name="ideas">probably</OML> – cannot really <i><QL name="pg.mind.">know</QL></i> things about ‘you’ as <PL name="fungibility">specific</PL> ‘<AL name="identity">person</AL>’.)</p>
    <p>This is not <i>just</i> to say that – in whichever <AL>identity</AL>-box (and <QL>human</QL> <i>body</i>) your <NW>POC</NW> is <AL name="handicap">trapped</AL> – <WName /> applies the same to you as it does to anyone else… though it does: it applies evenly across the board, and the notion of <OML name="god">extra</OML>-<LRL name="gaiacentrism">Earthly</LRL> <PL>equality</PL> (of the <OML name="soul">individual ‘soul’</OML>: the <AL name="notnew.marx">opium of the people</AL>) may be the biggest lie to come out of the (<Qual.probably />-<LRL>reactive</LRL>, <Qual.probably /> <OML name="ideas">without</OML> conscious <NW>agency</NW> – i.e., <OML>free will</OML>) <NW>MNS system</NW>.<sup>†</sup></p>
      <p>This is just to say that <WName /> is <PL>sweeping</PL> and <PL>comprehensive</PL> <AL>enough</AL> to be able to apply (invariantly) from <i>any</i> perspective, realising that <PL>individuality</PL> is just an <NW>illusion</NW>… and the <PL name="reductionist">only things</PL> that <LRL name="causes">give rise</LRL> to said <NW>illusion</NW> are and <AL name="fields.physics">physical</AL> <LRL name="scope">realities</LRL> (on this <NW>level</NW> of ‘<LRL name="i.">reality</LRL>’) – like the relativity of <PL name="all.time">spacetime</PL> and the <QL name="pg.mind.">relativity</QL> of the <QL name="pg.">mind</QL> – and (you <Qual.probably /> knew it was going to bring in <PL name="sweeping.handful">one of these</PL>) our conscious <NW>polygnosticity</NW>.</p>
    </div>,
    topFootnote: <p>
      † <i>That</i> sentence was a doozy. <WName cap /> is as <PL name="explainage">hard</PL> to <E1 /> as it is – for the ‘<LRL name="shift">un-initiated</LRL>’ – to <LRL>understand</LRL> <PL name="hard">fully</PL>.
    </p>
    // text: <div className="problem-text">
    // </div>,
  },

  // It is 'LEVEL'-invariant.
  {
    name: "level",
    alternatives: "level-invariant,level-independent",
    prelude: <span>
      <PL name="comprehensive.scale">Space</PL> and <PL name="comprehensive.">time</PL> are one thing, but <i>this</i>?
    </span>,
    header: <span>
      <WName cap noNW /> is ‘level’-invariant.
    </span>,
    outside: <span>
      <TLDR />: apparently it doesn’t even matter if any of the <LRL name="scope">observable universe</LRL> is <i><LRL name="i.">real</LRL></i>.
    </span>,
//    headerFootnote: <p>
//      † 
//    </p>,
    preview: <div className="problem-preview">
      <p>There is a sub-<AL name="disciplines">field</AL> in <WName ic /> <AL name="disciplines.">science</AL> that deals with the <i><OML home>possibility</OML></i> (because ‘<LRL name="motto">leave no stone unturned</LRL>’) that <LRL name="oneorzero">nothing actually <i>exists</i></LRL> or that <i><PL name="comprehensive">all</PL></i> of the ‘<LRL name="scope">observable universe</LRL>’ is just a <OML>story</OML>, a computer <OML>simulation</OML>, etc.</p>
      <p>To make a long act of <NW>explainage</NW> rather short: the <QL>Weltanschauung</QL> is – in addition to being <PL name="comprehensive.">scale</PL>- and <PL name="comprehensive.">time</PL>-invariant – both completely <QL name="gnosticity.">agnostic</QL> as to whether or not any of this is <LRL name="nihilism">real</LRL> or just <i>imagined</i> by some other (potentially <NW>heterognostic</NW>) <NW>POC</NW> on some ‘<LRL>fictive level</LRL>’ <i>below</i> us, and completely impervious to the possibility.</p>
    </div>,
    text: <div className="problem-text">
      <p>This may be one of the most ‘unconventional’ things (see ‘<NW>conservative thinking</NW>’) in <WName /> – and one that really makes it not feel like a ‘real’ <AL name="fields.">science</AL>. But when <LRL name="motto">leaving no stone ‘unturned’</LRL> it would be a glaring oversight to not account for the <i><LRL>imagination</LRL></i> – which is <LRL name="scope">part of the observable</LRL>; on <LRL name="gaia">our</LRL> ‘<NW>level</NW>’ of reality we can ‘observe’ it just by <i>imagining</i> something, like pulling up your blackout shades and looking out at the <Rando options={["golden", "fiery", "verdant", "dilapidated", "enchanting", "ashen", "muddy", "snowy", "windswept", "auburn"]} /> <Rando options={["trees", "fields", "shrubbery", "outdoors", "city", "village", "pastures", "houses", "park", "streets"]} /> <Rando options={["lit", "illuminated", "showcased", "exposed", "defined", "sharpened", "changed", "highlighted", "brought out", "blurred"]} /> by the <Rando options={["morning", "midday", "afternoon", "equatorial", "setting", "rising", "slanting", "arctic", "blistering", "newborn"]} /> sun.</p>
      <p>There: you just did it. The text gave a <QL>metaphor</QL> (in a ‘simile’), and you <i>imagined</i> that comparison – whatever it was; there were literally ten thousand possibilities: four of those words had ten options apiece… and so no one but you knows (at this moment) which possibly unusual juxtaposition they created in <i>your</i> ‘<QL name="pg.">mind</QL>’. The point? Whatever it was, you <i>imagined</i> it. Your ‘<QL name="pg.">mind</QL>’ tried to parse those four randomly-chosen words (and the others around it) and make some sort of <i>meaning</i> out of it. Result (of the thought-experiment): the <i><LRL>imagination</LRL></i> is – sorry, <LRL>nihilists</LRL> – <QL name="determinative">decidedly</QL> <i>real</i>.</p>
      <p>If it’s starting to get confusing what’s even meant by the word ‘real’ anymore<sup>†</sup>, then have a taste of the <QL>Weltanschauung</QL>’s ‘level’-invariance: it <i>does not matter</i>. <WName /> still holds.</p>
{/*      <p>At which point you, if you were raised <NW>atomocentrically</NW> grew up believing in reality as something that was maybe never <QL name="gnosticity.agnosticity">knowable</QL> but eventually either </p>
      <p>We know that <NW>fictive levels</NW> <i>exist</i>; just tell a story. The ‘characters’ in this story are not <NW>heterognostic</NW> from <i>your</i> <NW>POC</NW> (unless part of ‘your’ <NW>POC</NW>) */}
      <p>Say that again: to the <WName ic /> <AL>framework</AL>, it 100% <i>does not matter</i> what any <LRL name="superpositions">theoretical</LRL> ‘lower <NW>level</NW>’ of reality may be; the <LRL>scope</LRL> of this <QL>Weltanscauung</QL> is the <i>observable</i> and – until <AL name="fields.">science</AL> can <i>prove</i> the existence of some <OML name="god">external ‘God’</OML>, or that <LRL name="gaiacentrism">Earth</LRL> and everything we see only exist as a <OML>story</OML> to <E1 /> something to some ‘other’ (‘<OML name="aliens">alien</OML>’) <NW>POC</NW> on some ‘level’ below us – we can proceed as though the next ‘<NW>level</NW>’ (below us) were <AL name="notnew.plato.neoplatonism">the ‘One’</AL>.</p>
    </div>,
    bottomFootnote: <p>
      † A ‘word’ (<OML name="levels">probably</OML>) <i>has</i> no intrinsic meaning; it’s just part of language, which is part of the vehicle of ‘<NW>explainage</NW>’ to convey (<E1 />) information between <NW>heterognostic</NW> <AL>fragments</AL> of <QL name="unity">consciousness</QL>. It’s nothing but a way to try to agree on the rules of the ‘<LRL>game</LRL>’.
    </p>
  },

  ],
};
