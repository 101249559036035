import React from 'react';
import Box from '../Box';
import ErrorBox from '../ErrorBox';
import {findInList} from './findInList';
import './ItemBox.css';

/* 
  This will make a box as a basis for any component that displays in a list (Question, Problem, NewWord, etc.) with the potential for layering further lists of the same component within individual boxes (if a list component is provided).
*/

export default function ItemBox({
  data,
  list,
  search,
  propertyName = "name",
  alternativesName = null,
  ListComp,
  preview,
  listItem,
  className,
  // Defaults for picking the items out of data for a particular structure:
  preludeName = "prelude",
  headerName = "header",
  displayName, // Basically just for neologisms where name=header
  outsideName = "outside",
  previewName = "preview",
  readMoreName = "readMore",
  textName = "text",
  // Other customisations:
  expandText = null,
  // 'overwrite' allows you to overwrite any part of the data.
  overwrite,
  ...props
}) {
  if (!data) {
    // console.log(props);
    data = findInList(list, search, propertyName, alternativesName);
    if (!data) {return "Critical error: 'data' is empty.";}
    if (data.error) {
      return <ErrorBox>
        {data.error}
      </ErrorBox>;
    }
  }
  // Overwrite whatever's in 'data' with whatever's in 'overwrite'.
  if (overwrite) {
    for (let property in overwrite) {
      if (overwrite.hasOwnProperty(property)) {
        data[property] = overwrite[property];
      }
    }
  }

  let footnote = (!preview && data.bottomFootnote) ?
    [data.bottomFootnote] : null;
  if (data.topFootnote) {
    if (footnote) {footnote.unshift(data.topFootnote);}
    else {footnote = [data.topFootnote];}
  }

  let header = (displayName) ? 
    (data[displayName] || data[headerName]) : data[headerName];

  return <div className={"item-box " + className}>
    <Box
      prelude={data[preludeName]}
      header={header}
      outside={data[outsideName]}
      expandText={expandText}
      collapsible={listItem || (!preview && !search)}
      startOpen={!listItem && (preview || !!search)}
      coda={data.headerFootnote}
      codaInside={footnote}
    >
      {preview && data[outsideName] && <hr />}
      {data[previewName]}
      {preview && !!data[textName] && <p className="readmore">
        {data[readMoreName] || "Click the link to read more."}
      </p>}
      {!preview && data[textName]}
      {!preview && data.sublist && ListComp && <ListComp list={data.sublist} />}
      {/* Child items will point back to their 'mothers'. */}
      {!preview && !listItem && data && data.coda}
    </Box>
  </div>;
};
