import React from 'react';
import LongRead from './LongRead';
import PreviewLink from '../PreviewLink';
import './LongReadLink.css';

export default function LongReadLink(props) {
  return <PreviewLink
    {...props}
    PreviewComp={LongRead}
    linkClassName="long-read-link"
    linkURLPrefix="longreads"
  />;
};
