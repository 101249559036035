import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, TLDR, Qual, Rando, Planet} from '../../common';

// ‘’ … –

export const imagination = {
  name: "imagination",
  alternatives: "imagine,imagines,imagined,i",
  parentName: "the imagination",
  prelude: <span>
    What even <i>is</i> it, anyway?
  </span>,
  header: <span>
    This is the <i>imagination</i>.
  </span>,
  outside: <span>
    <AL name="notnew.lennon">Join us.</AL>
  </span>,
  preview: <div className="long-read-preview">
    <p>It’s <QL name="qualifiers">almost certainly</QL> the <AL name="bulletpoints">most important</AL> of the <AL name="framework.">puzzle pieces</AL> that <WName /> is ‘<LRL name="shift">re-arranging</LRL>’. It’s the only thing (besides <PL name="rude">hard and unfeeling</PL> <i>data</i>) that seems to really <i><QL name="meaning">matter</QL></i> to <WName />. And (at the <LRL name="superpositions">same time</LRL>) the <QL>Weltanschauung</QL> seem to not give much of a <OML name="offensive">rat’s ass</OML> if anything is even ‘<LRL name="nihilism">real</LRL>’ of if <PL name="all.story">we are all characters</PL> in a <i><OML>story</OML></i> told (one ‘<NW>fictive level</NW>’ <i>below</i> us) by <Planet.Aliens /> on an <LRL name="gaiacentrism">extraterrestrial</LRL> (and yet <NW>atomocentric</NW>) planet called <Planet.Name />. And <QL>human</QL> <AL name="fields.science">scientists</AL>, <AL name="notnew.philosophy">philosophers</AL>, the ‘<QL name="body.cells">heads</QL>’ of all of <LRL name="gaia">Earth</LRL>’s <NW>nations</NW> – and however many others – have <QL name="qualifiers">more or less</QL> <OML name="notnew">discounted</OML> it for how long?</p>
    <p>That’s the <i>imagination</i>.</p>
  </div>,
  text: <div className="long-read-text">
    <p><NW word="god">Inshallah</NW> <LRL name="gaia">this planet</LRL> (as an <AL name="fields.biology">interative</AL> <QL name="body.super-organ">conscious part</QL> comprising <AL name="framework.fragments">conscious parts</AL> of the <QL name="unity.answer">fabric of the universe</QL>: an <OML name="will">actor</OML> with <i><NW>agency</NW></i>) will – despite its <AL name="sick.">cancer</AL>, despite its <AL name="sick.did">dissociative identity disorder</AL>, despite its many <AL name="sick">diseases</AL> (<PL name="sweeping">all of which</PL> are <LRL name="symptoms">caused</LRL> by our conscious <QL>polygnosticity</QL>) – learn to <i><PL>love</PL> <AL name="self">itself</AL></i> and not just go ‘<OML>kaput</OML>’.</p>
  </div>,
  sublist: [

  // The imagination has NO LIMITS
  {
    name: "boundless",
    alternatives: "limitless,limits,limit,bounds",
    prelude: <span>
      If the only ‘limit’ is the <i>imagination</i>…
    </span>,
    header: <span>
      The imagination is literally <i>boundless</i>
    </span>,
  //outside: <span>
 // </span>,
    preview: <div className="long-read-preview">
      <p>The <LRL>imagination</LRL> does not have a <LRL>scope</LRL> or a <i>limit</i>.</p>
      <p>People say that, ‘The only limit is the imagination,’ but this isn’t qite true. The <LRL>imagination</LRL> is not a ‘limit’. It can <NW>transcend</NW> <PL name="all.level">every</PL> <NW>fictive level</NW>, and the only real limit is <i>your <NW>POC</NW>’s <NW>heterognostic</NW> <AL name="handicap">human</AL> ‘<QL name="pg.">mind</QL></i>’.</p>
    </div>,

  },

  // Free will?
  {
    name: "free will",
    alternatives: "will,agency,choice",
    prelude: <span>
      A <NW>POC</NW> you can imagine two things, and then <i>pick</i> only <i>one</i>.
    </span>,
    header: <span>
      What about FREE WILL?
    </span>,
    preview: <div className="long-read-preview">
      <p>So if you ever <LRL name="motto">questioned</LRL> the existence of <OML>free will</OML> (or <i><NW>agency</NW></i>), question on more; it may be impossible to <LRL name="superpositions">definitively</LRL> <i><AL name="fields.science">prove</AL></i> <QL name="difinitive">anything <i>else</i></QL> on our <NW>level</NW> of ‘<LRL>reality</LRL>’, but one thing is self-evidently observable: <i>thought</i>. And if you can <i><LRL>imagine</LRL></i> something other than how it is – and start to <i>act</i> through the <AL name="container">connection</AL> that your <NW>POC</NW> (and ‘imagination’) has wih the <NW>level</NW> that it can <i>observe</i> – then you have just thrown a multiverse-sized wrench into the ponderings of any ‘<OML>free will</OML> sceptic’ – the ‘multiverse’ being the size of this universe times <LRL name="superpositions">every possible outcome</LRL> that your <NW>POC</NW> could have <i>imagined</i>.<sup>†</sup></p>
    </div>,
    topFootnote: <p>† Are those imagined universes <i>real</i>? As ‘real’ on <i>their</i> <NW>fictive levels</NW> (‘above’ you) as you are on <i>your</i> <NW>fictive level</NW>. Hopefulle you’re getting a sense of what ‘<PL name="all.level">level-invariance</PL>’ means.</p>
  },

  // What is 'real', anyway?
  {
    name: "reality",
    alternatives: "real",
    prelude: <span>
      Is ‘<PL name="all.level">level-invariant</PL>’ just a <PL name="impolite">polite</PL> way to say ‘crazy’?
    </span>,
    header: <span>
      <WName cap noNW /> doesn’t even seem to know what is ‘real’.
    </span>,
    outside: <span>
      And what does ‘real’ even <i>mean</i>?
    </span>,
    preview: <div className="long-read-preview">
      <p><TLDR />: well, what is real? We know one thing <LRL name="i.self-evident">for sure</LRL>: the <LRL>imagination</LRL>.</p>
      <p>We think, therefore we are; that’s the <WName ic /> version. The imagination is <LRL name="imagination.">self-evident</LRL>. A <NW>POC</NW> can <OML home>call into question</OML> <QL name="qualifiers">pretty much</QL> anything <i>else</i>, but the imagination exists.</p>
      <p>Which means – under a <PL name="all.level">‘level’-invariant</PL> <AL>framework</AL> – that at least (if not <QL name="unity.1">at most</QL>) one thing is ‘real’; sorry <LRL name="nihilism">nihilists</LRL> and ‘<QL name="g.agnosticity">agnostics</QL>’ about everything. (The <QL name="roadblocks.god">external God</QL> then ‘exists’ too – in this way – at least <i><LRL>imagined</LRL></i> by a lot of <QL>humanity</QL>. The <NW>inductive</NW> <QL name="unity">premise</QL> of <WName /> asserts that this <OML>external God</OML> is as much an <NW>illusion</NW> as <PL>individuality</PL> or the <OML name="reincarnation">possibly-recurring</OML> <PL>individual</PL> ‘<OML>soul</OML>’.</p>
    </div>,
    text: <div className="long-read-text">
      <p>So what is ‘real’, after all? What does ‘real’ even <i>mean</i> (besides the obvious: it’s just – as <PL name="sweeping">all</PL> language – a piece of <QL>human</QL> ‘<NW>explainage</NW>’ to transfer <LRL>understanding</LRL> across <QL>polygnosticity</QL>)?</p>
      <p>This is where the concept of ‘<PL name="all.level">level-invariant</PL>’ <i>does</i> start to feel like just a <PL name="impolite">polite</PL> way to say ‘crazy’: it does not matter.</p>
      <p>The <LRL>scope</LRL> of <WName /> is the ‘observable’: i.e., <i>this</i> <NW>level</NW>. Is this ‘<NW>fictive level</NW>’ really ‘<OML name="story">fictive</OML>’ (beyond the <NW>illusions</NW> caused by <AL name="handicap">human biology</AL> and <PL name="sweeping.ig">enhanced</PL> by <NW>individual greed</NW>)? Don’t know, don’t care. There is (unless there <OML>isn’t</OML>†) <QL name="unity">a universal force of consciousness</QL>. The rest of the ‘<NW>fictive level</NW>’ mumbo-jumbo is just the confusing mess of <AL name="hell">separation</AL> that this consciousness must <QL name="polygnosticity">navigate</QL> to transfer (between distanced parts of its <PL name="ick.">system</PL>) energy and <i>information</i> (<LRL>understanding</LRL>.</p>
      <p>Once your <NW>POC</NW> can <LRL>understand</LRL> <i>that</i>, and yet still see the importance of our self-evident <i>consciousness</i>, then you are <WArticle /> <WName ist />.</p>
    </div>,
    bottomFootnote: <p>† If there <i>isn’t</i> a <QL name="unity">unified consciousness</QL>, it means that <LRL name="usandthem">one group</LRL> is <i>right</i> and <LRL name="them">everyone else</LRL> is <i>wrong</i>. Okay, maybe <WName /> is <i>wrong</i>… but at least – under the <WName ic /> <QL>Weltanschauung</QL> – the <NW>atomocentrists</NW> are just <i><NW word="illusions">deluded</NW></i> (or <PL name="sweeping.handful">maybe just</PL> <i><OML>bad</OML></i> for our <QL>body</QL>: <NW word="ig">individually greedy</NW>, or ‘<PL>evil</PL>’).</p>
  },

  // How is the imagination 'self-evident'?
  {
    name: "self-evident",
    prelude: <span>
      ‘But I could say that <i>your face</i>, is self-evident, because I can <i>see</i> is stupid grin.’
    </span>,
    header: <span>
      The imagination is ‘self-evident’.
    </span>,
    outside: <span>
      (Talk about <QL>definitive</QL> statements.)
    </span>,
    preview: <div className="long-read-preview">
      <p>In the end nothing <QL name="qualifiers">may be</QL> ‘<LRL name="i.">real</LRL>’ <i>but</i> our <LRL>imagination</LRL><sup>†</sup> (and the ‘laws’ of <AL name="fields.">physics</AL> that – on this <NW>level</NW> of ‘<LRL name="i.">reality</LRL>’ – seem to <PL name="ick.systems">govern</PL> how <LRL name="scope">our universe</LRL> <AL name="systems">interacts</AL>).</p>
      <p>But how do we <i>know</i> that the <LRL>imagination</LRL> is <i>real</i>? If you can <i>imagine</i> something kind of silly, say a<Rando options={["n energetic", " subdued", " horny", " lost", " dying", " thriving"]} /> <Rando options={["fly", "moth", "horsefly"]} /> <Rando options={["listlessly ", "angrily ", "", ""]} /><Rando options={["buzzing", "flapping"]} /> against the <Rando options={["window", "screen", "shoji", "window", "closed sliding glass door"]} />  <Rando options={["in a shed", "on the enclosed patio", "next to the kitchen table"]} /> <Rando options={[ "in a remote Andean village", "at home", <span>in your <NW word="family system"><Rando options={["mother", "father", "brother", "daughter", "cousin", "grandmother"]} /></NW>’s place</span>]} /> (and you just <i>did</i>, so kudos) then <i>voila</i>, Q.E.D.</p>
    </div>,
    topFootnote: <p>
      † Or – if thinking <NW word="atomocentric">atomocentrically</NW> – one could add an ‘S’: <i>imaginations</i> … though it’s <LRL name="motto">more likely</LRL> that the ‘imagination’ (<LRL name="superpositions">whatever it is</LRL>) is – as everything else – <QL name="unity.1">one and the same</QL> as <LRL name="scope">this whole universe and everything in it</LRL> (cf. the ‘<QL name="unity.answer">answer to everything</QL>’) and maybe even beyond, because the imagination <i>does</i> have a sort of ‘<PL name="all.">level-invariance</PL>’ (once this <NW>level</NW>’s <PL name="ick.">system</PL> of <QL name="unity">consciousness</QL> reach <NW>isognosticity</NW> – or ‘<NW>coalescence</NW>’, or ‘<QL name="gnosticity">gnostic</QL> equilibrium’ – and stop <AL name="illusions">arguing over the rules</AL> of our <LRL>game</LRL>).
    </p>,
  },

  // The imagination is POWERFUL
  {
    name: "power",
    alternatives: "powerful",
    header: <span>
      The imagination is more <i>powerful</i> than you (probably) know.
    </span>,
    outside: <span>
      If we are in <AL>Hell</AL>, the <LRL>imagination</LRL> is our way out.
    </span>,
    preview: <div className="long-read-preview">
      <p><AL name="notnew.lennon">John Lennon</AL> clearly believed in it. <AL name="notnew.">Jesus</AL> (with all those <QL name="metaphors">parables</QL>) clearly believed in it.</p>
      <p>Not to be an <NW word="atomocentrism">atomocentrist</NW>, but would it <QL name="qualifiers">maybe somehow be possible</QL> to <LRL>imagine</LRL> that – if all of <QL>humanity</QL> ditched their <NW>conservative thinking</NW> and <QL name="change">started</QL> to think <QL name="future">forward</QL> – we might (<LRL name="communism">together</LRL>) be able to <NW word="agency">intentionally</NW> build a <OML name="harmony">better world</OML>.</p>
      <p>And, of course, gave up their <PL>privacy</PL>: from the <QL>top-down</QL>, so starting with the billionaires before any of the rest of you have to <PL name="uncomfortable">worry about that one</PL>.</p>
    </div>,
  },

  ],
};


// Orphaned:

/*

<OML name="stupid">maybe one day</OML> your <NW>POC</NW> will be able to imagine a world , without <AL 

    <p>There is the <i>imagination</i> – which is self-evident – and then <PL name="sweeping">absolutely everything</PL> else could be an <NW>illusion</NW>. Maybe even the ‘laws’ are the ‘laws’ of ‘<AL name="fields.">physics</AL>’ that (on this <NW>level</NW> of ‘reality’) seem to <PL name="ick.systems">govern</PL> how <LRL name="scope">our universe</LRL> <AL name="systems">interacts</AL> are also ‘<NW word="illusions">illusive</NW>’. (But <WName /> deals with them because they are self-evidently ‘real’ <i>on this <NW>level</NW></i>; unless you <i>knew</i> that you were inside a <OML>simulation</OML> – and something that you could do something that would convince some <NW>POC</NW> <i>outside</i> the simulation to <i>alter</i> it in a way inconsistent with past observation – we proceed as though this observable universe is the next-lowest <NW>fictive level</NW>: just above true <QL>unity</QL>.)


in short: without without <NW>individual greed</NW> in <PL name="sweeping.ig">any form</PL>.
<p>Once your <NW>POC</NW> has the <PL>courage</PL> to imagine <LRL><WName ic noNW /> communism</LRL> and <i><LRL>understand</LRL></i> how sharing thoughts may not be such a bad (nor <PL>reductive</PL>, nor <OML name="specialisation">over-simplified</OML>, nor <QL name="hivemind.complexity">less complex</QL>) thing, and once you can spread (<E1 />) that to everyone else that you know, <QL>human</QL> society as a whole will <QL>evolve</QL> out of the <NW>Phanerozoic Eon</NW> of <LRL name="gaia">Earth</LRL>’s <AL name="fields.">biology</AL> and <LRL name="gaia">this planet</LRL> is on its way to being <QL name="salvation">saved</QL>.</p>
*/
