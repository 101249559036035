import React from 'react';
import WName, {WArticle} from '../WName';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, Qual, TLDR, Planet} from '../common';

export const levels = [

// Maybe 'Forms' DO exist.
{
  name: "forms",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if ‘Forms’ were <i>real</i>?
  </span>,
  outside: <span>
    <i>Most</i> of <AL name="notnew.">Plato</AL>’s idea mesh well with <WName />. This one, however – depending on how you view the nature of ‘<LRL name="i.">reality</LRL>’ – may not.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Once you admit that there’s an <QL name="objectivism">objective reality</QL> (outside <PL name="all.perspective">each</PL> <NW>POC</NW>’s <QL name="polygnosticity.subjectivity">perception</QL>), it only seems natural (as per <NW word="occam">Occam’s razor</NW>: most simple) to presume that humanity’s <LRL name="game">agreed-upon perception of objects</LRL> (say, roses) and their attributes (say, ‘beautiful’ or ‘good-smelling’) are also <i><LRL name="i.">real</LRL></i>, no?</p>
    <p>Well, either we take a sort of <OML name="authoritarianism">democratic</OML> approach and assume that if the <i>majority</i> of humanity agrees on something (e.g., that this is a ‘rose’, and that roses smell ‘good’), it must be ‘<LRL name="i.">real</LRL>’… or we have to dig down really, <i>really</i> <LRL>deep</LRL> into the nature of ‘<LRL name="i.">reality</LRL>’, stop <AL name="illusions">arguing</AL> over (surface-‘<NW>level</NW>’) <NW>illusions</NW> (i.e., appearances), deal with a <OML name="offensive">cr*p-ton</OML> of <LRL name="superpositions">uncertainty</LRL> (because it’s not yet <i>possible</i> for our <AL name="dysfunctional">messy</AL> and scattered and <NW>polygnostic</NW> <AL name="sleeping">slowly-waking</AL> <QL>conscious</QL> ‘<QL name="body.">super-organ</QL>’ to <QL name="definitive">definitively</QL> <i><LRL name="superpositions">prove</LRL></i> the <OML name="god">non-existence</OML> of a <NW word="mythology">mythical</NW> <QL name="roadblocks.god">external god</QL>) – and <QL name="qualifiers">qualify</QL> a bunch of stuff <LRL name="answers">for this reason</LRL>.</p>
  </div>, 
  text: <div className="or-maybe-text">
    <p>Or of course the ‘<AL name="notnew.plato">Platonic</AL> Forms’ could still be ‘<LRL name="i.">real</LRL>’ at some <NW>level</NW> that’s below <LRL name="scope"><i>our</i> reality</LRL> but still upwards of the <i>bottom</i> (the <AL name="notnew.plato.neoplatonism">Neoplatonic</AL> ‘One’), similar to how <OML>reincarnation</OML> or some <OML>afterlife</OML> could be theoretically possible at this intermediate <NW>level</NW>. The presence of ‘Forms’ as even more ‘<LRL name="i.">real</LRL>’ than the words we use to <E1 /> them doesn’t alter the <OML name="end">ultimate</OML> <QL name="life.meaning">meaning of life</QL>. But it’s just very <i>unlikely</i>.</p>
  </div>, 
  // There should not be a 'sublist'
},

// Maybe we are all just in a STORY
{
  name: "story",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if we were all just characters in a <i>story</i>?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>{TLDR} it <PL name="comprehensive.level-invariant">doesn’t make a difference</PL> for <LRL name="gaia">our</LRL> end <QL>goal</QL> (at this ‘<NW>level</NW>’) in the least, which is still (<OML name="goal">probably</OML>) ‘<NW>coalescence</NW>’ – i.e., reaching conscious <NW>monognosticity</NW> (<QL name="qualifiers">likely</QL> in some form of <QL name="hivemind.mortality">immortal</QL> ‘<QL name="hivemind">hive mind</QL>’), and working (with ‘<AL name="fields.">science</AL>’) to <LRL>understand</LRL> this <QL name="unity.1">universe</QL> that we both inhabit and <i><AL name="self">are</AL></i>.</p>
    <p>The rest is simply outside the <LRL>sscope</LRL> of this <AL>framework</AL> (<NW>synecdocentrism</NW>).</p>
  </div>, 
},

// Maybe we are all just in a COMPUTER SIMULATION
{
  name: "simulation",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if everything were just a <i>computer simulation</i>?
  </span>,
//  outside: <span>
  //</span>,
  preview: <div className="or-maybe-preview">
    <p>This is either the exact same as ‘<OML name="story">What if this were a <i>story</i>?</OML>’ or – if there were truly <OML name="levels">nothing beyond</OML> the computer (as hard as that may be to imagine) – it’s the same as the <LRL name="oneorzero">nihilism</LRL>.</p>
    <p><PL>Simplicity</PL>.</p>
  </div>, 
},

// Maybe we are all just figments of the imaginations of silly aliens
{
  name: "sillyplanet",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if we were just a figment of the (wild) imaginations of <Planet.AlienDescription /> aliens on a planet called <Planet.Name />?
  </span>,
//  outside: <span>
  //</span>,
  preview: <div className="or-maybe-preview">
    <p>Fortunately this is covered by the ‘<PL name="all.level">level-invariance</PL>’ of <WName />. Though the actual <i>chance</i> of that being the case is… <Qual.probably /> humanity hasn‘t <AL name="fields.maths">invented a number</AL> that small.<sup>†</sup></p>
    <p>There may be about as much chance of that as of there being – on some lower but <i><OML name="levels">final</OML></i> <NW>level</NW> – an <OML name="god">external god</OML> made in the ‘<PL name="special">image</PL>’ of <QL>humans</QL> who chose to manifest through the <QL>human</QL> <AL name="container">body</AL> of Jesus… but this <QL>Weltanschauung</QL> tends to like <AL name="notnew.">Jesus</AL> as a <QL name="pg.">mind</QL> (or what is <QL name="pg.mind.">preserved</QL> of it); it was only <QL>humanity</QL>’s later <NW>individual greed</NW> (<PL name="sweeping.ig">of course</PL>) that <PL name="dangerous">corrupted</PL> his message.</p>
  </div>, 
  topFootnote: <p>
    † Actually, given that the aliens’ <AL name="identity">name</AL> and <AL name="container">physical appearance</AL> <QL name="change.site"><i>changes</i> at a whim</QL>, it’s <Qual.probably /> next to impossible for <i>every iteration</i> of this to be simultaneously correct. This is not to say that it’s <i>impossible</i> (because, leaving <LRL name="motto">no stone ‘unturned’</LRL>, it’s still possible that a more <QL name="evolution">advanced</QL> <OML name="evolution">form</OML> of <QL>consciousness</QL> could <OML name="will">choose</OML> <i>not</i> to collapse <AL name="fields.physics">quantum-mechanical</AL> <LRL>superpositions</LRL>, but leave every possibility open… meaning that <PL name="all.perspective">from some perspective</PL> we <i>are</i> figments of the <LRL name="imagination">imaginations</LRL> of <Planet.AlienDescription /> <OML>aliens</OML> on a planet called <Planet.Name />, and <PL name="all.superposition">equally</PL> for <LRL name="motto">every other possible</LRL> name that <QL name="change.site">this thing can produce</QL> and countless more-absurd ones that – given its pseudo-English grammar structure – it <i>cannot</i> (like Planet ‘Zzzxxxeezzz’). See ‘<PL name="all.">superposition</PL>-invariance’.
  </p>,
},

// A rose might just be a rose
{
  name: "levels",
  alternatives: "level",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if a ‘rose’ were just a rose?
  </span>,
  outside: <span>
    And not, you know, just a <i>word</i> which can be used (as a function of <NW>explainage</NW>) by one <NW>POC</NW> to describe (<E1 />) <QL word="polygnosticity">to another</QL> a certain <LRL name="superpositions">observed state</LRL> of the (<AL name="notnew.plato.neoplatonism">one</AL>) universe.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>It would seem that <NW word="occam">Occam’s razor</NW> would cut the <NW>synecdocentric</NW> <E2 word="explanation" /> to shreds, no?</p>
    <p>Or, at least, it <i><QL name="qualifiers">might</QL></i> seem that way from a completely agnostic perspective. But this <QL>Weltanschauung</QL> doesn’t like <QL>agnosticity</QL> very much.</p>
    <p><NW>Synecdocentrism</NW> doesn’t make very many <QL name="definitive">hard-and-fast determinations</QL>… except for maybe about everything in which you’ve ever been ‘invested’: calling that all <i><NW>mythology</NW></i>. But what if (and <LRL>leave no stone unturned</LRL>, imagination (creativity) will be our <QL>salvation</QL>) this <LRL name="superpositions">wishi-washiness</LRL> of <NW>synecdocentrism</NW> were <i>not</i> (as the <QL>Weltanschauung</QL> claims) the ‘bottom’ level of reality.</p>
    <p>It’s similar to, ‘What if there were an <OML name="soul">individual “soul”</OML>?’ (i.e., ‘What if there were an <QL name="external god">external “God”</QL>?’ or, ‘What if <QL name="unity">unity consciousness</QL> were <OML>wrong</OML>’?). The way that <NW>synecdocentrism</NW> deals with that is to presume that anything <QL name="polygnosticity.subjectivity">subective</QL> (i.e., that could have a different but equally ‘real’ interpretation from one <NW>heterognostic</NW> <NW>POC</NW> to another) is just an <NW>illusion</NW>.</p>
  </div>, 
  // Not sure we’ll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if Earth were NOT an 'organism'?
{
  name: "gaia",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if Earth were <i>not</i> a single ‘organism’?
  </span>,
  outside: <span>
    Actually, that’s just a <AL name="framework">way of looking at things</AL>. You <PL name="ick.systems">can’t</PL> really <i>argue</i> that one.
  </span>,
  preview: <div className="or-maybe-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: the ‘<LRL>Gaia hypothesis</LRL>’ is (like <NW>synecdocentrism</NW> itself) only a <i><AL>framework</AL></i> to make sense of the <LRL name="scope">observable</LRL> world (in this case, Planet Earth).</p>
    <p>This is calling into question the ‘<LRL>Gaia hypothesis</LRL>’ that <NW>synecdocentrism</NW> – after claiming that it doesn’t make any <QL>definitive</QL> assumptions (but <OML name="wrong">one</OML>), and strives to ‘<LRL>leave no stone unturned</LRL>’ – just seems to import wholesale and use without questioning it. How is that?</p>
    <p>The thing to understand is that the ‘<LRL>Gaia hypothesis</LRL>’ – at its most basic level (or at least as <NW>synecdocentrism</NW> understands it) – is not actually making any <i>claim</i>. It’s just a <AL>framework</AL> with which to view Planet Earth: as a <i><AL>system</AL></i>.</p>
    <p>This is really all that <AL name="fields.">science</AL> does: deconstruction (<AL name="fields.linguistic">linguistically</AL>: ‘analysis’) to dig to the bottom and find the root <i>cause</i> of things (i.e., <E2 /> them), so as to not be only treating the <i><LRL>symptoms</LRL></i>.</p>
    <p>So the ‘<LRL>Gaia hypothesis</LRL>’ is only about looking at <LRL name="gaiacentrism">Earth</LRL> as a <AL>system</AL>. And – on some level – <PL name="uncomfortable.systems"><i>everything</i> is a system</PL>.</p>
    <p>Including Earth.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

];



