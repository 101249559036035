import React from 'react';
import {wName, wArticle} from '../wName';
import NW from './neologisms/NewWord';

export function WArticle(props) {
  return wArticle(props.cap);
}

// A component that can give a NW for the worldview name (whatever it is):
export default function WV(props) {

  if (!props) {
    return "Error: WV should de used as a component.";
  }

  let cap = (props.cap) ? true : false;

  let end = "ism";
  if (props.ic) {end ="ic";}
  else if (props.ist) {end = "ist";}
  else if (props.ists) {end = "ists";}
  else if (props.ically) {end = "ically";}
  else if (props.end) {end = props.end;}

  let worldviewName = wName(end, cap);

  // The 'noNW' prop will just get the characters without a NW:
  if (props.noNW) {
    return worldviewName;
  }
  else return <NW word="synecdocentrism">{worldviewName}</NW>;

}
