import React from 'react';
//import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
//import OML from '../ormaybe/OrMaybeLink';
//import AL from './AngleLink';
//import NW from '../neologisms/NewWord';
import {rand} from '../common';

export default function SillyNumberGenerator(props) {
  const steps = new Array(rand(4,7,true)*2);
  let totaldigit = 0;
  let maxIncrement = 10;
  for (let i=0;i<steps.length;i+=2) {
    let increment = rand(1, maxIncrement, true);
    maxIncrement *= 100000;
    totaldigit += increment;
    steps[i] = <li>
      <i>Move</i> your <QL name="polygnosticity.">mind</QL> {increment} numbers to the right.
    </li>;
    steps[i+1] = <li>
      <i>Increment</i> the current number ({totaldigit} to the right of the ‘decimal point’) by {rand(1, 9, true)} (cycling from 9 back to 0).
    </li>;
  }

  return <ol>
    <li>
      Write out (<LRL name="imagination">imaginatively</LRL>) your starting number from left to write in base ten.
    </li>
    <li>
      Begin with your ‘<QL name="polygnosticity.">mind</QL>’ at the ‘ones’ place (just to the left of the ‘decimal point’).
    </li>
    {steps}
  </ol>;
}


