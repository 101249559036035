import React from 'react';
import {faqs} from './list';
import Question from './Question';
// import './AllFAQs.css';

export default function AllFAQs(props) {
  const allFAQs = faqs.map((q, i) => <Question data={q} key={i} />);
  return <div id="all-faqs">
    {allFAQs}
  </div>;
};
