import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OpenLetter from './pages/OpenLetter';
import Gist from './pages/Gist';
import Answer from './pages/Answer';
import Neologisms from './pages/Neologisms';
import Word from './pages/neologisms/Word';
import AllWords from './pages/neologisms/AllWords';
// import Explainage from './pages/Explainage';
import FAQ from './pages/FAQ';
import AllFAQs from './pages/faq/AllFAQs';
import Question from './pages/faq/Question';
import PotentialProblems from './pages/PotentialProblems';
import Problem from './pages/problems/Problem';
import AllProblems from './pages/problems/AllProblems';
import Angles from './pages/Angles';
import Angle from './pages/angles/Angle';
import AllAngles from './pages/angles/AllAngles';
import OrMaybes from './pages/OrMaybes';
import OrMaybe from './pages/ormaybe/OrMaybe';
import AllOrMaybes from './pages/ormaybe/AllOrMaybes';
import LongReads from './pages/LongReads';
import LongRead from './pages/longreads/LongRead';
import AllLongReads from './pages/longreads/AllLongReads';
import Layout from './Layout';
import Opening from './Opening';
import Menu from './Menu';
import Warning from './Warning';
import E404 from './E404';
import './index.css';
// import reportWebVitals from './reportWebVitals';

function RoutedApp(props) {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Opening />} />
        <Route path="menu" element={<Menu />}>
          <Route index element={<Warning />} />
          <Route path="neologisms" element={<Neologisms />}>
            <Route index element={<AllWords />} />
            <Route path="all" element={<AllWords all />} />
            <Route path=":word" element={<Word />} />
          </Route>
          {/*<Route path="explainage" element={<Explainage />} />*/}
          <Route path="faq" element={<FAQ />}>
            <Route index element={<AllFAQs />} />
            <Route path=":name" element={<Question />} />
          </Route>
          <Route path="problems" element={<PotentialProblems />}>
            <Route index element={<AllProblems />} />
            <Route path=":name" element={<Problem />} />
          </Route>
          <Route path="angles" element={<Angles />}>
            <Route index element={<AllAngles />} />
            <Route path=":name" element={<Angle />} />
          </Route>
          <Route path="ormaybe" element={<OrMaybes />}>
            <Route index element={<AllOrMaybes />} />
            <Route path=":name" element={<OrMaybe />} />
          </Route>
          <Route path="longreads" element={<LongReads />}>
            <Route index element={<AllLongReads />} />
            <Route path=":name" element={<LongRead />} />
          </Route>
          <Route path="gist" element={<Gist />} />
          <Route path="answer" element={<Answer />} />
          <Route path="openletter" element={<OpenLetter />} />
        </Route>
        <Route path="*" element={<E404 />} />
      </Route>
    </Routes>
  </BrowserRouter>;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RoutedApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
