import React from 'react';
import WName, {WArticle} from '../WName';
import Eon from '../Eon';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Qual, the_key_is_imagination, get_out_there} from '../common';

// Privacy is a great evil.
export const privacy = {
  name: "privacy",
  alternatives: "secret,secrets",
  header: <span>
    <i>Privacy</i> (and the keeping of ‘secrets’) is a great evil.
  </span>,
  outside: <span>
    Privacy is a <LRL name="causes">caused</LRL> by the <NW>polygnosticity</NW> of our <QL>consciousness</QL>… and it is a form of – <PL name="sweeeping.ig">incidentally</PL> – <NW>individual greed</NW>.
  </span>,
  preview: <div className="problem-preview">
    <p><TLDR />: This is <Qual.probably /> the single most <QL>urgent</QL> issue (<QL name="roadblocks">roadblock</QL>) with <LRL name="gaia">Earth</LRL>’s <AL name="sleeping">burgeoning</AL> <QL>consciousness</QL>: and one that won’t only be <PL name="hard">hard</PL> to <LRL>understabd</LRL>, but also <LRL name="deep">profoundly</LRL> <PL>uncomfortable</PL> to <i><LRL name="communism">accept</LRL></i>; cf. ‘<PL name="denial">It’s tempting to go into denial.</PL>’ Privacy (as a form of <NW>individual greed</NW>) isn’t just <WName ically /> ‘<PL>evil</PL>’ but actively (or ‘<LRL name="reactivity">reactively</LRL>’) <i><OML name="kaput">killing</OML> our <LRL name="gaia">planet</LRL></i>; it’s keeping <LRL name="gaiacentrism">Earth</LRL> <QL>consciousness</QL> held apart (i.e., locked in <WName ic /> ‘<AL>Hell</AL>’). However, the <QL name="polygnosticity">problem</QL> (and <QL>urgency</QL> thereof) is <Qual.likely /> to be least <LRL>understood</LRL> by <AL name="handicap">humanity</AL>: <NW word="conservatism">conservatives</NW> and ‘<PL name="neoliberals">liberals</PL>’ alike.</p>
    <p>To try to <E1 /> why <i>privacy</i> is such a <QL name="pg.privacy">problem</QL> in <QL>humanity</QL>’s (<AL>sleeping</AL>) ‘<QL name="body.">super-organ</QL>’ of the <QL>conscious</QL> <QL>body</QL>, an <QL name="salvation">imaginative</QL> <LRL>thought experiment</LRL> is <LRL name="i.power">called for</LRL>.</p>
  </div>,
  readMore: "Well, then let’s do this. (Click the link to read the thought experiment.)",
  text: <div className="problem-text">
    {/* Think how <AL>dysfunctional</AL> it is for one part of the ‘<QL>body</QL>’ not to <i>know</i> what another part is doing. That’s basically the state of our <NW>polygnostic</NW> conscious <QL name="body.super-organ">body-part</QL> on <LRL name="gaia">Earth</LRL>. So <LRL name="answers">why</LRL> do we put up with this?</p>
    <p>It probably has to do with the fear of <i>not <PL name="pg.mind.">knowing</QL></i> what some <NW>heterognostic</NW> ‘other’ will do (i.e., the lack of <i><QL name="gnosticity.">intercommunication</QL></i>), which is a symptom of <QL>polygnosticity</QL> that’s ensured by the barriers of privacy.</p> */}
    <p>The thought experiment is this: if ‘<AL name="notnew.lennon.war">War is over if we want it,</AL>’ then why <i>doesn’t</i> <QL>humanity</QL> want to end it – seeing as intra-planetary (and, by the extensions of <PL name="all.">scale</PL>- and <PL name="all.level">level-invariance</PL>, intra-<i><QL>conscious</QL></i>) war is roughly the equivalent of a single <NW>monognostic</NW> <NW>POC</NW> cutting off its nose to spite its face?</p>
    <p>Using that <QL>metaphor</QL>, ask yourself: if <i>you</i> (as a single <NW>POC</NW>) were essentially ‘<OML>God</OML>’ in this world – and you had control (within the <AL name="fields.">physically</AL> <i>feasable</i>) over <i>everything</i> in the whole planet: taking away the <OML>free will</OML> of any of the Earth-<AL>system</AL>’s components; you alone have <NW>agency</NW>) – would you keep Earth as it is, or make everyone live in <OML>harmony</OML> with each ‘<LRL>other</LRL>’ and with ‘<PL>nature</PL>’, and advance your <LRL>understanding</LRL> of the whole planet?</p>
    <p>Let’s rephrase that: this is not to say that you suddenly have control over 8,000,000,000 separate ‘<LRL>others</LRL>’ who each formerly had their own <PL>individual</PL> ‘<OML>soul</OML>’. In this thought experiment, there are no ‘<LRL>others</LRL>’. There is only a single <QL>consciousness</QL> (<NW>POC</NW>) <LRL name="gaiacentrism">on Earth</LRL>: <i>you</i>; and all of the various components are just like the cells of <i>your</i> body – so you can flex them in whatever way you want. They are (in this world) just <i>your</i> many fingers: every human being (with whatever <LRL name="communism">capabilities and disabilities</LRL> each <AL name="container">body</AL> has), every <OML>animal</OML>, everything that would have had its own <NW>heterognostic</NW> awareness is (in this thought experiment) unified in <i>you</i>.</p>
    <p>And you have to imagine that your <QL name="pg.">mind</QL> can think fast enough to account for all of thier needs simultaneously. Think of it as playing a video game, like Age of Empires or Starcraft, except you can spend as long as you like planning your next move and there is nothing within the planet not known to you unless it’s unexplored by <i>any</i> part of <LRL name="gaia">Earth</LRL> <QL>conscousness</QL>.</p>
    <p>Are you going to use your 8,000,000,000+ ‘fingers’ to fight and kill each other, rape and abuse each other, form groups of <LRL name="usandthem">‘us’ and ‘them’</LRL> and oppress each other based on ‘race’ or ‘nationality’, and have them wage the <OML name="patriarchy">dick-measuring contest</OML> of the <NW>MNS system</NW> just because you <i>can</i>? Or are you going to have them work in <OML>harmony</OML> to <QL name="evolution">build</QL> a (<Eon Eon dash />) civilisation that can bring your planet’s <QL>consciousness</QL> (and <LRL>understanding</LRL>) – in <PL name="ai">whatever</PL> <AL>container</AL> – to Mars, Jupiter and beyond?</p>
    <p>Okay, this site doesn’t ask rhetorical questions, but… if you would torture yourself for no reason, maybe just go back to the Collosseum and <i>don’t</i> be a <AL name="notnew.plato.kings">philosopher-king</AL>. Hopefully you would not pointlessly torment your <QL name="conscious">unconscious</QL>, <QL name="pg.mind">mindless</QL>, and <NW>agency</NW>-deprived ‘fingers’. Hopefully you would use every part of your <QL name="hivemind">hive mind</QL> not to mutilate yourself but to conduct research and <AL name="fields.">science</AL> to try to truly understand your ‘<AL>self</AL>’ (as the ‘<OML>Earth-soul</OML>’) and the <LRL name="scope">observable universe</LRL> you inhabit.</p>
    <p>And you don’t have to worry about <i>death</i>. Remember: you are a <QL name="hivemind">hive mind</QL>. As long as there is a single <QL>human</QL> (or <QL>conscious</QL> <OML>animal</OML>, or <QL>conscious</QL> <OML>robot</OML>) <PL name="systems">functioning</PL> on <LRL name="gaia">Earth</LRL>, you ae effectivelt <QL name="Hivemind.mortality">immortal</QL>.</p>
    <p>Okay, start over. Changing the terms of the <LRL>thought-experiment</LRL> a little: this time you are not manifested <i>alone</i>. You now must share the Godhood with one <NW>heterognostic</NW> ‘<LRL>other</LRL>’. (And no, you don’t <QL name="pg.mind.">know</QL> their thoughts; that’s the definition of <NW>heterognosticity</NW>.)</p>
    <p>Both of you have control over exactly 50% of <LRL name="gaiacentrism">Earth</LRL> <QL>consciousness</QL>. Both of you <QL name="hivemind">hive minds</QL> are equally intelligent. (In the real world, <PL>equality</PL> may be a lie, but for this <LRL>thought experiment</LRL> just go with it.) So what has changed? Assuming that you both still want the best for the whole planet, there shouldn’t be a problem, right? You can work things out…</p>
    <p>Well, yes… but it’s <i>harder</i> now. You have to <i>trust</i> that this ‘<LRL>other</LRL>’ (whose <QL name="polygnosticity">separate</QL> and <NW>heterognostic</NW> <QL name="pg.">mind</QL> your <NW>POC</NW> does <i>not</i> <QL name="pg.mind.">know</QL>) will not suddenly send all of <i>their</i> ‘fingers’ to murder yours in their sleep (which would, in effect, ‘kill’ you in this scenario). Your <QL name="hivemind.mortality">immortality</QL> is no longer guaranteed. Suddenly there’s a huge chunk of the planet (namely: 50%) that is <i>out of your gaze</i>.</p>
    <p>One easy solution would be to agree with this other <OML>God</OML>-figure that you will live in ‘<QL>radical openness</QL>’ together, and neither will hide the doings of and of their ‘fingers’ from the ‘other’. Hopefully you can convince the other that this would be so much better than living in constant fear and distrust, and that working <i>together</i> you can manage to expand your consciousness to the stars and potentially even beyond, rather than killing yourselves by wasting all of your planet’s resources on an intra-planetary <AL name="notnew.lennon.">war</AL>.</p>
    <p>Okay, crisis averted. Now back up, repeat, and imagine that instead of two separate <NW>POCs</NW> there were <i>three</i> of you…</p>
    <p>Hopefully you can see where this is headed now. To understand how intentionality and <NW>agency</NW> can fall apart in the face of <QL>conscious</QL> <NW>polygnosticity</NW> (and each <NW>POC</NW> just looking after its limited sense of ‘self’) read ‘<LRL name="notions">This is the battlefield of ideas and notions.</LRL>’</p>
    <p>Meanwhile…</p>
    {the_key_is_imagination}
    {get_out_there}
  </div>,
};
