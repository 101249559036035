import React from 'react';
import {eonName, eraName} from '../eName';
import NW from './neologisms/NewWord';

// A component that can give a NW for the eon name (whatever it is):
// e.g. <Eon Eon dash /> might give 'Mechanozoic-Eon',
// <Eon era ally /> might give 'Eomechanically'.
export default function Eon(props) {

  if (!props) {
    return "Error: 'Eon' should de used as a component.";
  }

  let end = "";
  if (props.ally) {end = "ally";}
  if (props.Eon) {
    end = (props.dash) ? "-Eon" : " Eon";
  }
  else if (props.end) {end = props.end;}

  let display = (props.era) ? eraName() : eonName();
  display += end;

  // The 'noNW' prop will just get the characters without a NW:
  if (props.noNW) {
    return display;
  }
  else return <NW word="mechanozoic">{display}</NW>;

}
