//import React from 'react';

/********************************************
 * Randomises the name of 'synecdocentrism' *
 ********************************************/

// Options:
const options = [
  {
    name: "synecdo",
    langs: ["Greek", ""],
    words: ["συνείδηση", "κεντρική"],
    meanings: ["consciousness", "centred"],
    transcriptions: ["‘seen-EE-thee-see’: ", "‘kent-ree-KEE’: "],
  },
  {
    name: "synecdeso",
    langs: ["Greek", ""],
    words: ["συνείδηση", "κεντρική"],
    meanings: ["consciousness", "centred"],
    transcriptions: ["‘seen-EE-thee-see’: ", "‘kent-ree-KEE’: "],
  },
  {
    name: "apophaso",
    words: ["απόφαση", "κεντρική"],
    langs: ["Greek", ""],
    meanings: ["decision", "centred"],
    transcriptions: ["‘ah-PO-fah-see’: ", "‘kent-ree-KEE’: "],
    article: "an",
  },
  {
    name: "gnoso",
    langs: ["Greek", ""],
    words: ["γνώση", "κεντρική"],
    meanings: ["knowledge", "centred"],
    transcriptions: ["‘[G]NO-see’: ", "‘kent-ree-KEE’: "],
  },
  {
    name: "volyo",
    langs: ["Russian or Ukranian", "Greek"],
    words: ["воля", "κεντρική"],
    meanings: ["will – as in ‘free will’", "centred"],
    transcriptions: ["VO-lya: ", "‘kent-ree-KEE’: "],
  },
  {
    name: "agento",
    langs: ["English", "Greek"],
    words: ["agency/agent", "κεντρικός"],
    meanings: [null, "centred"],
    transcriptions: [null, "‘kent-ree-KOAS’: "],
    article: "an",
  },
];

// Global variable to pick it out of the array:
var chosen = null;

function choose() {
  // Make more complex later
  chosen = 0;
return 0;
  //  if (Math.random() < 0.5) {
    chosen = Math.floor(Math.random()*options.length);
    document.title = wName("ism", true);
  //}
  return chosen;
}

// Component for displying the etymology:
export function WEtymology(props) {
  if (chosen === null) {
    chosen = choose();
  }
  let ourWord = options[chosen];
  if (!ourWord) return "[Error fetching word]";
  let transcriptions = [null, null];
  if (props.transcriptions || Math.random() < 1/Math.E) {
    transcriptions = ourWord.transcriptions;
  }
  return <span>
    {ourWord.langs[0]} <i>{ourWord.words[0]}</i>
      {ourWord.meanings[0] && <span>
        {" "}({transcriptions[0]}{ourWord.meanings[0]})
      </span>}
    {" "}and {ourWord.langs[1]} <i>{ourWord.words[1]}</i>
      {ourWord.meanings[1] && <span>
        {" "}({transcriptions[1]}{ourWord.meanings[1]})
      </span>}
  </span>;
}

/* OUTPUT THE SELECTED NAME FOR THE THING */
export function wName(end="ism", cap=false) {
  if (chosen === null) {
    chosen = choose();
  }
  let firstPart = options[chosen].name;
  if (!firstPart) {
    console.log("error fetching name", chosen, firstPart);
    return "[error fetching name]";
  }
  if (cap) {
    firstPart = firstPart.charAt(0).toUpperCase() + firstPart.slice(1);
  }
  let worldviewName = firstPart + "centr" + end;
  return worldviewName;
}

export function wArticle(cap=false) {
  if (chosen === null) {
    chosen = choose();
  }
  let article = (options[chosen].article) ? options[chosen].article : "a";
  if (cap) {
    article = article[0].toUpperCase() + article.substr(1);
  }
  return article;
}
