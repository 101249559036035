import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import LRL from '../LongReadLink';
import OML from '../../ormaybe/OrMaybeLink';
import NW from '../../neologisms/NewWord';
import {E1, E2} from '../../common';

export const gaiahypothesis = {
  name: "gaia hypothesis",
  alternatives: "gaia",
  prelude: <span>
    Earth’s <NW word="atomocentrism">atomocentrists</NW> need to stop scrutinising the <i><AL name="dysfunctional.fragments">cells</AL></i>, and start to zoom out.
  </span>,
  header: <span>
    Planet Earth is a single, living ‘organism’.
  </span>,
  outside: <span>
    It’s a single living and conscious animal that is <AL name="sleeping">slowly <i>waking</i></AL>.
  </span>,
  preview: <div className="long-read-preview">
	       <p>It’s called the ‘Gaia hypothesis’. And under <NW>synecdocentrism</NW> it <i><AL name="framework.">works</AL></i> – <QL name="qualifiers">unless of course</QL> this is all <OML>wrong</OML>, and there actually exists (outside of the <NW>illusion</NW> of it) some<i>reality</i> to <QL name="polygnosticity.subjectivity">subjective</QL> <OML name="levels">perception</OML> – a lot better than other ways of <E2 suffix="ing" /> this <AL>mess</AL>.</p>
    <p>And <LRL name="gaiacentrism">it’s not just <i>Earth</i></LRL> – obviously – but for humans who are still so mired in the <NW>illusion</NW> of <PL>individuality</PL> the <i>first</i> step is to <AL name="self">stop seeing their ‘selves’ as somehow separate from the rest</AL> – and admit (as even the <PL>neoliberals</PL> know) that we’re all in this together.</p>
  </div>,
  text: <div className="long-read-text">
    <p>The ‘Gaia hypothesis’ is seeing Earth itself as a living organism. Which <NW>synecdocentrism</NW> clearly <i>does</i>… but (of course) <LRL name="different">in its own way</LRL>. Pointedly without the (<NW word="conservatism">conservatively-thinking</NW>) idea that ‘life’ must be <i>biological</i>.</p>
    <p>[Under construction]</p>
  </div>,
};
