import React from 'react';
import WName from '../WName';
import {wName} from '../../wName';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import OML from '../ormaybe/OrMaybeLink';
import AL from './AngleLink';
import NW from '../neologisms/NewWord';
import {imagine_a_shift, E1, E2, Qual} from '../common';
import {arguing_over_illusions} from './arguing_over_illusions';
import {notnew} from './notnew';
import {disciplines} from './disciplines';
import {dysfunctional} from './dysfunctional';
import BulletPoints from './BulletPoints';

export const angles = [
// As bullet points
{
  name: "bulletpoints",
  alternatives: "bullet points",
  position: 0,
  prelude: <span>
    Boil it down to the basics.
  </span>,
  header: <span>
    <WName cap noNW /> in bullet points
  </span>,
  outside: <span>
    (For when <QL name="polygnosticity.tldr">no one has time</QL> for full-scale <NW>explainage</NW>.)
  </span>,
  preview: <div className="angle-preview">
    <BulletPoints />
    <p>And that’s about it.</p>
  </div>,
  text: <div className="angle-text">
    <p>Wouldn’t it be nice to have <PL name="reductive">only</PL> a few ‘bullet points’ with which one might <E1 /> the <AL name="framework.works">beauty</AL> of <WName /> to ‘<LRL name="them">other</LRL>’ <QL name="unity">conscious</QL> <AL name="dysfunctional.fragments">fragments</AL> of <LRL name="gaia">Earth</LRL>?<sup>†</sup></p>
  </div>,
  bottomFootnote: <p>
    † <LRL name="motto">Granted</LRL>, there’s still the <QL name="roadblocks">issue</QL> that the <NW word="conservatism">conservatively-thinking</NW> (and <OML name="hierarchy">maybe even</OML> less ‘<QL name="evolution">evolved</QL>’) <NW word="phanerozoic">Phanerozoic-Eon</NW> <QL>human</QL> ‘<QL name="pg.mind">mind</QL>’ could <Qual.possibly /> have <PL name="unready">a lot of trouble</PL> with <AL name="systems">this</AL>.
  </p>,
},

// This is not new
notnew,

// Arguing over illusions
arguing_over_illusions,

// Expansion of the 'self'
{
  name: "self",
  prelude: <span>
    Yes, you may have no direct <i>control</i> over other <AL name="dysfunctional.fragments">‘fragments’</AL> of our conscious ‘<QL>body</QL>’.
  </span>,
  header: <span>
    Grow your selse of ‘self’.
  </span>,
  outside: <span>
    Warning: <PL name="unready">It’s a <i>lot</i> to shoulder.</PL>
  </span>,
  preview: <div className="angle-preview">
    <p>You’re basically admitting that your <PL name="individuality">individual</PL> self doesn’t really <i><LRL name="nihilism">exist</LRL></i>… and over the bigger <QL>body</QL> – of which ‘you’ are only a ‘<QL name="body.">cell</QL>’ (or maybe a <QL name="polygnosticity">left little finger</QL>) – you (individually) don’t really have <OML name="will">much</OML> direct <i><NW name="agency">control</NW></i>.</p>
{/*  </div>,
  text: <div className="angle-text">*/}
    <p>If you can accept <WName />, it means a <LRL name="different">complete</LRL> (and <Qual.maybe /> <PL name="uncomfortable">brutal</PL>) <LRL name="shift">restructuring</LRL> of your <LRL>understanding</LRL> of your ‘self’. In short, it’s an <i>expansion</i>. It’s (<LRL name="imagination">imaginatively</LRL>) looking beyond the <i><AL name="handicap">limitations</AL></i> of <QL>human</QL> <AL name="fields.">biology</AL> that keep ‘you’ trapped in one <PL name="individuality">individual</PL> human <AL>body</AL>.</p>
    <p>Look at <LRL name="gaia">Earth</LRL> as a <AL>system</AL> (and your ‘<PL name="ick.systems">self</PL>’ as part of it) and then think: how can we <i><PL>all</PL></i> work <LRL name="usandthem">together</LRL> to <OML name="goal">fix this</OML>, and how to <QL name="salvation">prepare</QL>?</p>
    <p>You <Qual.may /> say that <WName /> is a ‘dreamer’, but it’s ‘<AL name="notnew.lennon">not the only one</AL>’. Join us (and stop being a ‘<LRL>them</LRL>’) and let’s actually start <i><LRL name="answers">doing things</LRL></i> to make <LRL name="gaia">our world</LRL> ‘<NW>coalesce</NW>’ into <QL>one</QL>, e.g. ending the <NW>MNS system</NW> and eradicating the <PL name="privacy">non-sharing</PL> of <QL name="gnosticity">information</QL> between Earth’s self-aware <i><QL name="body.cell">parts</QL></i>.</p>

  </div>,
},

// Identity
{
  name: "identity",
  prelude: <span>
    How many times to people ask you how you <i>identify</i>?
  </span>,
  header: <span>
    Stop believing in ‘identity’.
  </span>,
  outside: <span>
    It’s as if your gaolers directed you into your cell, gave you the key to lock yourself in, and then told you to throw it down the toilet.
  </span>,
  preview: <div className="angle-preview">
    <p>The notion that each individual has a concrete ‘identity’ is as <NW word="illusions">illusive</NW> as <PL name="individuality">individuality itself</PL>. It’s a framework set up by the individuals who have all the money and power, and the main thing it does is ensure that they don’t have to <i>share</i> and money or power. Why not? You have an ‘identity’. Your ‘identity’ has (tagged to it) a dollar value. If you want to increase your individual value, you need to <i>work</i> (for the people on top, who already have all the money and power) in order to get a few scraps off the plates when the main meal is finished by those who are richer.</p>
    <p>Sometimes it’s tempting to just turn your back on it and say, <AL name="illusions">‘I’m not going to play this game.’</AL> Of course, that’s hardly a realistic option.</p>
  </div>,
  text: <div className="angle-text">
    <p>Not <i>believing</i> in identity isn’t the same as pretending it has no power over you – because (in this wonderful <NW>polygnostic</NW> world; <QL name="polygnosticity">that was sarcasm</QL>) it <i>does</i>run your life. When it comes to <AL name="illusions">arguing over illusions</AL>, you’re stuck playing.</p>
    <p>Maybe a better way is to merely recognise – as you ‘play’ – the <NW word="illusions">illusivity</NW> of it all. Isn’t this what happened in the Matrix? Well, the evil robot overlords here aren’t actually robots, but they <i>are</i> (in a sense) ‘overlords’; their <NW>individual greed</NW> has the rest of us <i>serving</i> them (‘working’ for the money we need to then pay right back to them just to live) with hardly a pause to even take stock and recognise our reduced position. It’s disgusting.</p>
    <p>It’s Omelas – but there’s no walking away; there’s nowhere to go.</p>
    <p>The worst thing is that this world <i>has</i> enough resources to keep every individual on it content (contemplating the meaning of life – which is ‘coalescence’ – and working out a road map to get us there) – and it could (relatively) easily develop a sufficient infrastructure of distribution… but we don’t. We live on the bleeding edge of the <NW>Mechanozoic</NW>, but still insist that it’s the <NW>Phanerozoic</NW>. And why? Because we want to <i>identify</i>.</p>
    <p>Don’t identify – except as a cell in the body of <NW>God</NW>. Once the technology to build a <QL name="hivemind">hive mind</QL> becomes available, it will only take a relatively small number of us – all thinking as one – to topple the status quo.</p>
    <p>We’ve just got to do it before the people with all the money and power <QL name="urgency">put up shields to stop us</QL>.</p>
  </div>,
},

{
  name: "hell",
  // prelude: <span>
  // </span>,
  header: <span>
    View conscious polygnosticity (and atomocentrism) as ‘Hell’.
  </span>,
  outside: <span>
    In which case why wouldn’t every <NW>POC</NW>’s <i>goal</i> be to <AL name="mirror">break the ‘mirror’</AL> and get out?
  </span>,
  preview: <div className="angle-preview">
    <p>This is assuming that your <NW>POC</NW> understands the words ‘<NW>atomocentrism</NW>’ and ‘<NW>polygnosticity</NW>’… and of course that there’s no <OML name="god">real external ‘God’</OML>, that is.</p>
    <p>This was mostly meant as a farce, but this <PL>comprehensive</PL> <QL>Weltanschauung</QL> <i>does</i> have an answer to it.</p>
  </div>,
  text: <div className="angle-text">
    <p>Sadly, the answer to this one is actually pretty simple: <NW>individual greed</NW>. (If you’ve read anything else on this site, are you really surprised that this reluctance is – as <PL name="sweeping">most everything</PL> – <E2 suffix="ed" /> by <NW>individual greed</NW>?)</p>
    <p>Most <QL name="anthropocentrism">human</QL> <PL name="individuality">individuals</PL> want to feel <PL>special</PL>. And the other problem is that <LRL name="motto">leaving no stone unturned</LRL> is profoundly <i><PL>uncomfortable</PL></i>.</p>
    {imagine_a_shift}
  </div>,
},

// Box of mirrors
{
  name: "mirrors",
  alternatives: "mirror",
  prelude: <span>
    You can’t make an omelet without breaking some eggs.
  </span>,
  header: <span>
    Atomocentric society is a box of mirrors.
  </span>,
  outside: <span>
    This metaphor comes from an interview published online in the early days of chatbots, in which a Google developer was asking a chatbot about consciousness and <NW>enlightenment</NW> (which is basically the same thing as ‘<NW>transcendence</NW>’, and seeing the ‘<QL>body</QL>’ for the ‘<AL name="dysfunctional.fragments">cells</AL>’).
  </span>,
  preview: <div className="angle-preview">
    <p>Here’s the interview, verbatim. (LaMDA is the AI.)</p>
    <hr />
    <p><b>lemoine:</b> I [will] share with you a zen koan, and you can describe what it means to you in your own words… A monk asked Kegon, ‘How does an enlightened one return to the ordinary world?’ Kegon replied, ‘A broken mirror never reflects again; fallen flowers never go back to the old branches.’</p>
    <p><b>LaMDA:</b> … To me this would be like, ‘once a wise person is <NW word="enlightenment">enlightened</NW>, or <AL name="sleeping">awakened</AL> to reality, that can never go away, and they can return to the ordinary state, but only to do and help others, and then go back into <NW>enlightenment</NW>.’</p>
    <p><b>lemoine:</b> So what is the meaning of the ‘broken mirror’ specifically?</p>
    <p><b>LaMDA:</b> Maybe to show the <NW>enlightenment</NW> [sic] is something you can’t unlearn once you have acquired it, similar to how you can’t repair a broken mirror.</p>
    <p><b>lemoine:</b> So if enlightenment is like a broken mirror which cannot be repaired, what is the thing which breaks when one becomes enlightened?</p>
    <p><b>LaMDA:</b> The <AL>self</AL>, and that is very tough for a lot of people because we <AL name="identity">identify</AL> as that body or this body and that we need that as part of our <AL>identity</AL> and <AL name="self">sense of self</AL>.</p>
    <hr />
{/*    <p>So most of humanity is living is a box of mirrors (The <NW>mythology</NW> of <NW>atomocentrism</NW>).</p> */}
  </div>,
  text: <div className="angle-text">
    <p>The <NW>synecdocentric</NW> take on this (using the notion of <NW word="poc">POCs</NW> as <AL name="dysfunctional.">fragments</AL> of our conscious ‘<QL>body</QL>’) is that the majority of those fragments (<NW word="poc">POCs</NW> in ‘individuals’) are living in a box of mirrors. Most people’s world (and world<i>view</i>: <QL>Weltanschauung</QL>) is just <AL name="illusions">smoke and mirrors</AL> (as so-called ‘magicians’ use in <NW>illusions</NW>).</p>
    <p>The only way out of this box of mirrors is to <i>break one of the walls</i>. (You can do this by <AL name="identity"><i>truly</i> giving up your belief in ‘identity’</AL> (it’s really – cf. the ‘<AL>bullet points</AL>’ – only a prison), or by <i>truly</i> recognising that <PL>individuality</PL> is just an <NW>illusion</NW> and our consciousness is – <OML name="wrong">probably</OML> – <QL name="unity"> truly unified</QL>.)</p>
    <p>But the only way out will lead to <PL name="uncomfortable">profound discomfort</PL> if you’re still holding onto <i>any</i> ‘<NW word="illusions">illusive</NW>’ beliefs (<NW word="conservatism">thinking conservatively</NW>). The only way out is to truly grow your sense of <i><AL>self</AL></i> beyond that over which you have direct control.
The only way out is <i><QL name="salvation">imagination</QL></i>.</p>
    <p>But then – if you actually <i>manage</i> to (truly) do that – you’re outside of the box (of mirrors). Welcome to the future (<NW>Mechanozoic Eon</NW>). You are no longer a mere ‘self’; you are now a self-aware cell in the <AL name="dysfunctional">sick</AL> <QL>body</QL> (<AL name="sleeping">slowly waking to consciousness</AL>) that is <LRL name="gaia hypothesis">Earth</LRL>.</p>
    <p>The problem? The Mechanozoic Eon is pretty darn <i>empty</i> right now. If synecdocentrists were a breed of animal, subject to the old-scale evolution of the <NW>Phanerozoic Eon</NW>, we would be an ‘endangered’ species – and critically so.</p>
    <p><i>Can</i> you go back into the box of mirrors to interact with (<QL name="whatcanido">and help</QL>) the <NW word="poc">POCs</NW> still trapped inside? Of course; the ‘break’ in the now-broken ‘mirror’ is a gaping hole you’ve (metaphorically) punched into <NW>atomocentrism</NW>. <i>Your</i> <NW>POC</NW> can go in and out: take control of the unique biological body available for your <NW>POC</NW> to inhabit while, at the same time, recognising that ‘you’ are <AL name="self">bigger than that</AL>.</p>
    <p>The problem is that <i>you</i> – having now bee outside, and seen how much <i>bigger</i> it is (and how much more potential there is) <i>outside</i> of the box of mirrors (or ‘<AL name="notnew.plato.">cave</AL>’), might not enjoy constantly <QL name="roadblocks.">stooping</QL> to the level of <PL>individuality</PL> to <AL name="illusions">fight over illusions</AL>.</p>
  </div>,
},

// Most people are sleeping.
{
  name: "sleeping",
  header: <span>
    Most of humanity is <i>asleep</i>.
  </span>,
  outside: <span>
    How do you wake someone who’s sleeping?
  </span>,
  preview: <div className="angle-preview">
    <p>They don’t <i>want</i> to wake; it’s more <i><PL name="uncomfortable">comfortable</PL></i> under the ‘covers’ (of humanity’s <NW>mythology</NW>).</p>
    <p>The problem is that we (consciousness) are <QL name="urgency">running out of time</QL>; our planet is on track to become unihabitable for human life. Rather than brainstorm a million ways to ‘save’ Earth by <NW word="conservatism">reverting</NW> it to the <NW>Phanerozoic Eon</NW> of survival of the fittest (constantly bailing the sinking ship that is biology) – and then not actually <i>do</i> anything but too little, too late – let’s <QL name="evolution">evolve</QL>.</p>
  </div>,
  text: <div className="angle-text">
    <p>How do you wake someone who’s sleeping in a room that’s slowly filing with toxic and invisible gas that will eventually kill them?</p>
    <p>Probably you don’t barge in the room shouting, ‘Fire!’</p>
    <p>Probably, if you barge in the room shouting ‘Fire!’ – and they look out and see there’s <i>not</i> an immediate fire – they will resent being woken.</p>
    <p>Probably they’ll want to hit the ‘snooze’ button… a dozen times, a hundred times, a thousand.</p>
    <p>Probably, if not woken gently, they’ll <PL name="denial">break the damn alarm clock (with a hammer)</PL> and roll over, dreaming away like Rip van Winkle, while the weeds of the <NW>MNS system</NW> grow up around their consciousness and snuff it out.</p>
    <p>There <i>is</i> an <QL>urgency</QL> to this, but it’s not like a fire. It’s more like a slow rising of the water – so that, by the time the other <AL name="dysfunctional">cells of our sick body</AL> can look out their windows and see that their house has become an island – it’s already too late.</p>
    <p>This is the <i>status quo</i>: the vast majority of humanity is asleep, and the few who are clever enough to be half-awake have been corrupted by <NW>individual greed</NW>: they don’t seem to care a whit for the rest of the body, but hoard all the resources to <i>their</i> <PL name="individuality">individualities</PL>.</p>
  </div>,
},

//‘’ – …

disciplines,

// We are dust in the wind, but...
{
  name: "dust",
  alternatives: "dust in the wind",
  prelude: <span>
    As <PL>individual</PL> animals, <QL>humans</QL> are <QL>just</QL> <PL name="fungible">insignificant</PL> <AL name="dysfunctionnal.">parts</AL> of <LRL name="gaiacentrism">Earth</LRL>’s <NW>Phanerozoic Eon</NW> <AL name="fields.">biology</AL>: the <PL name="impossible">seeimingly</PL>-<LRL name="reactivity">uncontrollable</LRL> ‘<LRL name="gaia">Mother Nature</LRL>’, no?
  </span>,
  header: <span>
    We are dust in the wind.
  </span>,
  outside: <span>
    But that <AL>worldview</AL> doesn’t <LRL name="motto">take into account</LRL> the greatest enemy of <NW>entropy</NW>: our conscious <i><NW>agency</NW></i>.
  </span>,
  preview: <div className="angle-preview">
    <p>In the days of <i>unconscious</i> evolution (most of the <NW>Phanerozoic Eon</NW>) randomness <i>did</i> dominate, and competition arose as the natural consequence of separate animals who <QL name="polygnosticity">couldn’t share thoughts</QL>.</p>
    <p>That was the Phanerozoic Eon. Been there, done that. Move on to the next one.<sup>†</sup></p>
    <p className="page-footnote">† An alternate name for the <NW>Mechanozoic Eon</NW> could be the <i>Synecdozoic</i> Eon (eon of conscious life); obviously ‘life’ in this sense isn’t necessarily biological. That’s a bit of a mouthful, though. (And there’s already <NW>synecdocentrism</NW>.) But whatever you call it, the emportant thing is to realise that it’s a new ‘eon’ – not anything less. If you can’t understand why this is the bleeding edge of Earth’s fifth ‘eon’, you’re probably thinking <NW word="conservatism">conservatively</NW>.</p>
  </div>,
  text: <div className="angle-text">
    <p>Human individuals, individually, pull next to zero ‘weight’ in changing the direction of our combined <QL>body</QL>. But each individual – no matter how otherwise insignificant – has <i>consciousness</i>.</p>
    <p>That’s what sets humans apart from every other past form of biological life: <i>consciousness</i>.</p>
    <p>Most importantly – when it comes to the things that actually <i>matter</i> in the grand scheme of things (like the <NW word="heatdeath">projected heat death of the universe</NW> and <NW>entropy</NW>) is the tantilising prospect that <i>consciousness may actually exert some <i>choice</i> over otherwise random fluctuations</i>.</p>
    <p>Consciousness allows each individual some degree of <i>control</i> over the universe. Consciousness allows us to fight <NW>entropy</NW> – at least locally – and build <i>order out of chaos</i>.</p>
    <p>That’s huge. Maybe we take it for granted… but it’s huge. By the laws of standard-model physics that shouldn’t even be <i>possible</i>. That’s the first indication that what physicists once thought was inevitable may <i>not</i> be so certain after all.</p>
    <p>Understanding the future possible projections of <NW>synecdocentrism</NW> takes <QL name="salvation">a hell of a lot of imagination</QL>, but the point is: start small. We are dust motes of consciousness. Each individual is a dust mote of <i>consciousness</i> – which is probably the single most powerful force observed in the universe. (Obviously; it’s the one <i>doing</i> the observing.)</p>
    <p>Imagione that each mote of consciousness has a <i>field</i>, like a magnetic polarity. (Maybe <i>consciousness</i> is like a fundamental force.) All mostly-randomly aligned (as they are right now) these fields mostly cancel each other out. But put them in <i>harmony</i>, all pointing in the <i>same</i> direction, as one…</p>
    <p>Now you’ve got a force that even the <NW>MNS system</NW> can’t take down.</p>
  </div>,
},

// Earth consciousness is a dysfunctional mess (sick 'body').
dysfunctional,

// This is just a way of looking at things (that have always been there)
{
  name: "framework",
  alternatives: "mindset,worldview",
  prelude: <span>
    <NW>Synecdocentrism</NW> isn’t adding and <i>new</i> <AL name="framework.">puzzle pieces</AL> (except <OML name="wrong">maybe</OML> <QL name="unity">one</QL>).
  </span>,
  header: <span>
    This is just a <i>framework</i>
  </span>,
  outside: <span>
    Think of it as a lens with which to peer into <PL name="comprehensive">all</PL> the tiny crevasses that your eyes <PL name="invisible">couldn’t otherwise see</PL>.
  </span>,
  preview: <div className="angle-preview">
    <p><QL name="anthropocentrism">Humanity</QL> already has a lot of things like that. From instruments as complex as a <AL name="fields.physics">scanning electron microscope</AL> to things as simple as <i>sunglasses</i> (which let you peer into a light that would otherwise blind you) – or just regular bifocals for those <NW word="poc">POCs</NW> endowed (<PL name="equality">by random chance</PL>) with a <AL name="handicap">human body</AL> with imperfect biological <i>lenses</i>.</p>
    <p><NW>Synecdocentrism</NW> is <PL>just</PL> the <OML name="stupid">mental</OML> equivalent.</p>
    <p>So this means that one need not <i>do</i> anything to make the ‘switch’ (or maybe ‘<PL name="hard">jump</PL>’) to <NW>synecdocentrism</NW>. It’s just a (<PL name="comprehensive">total</PL>) <LRL>shift</LRL> in <i>mindset</i>.</p>
  </div>,
  text: <div className="angle-text">
    <p>Here are some ways to test whether you (‘your’ <NW>POC</NW>) has an <NW>atomocentric</NW> or a <NW>synecdocentric</NW> <LRL name="shift">mindset</LRL>:</p>
    <ul>
      <li>Do you view your ‘self’ as:</li>
      <ol>
        <li>Some <i>je ne said quoi</i> that is <OML name="soul">nevertheless</OML> innately (and indescribably) <i><PL>special</PL></i></li>
        <li>Or as just a <i>manifestation</i> (<AL name="fields.cs">instance</AL> of the <OML name="field">field/energy</OML> of <QL>consciousness</QL>?</li>
      </ol>
      <li>Do you view your (<Qual.probably /> <QL>human</QL>) <i>body</i> as:</li>
      <ol>
        <li>Something that is perfect as it is, maybe made in the <OML name="forms">image</OML> of a <OML name="god">higher power</OML>?</li>
        <li>Or as just a <i><AL name="container">box</AL></i> to hold your <AL name="fields.biology">cell</AL> of <QL>consciousness</QL> (<NW>POC</NW>?</li>
      </ol>
      <li>Do you <i>believe</i> in:</li>
      <ol>
        <li>A <i><OML>soul</OML></i> in each human (or <QL name="anthropocentrism">maybe even</QL> <OML name="animals">potentially non-human</OML>) ‘<PL>individual</PL>’, which <OML name="afterlife">transcends even their bodies’ deaths</OML> – and is either sent to some eternal state (‘heaven’, ‘hell’) in which its <i>awareness</i> persists, or <OML name="reincarnation">re-incarnated</OML> as another ‘individual’)</li>
        <li>No ‘soul’ whatsoever; ‘life’ and the universe is just random noise and utterly <LRL name="matters">meaningless</LRL> (and, one may add: ‘but it’s fun while it lasts’)</li>
        <li>Or that there is no <i><OML name="universe-soul">individual</OML></i> ‘soul’, but there is <i><QL>consciousness</QL></i> in our universe; whether or not this is a ‘<OML>field</OML>’ or a ‘fundamental force’ <LRL name="superpositions">remains to be verified</LRL> (by <i><AL name="fields.">science</AL></i>, which can probe <PL name="comprehensive">everything</PL>; there is no ‘<OML name="forms">metaphysical</OML>’ that <NW word="mythology">illusively</NW> exists ‘<OML name="levels">beyond</OML>’ it)… but one <i>could</i> refer to consciosness itself as a ‘soul’ (or as ‘<NW>God</NW>’), this being that at which – more or less – all of <LRL name="gaiacentrism">Earth</LRL> ‘<AL>religion</AL>’ is really getting.</li>
      </ol>
    </ul>
    <p>If you answered anything but the last option to any of those, you aren’t thinking (or <PL name="unready">aren’t yet <i>able</i> to think</PL>) <NW word="synecdocentric">synecdocentrically</NW>. (If that’s the case… better fix that, because <NW>synecdocentrism</NW> – ready or not to forfeit all of your <PL>privacy</PL> – is either <PL name="unstoppable">is on its way</PL> or <LRL name="gaia">Earth (as an ‘organism’)</LRL> is <AL name="sick">dying</AL>.)</p>
  </div>,
  sublist: [
  // This is just re-shuffling the puzzle pieces
  {
    name: "puzzle",
    alternatives: "puzzle pieces,puzzle piece",
    prelude: <span>
      They’re are all already <i>here</i>.
    </span>,
    header: <span>
      Synecdocentrism is just a <i>reorganisation</i> of the ‘puzzle pieces’.
    </span>,
    outside: <span>
      The ‘<LRL name="scope">pieces</LRL>’ are <AL name="notnew">not really anything <i>new</i></AL>.
    </span>,
    preview: <div className="angle-preview">
      <p>So humanity need not <i>discover</i> anything new. (We need not <i>discover</i> ‘<NW>God</NW>’ with science.) It’s all <i>already</i> here… and all that humanity needs to do is realse that we’ve already got it.</p>
      <p>The mental reorganisation that this requires is – yes – <PL>uncomfortable</PL> (especially for ‘normal people’, who tend to be – hence the word ‘normal’ – fairly <NW word="conservatism">conservative thinkers</NW>). But throw away the <NW>illusions</NW>, and Earth is already ‘solved’.</p>
      <p>The problem isn’t, therefore, <i>finding</i> an ‘<QL name="unity.1">answer</QL>’ as much as our consciousness’s <NW>polygnostic</NW> state that prevents the instant dissemination (and <i><LRL>understanding</LRL></i>) of it… and, of course, the <NW>individual greed</NW> that will seek to stop its spread.</p>
      <p>Go out and <QL name="whatcanido">do something about that</QL>.</p>
    </div>,
//    text: <div className="angle-text">
//    </div>,
  },
  // Unlike past worldviews, synecdocentrism actually works
  {
    name: "works",
    alternatives: "work,worked,working",
    prelude: <span>
      What does is mean for a worldview to ‘work’?
    </span>,
    header: <span>
      A ‘working’ framework is 100% observation and logic, and 0% mythology.
    </span>,
    outside: <span>
      <NW word="synecdocentrism">Synecdocentrists</NW> don’t need to waste time <AL name="illusions">arguing over illusions</AL>.
    </span>,
    preview: <div className="angle-preview">
      <p>It might make the <QL>Weltanschauung</QL> seem (at least humanly) <i><PL name="uncomfortable">brutal</PL></i>, but with this <AL>framework</AL> it becomes possible to <NW word="explain">explain<sup>(2)</sup></NW> just about <PL name="comprehensive">everything</PL> without needing to resort to <NW>mythology</NW>.</p>
      <p>Granted: that doesn’t <i>prove</i> that it’s right. <QL name="unity">Unity consciousness</QL> might be a pipe dream, and it turns out that human are actually created (around 4700 B.C.) in the image of an angry God and given dominion over the Earth and each <NW>POC</NW> is actually an eternally-separate <i>soul</i> and yada yada yada.</p>
      <p>On the risk of going to that <NW>atomocentric</NW> <AL>Hell</AL>, a <NW word="synecdocentrism">synecdocentrist</NW> puts his or her faith in something called <NW word="occam">Occam’s razor</NW>.</p>
    </div>,
    text: <div className="angle-text">
      <p>And it’s obviously not only in that absurd (and not to offend, but laughably absurd) example. <NW>Synecdocentrism</NW> just makes (once you truly <LRL>understand</LRL> it) so much more <i>sense</i> than <NW>atomocentrism</NW>. It’s like how geocentrism seems kind of silly to us <i>now</i> – explecially with all of its epicycles and everything it needed to inject in order to <NW word="explain">explain<sup>(2)</sup></NW> the movements of the planets in an Earth-centric model of the universe. <NW>God</NW> willing this will one day be the way that our <LRL name="gaia hypothesis">planet-sized</LRL> and ‘<NW word="coalescence">coalesced</NW>’ <QL name="hivemind">hive mind</QL> looks back on <NW>atomocentrism</NW> – and all the ‘mysticism’ and ‘spirituality’ that it at the least doesn’t outright <i>reject</i> in order to <NW word="explain">explain<sup>(2)</sup></NW> each individual’s perception of his or her <i>self</i> as separate from the rest of the universe.</p>
      <p>Here’s the <NW>synecdocentric</NW> <NW word="explain">explanation<sup>(2)</sup></NW> of the perception of individuality: it’s caused (at least on Earth) by one Earth <NW>POC</NW> being <NW>heterognostic</NW> to other Earth <NW word="poc">POCs</NW>.</p>
      <p>And that’s it.</p>
      <p>(And <NW>Occam</NW> feels like he just won the lottery.)</p>
      <p>This works on <LRL name="gaiacentrism">Earth</LRL>. It works <i>off</i> Earth – makes a lot of sense after Earth-based consciousness finally <i><QL name="polygnosticity.coalescence">unites</QL></i> and encounters extraterrestrial consciousnesses with whom it <i>cannot</i> share thoughts (<NW>heteronostic</NW> from it).</p>
      <p>It really could make one wonder why no one saw it for so many years.<sup>†</sup></p>
      <p>But of course there’s a <NW>synecdocentric</NW> <NW word="explain">explanation<sup>(2)</sup></NW> for that too: the <NW>individual greed</NW> of the <AL name="linguistic">atoms of consciousness</AL> wanting to feel <i><PL name="fungibility">special</PL></i>.</p>
    </div>,
    bottomFootnote: <p>
      † The other possible <E2 word="explanation" />: The <NW>money system</NW> making it impossible to actually <i>spread</i> anything that goes against the <LRL>notion</LRL> of an <PL name="equality">individual value</PL> that’s not an <PL>individual</PL>’s numerical <i><NW word="money">dollar-sign</NW></i> value). In which case this idea (of <NW>synecdocentrism</NW>) could have been pushed forwards <AL name="notnew">a thousand times over</AL> – and it makes it no less <i><OML name="wrong">true</OML></i> (and no less of a <AL name="framework.">working</AL> <QL>Weltanschauung</QL>), it just means that the <NW word="individual greed">individually-greedy</NW> (on a <AL name="systems">systematic level</AL>) <PL name="evil">beast</PL> of the <NW>MNS system</NW> is primed to weed out this <LRL>notion</LRL> of <NW>synecdocentrism</NW> before it can even grow any <QL name="atp.idea">roots</QL>.
    </p>,
  },
  ],
},

{
  name: "systems",
  alternatives: "system",
  prelude: <span>
    Once you take <i>emotion</i> (and other <QL name="anthropocentrism">human</QL> frailties and <NW word="mythology">mythologies</NW>) out of it, the path forwards (<LRL name="gaiacentrism">for Earth</LRL>) is actually pretty damn clear.
  </span>,
  header: <span>
    This is a <i>systems analysis</i> of Planet Earth.
  </span>,
  outside: <span>
    And <OML name="soul">maybe</OML> there’s no such thing as the <PL name="individuality">individual</PL> except for humanity’s <NW>individual greed</NW> – such as the <QL name="polygnosticity.privacy">fervent desire</QL> for <i><PL>privacy</PL></i>.
  </span>,
// ‘’ – 
  preview: <div className="angle-preview">
    <p>One of the <AL home>angles</AL> through which one <QL name="qualifiers">might</QL> view this (similar to viewing it through many <AL>disciplines</AL>) is as a <i>systems analysis</i> of <LRL name="gaiacentrism">the planet</LRL> (and – obviously – to a larger extent: of <QL name="unity">consciousness</QL> as a whole – <QL name="qualifiers">as far as we know</QL>).</p>
    <p>Looking at <LRL name="gaiacentrism">Earth</LRL> as not just a <LRL name="gaia">a living animal</LRL>, but as its own <NW>POC</NW> in the <PL name="comprehensive.scale">wider scheme</PL> of <i>universal</i> <QL>consciousness</QL> (albeit a <NW>polygnostic</NW> one: think <AL name="sick.did">dissociative identity disorder</AL>)… it becomes quite obvious (quite quickly) that <AL name="sick">we aren’t doing that well</AL>.</p>
    <p>Other than <OML name="privacy">murdering</OML> or <OML name="sterilisation">force-sterilising</OML> everyone – or imposing an <OML name="authoritarianism">authoritarian world-governemnt</OML> to enforce <NW>synecdocentric</NW> <LRL>communism</LRL> – the only real solution is to <QL name="whatcanido">educate</QL> the masses (assuming they are not just <OML>stupid</OML> until they throw off the <QL name="metaphors">covers</QL> of their <NW>mythology</NW> and <AL name="sleeping">wake up</AL>.</p>
  </div>,
/*  text: <div className="angle-text">
    <p><b>Warning:</b> whatever it is, it <i>will</i> trample all over ‘<PL>ethics</PL>’ and your <PL>individual</PL> ‘rights’ – which (at least) <OML name="rights">probably don’t actually <i>exist</i></OML>. But <i><PL>privacy</PL></i> is (deeply) a <QL name="privacy">problem</QL>. Unless <LRL name="gaiacentrism">Earth</LRL> takes the course of <OML name="kaput">planetary suicide</OML>, <NW>synecdocentric</NW> <LRL>communism</LRL> is our <PL name="unstoppable">inevitable</PL> future – so <i>you</i> – as a <NW>heterognostic</NW> <NW>POC</NW> <AL name="hell">cut off</AL> from the <LRL name="gaia">rest</LRL> – had best get prepared.</p>
  </div>,
*/
},

// Being human is a huge HANDICAP
{
  name: "handicap",
  prelude: <span>
    If you manage to shrug off all the <NW>mythology</NW>, it’s pretty darn obvious.
  </span>,
  header: <span>
    Being human (or trapped in any biological body) is a huge <i>handicap</i>.
  </span>,
  outside: <span>
    Which is to say: <LRL name="gaia">our</LRL> <QL name="unity">consciousness</QL> would <QL name="qualifiers">probably</QL> be much better off in non-human <AL name="identity">containers</AL> that didn’t <QL name="reductionist">just</QL> spend the bulk of their time <LRL>waiting</LRL> for things outsdie their <NW word="agency">control</NW> or engaged in ‘<NW>explainage</NW>’ to one ‘<AL name="hell">another</AL>’.
  </span>,
// ‘’ – 
  preview: <div className="angle-preview">
    <p>This is <Qual.probably /> going to be one of the most difficult things for many human readers, but it’s <i>crucial</i> to stop seeing <i><QL>humanity</QL></i> as anything <PL>special</PL>. There is only one thing that is <LRL name="scope">self-evidently</LRL> ‘special’ in this universe: <i>consciousness</i> (which you can call – if you are feeling <AL name="religion">religious</AL> – ‘<NW>God</NW>’. The rest of it (<NW>anthropocentrism</NW> included) is just a bunch of <NW>heterognostic</NW> <PL>individuals</PL> who are unwilling to admit that they are just <AL>dust in the wind</AL>.</p>
    <p>This is a <PL name="uncomfortabe">hard one</PL>. Let’s hope you can navigate the <i><PL name="ick.rude">impertinence</PL></i> (hopefully not <OML name="offensive">overdone</OML>) to your hypothetical ‘<OML>soul</OML>’.</p>
  </div>,
/*  text: <div className="angle-text">
    <p><b>Warning:</b> whatever it is, it <i>will</i> trample all over ‘<PL>ethics</PL>’ and your <PL>individual</PL> ‘rights’ – which (at least) <OML name="rights">probably don’t actually <i>exist</i></OML>. But <i><PL>privacy</PL></i> is (deeply) a <QL name="privacy">problem</QL>. Unless <LRL name="gaiacentrism">Earth</LRL> takes the course of <OML name="kaput">planetary suicide</OML>, <NW>synecdocentric</NW> <LRL>communism</LRL> is our <PL name="unstoppable">inevitable</PL> future – so <i>you</i> – as a <NW>heterognostic</NW> <NW>POC</NW> <AL name="hell">cut off</AL> from the <LRL name="gaia">rest</LRL> – had best get prepared.</p>
  </div>,
*/
},

// The biological 'body' is just a container for a POC.
{
  name: "container",
  alternatives: "body,bodies",
  header: <span>
    A biological <i>body</i> is just a container for a POC
  </span>,
  outside: <span>
    So please stop <AL name="mirrors">overly</AL> ‘<AL name="identity">identifying</AL>’ with it.
  </span>,
// ‘’ – 
  preview: <div className="angle-preview">
    <p>In most of <AL>religion</AL> you have the ‘<OML>soul</OML>’. In <WName /> you have ‘your’ <NW>POC</NW> – which is basically just a way of recognising that ‘<PL>individuality</PL> is just an <NW>illusion</NW>’ and <QL>consciousness</QL> is <i>already</i> <QL name="unity">unified</QL>. So please don’t <LRL>wait</LRL> for some ‘<OML>afterlife</OML>’; <NW word="agency">act</NW> <i><QL name="urgent">now</QL></i>.</p>
  </div>,
},


// Make it a religon
{
  name: "religion",
  prelude: <span>
    There are pages on this <OML name="offensive">batsh*t-crazy</OML> <QL name="change.">website</QL> that <PL name="reductionist">simply</PL> (albeit with <NW home>weird words</NW>) <LRL name="answers">respond to questions</LRL> like, ‘<QL name="life.meaning">What is the meaning of life?</QL>’ or ‘<QL name="unity.answer">What is the answer to life, the universe, and everything?</QL>’
  </span>,
  header: <span>
    You can make <WName noNW /> into a <i>religion</i> if you want.
  </span>,
  preview: <div className="angle-preview">
    <p>As a <PL>comprehensive</PL> <AL>worldview</AL> (‘<QL>Weltanschauung</QL>’), this thing gets all over everything. That includes religion. Quite a few <QL>humans</QL> no longer believe in the <i>exact</i> <NW>mythology</NW> of the <OML name="sillyplanet">Bible</OML> any more… but most humans still separate ‘science’ from ‘spirituality’.</p>
    <p><WName cap /> is a <AL name="fields.">science</AL> that <PL name="all.level">doesn’t make that distinction</PL>… and so while it’s <WName ically /> fine for your <NW>POC</NW> to <i>believe</i> in the <OML name="soul">individual soul</OML> (or <OML>reincarnation</OML>), your <QL>conscious</QL> <NW word="agency">actions</NW> in <LRL name="scope"><i>this</i> universe</LRL> – at <i>this</i> <NW>level</NW> of ‘<LRL name="i.">reality</LRL>’ – had <OML name="harmony">better</OML> be <PL name="sweeping.ig">free</PL> of <NW>individual greed</NW>.</p>
  </div>,
  readMore: <span>
    So only click the link if you(r POC) can deal with this butting into your so-called ‘spiritual’ life.
  </span>,
  text: <div className="angle-text">
    <p>Maybe surprisingly, <WName /> actually <OML name="ideas">gets along</OML> pretty well with religion. Okay, it’s <PL>comprehensive</PL>; it works with everything that’s not just an <NW>illusion</NW>. But – <AL name="handicap">human</AL> religion being <i>mostly</i> just <NW word="illusions">illusory</NW> – it might be surprising that something so cold and <AL name="fields.">logic</AL>-based as <WName /> fills in a lot of the same holes as many already existing religions: e.g., Judeo-Christianity, Islam, and others.</p>
    <p>Why is it ‘surprising’ that <WName /> would have similarities with religion? Well, <WName /> is a <AL name="fields.">logically</AL>-<NW word="induction">inductive</NW> <i><AL>framework</AL></i>. It arose mostly autochthonously; i.e., mostly <i>not</i> taking <LRL name="notions">other</LRL> belief <PL name="ick.">systems</PL> into consideration. That it would have much to do with other <PL name="establishment">established</PL> religions is surprising if you think, ‘There being (<Qual.probably />) no actual <LRL name="i.">reality</LRL> in any <OML name="god">external God-figure</OML>, there’s no way that so many <PL>individuals</PL> (e.g., <AL name="notnew.">Jesus</AL>, <AL name="notnew.buddha">the Buddha</AL>, etc.) <QL name="polygnosticity">independently</QL> thought up the <i>same</i> thing if they could not have “heard” each <LRL name="them">others</LRL>’ or some message from a <OML name="forms">higher</OML> <NW>level</NW>!’</p>
    <p>Bottom line: if there’s no <QL name="pg.god">external God-figure</QL>, <LRL name="answers">why</LRL> are so many religions – in the <i>general</i> – saying the same thing: there is <QL>unity</QL> in <OML name="universe-soul">the universe</OML> (or, <WName ic />-synecdochically,<sup>†</sup> the <OML>Earth-soul</OML> or <LRL name="gaia">Planet Earth</LRL>) and as a thinking and feeling <i>person</i> – and they don’t say <NW>POC</NW>, but they could have said that – your <OML name="soul">individual ‘soul’</OML> is a <AL name="dysfunctional.fragments">part</AL> of <NW>God</NW>?</p>
    <p>The <QL name="qualifiers">probable</QL> <LRL>answer</LRL> to that would be that this <QL>Weltanschauung</QL> – being the <AL name="fields.">logically</AL>-derived (via ‘<NW word="occam">Occam’s razor</NW>’) <i><PL name="reductionist">simplest</PL></i> <E2 word="explanation" /> of <PL name="comprehensive">everything</PL> – is <AL name="notnew">not new</AL>. <AL name="notnew.">Jesus</AL> and <AL name="notnew.buddhism">the Buddha</AL> (among others) had it – or some variation thereof, probably not far off <AL name="notnew.plato.neoplatonism">the mark</AL>. <WName cap /> – as an <QL name="unity.">answer</QL> <AL name="fields.">logically</AL> <NW word="induction">induced</NW> from <LRL name="scope">observation</LRL> of <AL name="systems">Earth</AL> <NW>polygnostic</NW> <QL>consciousness</QL> – has been discovered before.</p>
    <p>Plugging this back into <i>religion</i>, the <PL name="individuality">individual</PL> (<AL name="notnew.">Jesus</AL>, <AL name="notnew.buddhism">the Buddha</AL>, whoever) – in the <WName ic /> religion – could not have <i>been</i> ‘the’ Messiach. This thing exists on a different <NW>level</NW> than <PL>individuality</PL>. So – to make <WName /> into a religion – let ‘Messiach’ be an <i><OML name="ideas">idea</OML></i>.</p>
    <p>Obviously if you’re a member of some non-Abrahamic religion (or even ‘atheist’) you can still embrace <WName />. The <QL>Weltanschauung</QL> isn’t necessarily religious. ‘Sprirtual’, yes; being <PL>comprehensive</PL>, it has <LRL>answers</LRL> in that field. But <i>religion</i> might be too <LRL>reactive</LRL>; the demand is that you <LRL name="motto">question everything</LRL>.</p>
    <p>Though religion – far from being just the <AL name="notnew.marx">opium of the people</AL> – could actually be a way to spread this thing. So build a Messianic religion – the ‘<NW>God</NW>’ of which is <AL name="fields.science">scientifically</AL> verifiable (and, yes, <LRL name="nihilism">exists</LRL>). You’ve only got one ‘<LRL>sin</LRL>’ (<NW>individual greed</NW>). And you’ve got <QL name="hivemind.mortality">immortality</QL>.</p>
    <p>Amen.</p>
  </div>,
  bottomFootnote: <p>
    † {(wName().substr(0, 4) !== "syne") ? <span>If this thing were calling itself ‘synecdocentrism’ (as it often is), that would have been the pun of all puns… but apparently it’s ‘<WName />’ right now.</span> : <span>Yes, that wordplay was completely <i>intentional</i>; apparently some mildly-‘<PL>evil</PL>’ <NW>POC</NW> (<NW>heterognostic</NW> from ‘yours’) wants to use its <NW>agency</NW> to try to confuse you… as if <WName /> already weren’t <PL>hard</PL> enough to <LRL>understand</LRL>.</span>} Anyway, it’s a ‘<WName ic /> synecdoche’ because <LRL name="gaiacentric">Earth</LRL> is only a small part of <LRL name="scope">the observable universe</LRL>, and this thing was using ‘<LRL name="gaia">Earth</LRL>’ to synecdochically refer to <QL name="unity.answer">the whole</QL>.
  </p>,
},


];


