import React from 'react';
import WName, {WArticle} from '../WName';
import Eon, {EonArticle} from '../Eon';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Qual, Rando, SillyName} from '../common';

export const hive_minds = {
  name: "hivemind",
  alternatives: "hive mind,hive minds",
  parentName: "hive minds",
  prelude: <span>
    In case you’re <PL name="uncomfortable">terrified</PL> of the <LRL>notion</LRL> of ‘<NW>coalescence</NW>’, maybe you ought to <LRL name="imagination">consider</LRL>:
  </span>,
  question: <span>
    What would a ‘hive mind’ actually <i>be</i> like?
  </span>,
  clarification: <span>
    You know, <i>experientially</i>?
  </span>,
  answerPreview: <div className="answer-preview">
    <h3>This entire section is undergoing a major remodel that may take a while</h3>
    <p>A possible fear is that – roughly analogous to ‘<QL name="polygnosticity">board meetings</QL>’ and other <PL name="ick.">systems</PL> set up in <NW>companies</NW> and other ‘<QL name="body.">organs</QL>’ of our <QL name="change">current</QL> <LRL name="gaia">Earthly</LRL> <QL>consciousness</QL> – you (or, as part of a ‘hive mind’, the part that is ‘you’ would have some brilliant <OML>idea</OML> but <i>nobody <LRL name="others">else</LRL> will listen to you</i> or they all <PL name="corrupt">screw it up</PL>. (You know, the fears for any <QL>human</QL>-based ‘<WName ic />’ <NW>POC</NW> who <AL name="handicap">has to</AL> use an <PL>individual</PL> ‘<AL>identity</AL>’ to <QL name="whatcanido">go out</QL> and start preaching <WName />.)</p>
    <p>This is not the case. Or it <i>should not</i> be the case; if this ends up being the case, <QL>humanity</QL> is <AL name="hell">screwed</AL> because we still have some form of intra-conscious <PL>privacy</PL> (i.e., <AL name="sick.">DID</AL>). Otherwise the key thing to understand about a ‘hive mind’ is that if you’re part of it – it’s not some ‘<LRL name="them">other</LRL>’ that’s <i>separate</i> from <PL name="individuality">your individual ‘self’</PL>. This is an <i>actual</i> (i.e., not merely <LRL name="imagination">imaginative</LRL>) <AL name="self">expansion of the ‘self’</AL>.  It <i>is</i> you, and you <i>are</i> it.</p>
{/*    <p>When your So you won’t have the experience of – as 
    <p> what would it mean to end our consciousness’s <NW>polygnosticity</NW>?</p>*/}
  </div>,
  readMore: <span className="warning-span">
    The notion of a ‘hive mind’ (monognostic consciousness) is probably going to be the single most misunderstood notion of <WName noNW /> (and ‘coalescence’). If there’s any part of you that thinks that a transition to a hive mind would be a <i>downgrade</i>, please click the link, read the sub-list of FAQs, and really try to understand why a shared consciousness <i>is</i> the eventual goal.
  </span>,
  answer: <div className="answer-text">
    <p>As a hive mind, you would <AL name="self">become one with your neighbour</AL>. The hive mind (‘you’) would simultaneously <i>know</i> every one of your neighbour’s individual personal concerns AND every one of ‘your’ individual personal concerns – and regulate the two lives accordingly.</p>
    <p>If, say, your neighbour had a mother who was ailing, and it was more beneficial for you both for your ex-individuality to <i>not</i> go skiing that weekend, but stay home and make some phone calls to help out your neighbour,<sup>‡</sup> then this is what would happen. You would both understand that this is what’s happening, and why. And you would be happy to do that – because you have become one mind with your neighbour, so that’s ‘your’ mother too. (Odds are the hive mind wouldn’t actually remember – or care – which individual body used to be whose; see <QL name="hivemind.mortality">the section on hive mind immortality</QL>.)</p>
    <p>And it’s not going to fall into the problem of limited processing power – like you have on 2020’s computers, where computers must employ ‘threading’ and halt the execution of some tasks while devoting their computing power to the execution of others. Think of a human brain as a CPU: central processing unit. In a hive mind configuration, ‘your’ individuality and your neighbour’s individuality still have two separate <i>brains</i>, each able to compute on its own; a ‘hive mind’ only means that your brains share thoughts, and are connected.</p>
    <p>The hive mind would be able to simultaneously manage the life of every individuality that joined into it: each individual providing one brain (CPU), so that tasks are not done in sequence, but in parallel (tandem). The only difference is that there would be no time wasted on <i><NW>explainage</NW></i> – which endeavour seems to occupy the bulk of many individual-to-individual interactions.</p>
    <p>Lastly, maybe you fear that the hive mind would be like being in some massive organisation or commune, where <i>your</i> individual <QL name="whatcanido">voice</QL> is drowned out by the hordes of others. No. The way that you perceive your ‘self’ as a distinct <i>part</i> of society, but society itslef as something bigger than that, is decidedly <i>not</i> the case here. You are not just a part of the hive mind. <AL name="self">You <i>are</i> the hive mind.</AL> The hive mind (<i>your</i> mind) would just <i>know</i> every one of your individual concerns,* just as it would <i>know</i> the concerns of everyone else who’s a part of it.</p>
  </div>,
  bottomFootnote: [
  <p> ‡ This is an absurd scenario. Probably – in a world with the technology to combine into a hive mind – no one would be making <i>phone calls</i>; phone calls are <PL>just</PL> <NW>explainage</NW> (and a symptom of <NW>polygnosticity</NW>). It’s just to make a point that <PL name="unready">someone</PL> <LRL name="eons">still living</LRL>in the <NW>Phanerozoic Eon</NW> <Qual.might /> <LRL>understand</LRL>.</p>,
  <p> * Better said, it would know every personal concern your body had <i>before</i> you joined. After you join, that’s no longer a <i>special</i> body for you. ‘You’ (as the hive mind) would have many bodies. There wouldn’t be any internal <PL>individuality</PL>.</p>,
  ],
  sublist: [
  {
    name: "loss",
    question: <span>
      Is there anything I would <i>lose</i> by joining a hive mind?
    </span>,
    answerPreview: <div className="answer-preview">
      <p>Other than the <PL name="individuality">illusion of individuality</PL>,<sup>‡</sup> you lose the joy of spending the bulk of your time with your ‘friends’ engaged in <NW>explainage</NW>.</p>
    </div>,
    answer: <div className="answer-text">
      <p>The one thing you <i>don’t</i> get to do, if you joined a hive mind, is <QL name="hivemind.breakaway">back out</QL>: leave it and reinstate ‘your’ <PL>individuality</PL>.</p>
      <p>If a hive mind is to be successful, there must be no way to ‘back out of it’ (even if just for a spell) and put up <PL>privacy</PL>-shields to re-isolate your ‘self’ from the collective. That’s <NW>individual greed</NW>: wanting your ‘own’ experiences (and/or thoughts) that aren’t shared with the whole.</p>
      <p>However, that’s only a sort of fear one would have <i>thinking</i> about joining a hive mind. If you actually <i>managed</i> to join a hive mind, you aren’t merely a part of the hive mind. <QL name="hivemind.self">Your sense of ‘self’ would <i>expand</i></QL> – and ‘you’ would actually be one conscious body with many arms, many legs, many separable bodies, and many brains (all thinking in harmony, as one).</p>
      <p>Breaking away shouldn’t even  be <i>possible</i>. When you <i>enter</i> a hive mind, there are no more <i><QL name="polygnosticity.privacy">secrets</QL></i>. You <AL name="self">would no longer have a separate sense of ‘you’</AL> (from your sense of self <i>as the hive mind</i>).</p>
      <p>Let’s make an analogy: would you, in the human body you currently inhabit, want to compartmantalise your ‘self’ into just a single organ? Say the right eye? You would cede control of the rest of the body (and, in the process, lose the bulk of your memories and cognative ability) and only exist in the eye. You could see (when the body didn’t close its right eyelid – over which ‘you’ would have no direct control) but not hear, but not small, but not control the body’s motion. The best you could do was <i>suggest</i> things for it to do – based on your knowledge as the right eye – but you’d only be able to make suggestions using <NW>explainage</NW> (and <i>language</i>)… and the rest of the body, able to share thoughts without need for <i>communication</i>, probably wouldn’t make time for ‘you’.</p>
      <p>That’s what ‘separating’ from a hive mind would be like. (You wouldn’t want to do it.)</p>
{/*      <p>Your individuality could <i>sleep</i>, of course – your individual brain (CPU) shutting down for a spell – but the thoughts of the hive mind would continue, and (because the hive mind shares all of <i>your</i> thoughts, as well as everyone else’s) your individual needs would still be considered – along with the individual needs of the others. (The only difference is that your individuality wouldn’t be contributing sensory perceptions while you slept. (However, if a conscious individuality in the same room as ‘your’ individuality were also a part of the hive mind, you would have sensory perceptions of your ‘self’<sup>‡</sup> from the outside.)</p>
      <p style={{fontSize: "smaller"}}>‡ Or, better said, the body in which your awareness <i>used to be trapped</i>; having joined the hive mind, ‘you’ are now bigger than any one individual’s biological body.</p>*/}
      <p className="page-footnote">‡ Until <QL name="polygnosticity.coalescence"><i>all</i> of Earth was ‘coalesced’</QL>, you wouldn’t entirely lose the illusion of individuality. It would only be lost between minds that had joined the hive. If there were (for whatever reason) multiple separate hive minds at one time on Earth, who (for whatever reason) shared thoughts internally, but not with each <i>other</i>… then this could get really icky. (It seems unrealistic, but there could be a weird cult or something… and then we will eventually need to <PL name="endsjustifymeans">resort to</PL> something like murder (or, more likely, forced sterilisation).</p>
    </div>,
  },
  {
    name: "self",
    prelude: "But maybe what you really want to know is:",
    question: "What happens to the sense of ‘self’ in a hive mind?",
    clarification: "Spoiler: it’s still there. Just different.",
    answerPreview: <div className="answer-preview">
    </div>,
    answer: <div className="answer-text">
      <p>It’s difficult to imagine – becasue it hasn’t happened yet – but it’s basically a transformation: you are no longer confined to the one body that you used to perceive as your ‘own’. <AL name="self">You are bigger than that.</AL> There’s no loss of diversity (and diverse perspectives); rather, all of this diversity only serves to make the hive mind <i>vaster</i> and more capable of understanding things on levels to which most individual humans, boxed as they are in their individaul bodies, wouldn’t dare look.</p>
      <p>As part of a hive mind, you are now multifaceted and immense. In the words of Walt Whilman, ‘you’ (the hive mind) are vast; ‘you’ now contain ‘multitudes’.</p>
      <p>There is still a sense of ‘self’. It’s just got bigger boundaries. The ‘self’ of the hive mind now extends to every individuality it comprises. They all understand one another, contribute, and resolve things together. They view themselves as one ‘self’ – yes – because the limit of the hive mind is the limit of their <i>agency</i> (ability to effect change in the world).</p>
      <p>Even if this hive mind doesn’t encompass ass of Earth consciousness, hopefully it understands that consciousness itself is truly unified. Even if it doesn’t encompass all consciousness in the universe (meaning consciousness that’s extraterrestrial, or ‘alien’), hopefully they want to incorporate that into their ‘self’ as well. Hopefully someone in the hive mind understands <WName /> – well enough that the hive mind can grasp such topics, debate them, and perfect them as</p>
      <p>It’s a greedy ‘self’, yes; but it’s not <i>individually</i> greedy. A hunger for new knowledge – and to <i>expand</i> the horizons of our conscious agency – isn’t the problem. The problem is <i>individual</i> greed: wanting something that the rest don’t have.</p>
    </div>,
  },
  {
    name: "altruism",
    question: "How does altruism work in a hive mind?",
    answerPreview: <div className="answer-preview">
    </div>,
    answer: <div className="answer-text">
      <p>There <i>isn’t</i> ‘altruism’ in a hive mind. Altruism is an individualistic concept: doing something that harms one’s individuality for the good of the whole. A hive mind <i>is</i> the whole, so it will always work in the interests of the whole – which is also self-interest.</p>
      <p>If a conscious hive mind encounters other forms of consciousness, it will join them (incorportaing them into its ‘self’, or its ‘self’ into them). The merging of hive minds is a two-way street (without barriers like privacy) that benefits both hive minds (which becomes just one larger and exponentially more complex hive mind).</p>
      <p>The hive mind only runs into trouble if it encounters an external consciousness that’s <i>not</i> willing to become one with it. In this case, there are two paths it will most likely have to follow:</p>
      <ol>
        <li>If <b>the foreign consciousness is <i>not</i> a threat to the hive mind</b> then it depends on the thing’s projected life-span.</li>
        <ol type="a">
          <li>If it has a limited lifespan (say: an individualistically-greedy human who opts, rather than to become one with the rest of consciousness, to live out his or her life in privacy and then die), then the hive mind will most likely ignore it and just let it die.</li>
          <li>If it has a potentially infinite lifespan (say: a robot that’s locked itself with some sort of unbreakable cryptography, so that no one but its self can ever know its thoughts), then the hive mind will eventually have to destroy it (kill it), because at one point of another the ever-expanding hive mind will need its resources.</li>
        </ol>
        <li>If <b>the foreign consciousness <i>is</i> a threat to the hive mind</b> then the hive mind will have to fight it. Honestly, however, I can’t ever see this happening. Once you get to the size of comprehension and understanding that this thought-experiment is imagining, I can’t see how any consciousness couls think it’s better to fight over the limited energy of this cosmos, rather than combine and all use it together (as one).</li>
      </ol>
      <p>It’s pretty literally just the line, ‘And they lived happily ever after.’ It doesn’t make a good <i>story</i> (because story needs <i>conflict</i>) but – envisioning my ‘self’ as this hive mind – I’ll take the happy ending.</p>
    </div>,
  },
  {
    name: "breakway",
    alternatives: "leave",
    prelude: "A combined hive mind may sound good in theory, but…",
    question: <span>What if some part of the hive mind <i>did</i> break away?</span>,
    answerPreview: <div className="answer-preview">
    </div>,
    answer: <div className="answer-text">
      <p>I can say wThere is a 99.99% certainty that this just <i>wouldn’t</i> happen.</p>
      <p>Why not? It’s a hive mind. Every thought is shared; there are no barriers (like biology). Therefore, everyone’s thinking as one. Within the various parts of the mind, there just wouldn’t <i>be</i> anything like individualism – because everyone would understand one another, and understand the greater picture: that they are really just <i>one</i> combined entity (with however many heads and however many separate pairs of legs).</p>
    </div>,
  },
  {
    name: "complexity",
    alternatives: "complex",
    prelude: <span>
      Valuing <i><PL name="neoliberals">diversity</PL></i>, one might want to ask:
    </span>,
    question: <span>
      Wouldn’t a single consciousness be <i>less</i> complex than 8,000,000,000+ separately-thinking individuals?
    </span>,
    answerPreview: <div className="answer-preview">
      <p>This is under consctruction but – when finished – will hopefully be one of the best examples on the site.</p>
      <hr />
      <p>If you’re asking a question like this, it just shows that your’re thinking ‘<NW word="phanerozoic">Phanerozoically</NW>’ (or <NW word="conservatism">conservatively</NW>) – and do not understand how a ‘<QL name="hivemind">hive mind</QL>’ would actually work.</p>
      <p>The notion that a single entity is <i>less complex</i> than a multitude comes from <QL>humanity</QL>’s thinking of single entities as <i>separate</i> (<NW>heterognostic</NW>) ‘<PL>individuals</PL>’ with the <QL name="polygnosticity">limitations</QL> of the <QL>human</QL> <AL name="container">body</AL> that comes from biology.<sup>†</sup></p>
      <p>A single <AL name="handicap">human brain</AL> is a single ‘<AL name="fields.cs.">CPU</AL>’ and can only think <i>in sequence</i>, multi-tasking like a <AL name="fields.cs">computer</AL>: by processing esch thing sequentially and rapidly switching between them. So it easily gets <i><AL name="handicap">overloaded</AL></i>. Therefore – yes – the ‘<PL name="all.">perspective</PL>’ of a single <QL>human</QL> <PL>individual</PL> is necessarily more limited than that of a diverse group of <i><PL>individuals</PL></i> from a range of backgrounds and expertises.</p>
      <p>A ‘<QL name="hivemind">hive mind</QL>’ is not an <PL>individual</PL>.</p>
    </div>,
    topFootnote: <p>
      † But even in biology there are <AL name="fields.biology">clusters</AL> of multi-‘organisms’ (<OML name="harmony">symbiosis</OML>).
    </p>,
    answer: <div className="answer-text">
      <p>Hearing about a ‘<QL>hive mind</QL>’ for the first time (or as it’s <Qual.probably /> portrayed in ‘<PL name="unready">popular</PL>’ <LRL name="gaia">culture</LRL>) the gut response is likely, ‘You took a million individual brains and made it ONE – therefore it <i>must</i> be less “complex”!’ This is the wrong way to look at it; a ‘hive mind’ takes many diverse experiences and comnbines them (without necessarily <i>losing</i> and of that diversity) into one <QL name="pg.">mind</QL>, with access to <AL name="container">many bodies</AL>.</p>
      <p>Example: say you have three <PL>individuals</PL> called <SillyName id="std" />, <SillyName id="two" />, and <SillyName id="three" />. <SillyName id="std" /> is your ‘average <SillyName id="std" />’: not exceptionally <i>brilliant</i>, but with a fairly high ‘EQ’ and easily able to relate to ‘<QL name="polygnosticity">others</QL>’ and ‘get by’. <SillyName id="two" /> is a <AL name="fields.physics">quantum physicist</AL>, who is ‘smart’ on paper, but to whom the notions of <PL name="all.scale">spatial</PL>-<PL name="all.time">temporal</PL> <QL name="pg.mind.">relativity</QL> and ‘<LRL name="superpositions">Scrodinger’s cat</LRL>’ make a hell of a lot more sense than how to deal with <i>people</i> – think ‘Asperger’s’. And <SillyName id="three" /> is a brilliant <i><QL name="salvation">painter</QL></i> who also dabbles in <QL name="divergent thinking">poetry</QL>, though <SillyName id="three" /> <i>is</i> autistic.</p>
      <p>So you have three separate <PL>individuals</PL>, each with a <AL>body</AL> with its own weaknesses and strengths.(For the example, we are limiting it to one of each… but the universe is more complex than examples, <OML name="offensive">okay</OML>?) Now if you grouped these three individuals into a room, well… probably they wouldn’t actually get much done. Yes, <SillyName id="std" /> would be good at <i>relating</i> to <SillyName id="two" /> and <SillyName id="three" />, but wouldn’t <i><LRL>undersatnd</LRL></i> them – especially as they are both on the ‘spectrum’ and <Qual.probably /> <PL name="explainage">struggle</PL> with <NW>explainage</NW>. <LRL name="them">They</LRL> may both be <i>geniuses</i>, but they don’t have the skills for dealing with <NW>heterognostic</NW> ‘<QL name="polygnosticity">others</QL>’ who don’t <i><LRL>understand</LRL></i> the things that <QL name="pg.mind">they do</QL>; for example: probably <SillyName id="two" /> has full <LRL>understanding</LRL> of <QL>gnosticity</QL>, but can’t <PL name="explainage">be bothered</PL> to <E1 /> it to you.</p>
      <p>Maybe they even resent the ease with which ‘others’ navigate a world that they can <LRL>understand</LRL> in the <i>abstract</i>, but baffles them in its particulars; maybe they even think, sometimes, ‘I would sacrifice some of my intelligence just to have some <i>freinds</i>.’</p>
      <p>Well – fortunately for <LRL>them</LRL> – they exist in a <LRL>thought experiment</LRL> where they’re about to get all of <SillyName id="std" />’s ‘<PL name="ick.rude">social skills</PL>’ without having to forfeit <i>any</i> of their brilliance, because they seem to ‘<LRL name="nihilism">exist</LRL>’ on a <NW>fictive level</NW> in which it’s <i>possible</i> to jam some <NW>POCs</NW> <AL name="fields.linguistic">together</AL>… and there’s a <AL name="notnew.plato.kings">philosopher-king</AL> or other <i><OML name="authoritarianism">leader</OML></i> here (brain <QL name="body.">cell</QL>) who looks at the <AL name="systems">current state</AL> of the <PL name="ick.">system</PL> that is this <LRL>thought experiment</LRL> and thinks it’ll be a lot better (for everyone) to merge these three <NW>heterognostic</NW> <NW>POCs</NW> into one.</p>
      <p>So make a magic noise: <i>vwoop!</i>… because <SillyName id="std" />, <SillyName id="two" />, and <SillyName id="three" />are no longer three <AL name="hell">separate</AL> <PL>individuals</PL>. They now have a single ‘<QL name="pg.">mind</QL>’. It’s maybe not <i>quite</i> ‘<NW>coalescence</NW>’ of the whole <i><LRL name="gaia">planet</LRL></i>, but <PL name="all.scale">the same idea</PL>; let’s call it ‘mini-“<NW>coalescence</NW>”’.</p>
      <p>So what happens to each ‘self’? There are still three <i><AL name="container">bodies</AL></i>, but the <SillyName id="std" />-<SillyName id="two" />-<SillyName id="three" /> <QL>hive mind</QL> is unified, and does not see any <i>one</i> of those <AL name="container">bodies</AL> more as a ‘<QL name="hivemind.">self</QL>’. The sense of ‘self’ has <AL name="self">expanded</AL>.</p>
      <p>Maybe you’re thinking, ‘But where did the original “<QL name="pg.mind">minds</QL>” <i>go</i>?’ They were ‘subsumed’ into the <QL>hive mind</QL>. They have all, in effect, <NW word="transcend">transcended</NW> (or were <i><OML name="authoritarianism">forced</OML></i> to <NW>transcend</NW>) the <NW>illusion</NW> of <PL>individuality</PL> – i.e., <QL>conscious</QL> <NW>polygnosticity</NW> – that used to be holding their separate <LRL name="understanding">understandings</LRL> <AL name="hell">apart</AL>.</p>
      <p>And what if one of them <i>dies</i>? Let’s say that <SillyName id="three" /> – having been, before mini-‘<NW>coalescence</NW>’ – autistic, had struggled to ‘<NW word="money system">make ends meet</NW>’, i.e., lacked a ‘support <PL name="ick.">system</PL>’ (cf. <NW>family system</NW>) and lived in a bad part of town. So the body that was <SillyName id="three" /> gets <i>killed</i>. Does that mean that <SillyName />’s contribution to the hive mind is <i><QL name="hivemind.leave">removed</QL></i>? Would the others suddenly lose the ability to brilliantly <QL name="salvation">paint or write poetry</QL>?</p>
      <p>Not at all. It was just a <AL>body</AL>; the ‘<QL name="pg.">mind</QL>’ survives. The <QL>hive mind</QL> has now been reduced to just <i>two</i> bodies, but it still has the same <i>knowledge base</i> (and <LRL>understanding</LRL>) as before. It just has less ability to <i>think</i> as quickly (i.e., one fewer <AL anme="fields.cs.">CPU</AL>), but it also needs to look after one less <PL>individual</PL> out in the world being exposed to potential dangers, so it works out <Qual.probably /> about even. See: ‘<QL name="hivemind.immortality">Is a hive mind immortal?</QL>’</p>
      <p>P.S. if two of these people ended up with the same name, sorry; just refresh the page. Some ‘kinks’ in this crazy <QL name="change.">website</QL> still need to be sorted out.</p>
      <hr />
      <h3>The old <E1 word="explanation" />:</h3>
      <p>A hive mind is not <i>less</i> complex (and multifaceted) than a group of individuals. </p>
      <p>It’s probably the opposite. The gain in brain processing power attained by joining two individuals’ ‘minds’ isn’t merely additive. It’s at <i>least</i> multiplicative, and maybe even exponential.</p>
      <p>Think of it in terms of tennis. Imagine two top players playing against two mediocre players. The top players will probably win every time. Now imagine that the two mediocre players started to share thoughts; not only will each contribute her minds’s <i>experience</i> of tennis to the whole (probably making the combined body at least a <i>decent</i> player, no longer just ‘mediocre’) but the combined body (‘hive mind’) now has two separate bodies that it can harmonously move around the court: each knowing, at every point, the entire state of the other.</p>
      <p>But then here’s where your average <SillyName /> <Qual.may /> <PL name="unready">struggle</PL>: the gut reaction to this may be: <i>well, fine, she has two bodies. We’ll just</i> distract <i>her.</i> But it doesn’t work like that. The misunderstanding comes from humans’ inability to truly multitask, and our biological need to (like a single CPU) do everything more or less in sequence. A ‘hive mind’ of two tennis players still has two brains. If one of the pro players tried to <i>distract</i> the opponent, the hive mind would use of of the two brains to respond to that (and assess it, its danger, etc.) while the other could stay 100% focussed on the game (knowing as soon as the other body discovers it – because the hive mind instantly shares thoughts – what caused the disruption).</p>
      <p>It’s <PL>hard</PL> – <AL name="handicap">trapped</AL> in an <PL>individual</PL> <QL>human</QL> <AL name="container">body</AL>, and <i><AL name="identity">identifying</AL></i> with it – to <LRL>imagine</LRL> <QL name="salvation">anything else</QL>. But maybe the good news is, if we <i>don’t</i> shift towards a goal of ‘<NW>coalescence</NW>’ before the technology to share thoughts becomes available… once it does, hive minds will just <i>dominate</i>, and <NW word="conservatism">conservative thinkers</NW> (like <PL>neoliberals</PL>) <PL name="endsjustifymeans">won’t have a chance</PL>.</p>
    </div>,
  },
  {
    name: "mortality",
    alternatives: "immortal,immortality",
    question: "Is a hive mind immortal?",
    answerPreview: <div className="answer-preview">
      <p>The short answer is yes, a hive mind is (essentially) immortal. Which means that, when ‘you’ join a hive mind, you become immortal. You become <AL name="self">a larger consciousness than a mere ‘self’</AL> – and so, when one individuality dies, the combined super-brain can keep on thinking and wondering (<WName ically />) at this self-awareness that we (consciousness) have.</p>
    </div>,
    answer: <div className="answer-text">
      <p>Think of it as analogous to a biological body comprising a whole multitude of cells. Cells don’t live forever When one cell dies, the body keeps on living.</p>
      <p>The best part is that (presumably; using human biology as a reference for <QL name="salvation">speculation</QL>) the hive mind’s <QL name="hivemind.self">sense of self</QL> persists beyond the death of any individual brain it comprises. When ‘you’ join a hive mind, you escape the limitation imposed on you by being trapped into one brain and one body. You <i>become</i> the hive mind. Your individuality <i>becomes</i> the individuality of the hive mind.</p>
      <p>‘You’ therefore become <i>immortal</i>.</p>
    </div>,
  },
  ],
};
