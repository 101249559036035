import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const disagreement = {
  name: "disagreement",
  prelude: <span>
    Your <NW>POC</NW> may <PL name="uncomfortable">not <i>like</i> it</PL>… but <NW>synecdocentrism</NW> (frankly, my dear) doesn’t give a damn.
  </span>,
  header: <span>
    If you don’t <i>agree</i>, you probably just don’t <i>understand</i>.
  </span>,
  outside: <span>
    Those – yes – be ‘fightin’ words’… but only in a <NW>polygnostic</NW> consciousness that views its ‘<AL>self</AL>’ as a bunch of <AL name="dysfunctional.fragments">disparate and eternally disconnected</AL> ‘<PL name="individuality">individuals</PL>’.
  </span>,
  preview: <div className="long-read-preview">
    <p>Now obviously if ‘you’ <i>don’t</i> understand these (potential) issues, youve both this is grouping you with everyone else who may disagree because they don’t <i>understand</i>.</p>
    <p>But if your <NW>POC</NW> <i>is</i> able to understand why ‘you’ are referred to a ‘POC’ instead of by the ‘second person’, and yet this <i>still</i> doesn’t seem right, it’s probably not any oversight in the underlying theory (which is just, after all, just logical <NW>induction</NW>). If this doesn’t make sense, there are two possibilities:</p>
    <ol>
      <li>There was a failure of ‘<NW>explainage</NW>’; it wasn’t <i><NW word="explain">explained<sup>(1)</sup></NW></i> well on this website.</li>
      <li>There is a problem with <i>your</i> reception (probably <NW>conservative thinking</NW>, or holding onto some <NW>mythology</NW> – both forms of <NW>individual greed</NW>).</li>
    </ol>




    <p style={{color: "red"}}>[Under conscruction still]</p>


  </div>,
  text: <div className="long-read-text">
    <p>If you think this is harsh to your <PL>individuality</PL>:</p>
    <ul>
      <li>You don’t understand <i><NW>illusions</NW></i> in <NW>synecdocentrism</NW>.</li>
      <li>You don’t understand <QL name="unity">unity consciousness</QL>.</li>
      <li>Or ‘you’ – maybe even deep down inside – are holding onto some <NW>individual greed</NW>.</li>
      <ul>
        <li>Maybe you are overly <AL name="identity">identifying</AL> with the container around your <NW>POC</NW>.</li>
      </ul>
    </ul>{/*
    <p>This is <i>radically</i> <i><LRL>different</LRL></i>. And that’s not ‘radical’ in the sense that it’s proposing a reshaping of something only mildly broken into something slightly better (like, say, the hope of ‘<LRL>communism</LRL>’ in the USSR, or any sort of ‘reform’). This is ‘radical’ that actually means <i>new</i> – and in a world where most of us have seen what we think to be <i>everything</i> (i.e. so many re-hashings of the same story tropes in different veins) you sure as hell <PL name="unready">are not going to be ready for this</PL>.</p>
    <p>So you – as a human <PL name="individuality">individual</PL>: probably (at least in some way) a ‘<NW word="conservatism">conserative thinker</NW>’, even if in only a small way – are primed to <i>disagree</i> with it, is all.</p>
    <p>It doesn’t mean that the theory has any holes. (As a theory it’s</p>
    <p>There’s basically only one thing that would unsettle it (which science hasn’t yet proven): the existence of some external ‘God’ (other than <NW word="god">our consciousness<NW>) who has sway over this world, but exists in some plane other than it. */}
  </div>,
};
