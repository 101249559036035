import React from 'react';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual, Rando} from '../common';

// What are roadblocks to 'coalescence'?
export const roadblocks = {
  name: "roadblocks",
  alternatives: "problem,problems",
  question: <span>
    What are some of the major <i>roadblocks</i> to <WName noNW />?
  </span>,
  clarification: "Just a smattering.",
  answerPreview: <div className="answer-preview">
    <p>This <QL>Weltanschauung</QL> can <LRL>answer</LRL> questions like ‘<Rando options={[<QL name="life.meaning">What is the meaning of life?</QL>, <QL name="life.reason">Why do I exist?</QL>, <QL name="unity.answer">What is the answer to life, the universe, and everything?</QL>]} />’ <Rando options={["without breaking a sweat", "without even a grunt of exertion", "as easy as baking a pie", "as if it were a walk in the park", "in a snap", "before it finishes with its ABCs", "easy as a breeze"]} />. Mostly it just <i><AL name="framework.">works</AL></i>. Why does <OML name="ideas">it fear</OML> that <QL>humanity</QL> isn’t going to <PL name="uncomfortable">like it very much</PL>?</p>
    <p>You know, other than <PL name="sweeping.handful">the usual suspects</PL>.</p>
    <p>Well, okay… in this case <NW>individual greed</NW> <i>would</i> be the <PL name="sweeping.ig">main reason</PL>. <QL>Humans</QL> seem to need to feel <i><PL>special</PL></i>.</p>
    </div>,
    answer: <div classNane="answer-text">
      <p>And <OML name="offensive">you aren’t</OML>. <Qual.probably cap />  – if this <QL>Weltanschauung</QL> <OML name="ideas">had</OML> the <NW>agency</NW> to <OML name="reincarnation">re-incarnate</OML> and end up on <LRL name="gaiacentrism">some other planet</LRL> where the conscious <QL name="body.">cells</QL> were less <AL name="notnew.lennon.peace">belligerent</AL> and less <OML name="god">narcissistic</OML> about <PL name="privacy">protecting</PL> their own <PL>individuality</PL> – it would do that.</p>
{/*
    <li>Most people (like ‘me’: my individuality) have no actual voice in the grand scheme of things.</li>
    <ul>
      <li>The problem with having no ‘voice’ is that anyone who gets to the level where they actually can speak and be heard is so irredeemably soiled by the money system and the nation-state system, beholden to commitments to corporations or governments… that (either for their own individual greed, or that of the systems they serve) they’d bend <WName /> into a monster it’s not. They’d present only the uncomfortable parts of this, as a way to <i>explain</i> to the masses why unity consciousness is a great evil, and you’re better off continuing to suffer under the oppression of the money system, as your ancestors suffered under the oppression of the money system (but maybe marginally less); have more children, make more humans, and if you dare to speak up about hive minds we’ll cancel you because maybe a hive mind isn’t right for <i>every</i> individual!</li>
      <li>Actually, <WName /> <i>does</i> work for every individual. Just a lot of individuals are blinded by things like the illusion of individuality or belief in fables (individual soul, external God) so they <i>think</i> it won’t work for them.</li>
      <li>The reason a lot of individuals might think that <WName /> is authoritarian and ‘evil’ is due to our polygnosticity – and this website being a pretty sad excuse of explainage of all of this.</li>
    </ul>
    <li>The core message, taken only in part, in unpalatable</li>
    <ul>
      <li>Most individuals <PL name="denial">don’t <i>want</i> to hear</PL> that their individuality doesn’t matter the least in the grand scheme of things.</li>
      <li>Most individuals don’t <i>want</i> to hear that their individuality is actually only the symptom of a <i>disease</i> of our consciousness (DID, AKA ‘split personality’) – and shouldn’t even <i>exist</i>!)</li>
      <li>In short, a lot of our polygnostic consciousness uses <i>ignorance</i> as a shield – which is a big, big problem. (Denial – or refusing to even <i>entertain</i> a notion, because it might be damaging to your individuality – is individual greed.</li>
    </ul>
    <li>The various parts of the body (the ‘cells’ – individuals – and the ‘organs’ – nation-states or companies) are too <i>busy</i> – and trying to maintain the flaking and crumbling illusion of individuality, rather than turn and face the truth (that <i>humanity</i> doesn’t actually matter to consciousness one whit).</li>
*/}
  </div>,
  sublist: [
  {
    // What is 'cancel culture'?
    name: "cancelling",
    question: <span>
      What is ‘cancel culture’?
    </span>,
    clarification: <span>
      There’s <NW>conservative thinking</NW>, and then there’s <PL name="denial">close-mindedness</PL>. If we truly had a <i>progressive</i> society, we wouldn’t all be walking on eggshells.
    </span>,
    answerPreview: <div name="answer-preview">
      <p>UNDER CONSTRUCTION</p>
{/*
      <p>‘Cancel culture’ is a form of <NW>conservatism</NW> in which Earth’s dominant (neoliberal) culture, faced with a datum (true or false) that causes it <i>discomfort</i>, chooses – rather than to engage with it critically – merely to shut it down (often pegging it with some label, such as ‘racist’, ‘homophobic’, etc.) that serves to quickly <i><NW word="explainage">explain</NW></i> it away to the rest of neo-liberalkind.</p>
    </div>,
    answer: <div className="answer-text">
      <p>Some <PL>neoliberals</PL> will even go as far as to claim that ‘cancel culture’ doesn’t exist; that it’s just an anti-liberal slur invented by conservatives to hold power in a dying world (in which the bulk of copnservative mythology is – in light of science – falling apart). They will then proceed with their <i>own</i> (<NW>atomocentric</NW>) <NW>mythology</NW> – no less fantastical – and tell you that you must account (individually) for <PL name="individuality">every other individual</PL> (the vast majority of whose minds you <QL name="polygnosticity">don’t know</QL>; thank our <QL name="body">conscious body’s</QL> <NW>polygnosticity</NW> for that).</p>
      <p>And if you dare to raise an objection (‘Er, sir, maybe there <i>is</i> <QL name="unity">another aspect to reality</QL> <PL name="invisible">that you can’t see</PL>…’) they won’t deign to debate it academically. You will be ‘cancelled’.</p>
      <p><WName /> is full of things that will make a ‘<PL>neoliberal</PL>’ <i>squirm</i>. Any one of these – if you’re a <QL name="whatcanido">left little finger</QL> that actually <i>cares</i> about the whole <QL>body</QL> (of which you’re a part) – could be grounds for your ‘cancelation’. (Living in the 2020’s world is standing on metaphorically thin ice.<sup>†</sup>)</p>
      <p>Theres a good outline of various potential discomforts (mainly for <PL>neoliberals</PL>) on <PL name="uncomfortable">this page</PL>.</p>
      <p>Just be careful with this – because, if you say any of this too loudly, someone (or some<i>thing</i>) with money and power is going to reach out a finger and squash you like a little ant.<sup>‡</sup></p>
      <p class="page-footnote">† Maybe its better to say that there are metaphorically thin walls – now, in light of <PL name="automation">AI</PL> – to humanity’s <QL name="mirror">box of mirrors</QL>, and the neoliberals (as much as the conservatives) are eager to preserve the illusion of individuality; better to be an <i>independent</i> individual (with the promise of unity or individual joy in some hypothesised life to come) than a cell in the armpit-hair of this universe’s <NW>God</NW>.</p>
      <p class="page-footnote">‡ The people (and super-human organisms, as much as those have consciousness) with money and power don’t <i>want</i> the bulk of our consciousness to <AL name="sleeping">wake</AL>’</p>*/}
    </div>,
  },
/*  {
    // How does one deal with the sense of superiority?
    name: "superiority",
    prelude: <span>
      If <WName noNW /> has <PL name="comprehensive">all the answers</PL>…
    </span>,
    question: <span>
      How does one deal with the sense of <i>superiority</i>?
    </span>,
    clarification: <span>
      (Without driving the <AL name="dysfunctional.fragments">rest</AL> of our conscious <QL>body</QL> bat-sh*t crazy)
    </span>,
    answerPreview: <div name="answer-preview">
      <p>Start out by clarifying: if you (individually) feel any sense of <i>superiority</i>, you’re doing <WName /> wrong. There’s no room for <i><PL name="individuality">individual</PL></i> feelings of superiority here. In the <Eon Eon />, there’s no room for <i>individuals</i>, period; wanting to be an ‘individual’ (apart from the rest) is just a form of <NW>individual greed</NW>.</p>
      <p>If that sounds harsh, you’re misunderstanding this whole thing. Sorry. By the time that ‘you’ (as an individual) understand this well, you’ll understand that there’s no point feeling <i>superior</i> to anyone ‘else’ because you <i>are</i> ‘other’ people as well; there is only <i>one</i> consciousness, and every self-aware thing in the univere is a part of it.</p>
      <p>Read the next one, about <QL name="roadblocks.alienation">not alienating other ‘cells’ of our ‘body’</QL>.</p>
    </div>,
  }, */
  {
    // How to avoid alienating 'others'
    name: "alienation",
    prelude: <span>
      If <i>you</i> can see that you and <LRL name="them">your ‘neighbour’</LRL> are just two <QL name="body.">cells</QL> in the <QL name="unity.answer">same</QL> ‘<QL>body</QL>’, but <QL name="polygnosticity">your ‘neighbour’ does <i>not</i></QL> <LRL>understand</LRL> this…
    </span>,
    question: <span>
      How to avoid alienating the non-receptive?
    </span>,
    clarification: <span>
      How does <WArticle /> <WName ic /> <NW>POC</NW> avoid <PL name="ick">alienating</PL> the un-<NW>enlightened</NW> (‘<AL>sleeping</AL>’) <AL name="dysfunctional.fragments">fragments</AL> of <QL>humanity</QL>?
    </span>,
    answerPreview: <div name="answer-preview">
      <p>Once one is well-versed in <WName />, the vast bulk of human ‘<AL name="illusions">debate</AL>’ <PL name="comprehensive.steamroller">seems <i>such</i> a waste of time</PL>; it’s easy to feel dismissive, and not <i>want</i> (or not have <i>time</i>) to play <QL>humanity</QL>’s <NW>MNS system</NW> ‘<LRL>game</LRL>’.</p>
      <p>Which will just serve to make <WName /> look (to ‘<LRL name="them">others</LRL>’ still trapped in the <PL name="individuality">illusion of individuality</PL>) like some high-falutin’ academic <i>theory</i> that doesn’t take into account the <PL name="invisible">reality of human experience</PL> (like <i><PL>love</PL></i>).</p>
      <p>That’s not going to help our <AL name="dysfunctional">sick, sick</AL> conscious <QL>body</QL>.</p>
    </div>,
    answer: <div name="answer-text">
      <p>Probably you’ll have to honour their <NW>mythology</NW>. If we are all like <AL name="illusions">children playing a game</AL>, maybe you have to give them an answer that fits within their (self-made, make-believe) <i>rules</i>.</p>
      <p>Think of unenlightened human as somewhat like children. Their <i>consciousness</i> is in a less mature state. Maybe they simply are <PL name="unready">not able</PL> to understand <WName />. (Think back on how long it took with <NW>Copernicus</NW>.)</p>
      <p>A good way to look at it is as if <AL name="sleeping">the bulk of humanity is <i>asleep</i></AL>. Think of how you’d rouse someone who’s sleeping. You wouldn’t shout in their ear. You’d (hopefully) be gentle.</p>
      <p>Unenlightened humans <i>can</i> still help the <QL name="unity">combined consciousness</QL> (even if they’re doing the good only in the <NW word="mythology">mythological</NW> belief that they have an individual immortal soul). By the time they die and discover they <i>don’t</i> have an <PL name="individuality">individual</PL> soul, they’ll be <i>head</i> (meaning that our shared consciousness is no longer manifesting as <i>their</i> individuality) </p>
    </div>,
  },
  {
    // What's an external god' and why is it a problem?
    name: "external god",
    alternatives: "god",
    question: <span>
      What is an ‘external God’?
    </span>,
    clarification: <span>
      Why wouldn’t <WName /> <OML name="god">allow for it</OML>, and why is that <LRL>notion</LRL> a <i><QL>problem</QL></i>?
    </span>,
    answerPreview: <div name="answer-preview">
      <p>As a <QL>Weltanschauung</QL>, <WName /> doesn’t qualify as <i><QL>atheistic</QL></i> because it <i>has</i> a ‘<NW>God</NW>’. The ‘<NW>God</NW>’ of <WName /> is furthermore <AL name="framework.work">100% observable</AL> and self-evident (‘<LRL name="think">We think, therefore we are</LRL>’). It’s just that – for a lot of <NW word="atomocentrism">atomocentrists</NW>, <QL name="qualifiers">maybe</QL> – it could feel anticlimactic to propose <i>consiousness</i> (i.e., our cosmic conscious <QL>body</QL>, and the <LRL name="gaia hypothesis">Earth-sized</LRL> <NW>POC</NW> that’s all of the body currently <i><QL name="polygnosticity">known</QL></i> on this planet) as ‘<NW>God</NW>’.</p>
      <p>However, many <QL name="anthropocentridsm">human</QL> ‘<PL name="individuality">individuals</PL> want (or even <i>need</i>) to feel <PL>special</PL>. To admit that your ‘<AL>self</AL>’ is just a little left finger (or less) in the ‘grand scheme of things’ may be quite <PL name="uncomfortable">difficult</PL>.</p>
      <p>Hence the <NW>mythology</NW> of the <OML name="god">external God</OML> who exists on a ‘<NW>level</NW>’ of <LRL name="i.">reality</LRL> outside of the <i><AL name="framework.work">observable</AL></i>… and so can never be definitively <i>disproven</i>.</p>
    </div>,
    answer: <div name="answer-text">
      <p>How to deal with this? How to have a reason-based discussion when your interlocutor injects into it something that <i>does not adhere to reason</i>?</p>
      <p>(Other than some of the <OML name="sterilisation">ideas</OML> that can come out of <AL name="framework">this thing</AL> when it is <OML name="ideas">exasperated</OML>.)</p>
      <p>Well… you <i>can’t</i>. Facing these <PL home>objections</PL> to <WName />, the best you can do (as a <NW>heterognostic</NW> <PL name="individuality">individual</PL> <NW>POC</NW>) is either <i><QL name="whatcanido.">education</QL></i> – or, if that doesn’t work, maybe you have to treat <WName /> like another <AL>religion</AL> and try to <i>convert</i> them. (Though be careful with that aproach, because our conscious <QL>body</QL> does <i>not</i>; and the phrase ‘dogmatic <WName ist />’ is anyway rather an oxymoron).</p>
      <p>And <OML home>what if</OML> <LRL name="them">they</LRL> don’t even <i>engage</i>? If they are <i>scared</i> of asking themselves ‘tough’ questions (like, ‘<QL name="life.meaning">What is the meaning of life?</QL>’ or ‘<QL>What is the answer to everything?</QL>’) – <Qual.maybe /> because they fear that the <LRL>answer</LRL> would expose some <AL name="framework.work">flaw</AL> in their <AL>worldview</AL> and they (<NW word="ig">individually-greedily</NW>) <PL name="sweeeping.ig">want to <i>preserve</i> it</PL>. <Qual.maybe cap /> they would rather stay forever <AL name="sleeping">asleep</AL>. Maybe that’s when we leave them to their <AL name="illusions">games</AL> and call in the <AL name="notnew.plato.kings">philosopher-kings</AL>, or it might be faster to just <OML name="sterilisation">spay and neuter</OML> all of Earth’s <NW name="conservatism">conservative thinkers</NW>.</p>
      <p>Because <WName /> is about <i>not being afraid to ask questions</i>. The motto is <LRL name="motto">leaves no stone unturned</LRL>… and so it will <i><QL name="salvation">consider</QL></i> everything – even that <OML name="wrong">this might all be wrong</OML> and an <OML name="god">external God might exist</OML>. But (and remember <NW word="occam">Occam’s razor</NW>) is that really <i>likely</i>?</p>
    </div>,
  },

  ],
};
