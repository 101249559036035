import React from 'react';

export default function PagePreview({name, title, intro}) {
  return <div className="page-preview">
    <h1 className={"section-title " + name + "-title"}>
      >> {title}
    </h1>}
    {intro}
  </div>;
}
