import React from 'react';
import WName, {WArticle} from '../WName';
import Eon from '../Eon';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {
  E1, E2, Qual,
  the_key_is_imagination,
  Rando, SillyName
} from '../common';

// INDIVIDUAL Love is just a function of our polygnosticity.
export const love = {
  name: "love",
  header: <span>
    ‘Other’-love needs to turn into ‘self’-love.
  </span>,
  outside: <span>
    A <WName ist /> has a big ‘<AL>self</AL>’.
  </span>,
  preview: <div className="problem-preview">
	<p>The <QL name="qualifiers">potential</QL> <LRL name="understand">misunderstanding</LRL> here is (as per the <PL name="explainage">need for</PL> ‘<NW>explainage</NW>’ to <QL name="polygnosticity">overcome</QL> <LRL name="gaia">our</LRL> <QL>conscious</QL> <NW>polygnosticity</NW>) the <i>word</i> ‘love’.</p>
    <p>In <AL name="notnew.plato.neoplatonism">Ancient Greece</AL> (for example) they had many words for what your 2020’s ‘average <SillyName />’ <Qual.might /> call ‘love’. Let’s use <i>those</i>.</p>
  </div>,
  readMore: <span>
    Read about the <WName noNW /> take on the Ancient Greek versions of ‘love’.
  </span>,
  text: <div className="problem-text">
{/*
	<p>Bottom line: there is <i>nothing</i> about <NW>polygnosticity</NW> that’s actually <i>good</i> for our unified consciousness. (Yes, <WName /> sometimes makes sweeping claims that will <PL name="uncomfortable">make you squirm</PL>.)</p>
	<p><i>Love</i> is just an illusion (part of <PL>individuality</PL>).</p>
	<p>It’s also incidentally a form of <NW>individual greed</NW>: placing your ‘significant other’ above the rest of our body. In a world less <AL name="dysfunctional">screwed up than this planet</AL>, it has no place. That comes across really harsh – and love isn’t <i>necessarily</i> problematic, as long as you care for the whole ‘<QL>body</QL>’ as much as you care for each ‘other’ – but most of humanity <AL name="sleeping">needs a wake-up call</AL>.</p>
	<p>This is <NW>Mechanozoic</NW> thinking. Living in the Mechanozoic Eon (and looking back at the <NW>Phanerozoic</NW>) it just <i>makes sense</i>. No lengthy <NW>explainage</NW> required.</p>
	<p>The problem is that most of the human <AL name="sleeping">sleepyheads</AL> just <PL name="unready">are not ready for this</PL>.</p>
  </div>, */}

    <ol>
  <li><b>Eros</b>: sexual or passionate love. Mostly this is <PL name="sweeping.ig">just a form</PL> of <NW>individual greed</NW>.</li>
  <ul>
    <li>Test: would you and your romantic ‘partner’ would be willing to combine bodies and be only one ‘<AL name="self">person</AL>’ (with a ‘<QL name="hivemind">hive mind</QL>’)?</li>
    <li>If <i>not</i>, then – <PL name="uncomfortable">sorry</PL> – there is a portion of your ‘love’ that is <NW word="ig">individually greedy</NW>.</li>
  </ul>

<li><b>Ludus</b>: flirting and other forms of ‘play’ – basically <QL name="polygnosticity">revelling</QL> in your (as one <QL>human</QL> <AL>body</AL>) <NW>heterognosticity</NW> from <LRL name="them">others</LRL>’. And this is not <i>good</i> for <LRL name="gaia">the whole</LRL>, <PL name="ick">sorry</PL>.</li>

<li><b>Pragma</b>: as it sounds, this is ‘practical’ love, like the ‘arranged marriages’ that are quite common in some of <LRL name="gaiacentrism">Earth</LRL>’s ‘<LRL name="usandthem">cultures</LRL>’ and used to be in more of them – maybe originally as a <LRL>symptom</LRL> of <LRL name="gaia">the whole planet</LRL> ‘<AL>sleeping</AL>’ and not using its <NW>agency</NW> as much as its <LRL>reactivity</LRL>, so family units navigating and propelled by the <AL name="fields.">biological</AL> (<LRL>reactive</LRL>) force of ‘<QL name="evolution.">natural selection</QL>’. While not necessarily <i><PL name="evil">bad</PL></i> (like ‘eros’), ‘pragma’ is just a way to navigate – in the presence of <i><PL>privacy</PL></i> (which is, <PL name="sweeping.ig">of course</PL>, <NW>individual greed</NW>) – the <NW>family system</NW> and the <PL name="ick.">system</PL> of <LRL name="usandthem">tribal allegiances</LRL> that preceded and <QL name="change">became</QL> the <NW>nation-state system</NW> – with some spillover into the <NW>money system</NW>, was from a 2020 <PL name="all.perspective">standpoint</PL>, <i><NW>companies</NW></i> <Qual.may /> soon take the place of <NW>nations</NW>.</li>

<li><b>Storge</b>: ‘familial love’ – basically just part of the <NW>family system</NW> that is unnecessarily restrictive; why can all of consciousness not be your ‘family’?</li>

<li><b>Philia</b>: kind of like ‘brotherhood’ or ‘sisterhood’ – and fine as long as you are not splitting <QL>consciousness</QL> into <LRL name="usandthem">an ‘us’ and a them’</LRL> (say: <OML name="patriarchy">men vs. women</OML>, <QL>humans</QL> vs. <OML>animals</OML>, or <OML name="alone">Earth</OML> vs. <OML name="aliens">extraterrestrial</OML>).</li>

<li><b>Philautia</b>: self-love, which is bad if it’s <NW>individual greed</NW>, but once you(r <NW>POC</NW>) has broken all the ‘<AL>mirrors</AL>’ and <NW word="transcendence">transcended</NW> the ‘<AL name="self">self</AL>’ (to the <i>maximum</i>, not even <PL name="all.level">ultimately</PL> <OML name="matters">caring</OML> what’s ‘<LRL name="i.">real</LRL>’) then this becomes indistinguishable from #7 – and is no longer ‘<PL name="evil">bad</PL>’, but very, very <i><QL name="salvation">good</QL></i>.</li>

<li><b>Agape</b>: and <i>this</i> is the ‘love’ of <WName />. <WName /> is actually <i>full</i> of love… but <i>it is not a discriminating and differentiating love</i>.</li>
  <ul>
    <li>This may be the hardest thing to <LRL>understand</LRL> about a so-called ‘<QL>Weltanschauung</QL> that seems to <AL name="handicap">hate humans</AL>; <Qual.maybe /> a better way to look at it is that it’s so full of ‘agape’ that any frustration expressed by <WArticle /> <WName ist /> could be just an outpouring of the <LRL name="deep">profound</LRL> <i>sadness</i> that <i><WName ists /> can see the end to all suffering</i> (i.e., ‘<LRL name="gaiacentrism">world</LRL>’ – and <i><LRL name="scope">Universal</LRL></i> <AL name="notnew.lennon.">peace</AL>) but – <LRL name="causes">because</LRL> of the <NW>polygnosticity</NW> of <LRL name="gaia">Earth</LRL>’s <QL>consciousness</QL> – it’s <PL name="explainage">oh so hard</PL> to <E1 italic /> it to you – and maybe ‘you’ just don’t <i>want</i> to <LRL>understand</LRL>.</li>
<li><OML>Harmony</OML> is <i>possible</i>, yes, but only once <PL name="sweeping">everyone</PL> can <LRL>shift</LRL> from <NW>atomocentrism</NW> to <WName /> – or <i>enough</i> of <QL>humanity</QL>, and they do something not to the <PL name="individuality">people</PL> who oppose it but to destroy the <i><LRL>notions</LRL></i> than <AL name="hell">hold us apart</AL>. Without – <NW word="god">inshallah</NW> – having to <i><OML name="murder">kill</OML></i> any <PL>individuals</PL>.</li>
</ul>
</ol>
  </div>,
};
