import React from 'react';
import WName, {WArticle} from '../WName';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Qual} from '../common';

// It's very uncomfortable (especially just in part). 
export const uncomfortable = {
  name: "uncomfortable",
  // Short name for getting to its sublist:
  alternatives: "ick",
  parentName: "discomforts this may cause",
  prelude: <span>
    Especially without <LRL>understanding</LRL> it fully…
  </span>,
  header: <span>
    <WName noNW cap /> is profoundly <i>uncomfortable</i>.
  </span>,
  preview: <div className="problem-preview">
    <p><TLDR />: actually, <LRL name="gaiacentrism">this planet</LRL> has enough <i>resources</i> for everyone; the <PL name="reductionist">only</PL> <i><QL name="roadblocks">problem</QL></i> in <LRL name="gaia">Earth</LRL>’s <QL>consciousness</QL> is that the ‘<AL name="self">Earth-self</AL>’ (or ‘<OML>Earth-soul</OML>’) is in <NW>heterognostic</NW> <AL name="dysfunctional.fragments">pieces</AL> that <OML name="harmony">don’t get along</OML> and so <PL name="secrets">hide things</PL> from <LRL name="usandthem">each ‘other’</LRL>… which results in a dissolution of <QL>conscious</QL> <NW>agency</NW> (<LRL>reactivity</LRL>).</p>
    <p>This <AL name="framework">project</AL> may be 100% <PL>love</PL>, but it is a form of <PL name="individuality">non-individualised</PL> ‘love’ that is <Qual.probably /> so <LRL name="different">foreign</LRL> to <QL>humans</QL> (and <NW>atomocentric</NW> modes of <LRL name="ideas">thought</LRL>) that it can <PL name="explainage">come across atrociously</PL> and look like <AL name="handicap">human-hating</AL>.</p>
{/*
    <p>It (<WName />) is basically saying: let us 
    <ul>
      <li>There’s no respect for <PL>individuality</PL>.</li>
      <ul>
        <li>It might first appear that the goal of ‘<NW>coalescence</NW>’ means a reduction in the <i>complexity</i> that we have . <QL name="hivemind.complexity">That would be dead <i>wrong</i></QL> – but neoliberals (as, essentially, <NW word="conservatism">conservative thinkers</NW>) probably cant imagine the reality of a ‘<QL name="hivemind">hive mind</QL>’.</li>
      </ul>
      <li><PL name="endsjustifymeans">The ends justify the means.</PL></li>
      <ul>
        <li>Our consciousness’s <NW>polygnosticity</NW> means that it’s very hard (currently) to <i>do</i> anything about that, but there you have it: ‘<PL>ethics</PL>’ is just an <NW>illusion</NW>.</li>
      </ul>
      <li>It might appear that <WName /> <PL name="storytelling">hates the arts</PL>.</li>
      <ul>
        <li>That’s yet another misunderstanding. <WName /> (at this stage) <QL name="salvation"><i>needs</i> imagination</QL> (and empathy: empathy in such a <AL name="self">weird and wild manner</AL> that you’ve probably never seen before). But it’s a possible cause for objection.</li>
      </ul>
      <li><PL name="equality">‘Equality’ is a lie.</PL></li>
      <ul>
        <li>It’s just a strategem of those who have to keep those who have not content with having not.</li>
      </ul>
      <li>‘<NW>Coalescence</NW>’ will (eventually) dismantle the <NW>mythology</NW> of <PL>love</PL>.</li>
      <ul>
        <li>Because ‘love’ is just a function of <PL>individuality</PL> (symptomatic of <NW>polygnosticity</NW>).</li>
      </ul>
    </ul>
*/}
  </div>,
  text: <div className="problem-text">
    <p>This is the old ‘<E1 suffix="er" />’:</p>
    <p>In short, this worldview is <i>not</i> compatible with <PL name="neoliberals">neoliberalism</PL> – and, if you <QL name="whatcanido">go trying to spread it</QL>, <QL name="roadblocks.cancelling">cancel culture</QL> is something of which you must be aware. <NW word="god">Inshallah</NW> we can one day overcome all means of <PL>denial</PL> (and <NW>polygnosticity</NW>: our perceived <AL name="hell">separation</AL> into disparate ‘<AL name="self">selves</AL>’). But maybe not in the 2020’s; without a lot of <PL name="equality">actual power</PL> on the <WName ic /> side (of <PL name="individuality">anti-individualism</PL>).</p>
    <p>The pieces on the <QL name="metaphors">metaphorical</QL> chess board have shifted – and the people with <NW>money</NW> and power (which used to be the  Church; ironic because <AL name="notnew.jesus">Jesus</AL>’ <PL>individuality</PL> was <i>not</i> rich, and he preached to the poor) are now <NW>nation-states</NW> and <i><NW word="company">corporations</NW></i>. Rather than imprison you with <LRL name="reactivity">fear</LRL> for your (<OML name="god">hypothetical</OML>) immortal ‘<OML>soul</OML>’, they ensnare us in the very ‘<LRL name="i.">real</LRL>’ trap of ‘<AL>identity</AL>’ – to which is pegged (in the <NW>MNS system</NW>) ‘your’ <PL>individual</PL> <i>worth</i> (<NW word="money">dollar value</NW>).</p>
    <p>So be careful when dealing with <WName />. Taken only in fragments, it could <PL name="endsjustifymeans">justify</PL> <OML>authoritarianism</OML>, <OML>murder</OML>, etc.; <Qual.probably /> it could even be used to justify trapping each <AL name="dysfunctional.fragments">fragment</AL> of <QL>consciousness</QL> into a <PL name="fungibility">separate</PL> ‘<AL>identity</AL>’, somehow. It’s an <LRL name="communism">all-or-nothing</LRL> deal. You don’t get to just pick and choose: some <NW word="induction">inductive thinking</NW>, and the rest filled in with <NW>mythology</NW>… or you get the <AL name="notnew.marx">USSR</AL> (<PL name="sweeping.ig">full</PL> of <NW>individual greed</NW>) – or worse.</p>
    <p>Taken only in part, this <QL>Weltanshauung</QL> is probably one of the most <i><PL name="corrupt">dangerous</PL></i> things <LRL name="notions">out these</LRL> (especially when stuffed into <NW>atomocentric</NW>, <NW word="conservatism">Phanerozoic-Eon thinking</NW>). So be careful with it, <i>please</i>.</p>
  </div>,
  sublist: [

  // Everything is a system
  {
    name: "systems",
    alternatives: "system",
    prelude: <span>
      You might not <i><PL name="uncomfortable">like</PL></i> it, but this <QL>Weltanschauung</QL> both <AL name="framework.">works</AL> and can be applied to <PL name="comprehensive">just about everything</PL> (within its extremely large ‘<LRL>scope</LRL>’).
    </span>,
    header: <span>
      Everything is a <i>system</i>.
    </span>,
    preview: <div className="problem-preview">
      <p>On some level<sup>†</sup> <PL name="sweeping">absolutely everything</PL> really <i>is</i> a ‘<AL>system</AL>’: from plate techtonics to biological bodies to mitochondria to atoms as systems of (the <NW>atomocentrically</NW>-named) ‘bosons’ and ‘fermions’… and even those ‘fundamental’ waves/particles are (<OML name="levels">probably</OML>) only an ‘emergent’ property of something still <LRL name="deep">deeper</LRL> – or at least thought some of the <AL name="notnew.plato.neoplatonism">Ancient Greeks</AL>, who <QL name="qualifiers">probably</QL> had less <QL name="gnosticity">knowledge</QL> of <AL name="fields.physics">quantum mechanics</AL> than (whoever ‘you’ are, if you live in the 21<sup>st</sup> century) <i>you</i> do.</p>
      <p>The <i>point</i> of all of this is that <i>you</i> are a system, <OML name="offensive">your mom</OML> is a system, the ‘love of your life’, and the person with whom you cheated on the ‘love of your life’ last night are all systems – and so is you (presumably <NW>polygnostic</NW>, presumably <QL name="polygnosticity.privacy">closely-guarded</QL> and highly <i><AL name="dysfunctional.fragments">fragmented</AL></i> ‘relationship’ with these ‘<PL name="individuality">others</PL>’.</p>
      <p>Okay?</p>
    </div>,
      topFootnote: <p>
        † Assuming, that is, that <OML name="levels">a ‘rose’ is not just a rose</OML>, but an outward (emergent) <LRL>symptom</LRL> of some <LRL>deeper</LRL> (<AL name="notnew.plato.">Neoplatonic</AL>-style) reality that <QL>human</QL> senses cannot directly perceive, but <AL name="fields.">science</AL> has shown us time and again is (cf. ‘<LRL>understanding</LRL>’) the best <E2 name="explanation" italic />.
      </p>,
//    text: <div className="problem-text">
//      <p></p>
//    </div>,
  },

  // This is super RUDE
  {
    name: "rude",
    alternatives: "impolite,discourteous",
    prelude: <span>
      Even when the website doesn’t appear to be just <OML name="offensive"><i>trying</i> to provoke you</OML>…
    </span>,
    header: <span>
      <WName noNW cap /> is extremely <i>rude</i>.
    </span>,
    outside: "What would Miss Manners say?",
    preview: <div className="problem-preview">
      <p>One of the biggest potential ‘<QL>roadblocks</QL>’ to <WName /> is – beyond its sheer <PL name="uncomfortable">unlikability</PL> – that it seems to have about zero regard for your <PL>individuality</PL>, your <PL name="fungibility">uniqueness</PL>, your <PL name="neoliberals">liberal values</PL>… or (frankly, my dear) your <i><PL>feelings</PL></i> at all: the <PL name="ick.systems">analytical</PL> and <PL>uncaring</PL> <QL>Weltanschauung</QL> lumps <PL name="sweeping">everything</PL> together and then <AL name="systems">dissects (‘analyses’)</AL> it with no regard whatsoever to <i>polite society</i>. How is this okay?</p>
      <p>This ‘rudeness’ can – however – be very simply <E2 suffix="ed" />:</p>
      <ol>
        <li><QL>Human</QL> ‘society’ is made up of a whole bunch of <NW>heterognostic</NW> <NW word="poc">POCs</NW> (<WName ically />: an ‘<LRL name="gaia">organism</LRL>’ with <AL name="sick.">DID</AL>).</li>
        <li>These <NW word="poc">POCs</NW> – being <NW>heterognostic</NW> – were thus <QL name="gnosticity.ig">subject</QL> to <NW>individual greed</NW>.</li>
        <li>Part of that <NW>individual greed</NW> was the <PL>desire</PL> for <i><PL>privacy</PL></i> (and ‘<PL name="privacy">secrets</PL>’).</li>
        <li>Rather than make the proclamation that <QL name="pg.privacy"><i>privacy</i> was the problem</QL> (which would have been dangerously <PL name="ick">unpopular</PL> under <OML>authoritarianian</OML> (think revolt), and unlikely to ever ‘pass’ under a ‘democracy’ of non-<LRL name="motto">critically-thinking</LRL> <AL>sleeping</AL> <PL>individuals</PL> who were <LRL>reactive</LRL> to the <QL name="evolution.darwin">rules</QL> of the <NW>Phanerozoic Eon</NW>), they invented a <PL name="sweeping.">system</PL> of rules (‘manners’) to govern the interactions between these <NW>heterognostic</NW> <NW word="poc">POCs</NW> so that each one could keep its <PL>individual</PL> <i><PL>privacy</PL></i>.</li>
        <li>Then this system (‘manners’) got so baked into the fabric of humanity that it seemed <i>appalling</i> (‘disgusting’, ‘grotesque’, ‘unthinkable’) to suggest that manners (like all of the ‘<LRL>game</LRL>’ were just an <NW>illusion</NW> </li>
        <li>Until this presentation of <WName /> (leaving <LRL name="motto">no ‘stone unturned’</LRL>) just did.</li>
      </ol>

      </div>,
    text: <div className="problem-text">
{/* ‘’ … – † */}
      <p>You probably knew that the <E2 word="explanation" /> of <i>manners</i> (‘politeness’) would pull in one of the <PL name="sweeping.handful">well-loved</PL> <E2 word="explanations" /> of this <QL>Weltanschauung</QL>: in this case, the notion of <NW>illusions</NW>. Turns out that <i>manners</i> (like <PL>individuality</PL> itself) is just an <NW>illusion</NW> – and the <PL name="sweeping.ig">brings in</PL> <NW>individual greed</NW>.</p>
      <p>Here’s <WArticle /> <WName ic /> way to make sense of it all:</p>
      <p>(And remember, <i><PL>privacy</PL></i> is the real ‘<PL>evil</PL>’.)</p>
      <ul>
        <li>‘Manners’ is a way to facilitate interactions (such as <NW>explainage</NW>) between <NW word="poc">POCs</NW> who do not <LRL name="communism">share thoughts</LRL>.</li>
        <ul>
          <li>It is a way for various <NW>heterognostic</NW> <PL>individuals</PL> to agree on (i.e., not <AL name="illusions">waste their time arguing over</AL>) the rules of the ‘<LRL>game</LRL>’.</li>
        </ul>
        <li>However, the rules of ‘manners’ are <i>not</i> designed for true (<WName ic />) <LRL>communism</LRL>, nor for <QL>radical openness</QL>; they are designed to allow each <NW>POC</NW> to keep its <i><PL>privacy</PL></i>, especially those who ‘<AL name="notnew.lennon.">own</AL>’ something that the ‘other’ </li>
        <ul>
          <li>That is quite literally the <NW word="individual greed">definition of individual greed</NW>: to want something for one’s ‘<AL>self</AL>’ that is not shared with the rest of the conscious ‘<QL>body</QL>’.</li>
          <li>But it would be ‘rude’ to demand that they share.</li>
        </ul>
      </ul>
      <p>[Under construction]</p>
    </div>,
  },

  ],
};

