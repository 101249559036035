import React from 'react';
import ButtonBox from './ButtonBox';
import {SillyName} from './pages/common';
import {Outlet, useLocation} from 'react-router-dom';
import {shuffle} from './pages/common';
import './Menu.css';

const menuItems = [
{
  url: "problems",
  header: "There are some ‘uncomfortable’ parts.",
  text: "Potential problems",
  footer: <span>(for any 21<sup>st</sup>-century ‘average <SillyName persist />’)</span>,
},
{
  url: "ormaybe",
  header: "Leave no stone unturned.",
  text: "Or maybe…",
  footer: "Creativity will be our salvation",
},
{
  url: "angles",
  header: "Why all the wishy-washiness?",
  text: "There are many angles",
  footer: "(By which one might approach this.)",
},
{
  url: "neologisms",
  header: "The word for it could be ‘coinage’.",
  text: "Neologisms",
  footer: "They’re ubiquitessential.",
},
{
  url: "faq",
  header: "And if you were wondering…",
  text: "FAQ",
  footer: "(Feasibly-asked questions.)",
},
{
  url: "longreads",
  header: "Take a dive into the ‘deep end’.",
  text: "Long reads",
  footer: <span>It’s the price of <i>understanding</i>.</span>,
},
/*{
  url: "openletter",
  text: "An open letter to humanity",
  footer: "…to not kill our shared consciousness, please?",
},*/
];

function getMenuURL(location) {
  if (!location) {return null;}
  const {pathname} = location;
  if (!pathname) {return null;}
  const splitURL = pathname.split("/");
//  console.log("split:", splitURL);
  for (var i=0;i<splitURL.length-1;i++) {
    if (splitURL[i] == "menu") {
      return splitURL[i+1];
    }
  }
  return null;
}

export function getSectionTitle(menuURL) {
  if (!menuURL) {return null;}
  for (var i=0;i<menuItems.length;i++) {
    const item = menuItems[i];
    if (menuURL == item.url) {
      if (item.title) {return item.title;}
      else return item.text;
    }
  }
}

function Menu(props) {
  const location = useLocation();
  const menuURL = getMenuURL(location);
  const sectionTitle = getSectionTitle(menuURL);
  const menuInOrder = (Math.random() < 1/Math.PI) ?
    shuffle(menuItems) : menuItems;
  const menuList = menuInOrder.map((item) => {
    if (!item) {
      console.log("bad item: ", item, menuItems, menuInOrder);
alert('oops');
      return;
    }
    return <div key={item.url} className='menu-item'>
      <ButtonBox
        src={item.url}
        header={item.header}
        text={item.text}
        footer={item.footer}
        onClickButton={()=>this.set}
        className={"menu-" + item.url}
      />
    </div>;
  });
  return (
    <div id="menu-wrapper">
      <div id="page-top">
        {sectionTitle && <h1 className={"section-title " + menuURL + "-title"}>
          >> {sectionTitle}
        </h1>}
        {menuURL && <div className="back">
          <ButtonBox src="/menu">Main menu</ButtonBox>
        </div>}
      </div>
      <div style={{clear: "left"}} />
      <Outlet />
      <hr />
      <div id="menu">
        {menuList}
      </div>
    </div>
  );
}

export default Menu;
