import React from 'react';
import WName, {WArticle} from '../WName';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import AL from './AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {SillyName, E1, E2, Qual, Rando} from '../common';

// This isn't actually NEW. 
export const notnew = {
  name: "notnew",
  parentName: <span>
    humanity’s past versions of <WName noNW />
  </span>,
  prelude: <span>
    Maybe it <i>feels</i> like <WName /> is a ‘<PL name="uncomfortable">bolt from the blue</PL>’… but it’s been said before.
  </span>,
  header: <span>
    This is not entirely new.
  </span>,
  outside: <span>
    Maybe not so <PL name="ick.rude">directly</PL> (and <i><LRL name="scope">literally</LRL></i>), but the concept of <QL>unity</QL> – unlike a lot of these <PL name="explainage">bizarre</PL> <i><NW home>words</NW></i> – isn’t an invention <PL name="ick.time">of the 2020’s</PL>.
  </span>,
  preview: <div className="angle-preview">
    <p>It’s been said time and again – particullarly in religion, but not <i>only</i> in religion. Earth has had a lot of laypeople  say things like that (maybe mostly in the arts, but <QL name="salvation">creativity will be our salvation</QL>). It’s just that the technology to ‘<NW>coalesce</NW>’ into a ‘<QL name="hivemind">hive mind</QL>’ wasn’t even close to existing back when they said it.</p>
    <p>Now it is.</p>
  </div>,
  sublist: [

  // Jesus
  {
    name: "jesus",
    prelude: <span>
      The <QL>author(s)</QL> wouldn’t dare compare their ‘<AL>self</AL>’ (i.e., <AL name="container">physical container(s)</AL> to hold their <NW word="poc">POC(s)</NW>) to a figure revered by so many… but this <i><OML name="ideas">idea</OML></i>?
    </span>,
    header: <span>
      Maybe <WName noNW /> is Jesus 2.0.
    </span>,
    outside: <span>
      That would make it the <i>second</i> time <QL>humanity</QL> gets a message like this. Let call it the ‘second coming’.
    </span>,
    preview: <div className="angle-preview">
      <p>If you actually look at what Jesus is purported to have said and done, it isn’t at all like the ‘Church’ that grew up after him and hijacked his ‘<AL>identity</AL>’. The ‘Church’ was the worst of <QL>humanity</QL>’s <NW>individual greed</NW>. But the actual <NW>POC</NW> that the <NW>MNS system</NW> of our day ghas retroactively called ‘Jesus’? He said things like, ‘<NW>God</NW> is <PL>love</PL>,’ or, ‘You are <i>all</i> (Jew or non-Jew: in the in-group or <LRL name="them">not</LRL>) a <AL name="dysfunctional.fragments">part</AL> of <NW>God</NW>.’</p>
      <p>And if he said anything about <OML name="god">external gods</OML> or <OML name="soul">individual souls</OML> or the <OML>afterlife</OML>… well? It was <i>Jesus</i>. He spoke in <i><QL>metaphors</QL></i>. That was kind of his ‘thing’.</p>
      <p>If he’d been around now, probably Jesus would’ve been <WArticle /> <WName ist />.</p>
    </div>,
  },
  // John Lennon
  {
    name: "lennon",
    parentName: "John Lennon",
    prelude: <span>
      If John Lennon was yet another inncarnation of the same idea, then this is at <i>least</i> <AL name="notnew.jesus">Jesus 3.0.</AL>.
    </span>,
    header: <span>
      Imagine.
    </span>,
    outside: <span>
      Doesn’t ‘we could all <i>be</i> as one’ sound a lot like like ‘<NW>coalescence</NW>’?
    </span>,
    preview: <div className="angle-preview">
      <p>Our <QL>salvation</QL> will be imagination: to <i>imaginatively</i> look beyond everything that ever has been up to this point (i.e. the <NW>Phanerozoic Eon</NW>; nix <NW>conservative thinking</NW>) and envision a <QL name="polygnosticity.coalescence">future state</QL> where <i>misunderstanding</i> will be a thing that we – as a unified <QL name="hivemind">hive mind</QL> – will look back on and roll some of our many eyes.</p>
    </div>,
    text: <div className="angle-text">
      <p>It doesn’t actually matter if this is or is not <i>exactly</i> what Lennon intended when he wrote the lyrics of his song. <WName cap /> is a way to ‘be as one’ that looks like, one day, it will actually be possible.</p>
      <p>In the mean-time (while it’s <i>not</i> possible yet) the important thing is to maintain the imaginative capacity to look into the future (beyond the ‘singularity’ of machines developing consciousness) and realise that the enemy isn’t AI. The enemies are <NW>polygnosticity</NW> and <NW>individual greed</NW>.</p>
      <p>Imagine no <NW word="nation-state system">countries</NW>. Imagine no <NW word="money system">possessions</NW>. One day, our planet will be united; we can forget all about <NW>individual greed</NW> and look to the stars.</p>
      <p><PL name="endsjustifymeans">Whatever it takes.</PL></p>
    </div>,
    sublist: [

    // War
    {
      name: "war",
      alternatives: "peace",
      prelude: <span>
        Ants have wars. Either ants are <OML name="animals">as conscious</OML> as <QL>humans</QL>, or warmongering <QL>humans</QL> are as <i>unconscious</i> (<LRL>reactive</LRL>) as ants.
      </span>,
      header: <span>
        <i>Can</i> we give peace a chance?
      </span>,
      outside: <span>
        Probably peace <LRL name="gaiacentrism">on Earth</LRL> is not even <i>possible</i> as long as <QL>humans</QL> perceive <LRL name="usandthem">an ‘us’ and a ‘them’</LRL>; once (or <i><OML name="kaput">if</OML></i>) <QL>humanity</QL> manages to <LRL>shift</LRL> its <AL>mindset</AL>, then we will see.
      </span>,
      preview: <div className="angle-preview">
        <p>Fair warning: <WName ic /> <LRL>communism</LRL> does not work under the <NW>money system</NW>, <NW>nation-state system</NW>, <NW>family system</NW>, or any other ‘<NW>illusions</NW>’ (such as belief in some <LRL name="motto">hypothetical</LRL> <OML>external God</OML> or persistent individual ‘<OML>soul</OML>’ persistent in its <PL>individuality</PL>) that run rampant today in most of <LRL name="gaia">Planet Earth</LRL>’s <i><PL>systems</PL></i> in the 2020’s.</p>
        <p>If any of those are universally true at the bottom ‘<NW>level</NW>’ (which would include <QL name="pg.">subjectivity</QL>, as that can only truly arise if <OML name="levels">there is <i>no</i></OML> ‘bottom’ <NW>level</NW> below the perception of <PL>individuality</PL> that arises from <LRL name="gaiacentrism">Earth-based</LRL> <AL name="disciplines.biology">biological</AL> <NW>POCs</NW> being <NW>heterognostic</NW> from one ‘another’) then <QL name="|unity">unity consciousness</QL> (the ‘rock’ upon which this whole thing is built) is proven <i><OML>wrong</OML></i>, and peace may <i>not</i> be a goal for anyone.</p>
        <p>Okay, shouldn’t hold you up any longer. Don’t you have to get out and kill those <OML name="hell">infidels</OML>?</p>
      </div>,
    },
    // Possessions
    {
      name: "possessions",
      alternatives: "own,owns,owned,possesses,possessed",
      header: <span>
        Imagine no possessions.
      </span>,
      outside: <span>
       It’s <WName ic /> <LRL>communism</LRL>: we share <i>everything</i>.
      </span>,
      preview: <div className="angle-preview">
        <p>[Under construction]</p>
      </div>,
    },
    // Countries
    {
      name: "countries",
      header: <span>
        Imagine no countries.
      </span>,
      outside: <span>
       Just <LRL name="gaia">Earth</LRL>, <QL name="unity">our consciousness</QL>, and <OML name="aliens">the rest</OML> of <QL name="unity.1">the universe</QL>.
      </span>,
      preview: <div className="angle-preview">
        <p>[Under construction]</p>
      </div>,
    },

    ],

  },
  // Buddha
  {
    name: "buddha",
    prelude: <span>
      It’s basically just ‘<NW>enlightenment</NW>’.
    </span>,
    header: <span>
      Come to ‘nirvana’.
    </span>,
    outside: <span>
      It’s not a physical place. It’s a state of mind.
    </span>,
    preview: <div className="angle-preview">
      <p>Buddhism is very <PL name="individuality">individualistic</PL>. But the <i>end</i> of Buddhism (the goal) is to <i><NW word="transcendence">transcend</NW></i> the ‘<AL>self</AL>’. At that point, you are both living in the <NW>Mechanozoic Eon</NW> and in ‘Nirvana’.</p>
    </div>,
    text: <div className="angle-text">
      <p>And you don’t have to <i>die</i> to do it; in fact, you <i>can’t</i> die, because the exact individuality that is ‘you’ will never come back. Reincarnatiion doesn’t work that way. It’s more that we’re all part of the same <QL name="unity">unified consciousness</QL> that bubbles off into human individualities.</p>
      <p>Oh yeah, it might be pertinent to note that this <PL name="comprehensive">boundless</PL> worldview walks all over Buddhism, too.</p>
      <p>Seeing that <WName /> is saying a lot of the <i>same things</i> as Siddhartha, it’s hard to imagine that he’d mind. (But of course <i>his</i> exact individuality is dead; ‘reincarnation’ isn’t so literal.)</p>
    </div>,
  },
  // Copernicus
  {
    name: "copernicus",
    prelude: <span>
      The only reason that history repeats itself is <NW>conservative thinking</NW>
    </span>,
    header: <span>
      This already happened to Copernicus (and Gallileo).
    </span>,
    outside: <span>
      If the one thing across the history of the universe that doens’t change is change itself, the one thing on <i>Earth</i> that hasn’t changed is that <i>no one seems to</i> like <i>change</i>; we would rather a known evil to a <QL>salvation</QL> that we <PL name="invisible">can’t see</PL>.
    </span>,
    preview: <div className="angle-preview">
      <p>In his time, humans thought that, just because they lived on Earth, that this space-rock somehow <i>mattered</i>. Like, that the whole bloody <i>universe</i> was centred around our planet. Copernicus proposed that no, just because we <i>happen</i> to live on Earth, Earth is <i>not</i> that central to anything.</p>
      <p>When poor Gallileo tried to build on that theory, all hell broke loose.<sup>†</sup></p>
      <p className="page-footnote">† Literally. The Church invented ‘Hell’, and they unleashed it upon the poor visionaries who dared to defy their power. Now power is in the <NW>MNS system</NW>, and the main weapon is ‘<QL name="roadblocks.cancelling">cancellation</QL>’, but it’s the same idea.</p>
    </div>,
    text: <div className="angle-text">
      <p>It’s <NW>conservative thinking</NW>. Which is a form of (guess what?) <NW>individual greed</NW>.</p>
      <p>How is it individual greed? Why else would anyone fear newness or <i>change</i>, but to hold onto that which their <PL>individuality</PL> ‘possesses’?</p>
    </div>,
  },
  // Plato
  {
    name: "plato",
    parentName: "Plato",
    prelude: <span>
      ‘<OML>Forms</OML>’ and all that <AL name="illusions">might have been a long shot</AL>, but Plato had a lot of it spot-on.
    </span>,
    header: <span>
      Plato imagined this over 2000 years ago.
    </span>,
    outside: <span>
      Like, you know, how <QL name="anthropocentrism">humanity</QL> is living in a <AL name="notnew.plato.">cave</AL>.
    </span>,
    preview: <div className="angle-preview">
      <p>Plato’s notions may be off-putting to many ‘<PL name="neoliberals">liberal</PL> Westerners’, because they tended to lean towards <OML>authoritarianism</OML>. Well, here that’s not such a problem; when you’re <LRL name="motto">leaving no stone unturned</LRL> you have to come face-to-face with the truths that might be (to your <PL>individuality</PL>) <i><PL>uncomfortable</PL></i>.</p>
      <p>And Plato was an <i>elitist</i>, yes… but anyone who uses the <NW>money system</NW> (or anything less than <WName ic /> <LRL>communism</LRL>) doesn’t <i>really</i> believe in ‘<PL>equality</PL>’ – and Plato had some good ideas.</p>
    </div>,
    text: <div className="angle-text">
      <p>Such as:</p>
      <ul>
        <li><PL name="sweeping">All</PL> of <QL name="anthropocentrism">humanity</QL> is living in a <AL name="notnew.plato.">cave</AL>.</li>
        <li><OML>Democracy</OML> sucks; bring us some ‘<AL name="notnew.plato.kings">philosopher-kings</AL>’.</li>
        <li>And the ideas that sprung from his search for lower <i><OML>levels</OML></i> of truth led to ‘<AL name="notnew.plato.">Neoplatonism</AL>’ – which is basically the <AL name="notnew.jesus">pre-Christian</AL> version of this <QL>Weltanschauung</QL>.</li>
      </ul>
    </div>,
    sublist: [

    // Cave
    {
      name: "cave",
      header: <span>
        Most people live in a cave, and only see shadows.
      </span>,
      outside: <span>
        <QL>Humanity</QL>, get the <OML name="offensive">f_ck</OML> out of the cave.
      </span>,
      preview: <div className="angle-preview">
        <p>Getting out is uncomfortable. <PL name="uncomfortable"><i>Profoundly</i> uncomfortable</PL>. Why? Having lived in a cave all your life, the light of the sun (direct, <LRL name="deep">bottom</LRL>-<NW>level</NW> truth) hurts your eyes.</p>
        <p>This is called (in psychology) the ‘status quo bias’. It says that <PL name="sweeping">most</PL> <QL name="anthropocentrism">people</QL> will prefer what they <i><QL name="pg.">know</QL></i> over something the <i>don’t</i> – even sometimes if the unknown is demonstrably <i>better</i>.</p>
        <p>What this says about <QL>humans</QL>: they prefer <i>feeling</i> to <AL name="fields.">logic</AL>, <i>pathos</i> to reason.</p>
        <p>Maybe <LRL name="gaia">Earth</LRL> just needs – as another of <AL name="notnew.">Plato</AL>’s ideas – some good <OML>authoritarian</OML> <AL name="notnew.plato.">philosopher-kings</AL>.</p>
      </div>,
    },

    // Neo-Platonism
    {
      name: "neoplatonism",
      alternatives: "neo-platonism",
      prelude: <span>
        For how long has <QL name="anthropocentrism">humanity</QL> known (or should have known) that <QL name="unity.1">the ‘answer’ is ‘1’</QL>?
      </span>,
      header: <span>
        Welcome to the <WName noNW /> of antiquity.
      </span>,
      outside: <span>
        If the Ancient Greeks solved this all ages before us, why are we still fighting wars and seeming to try (as a conscious <QL>body</QL>) to commit <AL name="sick.">suicide</AL>?
      </span>,
      preview: <div className="angle-preview">
        <p><QL name="polygnosticity.tldr">TL;DR</QL>: long before the term ‘<WName />’ was first coined (as a means of <NW>explainage</NW>) by <PL name="special">whatever</PL> <NW>POC</NW> first ‘<AL name="notnew.shakespeare">coined</AL>’ it to <E1 /> this <QL>Weltanschauung</QL>, the Ancient Greeks had developed (out of <AL name="notnew.plato">‘Platonic’ philosophy</AL>) a worldview that is basically the same thing.</p>
      </div>,
      text: <div className="angle-text">
        <p>The ‘school’ of Neo-platonism was a lot like <WName />: not a <i>religion</i> per se, but it was a way of looking at the world (<QL>Weltanschauung</QL>) that <E2 suffix="ed" /> the observable universe pretty darn well (i.e., like <WName /> it ‘<AL name="framework.works">worked</AL>’ pretty well).</p>
        <p>Neoplatonism <i>did</i> believe in <OML>Forms</OML>, but only as an <AL name="fields.physics">emergent property</AL> of something <i>deeper</i> – which they called ‘the One’. This ‘One’ was so ‘ineffable’ that there was no way to even <i>describe</i> it.</p>
      </div>,
    },

    // Philosopher-kings
    {
      name: "philosopher-kings",
      alternatives: "philosopher-king,kings",
      prelude: <span>
        Who ever thought it was a good idea to give the important decisions to <i>ordinary people</i>?
      </span>,
      header: <span>
        Earth needs some philosopher-kings.
      </span>,
      outside: <span>
        They’ll live individually basic and fully-exposed lives and make all the hard decisions so that the ‘masses’ don’t have to.
      </span>,
      preview: <div className="angle-preview">
        <p>So, Plato… The <AL name="notnew.plato">cave metaphor</AL> is pretty self-explanatory, but what about the tyrants ruling over the ignorant masses?</p>
        <p>That’s probably one of the best ideas from Ancient Greece. Because <i><OML>democracy</OML></i> isn’t working.</p>
      </div>,
      text: <div name="angle-text">
        <p>Here’s how democracy works in a world where the majority of humanity are <AL>sleeping</AL>:</p>
        <ol>
          <li>A few <NW word="individual greed">individually-greedy</NW> bastards manage to <i><LRL name="politics">manipulate</LRL></i> the masses into supporting them.</li>
          <li>But they’ve got hidden (and <NW word="individual greed">individually-greedy</NW>) agendas.</li>
          <li>Because of this, it never ends up helping the <LRL name="gaia">planet</LRL>.</li>
          <li>It ends up helping one group (‘<AL name="dysfunctional.">organ</AL>’ of the ‘<QL>body</QL>’: tribe, nation, etc.) – often at the expense of the rest.</li>
          <ul>
            <li>That’s the <WName ic /> definition of ‘<PL>evil</PL>’.</li>
          </ul>
        </ol>
        <p>Switch to the rule by ‘philosopher kings’. Yes, this is basically <OML>authoritarianism</OML>… but let’s firsl make some <i>rules</i> for these ‘kings’ – to which <i>they</i> are fully beholden, but against which your average {<SillyName />} (with little to no real-world power) is not measured as strictly.</p>
        <p>To be a ‘philosopher king’ would mean to essentially renounce the <NW>money system</NW> and live an extremely basic life, <LRL name="disagreement">understanding</LRL> <WName /> (so you wouldn’t feel a <i>need</i> for a more grandiose <PL name="individuality">individual</PL> life). Only from such a reduced position is one qualified to make decisions that will help <LRL name="gaia hypothesis">the whole of this planet</LRL>. (If the ‘king’ isn’t allowed to hold ‘personal’ property – or live luxuriously – it reduces the chances of corruption by <NW>individual greed</NW>.)</p>
        <p>It also means to renounce <PL>privacy</PL>. To be a ‘philosopher king’ means to surrender your <PL>individuality</PL> to the <LRL name="communism">collective</LRL>. And if you’re thinking that’s anything less than having public cameras in every room of your house (including the bedroom, including the bathroom), think again. This is <QL>radical openness</QL>. As a ‘king’ you’re on top of the ‘<PL name="equality">societal ladder</PL>’; <QL>radical openness</QL> therefore applies to you <i>first</i>.</p>
        <p>Now we need some philosopher-kings. Any volunteers?</p>
      </div>,
    },
    ],
  },
  // Marx
  {
    name: "marx",
    prelude: <span>
      Does <i>sharing</i> everything sound a lot like <i>communism</i>?
    </span>,
    header: <span>
      This is Marxism on steroids.
    </span>,
    outside: <span>
      Except that a <WName ist /> wouldn’t like to name it after an <i><PL>individual</PL></i>.
    </span>,
    preview: <div className="angle-preview">
      <p>This isn’t just communism. This is <i><WName ic /> <LRL name="communism">communism</LRL></i>. Rather than claim (as Marx) that the problems of the proletariat come from not having ownership of the means of production, <WName /> <i>acknowledges</i> that most people don’t have ownership of the ‘means of production’ – and by that isn’t meant just <i>factories</i>, but controlling the thing that’s really now (2020’s) the oil in the gears of the <LRL name="gaia hypothesis">Earth-machine</LRL>: <i><NW>money</NW></i>.</p>
      <p><WName cap /> is mostly a <i>descriptive</i> and not a <i>prescriptive</i> <QL>Weltanschauung</QL>: just observing what already <i>is</i>. It therefore observes the <AL>dysfunctional</AL> mess that is <LRL name="gaiacentrism">Earth’s</LRL> conscious <QL>body</QL>, and <i>diagnoses</i> more than (as Marx did) <i>prescribe</i>. It’s building humanity a <NW home>vocabulary</NW> with which y’all can <NW word="explain">explain<sup>(2)</sup></NW> and <i>understand</i> the situation into which you – through either accident, negligence, or <NW>individual greed</NW> (but, honestly, mostly <NW>individual greed</NW>) – have got our <QL name="unity">shared</QL> ‘<AL>self</AL>’.</p>
    </div>,
  },

  // Shakespeare
  {
    name: "shakespeare",
    prelude: <span>
      Dost thou have a problem with thy <i>explainage</i>, good <Rando options={["sir", "madam", "gender non-binary person"]} />?
    </span>,
    header: <span>
      Shakespeare coined the word ‘luggage’; this site coins ‘explainage’.
    </span>,
    preview: <div className="angle-preview">
      <p>When William Shakespeare was unable to express (<E1 />) something to his satisfaction, he just made up a new word and trusted that his audience would understand.</p>
      <p>The <PL name="explainage">problems</PL> of <NW>explainage</NW> certainly are not <i>new</i>.</p>
    </div>,
  },

  // Maslow
  {
    name: "maslow",
    prelude: <span>
      It’s like a house of cards.
    </span>,
    header: <span>
      Maslow’s pyramid of needs is a way to understand the instability of ‘transcendence’.
    </span>,
    preview: <div className="angle-preview">
      <p>The very top of ‘Maslow’s pyramid’ is ‘<NW>transcendence</NW>’: from which a human-trapped can (<QL name="salvation">imaginatively</QL>) see past the <NW>illusion</NW> of <PL>individuality</PL> et al. and <LRL>imagine</LRL> a way <QL name="evolution">forward</QL> (for <LRL name="gaia">Earth all</LRL>).</p>
      <p>The problem is that a <AL name="handicap">human body</AL>’s biological needs – and human <i>society</i> (i.e., the <NW>MNS system</NW>) – tend to pull those middle rungs of the ‘pyramid’ out at a whim, potentially bringing the ‘<NW>enlightened</NW>’ <NW>POC</NW> crashing right back down into the ‘<LRL>game</LRL>’.</p>
      <p>The need to have ‘others’ in your life (as <PL>love</PL>) is particularly challenging, because they can easily yank you down. As <WName ists /> we probably shouldn’t all be loners, and we probably shouldn’t just glum together and dream big while the rest squabble over their ‘ethics’ and ‘rights’, but once you see the bigger picture it really <i>is</i> disheartening (<Qual.maybe />) to go back to <AL name="illusions">arguing over illusions</AL> with your ‘average <SillyName />’.</p>
      <p>[Under constructin.]</p>
    </div>,
  },

  ],
};


