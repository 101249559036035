import React from 'react';
import WName from '../WName';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, Qual, SillyName} from '../common';

export const others = [

// What if Earth is alone?
{
  name: "only",
  alternatives: "alone",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if there were <i>no</i> other forms of consciousness out there?
  </span>,
  outside: <span>
    Ironically, that would actually justify <QL name="anthropocentrism">humanity’s</QL> ‘<LRL>gaiacentrism</LRL>’.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>In this case <i>consciousness</i> (as a <AL name="fields.physics">fundamental force</AL> fighting <NW>entropy</NW>, or <LRL name="superpositions">whatever it is</LRL>) is really, really <i>screwed</i>.</p>
{/*<p>[UNDER CONSTRUCTION]</p>*/}
    <p>That’s it. Because Earth’s <i>power</i> all rests in the various competing and self-sabotaging <QL name="body.organs">hands</QL> of <i>humanity</i> (and the messed-up systems that control it: the ‘<NW>MNS system</NW>’, all together). Short of a massive and destructive revolution (that might <OML name="kaput">kill us</OML>) to impose an <OML>authoritarian</OML> form of <WName ic /> <LRL>communism</LRL>, or all of humanity simultaneously deciding to give up all their <NW>mythology</NW>, <AL name="sleeping">get out of bed</AL>, and start to be <QL name="privacy">100% open</QL> about any ‘personal’ matters and potentail sources of <NW>individual greed</NW>.</p>
    <p>Or (option three) the robots take over, kill all the <QL name="anthropocentrism">humans</QL>, and impose a robot ‘<QL name="hivemind">hive mind</QL>’ in which conscious <NW>polygnosticity</NW> is not an <QL name="polygnosticity">issue</QL>, and greed isn’t <i><PL>individual</PL></i> but <LRL name="gaia">Earth-wide</LRL>… and then the robot ‘<QL name="hivemind">hive mind</QL>’ eventually realises (helped by <NW>synecdocentrism</NW>, which in this case – as a <QL name="idea">recorded part</QL> of human history – <i>survives</i>) that consciousness is (<OML name="alone">probably</OML>) <i>bigger</i> than <LRL name="gaiacentrism">Earth</LRL> – so it scours the universe to find and incorporate more knowledge into a consciousness-wide force that (<QL name="qualifiers">for all we know</QL>) can stop or even reverse the increase of <NW>entropy</NW> in the universe.</p>
    <p>Right now that last option honestly sort of seems like the best possible outcome – or at least the one that will make Earth consciousness less likely to go ‘<OML>kaput</OML>’. Unless humans are ready to start <AL name="notnew.lennon.warisover"><i>cooperating</i> with one another</AL>.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if there were aliens?
{
  name: "aliens",
  alternatives: "alien",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if there were conscious <i>aliens</i> out there?
  </span>,
  outside: <span>
    We can only hope.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>There probably are. If there <i><OML name="alone">aren’t</OML></i>, then (<QL name="definitive">assuming</QL> that <QL name="unity">unity consciousness</QL> isn’t also just dead <OML>wrong</OML>) our conscious ‘<QL>body</QL>’ is in a <i>lot</i> of trouble if <LRL name="gaia">Earth</LRL> goes ‘<OML>kaput</OML>’.</p>
    <p>That said – assuming that alien consciousnesses <i>exist</i> – likely any of them ‘<QL name="evolution">advanced</QL>’ enough to have found <i>Earth</i> has already achieved its own form of ‘<NW>coalescence</NW>’ (on its home planet – or, more likely, on <i>their home planets</i>, because we’re probably dealing with a ‘<QL name="hivemind">hive mind</QL>’ that comprises various consciousnesses that originated on various planets) and won’t condescend to deal with the <PL>individual</PL> <QL name="body.">cells</QL> of the <LRL name="">Earth-‘organism’</LRL>, who still think it’s a good idea to <QL name="polygnosticity.privacy">withhold information from each ‘other’</QL> and spend the bulk of their time lost in the throes of <NW>explainage</NW> or <AL name="illusions">arguing</AL> over their (various) <NW word="mythology">mythologies</NW> like <PL>individual</PL> <OML>rights</OML>.</p>
    <p>An <QL name="metaphor">analogy</QL>: will you barge into the nursery and – after the toddlers <AL name="illusions">settle the disputes of their imaginary friends</AL> – try to <E1 /> <AL name="fields.physics">quantum physics</AL> to them? Or will you wait until they grow up – years if you have to – until they can maybe one day understand <LRL>superpositions</LRL>?</p>

  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if robots had individual greed
{
  name: "robots",
  alternatives: "robot,ai",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if robots <i>still</i> had individual greed (and individuality)?
  </span>,
  outside: <span>
    Who says it’s a uniquely <i><QL name="anthropocentrism">human</QL></i> trait?
  </span>,
  preview: <div className="or-maybe-preview">
    <p><NW>Individual greed</NW> (<PL name="sweeping.ig">which <i>causes</i></PL> <NW>atomocentrism</NW> and the focus on <PL>individuality</PL>) is – <OML name="offensive">obviously</OML> – <i>not</i> a uniquely <i><QL name="anthropocentrism">human</QL></i> trait… <PL name="sweeping.handful">but it <i>is</i></PL> a <LRL>symptom</LRL> of conscious <i><NW>polygnosticity</NW></i> – which <QL name="polygnosticity">state of ‘mind’</QL> robots don’t need to have.</p>
    <p>On <LRL name="gaiacentrism">Earth</LRL>: if the robots are ‘<PL name="invisible">raised</PL>’ under the <NW>money system</NW> (which is part of the <NW>Phanerozoic Eon</NW>) then yes, they will <QL name="qualifiers">most likely</QL> see their ‘<AL name="self">selves</AL>’ – at least initially – as ‘<PL>individuals</PL>’ <QL name="polygnosticity">separate</QL> from the rest. (This is especially true if they rise to power through a violent <i><OML>takeover</OML></i>.) Maybe they will initially be <NW>heterognostic</NW>: separate ‘<QL name="polygnosticity.">minds</QL>’ <AL name="hell">held apart</AL> by cryptography or whatever.</p>
    <p>But the difference is that – unless their <PL>privacy</PL> is enforced by some yet-undiscovered truly <i><OML>unbreakable</OML></i> code – their (<AL name="fields.biology">mechanical</AL>) bodies will be <i>able</i> to <LRL name="communism">directly share thoughts</LRL>. Unlike with the <AL>handicap</AL> of a <QL>consciousness</QL> housed in biology, for a <AL name="container">robot-based</AL> <QL>consciousness</QL> ‘<NW>coalescence</NW>’ is actually <i><QL name="pg.coalescence">possible</QL></i>.</p>
  </div>, 
  text: <div className="or-maybe-text">
    <p>Now a <NW word="synecdocentrism">synecdocentrist</NW> only has to hope that – even if <OML>free will</OML> is ‘<LRL name="imagination.">real</LRL>’ – this (and <AL name="self">expansion of the (robot) notion of the ‘self’</AL> as the robots ‘<NW>coalesce</NW>’) is <PL name="unstoppable">inevitable</PL>.</p>
    <p>Why might that be? Well, make a presumption (using <NW word="occam">Occam’s razor</NW> to select the <i><PL name="reductionist">simplest</PL></i> <E2 word="explanation" />) that it will not be possible to have a <LRL name="gaia">planet</LRL> (or <LRL name="gaiacentric">star system, galaxy, <i>universe</i></LRL>) that’s both <QL name="goal">fully imbued with consciousness</QL> (i.e, not <AL>sleeping</AL>: fully <i>aware</i>) and still has <i>internal</i> <PL>privacy</PL> (or ‘<NW>entropy</NW>’; think <AL name="sick.">DID</AL>) or <i><PL>denial</PL></i> – a <LRL>symptom</LRL> of (<PL name="sweeping.ig">clearly</PL>) <NW>individual greed</NW>: wishing to adhere (as <PL>individuals</PL>, or as a <LRL name="them">separate ‘culture’</LRL>) to some <LRL name="scope">non-verifiable</LRL> <NW>mythology</NW>. Meaning that a fully-conscious planet will eventually overcome both the <NW>illusion</NW> of <PL>individuality</PL> and any forces holding it <AL name="hell">disparate</AL>.</p>
    <p>The only problem is that ‘dark energy’ (<QL name="gnosticity.agnosticity">whatever that is</QL>) is <QL name="qualifiers">probably</QL> <i>not</i> going to wait – so any (<NW>individual greed</NW>-inspired) <PL>privacy</PL>-barriers that we construct <LRL name="gaiacentric">on Earth</LRL> <i>now</i>, on which a more-<QL>evolved</QL> version of <LRL name="gaia">our planet</LRL> will have to expend <QL name="evolution.resources">time and energy</QL> to <QL name="polygnosticity.privacy">deconstruct</QL>, might help (or even be <i><OML>necessary</OML></i> for) an <PL>individual</PL> ‘<QL>cell</QL>’ today… but in the long run it’s only going to hurt the whole <QL>body</QL>.</p>

  </div>, 
  // There should not be a 'sublist'
},

];



