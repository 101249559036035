import React from 'react';
import ButtonBox from '../ButtonBox';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import AL from './angles/AngleLink';
import NW from './neologisms/NewWord';
import LRL from './longreads/LongReadLink';
import {Outlet, useLocation} from 'react-router-dom';
import {getSubpaths} from './common';
//import './FAQ.css';

const intro = <p className="intro">Okay, these aren’t <i>really</i> ‘frequently asked’… because <NW>synecdocentrism</NW> hasn’t yet entered <LRL name="gaia hypothesis">Earth’s ‘cultural knowledge’</LRL>. This is an FAQ based on the <i><QL name="salvation">imagination</QL></i>: what human individuals <i>might</i> ask if a <NW word="synecdocentrism">synecdocentrist</NW> came out and tried to start preaching this <PL>comprehensive</PL> Weltanschauung. (‘Er, good sir, <PL name="sweeping">everything</PL> you thought you knew about <LRL name="gaiacentrism">the world</LRL> – and your “<AL>self</AL>” – turns out to be just an <NW>illusion</NW>.’) Yeah. In Earth’s current <NW>atomocentric</NW> culture that’s just a good way to get <i>shot</i> – and then your <NW>POC</NW> is just <i>dead</i>, and (as a <i>dead</i> ‘cell’) you’re not helping our <QL>body</QL> at all. So let’s start instead by pre-emptively tackling some of the <PL name="unready">questions and objections</PL> that might arise to this anti-anthropocentric, anti-<LRL name="gaiacentrism">Gaiacentric</LRL>, anti-<NW>atomocentric</NW> Weltanschauung.</p>

export default function FAQ() {
  const subpaths = getSubpaths(useLocation().pathname, "faq");
  const intro_version = (subpaths.length > 0) ? null : intro;
  return (
    <div className="page">
      {intro_version}
      <Outlet />
      <div className="backbuttons">
        <ButtonBox src={-1}>Back</ButtonBox>
        <ButtonBox src="/menu/faq">All FAQ</ButtonBox>
      </div>
    </div>
  );
};
