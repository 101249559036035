import React from 'react';
import OrMaybe from './OrMaybe';
import PreviewLink from '../PreviewLink';
import './OrMaybeLink.css';

export default function LongReadLink(props) {
  return <PreviewLink
    {...props}
    PreviewComp={OrMaybe}
    linkClassName="or-maybe-link"
    linkURLPrefix="ormaybe"
  />;
};
