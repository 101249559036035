import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, TLDR, Qual, Switcheroo} from '../../common';

// ‘’ … –

export const communism = {
  name: "communism",
  prelude: <span>
    We share <Switcheroo values={['televisions', 'video streaming', 'schoolbooks', 'trinkets', 'food', 'toys and games', 'novels', 'tools']} middle=", " omit={2} />, <Switcheroo values={["vehicles", "medical care", "special moments", "services", "opportunities"]} middle=", " omit={1} />, <Switcheroo values={["siblings", "kids", "parents and grandparents"]} middle=", " />, <Switcheroo values={["disabilities and super-abilities", "our individual weaknesses and our individual strengths"]} middle=", " />, our human blood and our human genome, <Switcheroo values={['understandings', 'hopes', 'dreams', 'fears', 'joys', 'yearnings']} middle=", " omit={1} />, our houses, our planet, our bodies… and even, one day, our <i>thoughts</i>.
  </span>,
  header: <span>
    This is <WName ic noNW /> communism.
  </span>,
  outside: <span>
    (And it’s <i>not</i> going to be popular.)
  </span>,
// – ‘’ …
  preview: <div className="long-read-preview">
    <p><TLDR />: three options, <QL>humanity</QL>:</p>
    <ol>
      <li><LRL name="gaia">Earth</LRL> gets a <LRL name="usandthem">global</LRL> <OML>authoritarian</OML> ‘regime’ (i.e., ‘<QL name="body.organs">brain</QL>’) that disallows any <PL>privacy</PL> (for anyone), using <PL>AI</PL> and <OML name="takeover">robots</OML> to keep any <QL>human</QL> ‘free radicals’ in line.</li>
      <li><QL>Humans</QL> fight WWIII (and <Qual.maybe /> WWIV and WWV) and eventually blow themselves up, and if the whole <LRL name="gaia">planet</LRL> doesn’t just go <i><OML>kaput</OML></i>, then it regresses to the <QL name="conscious">unconscious</QL> (<LRL>reactive</LRL>) force of <AL name="fields.">biology</AL> of the <NW>Phanerozoic Eon</NW>: a <QL>life</QL> without any <NW>agency</NW>, just <LRL>waiting</LRL>.</li>
      <li>Or <i><PL name="sweeping">every</PL></i> ‘<QL name="body.">cell</QL>’ of <QL>humanity</QL> just <OML name="">decides</OML> to stop being <LRL>reactive</LRL> and <QL name="change">start</QL> to <i><LRL name="shift">use</LRL></i> its <QL>consciousness</QL> to <AL name="sleeping">wake up</AL> (to our <OML name="wrong">probably</OML> <LRL name="deep">underlying</LRL> <QL>unity</QL>), <PL name="privacy">share things</PL>, and <AL name="notnew.lennon.peace">give peace a chance</AL>.</li>
    </ol>
    <p>The <PL name="endsjustifymeans">ends justify the means</PL> under <WName />, so this <QL>Weltanschauung</QL> (<OML name="ideas">if it could ‘care’</OML>) 100% doesn’t care <i>how</i> <LRL name="gaia">Earth</LRL> ‘<NW word="coalescence">coalesces</NW>’, as long as this <LRL>notion</LRL> off <LRL name="gaiacentrism">this rock</LRL> to help any <OML name="aliens">potential</OML> ‘<LRL name="them">others</LRL>’ out there and become part of ‘them’ (and ‘they’ become part of ‘us’).</p>
    <p>The ‘<OML>goal</OML>’ of this <AL>framework</AL> seems to be <LRL name="scope">universe-wide</LRL> <QL>conscious</QL> <NW>monognosticity</NW>: treating the <i><LRL name="deep">causes</LRL></i> of the observed <QL>conscious</QL> <AL name="sick">sickness(es)</AL>, and not just the <LRL>symptoms</LRL>.</p>
  </div>,
  text: <div className="long-read-text">
    <p>It’s not going to be <i><PL name="uncomfortable">popular</PL></i> among <QL>human</QL> <NW word="atomocentrism">atomocentrists</NW> (i.e., both <NW word="conservatism">conservatives</NW> and <PL>neoliberals</PL>)… but it’s actually the only way to make the dream of <QL name="individuals">historical figures</QL> like <AL name="notnew.marx">Karl Marx</AL> a reality: abolish the <LRL name="shift">notion</LRL> of <i><PL>privacy</PL></i>.</p>
    <p><AL name="notnew.marx">Marx and Engels</AL> had the right idea, however…</p>
    <ul>
      <li>Communism doesn't work without <i>full</i> transparency (from <PL name="comprehensive">all parties</PL> involved – eventually – but <i>starting</i> <QL name="top-down">from the top</QL>).</li>
      <ul>
        <li>So either <PL name="sweeping">every</PL> <QL>human</QL> ‘<PL>individual</PL>’ on <LRL name="gaia">Earth</LRL> has to <i>decide</i> (<NW>agency</NW>) to live in <OML>harmony</OML>, sharing <i><PL name="privacy">everything</PL></i> with (and not ‘<PL name="corrupt">taking advantage of</PL>’) <LRL name="usandthem">one another</LRL>.</li>
        <li>Or <QL>humans</QL> <LRL name="shift">start to accept</LRL> <AL name="handicap">limitations</AL> of the <AL name="fields.biology">human body</AL> and <OML name="stupid">back down</OML> from trying to make world-influencing decisions: all living (<LRL name="usandthem">together</LRL>) under a non-<QL>democratic</QL> (<OML>authoritarian</OML>) run by fully-transparent <PL>AI</PL> or <AL name="notnew.plato.kings">philosopher-kings</AL> who are <OML name="smart">brilliant</OML>, <QL name="pg.mind.know">well-informed</QL>, and not fully transparent about any inclination towards <NW>individual greed</NW>.</li>
      </ul>
      <li>And it (<Qual.probably />) won't work without being a <i><LRL name="gaia">global</LRL></i> solution.</li>
      <ul>
        <li>If we're <QL name="change">still</QL> <AL name="illusions">playing</AL> the <NW word="phanerozoic">Phanerozoic-Eon</NW> (<LRL>reactive</LRL>) ‘<LRL>game</LRL>’, a lack of ‘<PL>privacy</PL>’ makes an ‘<AL name="handicap">entity</AL>’ (if somehow <QL name="polygnosticity">separated</QL>/<NW>heterognostic</NW> from <LRL name="them">the rest</LRL>) ‘vulnerable’ (to the <NW>individual greed</NW> of <LRL name="them">those</LRL> still keeping <i><PL>secrets</PL></i>).</li>
        <ul>
          <li>It devolves consciousness <NW>agency</NW> into <LRL>reactivity</LRL> and <PL name="privacy.will">essentially neutralises</PL> the <LRL name="i.">power</LRL> of <OML>free will</OML> on a large <QL>scale</QL>.</li>
        </ul>
        <li>Therefore a non-private ‘<NW word="nation-state">>state</NW>’ necessary for true (<WName ic />) communism wouldn't be able to survive while ‘others’ exist on the field still hiding in their <QL name="metaphors">metaphorical</QL> ‘<QL name="pg.privacy">shells</QL>’ (of <PL>privacy</PL>).</li>
        <li>One <NW>nation-state</NW> (or one ‘<NW>company</NW>’) <QL name="qualifiers">most likely</QL> needs to subsume (<AL name="notnew.lennon.war">conquer</AL>, <AL name="fields.biology">incorporate</AL>, <LRL name="superpositions">whatever</LRL>) all of the rest.</li>
        <ul>
          <li>The it enforces both full disclosure (‘<QL>radical openness</QL>’) and <WName ic /> ‘communism’ for any <NW>citizen</NW> who wants any <i>say</i> in his or her governance, see above.</li>
        </ul>
      </ul>
      <li>Well, it’s <i>that</i>, or all of <QL>humanity</QL> just <OML name="harmony">decides to get along</OML> and not keep ‘<PL>secrets</PL>’.</li>
    </ul>

{/*
      <p>And what about when it makes a decision that you don’t <i>like</i>? (‘I’m sorry, ma’am, but we’re going to give you an abortion.’)</p>
      <p>Here’s the thing about a commune run by a robot <QL name="hivemind">hive mind</QL> that knows every detail of the individual lives of each of the inhabitants (or at least inasfar as each individual member has forfeited their <NW word="illusions">illusive</NW> belief in the goodness of <i><PL>privacy</PL></i>.
and if you don’t like something you can either argue ir with logic (s have it <NW word="explain">explained<sup>(1)</sup></NW> to you – and hopefully by AI, becuase we really don’t need <i>that</i> many <WName ists /> in the world; our shared <QL>body</QL> </p>

*/}
  </div>,
};
