import React from 'react';
import ItemBox from '../ItemBox';
import AnglesList from './AnglesList';
import {angles} from './list';
import {useParams} from 'react-router-dom';
import './Angle.css';

export default function Angle(props) {
  var {name} = useParams(); // If coming from react router.

  return <ItemBox
    {...props}
    className="angle-box"
    preview={props.preview}
    data={props.data}
    list={!props.data ? angles : null}
    search={(props.name) ? props.name : name}
    propertyName="name"
    alternativesName="alternatives"
    ListComp={AnglesList}
    previewName="preview"
    textName="text"
  />;
};
