import React from 'react';
import ItemBox from '../ItemBox';
import FAQList from './FAQList';
import {faqs} from './list';
import {useParams} from 'react-router-dom';
import './Question.css';

export default function Question(props) {
  var {name} = useParams(); // If coming from react router
  return <ItemBox
    {...props}
    className="question-box"
    preview={props.preview}
    data={props.data}
    list={!props.data ? faqs : null}
    search={(props.name) ? props.name : name}
    propertyName="name"
    alternativesName="alternatives"
    ListComp={FAQList}
    headerName="question"
    outsideName="clarification"
    expandText="Answer"
    previewName="answerPreview"
    textName="answer"
  />;
};
