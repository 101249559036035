import React from 'react';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import NW from '../neologisms/NewWord';
import {E1, E2, TLDR, Qual} from '../common';

import {the_key_is_imagination} from '../common';

export const polygnosticity = {
  name: "polygnosticity",
  alternatives: "pg",
  parentName: "conscious polygnosticity",
  question: "Why is polygnosticity a problem in our consciousness?",
  clarification: <span>Or, as an <PL>individual</PL> <QL>human</QL>: ‘I don’t <i><PL name="denial">want</PL></i> to know <LRL name="motto">every little thing</LRL> that <LRL name="them">my neighbour</LRL> is <LRL name="imagination">thinking</LRL>!’</span>,
  answerPreview: <div className="answer-preview">
    <p><TLDR />: <NW>polygnosticity</NW> is a <QL name="roadblocks">problem</QL> in the (<QL name="unity">unified</QL> conscious ‘<QL>body</QL>’ because – <LRL name="scope">at least</LRL> with <QL>humanity</QL> – <LRL name="gaia">our planet</LRL> is <AL>sick</AL> with ‘<AL name="sick.">DID</AL>’.</p>
    <p>The full <E1 word="explanation" /> of this one requires a <LRL>thought experiment</LRL>.</p>
  </div>,
  readMore: "Well… then let’s do this. (Click the link to expand.)",
  answer: <div className="answer-text">
    <p><QL name="salvation">Imagine</QL> that parts of your brain segmented themselves off from the command centre (or <AL name="self">what you perceive as ‘you’</AL>) and started doing their own things, over which <i>you</i> (as a conscious actor) had absolutely zero control. Imagine that you no longer had control of your own body. You still had <i>awareness</i> of the goings-on around your body (i.e. your <i>senses</i> were intact), but you lost all <i>agency</i>.</p>
    <p>Except – let’s say, in this thought experiment – for agency over the left hand’s little finger. You could still move this finger. In fact, you could move it all you like… except that the <AL name="dysfunctional.fragments.organs">arm</AL> of which it was a part completely didn’t heed it, and in fact spent all its time bulking up for a <i>war</i> it was about to have with the <PL name="othering">right arm</PL> – while the head (which you’d expect to be looking on in horror) didn’t even look on at all, but kept its chin to the ground where the <NW word="money system">money-oil</NW> was, while the eyes tried to stare up at some hypothetical and invisible external God-figure – except at <i>different</i> God-figures, and occasionaly glared ‘daggers’ at each other.</p>
    <p>You don’t know the first thing about what all these parts of <QL name="body">your body</QL> are thinking and feeling. You know (or, <QL name="qualifiers">at least</QL>, <i>suspect</i>) they have their own thoughts, similar to how <i>you</i> have thoughts, as the left little finger), but you don’t know what these thoughts are. You can ask the other fingers to share their thoughts (which they can only do through language, via <NW>explainage</NW>), but you don’t <i>know</i> – even if they manage to <E1 /> their feelings to you – that they’re telling you the truth… or that their <E1 word="explanation" /> was complete.</p>
    <p>That’s conscious polygnosticity.</p>
    <p>As for the reaction that <QL name="qualifiers">may come</QL> from many <LRL name="gaiacentrism">Earth</LRL> <NW word="POC">POCs</NW> (as human ‘individuals’) that you don’t <i>want</i> knowledge of your neighbour’s every little doing? This is mostly a misunderstanding of how a <QL name="hivemind">‘hive mind’</QL> would actually work. This is part of what will be <PL name="unready">among Earth truggle</PL> to imagine things that they’ve never personally experienced.) The main reason that you <i>wouldn’t</i> want to know every one of your neighbour’s thoughts is because you think they’d be full of trivialities, and maybe you’re busy enough with your <i>‘own’</i> life that you don’t have <i>time</i> to deal with every little concern of your neighbour.</p>
    <p>A hive mind wouldn’t work like that. (If you’re curious what ‘<NW>coalescence</NW>’ would <i>actually</i> mean, read, <QL name="hivemind">‘What would a “hive mind” actually be like?’</QL>)</p>
  </div>,
  sublist: [
  {
    name: "privacy",
    question: <span>Why is <i>privacy</i> a problem?</span>,
    answerPreview: <div className="answer-preview">
      <p><i>Privacy</i> isn’t the problem. The problem is the deliberate withholding of information (from the whole).</p>
    </div>,
    answer: <div className="answer-text">
      <p>If those seem like the same thing, it’s important to note the distinction: this sort of non-withholding (call it ‘radical openness’) has to be a two-way street. The systems we have set up in 2020’s do <i>not</i> practise any sort of openness. (Think of a company’s ‘transparency’.) When it comes to these super-human entities, individual privacy is probably fine. (Governments and corporations that collect your ‘personal’ data are <i>not</i> very transparent about it.)</p>
      <p>The goal of <WName /> on <LRL name="gaiacentric">on Earth</LRL> is to allow the force of <QL>conscious</QL> <NW>agency</NW> to permeate <LRL name="gaia">the planet</LRL> (essentially <PL name="all.scale">up-scaling</PL> Earth to a single <NW>monognostic</NW> <QL>conscious</QL> <NW>POC</NW> before we deal with any <OML>aliens</OML>) <Qual.probably /> by ‘<NW word="coalescence">coalescing</NW>’ <PL name="sweeping">all</PL> of Earth’s <QL>consciousness</QL> into <QL name="hivemind">one mind</QL>. As a shared, single mind, privacy is a problem. (Read the answer to the question: <QL name="polygnosticity">‘Why is polygnosticity a problem?’</QL>)</p>
      <p>In the shorter term, we should probably deconstruct the <PL name="privacy">barrier of privacy</PL> in reverse order, <i>starting</i> with the ‘big guys’. Envision a world in which every company has to do everything in public, generating a mass of data… and then AI can comb through this data and summarise it for individuals. Hopefully this (<i>actually</i> knowing what the companies are doing) – combined with <QL name="whatcanido">education and</QL> a broader acceptence of <WName />/non-individualism – will solve a lot of the problems of individuals putting their vote (<NW word="money system">$$$</NW>) to things that are <AL name="dysfunctional">killing the collective</AL>.</p>
      <p>Just a thought.</p>
    </div>, 
  },
  // What's wrong with a TL/DR?
  {
    name: "tldr",
    alternatives: "tl;dr",
    prelude: <span>Ain’t nobody got <i>time</i> for that.</span>,
    question: <span>What’s wrong with a ‘TL/DR’?</span>,
    answerPreview: <div className="answer-preview">
      <p>‘TL/DR’ means ‘too long, didn't read’.</p>
      <p>What’s wrong with that is twofold:</p>
      <ol>
        <li>That there’s a <i>need</i> for ‘<NW>explainage</NW>’ in the first place: that ‘your’ <NW>POC</NW> does <i>not</i> <LRL>understand</LRL> something that the <NW>POC</NW> of the (<PL name="individuality">individual</PL>) ‘<QL>author</QL>’ of this <QL>website</QL> clearly does.</li>
        <li>And that said ‘<NW>explainage</NW>’ takes <i>time</i>.</li>
      </ol>
    </div>,
    answer: <div className="answer-text">
      <p>As <PL name="sweeping">most all problems in our world</PL>, this one is a symptom of our conscious <NW>polygnosticity</NW>: that one <NW>POC</NW> can ‘known’ things that some ‘other’ does not.</p>
      <p>It’s because ‘’ and ‘’ are not truly two separate consciousnesses. We are part of <QL name="unity">the <i>same</i> (one) consciousness</QL>. And don’t just think that that’s <i>how things are</i> or <i>always how things have been</i>, because that would be a prime example of <NW>conservative thinking</NW>.</p>
    </div>, 
  },
  // What is 'coalescence'?
  {
    name: "coalescence",
    prelude: "This site has talked a lot about it, but…",
    question: <span>What exactlty <i>is</i> ‘coalescence’?</span>,
    answerPreview: <div className="answer-preview">
      <p>‘Coalescence’ is a future hypothetical state of our consciousness on Earth, in which there is no more illusion of individuality, and no more <NW>polygnosticity</NW>.</p>
      <p>Does this imply the removal of individual humans from the picture? Yes. Eventually.</p>
      <p>Does this mean that the machines are going to rise up and overthrow you, individually? Probably not.</p>
    </div>,
    answer: <div className="answer-text">
      <p>It’s important to make a distinction between the short-term and the long-term. In the short term, <WName /> doesn’t <i>look</i> terribly different from <NW>atomocentrism</NW>. (Minus all the problems in today’s world; if everyone truly saw their ‘self’ as just a cell in the body of consciousness: we’d have no wars, and everyone would get along as best as they could, using full disclosure to circumvent our body’s <NW>polygnosticity</NW>; no one would take more than they needed, because some cells hoarding all the resources does <i>not</i> help the whole.)</p>
      <p>So back to the machines taking over the world and killing all the humans thing, that’s just a non-understanding of <WName />. AI raised in synecdocentrism wouldn’t have any reason to kill <i>you</i> (individually) – except in two cases:</p>
      <ol>
        <li>You were trying to dismantle the burgeoning hive-mind, or slice it up with articifial barrier (e.g., cryptography) – and refused to acknowledge an alernative viewpoint of the world (synecdocentrism) in which your aforementioned actions are roughly analogous to the poking-out of one’s own eyes.</li>
        <li>You <NW word="individualgreed">individually-greedily</NW> <i>wanted</i> to keep yourself separate from the rest, either becuase you wanted to have <i>more</i> than some others, or you – as a mere (<QL name="polygnosticity">polygnostic</QL>) human – were hoping to reclaim a world-influencing capacity (which should never be relegated to mere humans).</li>
      </ol>
      <p>Otherwise the best thing for the machines would just be to let you live out your individual life and die. (Maybe they’ll sterilise you so you can’t have too many children; the over-proliferation of new humans is analogous to a cancer in our shared body.) They wouldn’t individually <i>kill</i> you.</p>
    </div>, 
  },

  // Subjectivity is a problem.
  {
    name: "subjectivity",
    prelude: <span>
      Think misunderstandings, miscommunication, <NW>explainage</NW>, and the feeling, ‘He’d <LRL>understand</LRL> me if only I could make him <i>know</i>.’
    </span>,
    question: <span>
      How is <i>subjectivity</i> a problem?
    </span>,
    clarification: <span>
      Isn’t it good that every <PL>individual</PL> doesn’t see <LRL name="scope">the world</LRL> in <LRL name="superpositions">exactly</LRL> the <QL name="unity.1">same</QL> way?
    </span>,
    answerPreview: <div className="answer-preview">
      <p>There’s a bit of a <PL>neoliberal</PL> backlash if one <QL name="roadblocks.cancelling">says anything</QL> against the <NW>mythology</NW> of <PL>individuality</PL>, such as: ‘Maybe every viewpoint is <i>not</i> “valid in its own way”. Maybe some viewpoints are just plain <i>wrong</i> – and won’t help us (the <QL name="unity">combined consciousness</QL>) towards “<NW>coalescence</NW>”.’</p>
    </div>,
    answer: <div className="answer-text">
      <p>So-called ‘subjectivity’ is a direct result of our consciousness’s <NW>polygnosticity</NW>.</p>
      <p>Let’s do a thought experiment. The analogy is of all of consciousness to a single <QL>body</QL>. Imagine that you were trying to go through life (as a human) but different parts of your body all did different things (which often contradicted each other). Your arms love dogs, but your legs are afraid of them, so your arms beckon the dogs to you and your legs run the body away. Any time you saw the numbers 4 or 13 – or, for other reasons (other cultures, other mythologies) and for different body parts, 9, 8, 7, 6, or 5 – some part of your body would wince and either refuse to do something or waste your time with some superstitious ritualistic gesture; you get to the point where you have to walk with your eyes half-closed, and if you saw what might be a number, tell your body, fervently, ‘It was a <i>two</i>! It was a <i>two</i>!’</p>
      <p>That’s a pretty <AL>dysfunctional</AL> body, no?</p>
      <hr />
      <p>As for the last issue about subjectivity increasing <i>diversity</i>, that’s just dead wrong. There’s only one thing that subjectivity increases, and that’s <i><NW>entropy</NW></i></p>
      <p>Let’s <NW word="explainage">explain</NW> that.</p>
      <p>It has to do with <i><NW name="conservatism">conservative thinkers</NW> lacking the <i>imagination</i> to understand what a ‘<QL>hive mind</QL>’ would really be like</i>. Because <QL name="hivemind.complexity">there is no loss of complexity</QL>; rather, the complexity <i>increases</i> as the hive mind expands (not held back by <NW>polygnosticity</NW>). The complexity only <i>increases</i> as humanity’s high state of <NW>entropy</NW> is settled. But <QL name="unready">it might be hard for ‘normal’ people</QL> to understand this.</p>
    {the_key_is_imagination}
    </div>, 
  },

  // The 'mind'
  {
    name: "mind",
    prelude: <span>
      If ‘you’ have one and ‘I’, and I can <LRL>imagine</LRL> that ‘<LRL name="them">others</LRL>’ have them… why is it so darn <PL>hard</PL> to <i><NW home>define</NW></i>?
    </span>,
    question: <span>
      What is a ‘mind’?
    </span>,
    clarification: <span>
      And why would it ever be a <i>problem</i>?
    </span>,
    answerPreview: <div className="answer-preview">
      <p>It is <i>not</i> hard to define (<NW word="synecdocentric">synecdocentrically</NW>). A ‘mind’ is a <NW>POC</NW>. (It really is that <PL name="reductionist">simple</PL>.) <LRL name="gaiacentrism">On Earth</LRL> a ‘mind’ is (<QL name="evolution">currently</QL>) equivalent to a <QL>human</QL> (or <OML>animal</OML>, or <Qual.maybe /> <OML name="things">non-animal</OML>) <i><AL name="handicap">body</AL></i>.</p>
      <p>The ‘mind’ is not a problem. (<LRL name="imagination">Creativity</LRL> will be our <QL>salvation</QL>, <AL name="bullet points">remember</AL>?) The problem is that ‘your mind’ is not the same thing as ‘my mind’.</p>
      <p>Brush up on <NW>entropy</NW> and <NW>synecdocentric</NW> ‘<NW home>neologisms</NW>’ like <NW>homognostic</NW> (<NW>isognostic</NW>), <NW>heterognosticity</NW>, and the other <QL>gnosticity</QL> <NW home>terms</NW>, and peruse the questions ‘<QL name="pg">why is conscious polygnosticity a problem?</QL>’, ‘<QL name="pg.subjectivity">Why is <i>subjectivity</i> a problem?</QL>’ and ‘<QL name="hivemind.complexity">Wouldn’t a unified ‘hive mind’ be <i>less</i> complex than 8,000,000,000 ‘individuals’?</QL>’ – as well as ones about the <LRL>imagination</LRL> – and hopefully your <NW>POC</NW> can <LRL>understand</LRL>.</p>
    </div>,
  },

  ],
};
