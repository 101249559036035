import React from 'react';
import ButtonBox from '../ButtonBox';
import WName from './WName';
import AL from './angles/AngleLink';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import NW from './neologisms/NewWord';
import OML from './ormaybe/OrMaybeLink';
import LRL from './longreads/LongReadLink';
import {E1, E2} from './common';
import {Outlet} from 'react-router-dom';

const intro = <div className="intro-long-reads">
  <p>To get into these ‘long reads’ you really need a baseline <LRL>understanding</LRL> of <WName />. These are deep dives into the ‘<PL>rabbit holes</PL>’ of this <LRL name="superpositions">oft-indeterminate</LRL> <OML name="wrong">self-questioning</OML> <QL name="qualifiers">maybe</QL> <PL name="unready">non-intuitive</PL> <AL name="fields.physics">quantum-mechanics-esque</AL> <LRL name="scope">world</LRL>-<E2 word="explanation" /> (<QL>Weltanschauung</QL>) – as per the <WName ic /> insistence to <i><LRL>leave no stone unturned</LRL></i>.</p>
  <p>But maybe try the <AL home>angles</AL> first.</p>
</div>;

export default function LongReads() {
  return (
    <div className="page">
      {intro}
      <hr />
      <Outlet />
      <div className="backbuttons">
        <ButtonBox src={-1}>Back</ButtonBox>
        <ButtonBox src="/menu/longreads">All Long Reads</ButtonBox>
      </div>
    </div>
  );
};
