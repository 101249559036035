import React from 'react';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {
  E1, E2, Qual, TLDR, 
  Rando, Switcheroo, Cycle,
  rand, SwearMaybe,
  Planet, SillyName
} from '../common';

// Why is everything changing?
export const change = {
  name: "change",
  parentName: "change",
  prelude: <span>
    Okay, a ‘<AL name="fields.physics">quantum-mechanical approach to philosophy</AL>’ is one thing… but <i>this</i>? Both your 21<sup>st</sup>-century ‘average <SillyName persist />’ and your <Planet.AlienDescription /> <OML>aliens</OML> from planet <Planet.Name /> would agree: this is too much.
  </span>,
  question: <span>
    Are there <i>any</i> constants in life?
  </span>,
  clarification: <span>
    But before you ask that one, think… because you probably <i>do</i> know the answer; it’s just that you might not <i><PL name="uncomfortable">like</PL></i> it very much.
  </span>,
  answerPreview: <div className="answer-preview">
    <p>Any <i>constants</i> in life? Well, what – besides the obvious? Because there is at least <i>one</i> thing you can count on: <LRL name="scope">the universe</LRL> will (or <QL name="qualifiers">appears that it will</QL>) always <i>change</i>.<sup>†</sup></p>
    <p>Now is that a <PL>sweeping</PL> statement? <Qual.probably cap />. Is that the sort of <QL>definitive</QL> statement that this <AL>framework</AL> (as part of ‘<LRL>leave no stone unturned</LRL>’) said that it ‘should’ <i>not</i> make? Does the <WName ic /> <QL>Weltanschauung</QL> give a <OML name="offensive">crap</OML>? No. Because when everything changes… the one thing that you can rely on is that things will change.</p>
    <p>This <AL>framework</AL> is <PL name="all.">scale-invariant</PL>, <PL name="all.">time-invariant</PL>, and even what it calls ‘<PL name="all.">level-invariant</PL>’ – which means that it doesn’t even care if we’re all just in a <i><OML>story</OML></i>, or what is even meant by the word ‘<LRL name="i.">real</LRL>’.</p>
  </div>,
  topFootnote: <p>
    † This does not mean that change is a constant; cf. <NW word="heatdeath">the heat death of the universe</NW>. However, if the universe truly suffers a ‘heat death’, there will (<Qual.probably />) be no energy left <i>to contemplate it with</i>. So it won’t <i>matter</i>. What you can say, maybe (<QL name="definitive">definitively</QL>) is that <i>while there is <QL name="unity">consciousness</QL> and conscious <NW>agency</NW></i> there will always be change.
  </p>,
  answer: <div className="answer">
    <p>Yes, <QL>humans</QL> like stability. However, the universe was (<Qual.likely />) was <i>not</i> <OML name="god">made for humans</OML>, or <OML name="soul">in their ‘image’</OML>.</p>
    <p>[under construction]</p>
  </div>,
  sublist: [

  // Why does THIS SITE change all the time?
  {
    name: "site",
    alternatives: "website,this website",
//    prelude: <span>
  //  </span>,
    question: <span>
      Why does this <SwearMaybe noOML /> website <Switcheroo values={[<Rando options={["sometimes", "often", "occasionally", "randomly", "unexpectedly"]} />, <Rando options={["– at a moment’s notice –", "– without warning –", "(as if it actually mattered)", "(as if on a whim)", "(as if just to drive your POC bonkers)"]} />]} middle=" " /> <i>change</i>?
    </span>,
    clarification: <span>
      Is <WName /> just a <i><LRL>game</LRL></i>?
    </span>,
    answerPreview: <div className="answer-preview">
      <p>Your 21<sup>st</sup>-century ‘average <SillyName persist />’ will – if you refresh the page – get a new name. <Qual.probably cap />. The <LRL name="imagination">imaginative</LRL> planet that <OML name="goal">on one page</OML> is named ‘<Planet.Name />’ will, if you reload the page, <Qual.probably /> be called something else, and its <LRL name="imagination">imaginative</LRL> <i><PL name="individuality">inhabitants</PL></i> – while still <NW>atomocentric</NW> <OML>aliens</OML> – <Qual.may /> have morphed in their physical <i>appearance</i>. <QL name="qualifiers">Just about</QL> every example on this <Rando options={["[____ing]", <OML name="offensive">damn</OML>, "", "", ""]} /> site seems to have multiple <i>presentations</i> (i.e., <Rando options={[<span>many ‘pronouns’: ‘<Switcheroo values={["he", "him", "his", "she", "her", "they", "them", "hers", "their", "theirs"]} middle="’/‘" />’ for example</span>, <span>as many separate ‘<AL name="identity">identities</AL>’ as <LRL name="gaiacentrism">Earth</LRL> with its ‘<AL name="sick.">DID</AL>’</span>]} />) and on <i>some</i> pages (like the <AL name="fields.">maths</AL> one) it’s just complete <i>chaos</i>. The page on ‘<LRL name="communism"><WName ic noNW /> communism </LRL>’ can’t seem to decide <i>which</i> things we are going to share and <i>in what order</i> we are going to share them. If you’re reading this thing and trying to take it <i>seriously</i>, it’s hopeless. {/*Some things may change every <Cycle values={["couple of", "few", "several", "three or so"]} min={10000} max={50000} /> <i>seconds</i> (and driving you crazy if you try to read any text beyond it, that will always be shifting).*/}</p>
      <p>Even the name of the darn <i><QL>Weltanschauung</QL></i> (of <WName /> – or whatever the site wants to call it this page-lead) doesn’t appear to be ‘set in stone’. Seriously, what is the <i>point</i> of all this?</p>
      <p>Well, just know this: as outwardly <i>playful</i> (or een silly) as it may seem, it’s 100% dead serious. If the playfullness comes from anything, it’s frustration with <QL>humanity</QL>’s <NW>conservative thinking</NW>: ‘<Switcheroo values={[<span>Some people make more <NW>money</NW> than <LRL name="them">others</LRL>, and some people make <PL name="equality">less</PL>, and it’s always been that way and <QL name="change">always will</QL></span>, <span>But <QL>evolution</QL> <i>must</i> be fuelled by competition: that’s the <i>definition</i></span>, <span><OML name="patriarchy">Boys</OML> will be <OML name="patriarchy">boys</OML></span>, <span><AL name="notnew.marx">Communism</AL> didn’t work; just look at the USSR… and where’s <i><OML name="offensive">Amurica</OML></i> now: still goin’ strong</span>]} middle=",’ or, ‘" />.’</p>
      <p>Sorry, <WArticle /> <WName ist /> gets a little peeved by talk like that. So <QL name="author">some of us</QL> make websites that are like to drive you <Rando options={["nuts", "bonkers", "ballistic", "crazy"]} />. Just to, you know, try to make a point amongst all the <NW>explainage</NW>.</p>
    </div>,
//    answer: <div className="answer">

//    </div>,
  },
  ],

};
