import React from 'react';
import Box from '../Box';
import WName from './WName';
import AL from './angles/AngleLink';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import NW from './neologisms/NewWord';
import LRL from './longreads/LongReadLink';
import OML from './ormaybe/OrMaybeLink';
import {E1, E2, TLDR} from './common';
import "./Answer.css";

const confession = <Box
  prelude=<span>This website is a hideous, <i>ugly</i> thing…</span>
  header=<span>But it has inside it a <i>kernel</i> of non-individualised love that’s so beautiful it will bring tears to your eyes.</span>
  outside=<p>The <LRL name="i.power">force</LRL> of <QL>consciousness</QL> behind this thing does <i>not</i> hate <QL>humanity</QL>. Please do not get that sense. The <NW>agency</NW> behind this (as <QL name="author">one or more</QL> <NW>POCs</NW>) is just all <i><AL name="handicap">trapped</AL></i> inside <QL>human</QL> <AL name="fields.">biology</AL> that <PL name="explainage">struggles</PL> to <E1 italic />.</p>
/>;

const answer = <Box
  prelude=<span>We can <i>imagine</i> peace, and we can <i>imagine</i> that peace on Earth would be better for every individual (human or otherwise) involved.</span>
  header=<span>So why don’t we <i>cooperate</i> and <i>enact</i> it?</span>
  outside=<div>
    <p>The answer to this question is why we need to (<LRL name="different">radically</LRL>) <LRL>shift</LRL> to a new world-understanding.</p>
    <hr />
    <p>The underlying question to <WName /> is this: if we <i><LRL name="imagination">could</LRL></i> just <i>decide</i> to <OML name="harmony">all get along</OML>, then why <i>don’t</i> we? Why <i>don’t</i> we (as <QL>humanity</QL>, part of a <LRL name="gaia">planetary</LRL> <PL name="ick.">system</PL> comprising billions of <AL name="sick.">fragments</AL> of <QL>conscious</QL> <NW>agency</NW>) <AL name="notnew.lennon.peace">give peace a chance</AL>?</p>
      <p>The <PL name="sweeping.ig">obvious</PL> <LRL>answer</LRL> (using <WName ic /> <NW home>terminology</NW>) is <NW>individual greed</NW>. But that isn’t actually <i>helpful</i>; it is just a <LRL name="symptoms">diagnosis</LRL>, not a plan for treatment.</p>
      <p>So look <LRL>deeper</LRL>.</p>
      <p><NW>Individual greed</NW> is in turn <LRL name="causes">caused</LRL> (<PL name="sweeping.handful">as most things</PL> we <LRL name="scope">observe</LRL> in our <AL name="dysfunctional.fragments">fragment</AL> of the ‘<QL>body</QL>’) by <QL>humanity</QL>’s <QL>conscious</QL> <NW>polygnosticity</NW>. But <i>how</i>?</p>
      <p>The <LRL>answer</LRL> is that <QL>humanity</QL>’s <NW>polygnosticity</NW> (and – on an <PL>individual</PL> <NW>level</NW> – the <NW>heterognosticity</NW> of each ‘<QL name="pg.">mind</QL>’) dilutes <QL>conscious</QL> <NW>agency</NW> (‘<OML>free will</OML>’) to the point where the <PL name="all.scale">scale-invariance</PL> of <WName /> does <i>not</i> hold and the whole (synecdochically, <LRL name="gaia">Planet Earth</LRL>) starts to behave in the universe-<PL>system</PL> not as a conscious actor but as an <i>unconscious</i> one beholden to the laws of nature that biologists observed ages ago (<QL name="evolution.">natural selection</QL>): a phenomenon called <i><LRL>reacticity</LRL></i>.</p>
    <p>Many people (<NW word="conservatism">conservatively</NW>) say things like, ‘It’s always been that way and it always will.’ This is then a radical <i>rejection</i> of that <LRL name="notions">mode of thinking</LRL>, and instead a hope that we can one day throw away all our <NW>illusions</NW> of <PL>individuality</PL> and <OML name="harmony">actually decide</OML> to <AL name="notnew.lennon.peace">live in peace</AL>.</p>
    <p>And if this site ever seems <AL name="handicap">human-hating</AL> or pro-<OML>authoritarianism</OML>, just know that there <i>is</i> <OML name="harmony">another choice</OML>: the <i>voluntary</i> (with <LRL name="reactivity">united</LRL> <NW>agency</NW>) <AL name="mirrors">dismissal</AL> of <i><PL>privacy</PL></i> (from the <QL>top down</QL>) and building in its place an <LRL name="communism">entirely-shared</LRL> and <QL>conscious</QL> mutual <LRL>understanding</LRL> on the way to a single ‘<NW name="coalescence">coalesced</NW>’ <QL name="hivemind">Earth-mind that incorporates us all</QL>.</p>
  </div>
/>;

export default function Answer() {
  return (
    <div className="page">
      <h1 id="answer-title">>> This is how war can be over</h1>
      <h2 id="answer-subtitle">There is <PL>love</PL> and there is <QL name="salvation">hope</QL> in this universe. It just might not look how you expect.</h2>
      {confession}
      <br style={{marginBottom: "20px"}} />
      {answer}
    </div>
  );
}
