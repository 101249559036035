import React from 'react';
import WName, {WArticle} from '../WName';
import Eon, {EonArticle} from '../Eon';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import OML from '../ormaybe/OrMaybeLink';
import AL from './AngleLink';
import NW from '../neologisms/NewWord';
import {E1, E2, Qual, rand, PrimeNumber} from '../common';
import SillyNumberGenerator from './SillyNumberGenerator';

// In various disciplines...
export const disciplines = {
  name: "disciplines",
  alternatives: "fields",
  parentName: "approaching from different backgrounds",
  prelude: <span>
    It really <i>is</i> very flexible.
  </span>,
  header: <span>
    You can understand this from many different backgrounds.
  </span>,
  outside: <span>
    It’s one of the advantages of balancing <LRL name="superpositions">multiple possible states</LRL> of ‘truth’ all at once.
  </span>,
  preview: <div className="angle-preview">
    <p>So just pick.</p>
  </div>,
  //text: <div className="angle-text">
  //</div>,
  sublist: [

  // Linguistic.
  {
    name: "linguistics",
    alternatives: "linguistic,linguistically",
    prelude: <span>
      An ‘<PL>individual</PL>’ may be <i>indivisible</i>, but…
    </span>,
    header: <span>
      Let’s look at <i>individuals</i> (Greek <i>άτομα</i>: ‘atoma’) as the <i>atoms</i> of consciousness.
    </span>,
    outside: <span>
      Linguistically, it makes sense.
    </span>,
    preview: <div className="angle-preview">
      <p>Okay, we shouldn’t give too much weight to <i>language</i> – it being just a means of ‘<NW>explainage</NW>’ – but once you realise that the big <NW>mythology</NW> of our <NW>atomocentric</NW> society (individuality) is just <AL name="mirrors">an elaborate box of mirrors</AL>… who’s to say we can’t <i><QL name="salvation">imagine</QL></i> jamming some <i>άτομα</i> together?</p>
    </div>,
    text: <div className="angle-text">
      <p>You have to think non-<NW name="conservatism">conservatively</NW>. If our underlying <QL>unity</QL> is <OML name="wrong">real</OML>, our conscious <QL>body</QL> has one big <AL name="dysfunctional">disease</AL>: our <NW>polygnosticity</NW>.</p>
      <p>The answer (and probably the <QL name="polygnosticity.coalescence">only realistic path</QL> to <LRL>world peace</LRL>) is ‘<NW>coalescence</NW>’ into a <QL name="hivemind">hive mind</QL>, forever ending the notion of ‘you’ and ‘me’ and entering a <NW>homognostic</NW> state in which <PL>privacy</PL> will become a <OML name="privacy">non-issue</OML>.</p>
      <p>(Yes, it’s <PL>uncomfortable</PL>. Most of humanity <PL name="unready">simply isn’t ready for this</PL>; they would rather live in the <AL name="mirrors">box of mirrors</AL> (or ‘<AL name="notnew.plato.">cave</AL>’ of <QL name="anthropocentrism">humanity’s</QL> <NW>illusions</NW>.)</p>
    </div>,
  },

  // Biology
  {
    name: "biology",
    alternatives: "biological,biologically",
    prelude: <span>
      In biology you have multicellularity
    </span>,
    header: <span>
      What about multi-individuality?
    </span>,
    outside: <span>
      In <NW word="family system">families</NW>, <NW>nations</NW>, and <NW word="company">corporations</NW> it’s basically <QL name="body.organs">already happened</QL>.
    </span>,
    preview: <div className="angle-preview">
      <p>This is basically just a matter of what <WName /> calls ‘<PL name="all.scale">scale-invariance</PL>’.</p>
      <p>[Building....]</p>
    </div>,
//    text: <div className="angle-text">
  //  </div>,
  },

  // Physics
  {
    name: "physics",
    alternatives: "physical,physically,philosophy,philosophical,philosophically",
    prelude: <span>
      Do you need to be a <i>quantum physicist</i> to really <LRL>understand</LRL> this thing?
    </span>,
    header: <span>
      This is a quantum-mechanical approach to philosophy.
    </span>,
    outside: <span>
      (As opposed to a ‘classical’ one.)
    </span>,
    preview: <div className="angle-preview">
      <p>The ‘quantum-mechanical’ side of <WName /> is more or less in its ‘motto’: <i><LRL>leave no stone unturned</LRL></i>.</p>
      <p>This might also tie into the ‘<QL name="pg.mind.">relativity of the mind</QL>’</p>
    </div>,
    text: <div className="angle-text">
    </div>,
  },

  // 'Individuals' as instances of consciousness
  {
    name: "cs",
    alternatives: "instances",
    prelude: <span>
      If <PL name="individuality">individuals</PL> are really just (mostly) <i><PL name="fungibility">fungible</PL></i>, then, going off computer science:
    </span>,
    header: <span>
      Every so-called ‘individual’ is just an <i>instance</i> of consciousness.
    </span>,
    outside: <span>
      Object-oriented programming might be the modern-day equivalent of <AL name="notnew.">Plato</AL>’s <OML>Forms</OML>.
    </span>,
    preview: <div className="angle-preview">
      <p>If you don’t know computer science, this <AL home>angle</AL> might not make sense to you. But if you’ve ever programmed a computer, this is probably the best way to understand the <PL name="fungibility">interchangeability</PL> of individual humans, and our (‘my’, ‘your’, ‘their’) <i>place</i> in a <WName ic /> world.</p>
      <p>‘You’ and ‘I’ are just <i>instances</i> of some prototype. Yes, while we exist we are <i>not</i> interchangeable; we occupy different spaces in the computer’s memory, for one, and have different values in each of our different parameters. But ‘<NW>God</NW>’ (even if it’s an <OML name="god">external ‘God’</OML>) is just the programmer. To the programmer the individual instances of each object don’t overly <i>matter</i>. (They aren’t <PL>special</PL>.) The goal is to make a <LRL name="gaia">whole program</LRL> that <i>works</i> – and not just on <LRL name="gaiacentrism">Earth</LRL>, either.</p>
    </div>,
    //text: <div className="angle-text">
    //</div>,
  },

  // Maths
  {
    name: "maths",
    alternatives: "math,mathematics,mathematical,mathematically",
    prelude: <span>
      Is <AL name="fields.">physics</AL> really just ‘applied mathematics’, or is the whole field of maths just an ‘emergent’ property to <E2 /> the lower-level reality of <i>physics</i>?
    </span>,
    header: <span>
      You can use mathematics to understand ‘illusions’ in <WName noNW />.
    </span>,
    outside: <span>
      Maybe, for many modern-day scientists, it’s a radical notion… though it <QL name="qualifiers">might</QL> not have been so in <AL name="notnew.plato.neoplatonism">Ancient Greece</AL>.
    </span>,
    preview: <div className="angle-preview">
      <p>Take ‘incalculable numbers’. These are ‘irrational’ numbers that <i>cannot</i> be calculated. Mathematicians believe that there are an infinite number of these things (and on a scale of ‘infinity’ that’s even bigger than the infinite number of ‘rational’ numbers: ‘uncountably’ as opposed to ‘countably’ infinite). {/* In short, for each rational number you could theoretically make an infinite number of incalculable numbers by (imaginatively) writing the number down on an infinite stretch of paper and then changing one or more of the digits at random to make it ‘incalculable’; there are (obviously) an infinite number of ways */} According to a ‘pure’ mathematician, the majority of <i>numbers</i> (which, in the mind of the ‘pure’ mathematician, exist at a deeper ‘level’: cf. <AL name="notnew.">Plato</AL>’s ‘<OML>Forms</OML>’) can never be <i>known</i>.</p>
      <p><WName cap /> – on the other hand – has (as per its obsession with <NW word="occam">Occam’s razor</NW>) a <i>simpler</i> way to look at it: if a number is ‘incalculable’, it simply doesn’t <i>exist</i>.</p>
    </div>,
    text: <div className="angle-text">
      <p>And it’s <LRL name="deep">deeper</LRL> than that: in this example: take a rational number; this could be a string of all {rand(0, 9, true)}’s, π (3.14159…), {rand(1, 100000, true)}/<PrimeNumber />, {rand()}… whatever you want, as long as somehow it can be <i>calculated</i>.</p>
      <p>Now please carefully follow these instructions. (In your imagination; you don’t actually have to – and, honestly, <QL name="qualifiers">probably</QL> never <i>could</i> – write this thing down.)</p>
      <p>(If is says to ‘increment’ a digit that brings it beyond 9, just loop back to 0; it won’t ask you to ‘increment’ any digit by more than 9.)</p>
      <SillyNumberGenerator />
      <p>Okay, now you are thinking of a pointedly <i>bizarre</i> number. This is your number. And it <i>is</i> ‘calculable’ to you: you know your initial number, and the precise steps you followed… though to everyone else it’s so pointedly bizarre that there is an infinitesimally small chance they will ever develop it: not <i>incalculable</i> in that it can’t be calculated (because you just <i>did</i> calculate it) but so pointedly <i>bizarre</i> that one can say <QL name="qualifiers">almost</QL> with certainty that this particular number will never occur – unprompted – to <i>their</i> minds.</p>
      <p>And no, there’s <QL name="qualifiers">probably</QL> no where that it’s been stored, and even if the steps have been stored, nobody knows your initial number. Unless you picked something super-bland like all 0’s, and then published a screenshot of this page to your TikTok, in which case why don’t you (just for you) add an extra step to change up a number at decimal place that hasn’t been changed yet; increment that by a random number between one and nine and (unless your mind is totally unoriginal and you picked decimal place 137, because that’s what’s written here) you now have a number that is <QL name="qualifiers">probably</QL> unique on <LRL name="gaiacentrism">this planet</LRL>.</p>
      <p>P.S. If you’re worried that you actually have to <i>remember</i> that number… you <i>don’t</i>; this isn’t like the ‘passwords’ that have become ubiquitous (<NW home>ubiquintessential</NW>?) and taken over <LRL name="gaia">our</LRL> sad <NW>atomocentric</NW> internet in the name of <PL>privacy</PL>. This number will be (like a <QL name="anthropocentrism">human’s</QL> <PL name="individuality">individual</PL> ‘life’) evanescent: contemplated only today (as part of a ‘thought experiment’, of which this website has several) and then lost forevermore, infinitesimally unlikely to ever be <i>imagined</i> again by a human ‘individual’.</p>
      <p>Maybe this has now lost you completely, but – <OML name="things">if a web-page could <i>hope</i></OML> – this webpage hopes not. (Of course, there’s a good chance that you are <OML>smarter</OML> in some ways that <QL name="author">whoever</QL> coded that silly number generator (with its highly inventive React ‘component’ name of ‘SillyNumberGenerator’). Well, you clicked on ‘maths’, so this was assuredly going to do some arithmatic; and if you don’t like the weird and half-defined <LRL name="superpositions">quantum-superposition-esque</LRL> approach here, <LRL name="disagreement">odds are</LRL> you haven’t yet managed to make the <LRL>shift</LRL> to what this site calls ‘<AL name="fields.physics">quantum-mechanical philosophy</AL>’ (and the <NW>Mechanozoic Eon</NW>).</p>
      <p>Well, the webpage can only hope (<OML name="things">if a web-page could ‘hope’</OML>) that you’re following. Let’s proceed assuming that you <i>are</i> following: and you have now generated a pointedly bizarre number that has <QL name="qualifiers">almost certainly</QL> (short of an infinitessimal chance) never been before contemplated. And in the whole of Earth consciousness, there is only <i>one</i> <NW>POC</NW> who is now thinking of this pareticular (or likely ever <i>will</i> think of it, unless you remember your starting number and take a screenshot of the steps to save this for your own memory): <i>yours</i>. (Don’t you feel a bit <PL>special</PL>?)</p>
      <p>Anyway, you can call that number ‘[your <NW>MNS system</NW>-name]’s number’ – unless you don’t <i>want</i> to use your <NW>MNS system</NW> name (e.g., if you don’t <AL name="identity">identify</AL> with it)… and of course if you were <AL name="notnew.">Jesus</AL> or <AL name="notnew.plato">Socrates</AL>, you wouldn’t put the S after the apostrophe.</p>
      <p>Now comes the <WName ic /> weirdness.</p>
      <p>(You’re probably thinking: ‘Wait, I thought that everything I’ve just <i>done</i> was the “<WName ic /> weirdness”!’)</p>
      <p>The question is not ‘Does [your <NW>MNS system</NW>-name]’(s) number <i>exist</i>?’ – because clearly it <i>does</i>; you are thinking of it right now, ergo the number is currently a part of <LRL name="gaia">Earth consciousness</LRL>. The question that <WName /> (<Qual.probably />) <i><OML name="ideas">wants</OML></i> you to ask is, rather, ‘Did [your <NW>MNS system</NW>-name]’(s) number “exist” <i>before</i> you just imagined it?’</p>
      <p>To which the <WName ic /> <LRL>answer</LRL> would be a resounding <i>no</i>.</p>
      <p>This might (if you don’t fully <i><LRL>understand</LRL></i> the <QL>Weltanschauung</QL>) seem to steer <WName /> dangerously close to <QL name="polygnosticity.subjectivity">subjectivism</QL>, but that’s only if you don’t yet understand what is meant (in the context of this <AL>framework</AL>) by an ‘<NW>illusion</NW>’.</p>
      <p>The bottom-level debate (and one that even this <PL>comprehensive</PL> <QL>Weltanschauung</QL> <LRL name="scope">can’t solve</LRL>) is whether </p>
<p>[To be continued]</p>
    </div>,
  },

  // This is just science.
  {
    name: "science",
    alternatives: "scientific,scientifically",
    prelude: <span>
      Is this <i>really</i> a ‘science’?
    </span>,
    header: <span>
      This is just <i>science</i>.
    </span>,
    outside: <span>
      By wrenching away <QL name="anthropocentrism">humanity</QL>’s <NW>mythology</NW> about <AL name="container">itself</AL>, one can build a <AL name="fields.">logic</AL>-based <AL>framework</AL> with which to <LRL>understand</LRL> once-obscure <LRL>notions</LRL> like ‘<NW>God</NW>’. You just have to not care that <PL name="sweeping">most (if not all)</PL> of your ‘<LRL name="them">neighbours</LRL>’ aren’t going to <PL name="uncomfortable">like it</PL> very much.
    </span>,
    preview: <div className="angle-preview">
      <p>The way that <WName /> stays within the bounds of ‘science’ is simple: it has a limited <LRL>scope</LRL>.</p>
      <p>Yes, it might feel like it <PL name="comprehensive">gets into everything</PL>… but it actually draws a hard-and-fast line at the <i>observable</i> (with the added presumption of <QL name="unity">unity consciousness</QL>, which is <NW>induced</NW> via <AL name="fields.">logic</AL>: ‘<NW word="occam">Occam’s razor</NW>’).<sup>†</sup></p>
    </div>,
    topFootnote: <p>
      † This includes a <OML name="forms">system</OML> of ‘levels’ by which to <E2 /> the <PL name="invisible">non-observability</PL> of our <QL>body</QL> of consciousness (which is still just a <QL name="body.super-organ">part</QL> of a potentially <OML name="aliens">universe-wide</OML> <QL name="unity">unified consciousness</QL>; cf. the <QL name="unity.1">‘answer’ to everything</QL>). Thus it <i>does</i> posit that there is a deeper ‘level’ of reality than that which can be directly observed by the human body – though this is in line with basically all of 2020’s science: think ‘<AL name="fields.linguistic">atoms</AL>’. Anything accepted by <WName /> that <OML name="wrong">can’t be <i>proven</i></OML> has been logically <i><NW>induced</NW></i>… and in the end isn’t that all that ‘science’ is? (And, obviously, this <QL>Weltanschauung</QL> – as with <i>all</i> theories of science – can be disproven or forced to adjust by the introduction of new data that it can’t <E2 italic />; however, so far, it <E2 suffix="s" /> all of <LRL name="gaiacentric">Earth</LRL> with flying colours.)
    </p>,
    text: <div className="angle-text">
      <p>For example: is <OML>reincarnation</OML> a real thing? No idea. To the <QL>Weltanschauung</QL>, that completely doesn’t matter; it mostly treats <OML>a ‘rose’</OML> as <E2 suffix="ed" /> by <AL name="fields.">physics</AL>: just as a word to <E1 /> a combination of various quark-waves and photon-waves (and the collapsing from their <LRL>superpositions</LRL> of other such ‘particles’, the names of which are <NW>atomocentric</NW> as <AL>Hell</AL>, no offence to <QL name="snthropocentrism">humanity</QL>’s past great thinkers like Mr. Bose and Mr. Fermi).<sup>‡</sup></p>
      <p>There can be however many theoretical ‘levels’ between our observable universe and the bottom-level (the <AL name="notnew.plato.neoplatonism">Neoplatonic</AL> ‘One’); to <WName /> the existence of these (or lack thereof) matters not at all, and the <QL>goal</QL> (on <i>our</i> ‘science’) is still ‘<NW>coalescence</NW>’.</p>
      <p>This is, after all, just science.</p>
    </div>,
    bottomFootnote: <p>
      ‡ If your <NW>POC</NW> <PL name="notready">struggled</PL> to <LRL name="understand">follow</LRL> this, why did you click the one called ‘science’?
    </p>,
  },

  // This is just logic.
  {
    name: "logic",
    alternatives: "logical,logically",
//    prelude: <span>
//    </span>,
    header: <span>
      This is just logic.
    </span>,
    outside: <span>
      It’s called <NW>inductive reasoning</NW>: sort of the anti-Sherlock Holmes.
    </span>,
    preview: <div className="angle-preview">
      <p>The (fictional) genius Sherlock Holmes knows a lot about how the world works (i.e., the ‘rules’ of the world) and he uses these rules to <i>deduce</i> – out of very scant data – the most likely occurence that could have generated that the data.</p>
      <p>This <QL>Weltanschauung</QL> was built in a way that’s entirely the opposite. Start off assuming that you know <i>nothing</i> (i.e., you know <i>no</i> ‘rules’ of how the world works) and then gather a <i>crap-ton of data</i> and use this veritable mountain of information to <i><NW word="induction">induce</NW></i> the underlying ‘rules’ that could best <E2 /> it.</p>
      <p>Sherlock may not use the ‘science of <NW>induction</NW>’ very much, but both of these methods are equally valid <i>logic</i>.</p>
    </div>,
    text: <div className="angle-text">
      <p>Why might it then be <PL name="unready">hard</PL> for <QL name="anthropocentrism">human</QL> <PL name="individuality">individuals</PL> to use this sort of <NW>inductive reasoning</NW> (also known as the ‘scientific method’)? <WName /> <E2 suffix="s" /> this as a product of two potential factors: <NW>conservative thinking</NW> and (<PL name="sweeping.ig">of course</PL>) <NW>individual greed</NW>.</p>
    </div>,
  },

  ],
};

