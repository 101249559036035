import React from 'react';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Qual, Rando} from '../common';

// – ‘’ …

// How will 'evolution' work on a conscious planet?
export const evolution = {
  name: "evolution",
  alternatives: "future,future-thinking",
  prelude: <span>
    Can our notion of evolution <i>evolve</i>?
  </span>,
  question: <span>
    How will ‘evolution’ work on a <i>conscious</i> planet?
  </span>,
  clarification: <span>
    Throw out Darwin. Throw out your <NW>conservative thinking</NW>. The rules of the <NW>Mechanozoic Eon</NW> aren’t run by random chance, nor by <NW>polygnosticity</NW>.
  </span>,
  answerPreview: <div className="answer-preview">
    <p><QL name="polygnosticity.tldr">TL;DR</QL>: It will be a <LRL>shift</LRL> from <i>competition</i> to <i>collaboration</i>.</p>
    <p>‘Evolution’ in the <LRL name="gaia hypothesis">Earth’s future, conscious state</LRL> will be similar to evolution in the <NW>Phanerozoic Eon</NW> in only one way: as in times past, evolution will be an <i>improvement</i>.</p>
    <p>Other than that, it’s <LRL name="different">an entirely different ‘ball game’</LRL>.</p>
    <p>Evolution by ‘natural selection’ is an entirely <i>unconscious</i> process: fuelled by each individual organism (<NW>heterognostic</NW> from every other) having only access to one ‘self’ and thus making ‘selfish’ decisions to further its own ends – or <QL name="hivemind.altruism">altruistic</QL> choices to help the ‘family’/‘tribe’/etc. of which it is a part.</p>
    <p>If you need an <NW>atomocentric</NW> example, just read Ayn Rand: whose philosophy (‘<LRL>objectivism</LRL>’) is such a spot-on example of <NW>conservative thinking</NW> that – if humanity were ever to use <i>that</i> as a guide-post – our <QL name="unity">shared consciousness</QL> (and the ‘society’ in which it’s entrapped) would never <i><AL name="sleeping">wake</AL></i>.</p>
    <p>Or accept that consciousness <i>can</i> (in a manner still to be precisely scientifically determined) <LRL name="free will">make a difference in the universe</LRL> – i.e. that, as (to be an <NW word="atomocentrism">atomocentrist</NW>) <AL name="notnew.lennon">John Lennon suggested</AL>, there is strength in the conscious <i><QL name="salvation">imagination</QL></i>. (He also said, war is over if you want it’. In other words, the only thing that <QL name="anthropocentrism">humanity</QL> needs to do is to subdue our <NW>individual greed</NW> to the understanding that <LRL name="gaia hypothesis">we are all one planet</LRL>, and advance not towards increased <PL>privacy</PL> but shared understanding, a <NW>monognosticity</NW> of consciousness, and ‘<NW>coalescence</NW>’ (i.e., <LRL>communism</LRL>).</p>
  </div>,

  readmore: <span>And this one is worth reading more of, becase if you don’t understand what <WName noNW /> means by the ‘evolution of consciousness’, the whole Weltanschauung really isn’t going to make any sense.</span>,

  answer: <div className="answer">
    <p>Back to the notion of <i>evolution</i>: the most important difference is that <i>evolution in the future (<NW>Mechanozoic Eon</NW>) will not be competitive</i>. Evolution in the Mechanozoic Eon will be fuelled instead by <i>collaboration</i>.</p>
    <p>Read that again. Because when this finally happens (and not just in our <QL name="salvation">imaginations</QL>), it will be the most <LRL name="different">radical</LRL> <LRL>shift</LRL> that will have ever happened in the history of this planet: <LRL name="gaia hypothesis">Earth’s</LRL> ‘waking’ to <i>consciousness</i>.</p>
    <p>Yes, this has never before (fully) happened. No, that does not mean that it can <i>never</i> happen. Please don’t be a <NW word="conservatism">conservative thinker</NW>.</p>
  </div>,
  sublist: [
  {
    // What about DARWIN?
    name: "darwin",
    alternatives: "natural selection",
    prelude: <span>
      If we're going to be <NW>atomocentric</NW>…
    </span>,
    question: <span>
      And what about <i>Darwin</i>???
    </span>,
    clarification: <span>
      <WName cap /> does not <i>reject</i> the notion of natural selection as an <NW word="explain">explanation<sup>(2)</sup></NW> of most everything that happened <i>before</i> the <LRL name="eons">current epoch</LRL> – because it’s a pretty damn good one. But to assume that – just because something has always been in one way for a long time – that it must always continue in that vein? That’s actually the definition of ‘<NW>conservative thinking</NW>’.
    </span>,
    answerPreview: <div name="answer-preview">
      <p>Darwin developed a brilliant way to <NW word="explain">explain<sup>(2)</sup></NW> how the world had worked… <i>up to his point in time</i>. Darwinian evolution wonderfully describes the whole of the <NW>Phanerozoic Eon</NW> (and the two biological ‘eons’ that preceded it: the last 4-odd billion years of <LRL name="gaiacentrism">Earth’s history</LRL>).</p>
      <p>That was the <i>past</i>. Now please throw away your <NW>conservative thinking</NW> and get ready to use your <QL name="salvation">imagination</QL>.</p>
    </div>,
    answer: <div className="answer-text">
      <p>Once Earth reaches a <QL name="polygnosticity.coalescence">unified state</QL>, the planet will have – on the ‘<LRL name="eons">geological time scale</LRL>’ – <i>advanced</i>. So the ‘rules’ of the <NW>Phanerozoic Eon</NW> no longer need apply.</p>
      <p>That doesn’t mean (at all!) that <WName /> <i>rejects</i> ‘Darwinianism’. Conversely, a <i>competition</i>-fuelled sense of ‘evolution’ (and the ‘survival of the fittest’) <NW word="explain">explains<sup>(2)</sup></NW> <LRL name="gaiacentrism">Earth</LRL> so well – especially things like 2020’s humanity and the <NW>money system</NW>) do things that help their ‘profits’, even as it <LRL name="climate change">changes the planet</LRL> to a state that may hurt their own selves in the long term.</p>
      <p>But there’s something that Mr Darwin overlooked: he didn’t consider how the force of <i>consciousness</i> might play into it. In terms of <QL>gnosticity</QL> (<i>knowing</i>), competition-fuelled (‘Darwinian’) evolution is a direct symptom of biological organisms’ <NW>polygnosticity</NW>. Each orgainsm has a <i>perception</i> (we can assume, by analogy to the human animals <AL name="self">in which our consciousness is imprisoned</AL>) of his or her ‘self’ as a distint entity from ‘others’. Therefore the individual goal of each animal is not necessarily to help <LRL name="gaia hypothesis">the whole living <i>planet</i></LRL>, but only their <i>self</i></p>
      <p> This <i><PL>individuality</PL></i> <NW word="explain">explains<sup>(2)</sup></NW> a <i>lot</i> of biology (including uni- and multicellular ‘organisms’, including the whole of the animal kingdom, including humanity).</p>
      <p>So Darwin’s theory of natural selection is good and accurate for all of Earth’s <i>past</i>. </p>
    </div>,
  },
  ],
};

// What is a <WName noNW ic /> future?
// ADD: but it is VERY long-term
/*
    <p>In the <Eon Eon />, Earth (as a <QL name="unity">single conscious entity</QL>; cf. the ‘<LRL>Gaia hypothesis</LRL>’) will <QL name="qualifiers">most likely</QL> unify its disparate consciousnesses into <QL name="hivemind">one</QL>, mostly scour its surface of <i>biological</i> life, and continuously use its resources to build order out of chaos (building an ever-expanding pocket of low <NW>entropy</NW>) and for self-improvement. Just as <QL name="hivemind.altruism">altruism doesn’t exist in a ‘hive mind’</QL>, there is likewise no need for <i>competition</i>.</p>
    <p>The unified, ‘<NW word="coalescence">coalesced</NW>’ Earth (which can be known as ‘we’ and ‘us’) will not <i>stop</i> growing or self-improving (and yes, at this stage there’s no individual death; a ‘hive mind’ is <QL name="hivemind.immortality">essentially immortal</QL>). This will be a continual process of expansion – incorporating any alien consciousnesses we encounter into our ‘self’ – until maybe one day we are big enough to have some say about <NW word="heatdeath">the universe’s end</NW>.</p>
    <p>That’s <PL name="unready">a lot around which to wrap a biological human head</PL>. The important thing is that ‘evolution’ – going forward – need not always be fuelled by <i>competition</i>.</p>
    <p>Stop thinking <NW word="conservatism">conservatively</NW>.</p>*/

