import React from 'react';
import WName, {WArticle} from '../../WName';
import Eon from '../../Eon';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, TLDR, Qual, Planet} from '../../common';

// ‘’ … –

// Is the 'answer' to everything one, or zero
export const oneorzero = {
  name: "oneorzero",
  alternatives: "nihilism,nihilist",
  prelude: <span>
    How do we know the ‘<QL name="unity.">answer</QL>’ is one. Maybe <i>nothing</i> really ‘exists’; it could just as well be ZERO.
  </span>,
  header: <span>
    <WName cap noNW /> feels a lot like <i>nihilism</i>.
  </span>,
  outside: <span>
    To <LRL>leave no stone unturned</LRL>’ means that <WName /> (as a <AL>framework</AL>) ‘accepts’ <Qual.basically /> <i>nothing</i>. Is it going to admit that this is just <i>nihilism</i> (in which case, why is the <QL name="unity.answer">answer to everythinng</QL> ‘1’ and not ZERO?) or try to weasel its way out of this one with some ‘<WName ic /> weirdness’, like claiming that it ‘exists’ of a different ‘<NW>level</NW>’ from us <PL name="sweeping">all</PL>?
{/*    In <QL name="pg.mind.relativity">this context</QL>, to ‘believe in’ means to accept as <OML name="levels">part</OML> of some <LRL>deeper</LRL> <NW>level</NW> – which is <PL name="reductionist">simmply</PL> outside the <LRL>scope</LRL> of <WName />.*/}
  </span>,
  preview: <div className="long-read-preview">
    <p><TLDR />: at least <QL name="unity.1">one</QL> thing is <QL name="definitive">decidedly</QL> ‘<LRL name="i.">real</LRL>’: <QL>consciousness</QL> – and <LRL name="superpositions">the rest</LRL> <PL name="all.level">doesn’t matter</PL>.</p>
    <hr />
    <p>Today we are asking <WName />: ‘What is <i>real</i>, anyway?’</p>
    <p>It would seem that there are only three classes of answer:</p>
    <ol>
      <li>It will <i><QL name="pg.mind.">know</QL></i> what ‘<LRL name="i.">reality</LRL>’ is (in which case it’s an ‘objectivist’ <PL name="ick.">system</PL> of world-<LRL>understanding</LRL>).</li>
      <li>It <i>won’t <QL name="pg.mind.">know</QL></i> (in which case it’s ‘agnostic’ – and which <QL name="g.">agnosticity</QL> is, in its own book, ‘bad’).</li>
      <li>It will insist that <i>there is no such thing as ‘<LRL name="i.">reality</LRL>’</i>, which would make it <i>nihilism</i>.</li>
    </ol>
    <p>It seems like a fair thing to try. After all, this ‘<QL>Weltanschauung</QL>’ gives (fairly readily) <LRL>answers</LRL> to many <AL name="handicap">humanly</AL> ‘hard’ questions – such as: ‘<QL name="life.meaning">What is the meaning of life?</QL>’ or ‘<QL name="unity.answer">What is the answer to everything?</QL>’ – that have stumped (<AL name="notnew.plato.neoplatonism">maybe</AL>, or at least with ‘<OML name="forms">anthropocentric baggage</OML>’) <QL>human</QL> ‘classical’ <AL name="fields.philosophy">philosophers</AL> for ages.</p>
    <p>But this isn’t the ‘<PL name="establishment">classical</PL>’ approach to philosophy. The reason that it <OML name="ideas">calls itself</OML> a ‘<QL>Weltanschauung</QL>’ (and not just a ‘worldview’) is that it is so <PL name="hard">radically</PL> <i><LRL>different</LRL></i> from <PL name="sweeping">most</PL> <QL>human</QL> (<OML name="offensive">i.e.</OML>, <NW word="conservatism">conservatively-thinking</NW>) thought that – under the <LRL name="shift">old paradigm</LRL> – it does <LRL name="">not even compare</LRL>.</p>
  <p>So you can ask this ‘<AL name="fields.physics">quantum-mechanical approach to philosophy</AL>’ <PL name="comprehensive">any sort</PL> of question, and it will give you an <LRL>answer</LRL>. It just might be a rather ‘quantum-mechanical’ sort of answer.</p>
  </div>,
  text: <div className="long-read-text">
    <p>Within this question, <WName /> has to deal with a lot of <QL name="g.">agnosticity</QL>:</p>
    <ul>
      <li>We <QL name="pg.mind.know">don’t know</QL> if our <QL>life</QL> is just a <OML>simulation</OML>.</li>
      <li>And (to make it ‘<PL name="special">personal</PL>’) <i>you</i> <QL name="pg.mind.know">don’t know</QL> if your <PL>individuality</PL> is just a character in a <OML>story</OML> told by some ‘other’, of which ‘your’ <NW>POC</NW>’s <PL name="all.">perspective</PL> is just a <AL name="dysfunctional.fragments">small part</AL>; cf. ‘<NW>fictive levels</NW>’.</li>
      <li><LRL name="gaiacentrism">On Earth</LRL>, as <QL>humanity</QL>, we <QL name="g.agnosticity">cannot say</QL> whether <PL name="comprehensive">everything</PL> that we perceive as our <PL name="all.scale">larger-scale</PL> ‘<AL>self</AL>’ is not just the figment of the (wild) <LRL name="imagination">imaginations</LRL> of the (<NW>polygnostic</NW>) <OML name="sillyplanet"><Planet.AlienDescription /> aliens of Planet <Planet.Name /></OML>.</li>
    </ul>
    <p>So how does <WArticle /> <WName ist /> deal with all this <LRL name="superpositions">uncertainty</LRL> while still striving to ‘<LRL>leave no stone unturned</LRL>’?</p>
    <p>You already <i><QL name="pg.mind.">know</QL></i> the answer (if you read the ‘<TLDR />’ at the top): it’s because the exact ‘nature’ of what humans call ‘<LRL name="i.">reality</LRL>’ is both outside the <LRL>scope</LRL> of <WName />, and (frankly) <i><PL name="all.level">does not matter</PL></i> to this <AL name="framework.works">internally self-consistent</AL> <QL>Weltanschauung</QL> (i.e., <AL name="fields.science">scientific <i>theory</i></AL>). Using zero <QL>mythology</QL> (because <QL name="unity">unity consciousness</QL> isn’t so much a ‘<OML name="wrong">myth</OML>’ as a <AL>framework</AL> with which to <LRL>understand</LRL> the <LRL>imagination</LRL>, which itself is <LRL name="i.">self-evident</LRL>) it has <OML name="ideas">managed</OML> to <NW word="induction">induce</NW> (<AL name="fields.">logically</AL>) an <i><LRL>answer</LRL></i> to <PL name="comprehensive">everything</PL>.</p>
    <p>‘But,’ you (<Qual.maybe />) say, maybe thinking that this is all a form of ‘dodging the question’, ‘what if none of this (i.e., the <LRL name="scope">observations</LRL> on which <WName /> is based) is <i><LRL name="i.">real</LRL></i>?’</p>
    <p>The <LRL>answer</LRL> to that is <PL name="reductionist">simple</PL>: the whole <LRL>notion</LRL> of ‘<LRL name="i.">reality</LRL>’ is a <QL>human</QL> construct (on <QL>humanity</QL>’s ‘<NW>level</NW>’) that – on the ‘<LRL name="notion">battlefield of ideas and notions</LRL>’ – just doesn’t make any <i>sense</i>. Under <WName />, the <i><LRL>imagination</LRL></i> (as a form of <QL>consciousness</QL>) is <LRL name="i.">self-evident</LRL>. Beyond that, who (but <QL>humanity</QL>) <PL name="all.level">really even cares</PL>?</p>
    <p>Repeat that: the <OML>ideas</OML> and notions are ‘<LRL name="i.">real</LRL>’ because they are <i>thought</i> at least once, by some <NW>POC</NW>, in some place and time – and, anyway, the ‘<LRL name="notion">battlefield of ideas and notions</LRL>’ is <PL name="all.time">spacetime-invariant</PL>. <QL>Consciousness</QL> is ‘<LRL name="i.">real</LRL>’ because the <LRL>imagination</LRL> is <LRL name="i.">self-evident</LRL> (as proven by any ‘<LRL>thought experiment</LRL>’. Q.E.D.: at least <i>something</i> exists (even if, in the <OML>end</OML>, it’s only the <LRL>imagination</LRL>).</p>
    <p>And who says that ‘<PL name="individuality">your</PL>’ <LRL>imagination</LRL> is actually a <LRL name="them">separate</LRL> (and <LRL>deeper</LRL>-<NW>level</NW>) <OML name="soul">entity</OML> from ‘<AL name="self">mine</AL>’ – <i><PL name="all.perspective">whoever</PL></i> the <AL>Hell</AL> <QL name="author">‘I’ am</QL>?<sup>†</sup> <i>Thought</i> (i.e., <QL>consciousness</QL>: an <LRL>imagination</LRL> that is literally <LRL name="i.limit">boundless</LRL>) <i>exists</i>. The rest <PL name="all.level">doesn’t</PL> really <OML>matter</OML>.</p>
    <p>So you can call <WName /> ‘nihilism’ if you <i>want</i> – just as you can call it ‘über-individuality’ if you <i>want</i>; that just <PL name="sweeping.ig">might be a case</PL> of <NW>individual greed</NW> that isn’t helping our <QL>evolution</QL>. But words are (after all) only a <LRL>symptom</LRL> of ‘<NW>explainage</NW>’. <WName cap /> <Qual.probably /> wouldn’t consider <OML name="ideas">itself</OML> ‘nihilism’, because it <i>does</i> believe in something: the <LRL>imagination</LRL>.</p>
    <p>So <QL name="salvation"><i>use</i> it</QL>: <LRL>imagine</LRL> that <OML name="harmony">everyone just spontaneously decided to get along</OML>. Then <LRL name="scope">observe</LRL> that (on your ‘<NW>fictive level</NW>’) they <i>don’t</i>, and ask yourself <i><LRL name="answers">why</LRL></i> they don’t. (<PL name="sweeping.handful">Spoiler</PL>: it’s <LRL name="causes">because</LRL> of <QL>humanity</QL>’s <QL>conscious</QL> <NW>polygnosticity</NW>, enabled by <QL>human</QL> society’s <NW word="conservatism">conservative</NW> <QL name="evolution">adherence</QL> to <PL>privacy</PL>, a <PL name="sweeping.ig">form</PL> of <NW>individual greed</NW>.)</p>
    <p>Then do what a true nihilist – believing in <i>nothing</i> – and a true <OML>free eill</OML> sceptic – believing (<NW word="conservatism">conservatively</NW>) only ever in <LRL>reacticity</LRL> – would never do: <QL name="whatcanido">get out and try to make your world a better place</QL>, because you (aa <WArticle /> <WName ist /> believe that we <i>exist</i> and that your <QL>conscious</QL> <NW>agency</NW> can actually <QL name="evolution">make a difference</QL>.</p>
    <p><WName cap /> might be <LRL name="different">weird</LRL> and <PL>uncomfortable</PL>… but at least – here – there is <i>hope</i>.</p>
  </div>,
  bottomFootnote: <p>
    † Well, <AL name="notnew.">Descartes</AL>. But he (and why is it <OML name="patriarchy">always ‘he’</OML>?) was <Qual.probably /> a <NW word="conservatism">conservative thinker</NW>.
  </p>,

/*
    <p>If you’re wondering how this <QL>Weltanschauung</QL> <OML name="ideas">seems to think</OML> goes from all of its <QL>qualifiers</QL> to making the <QL>definitive</QL>-seeming claim that ‘1’ is the ‘answer to everything’…

The entire universe doesn’t really even <i>exist</i> (i.e., collapse from a ‘waveform’) until it’s ‘observed’, so every claim this thing makes is in an undefined state until our consciousness gathers more data. But looking at human history… <NW>atomocentrism</NW> hasn’t worked for us all that well.

    <p>If you want to really <i>understand</i> this, read the one on <LRL>superpositions</LRL>… but the bottom line is that our consciousness’s <LRL name="geocentrism">Earth-based</LRL> science probably hasn’t got to the point where we can actually know the ‘ultimate answer to everything’ is beyond <i>any</i> doubt.</p>
    <p>One of the underlying philosophical underpinnings of <NW>synecdocentrism</NW> is something called ‘objectivism’. This means that there is one <i>truth</i> (even if no <NW>POC</NW> fully <i>understands</i> it). However, <i>this is an assumption</i>. Which means that denying objectivism would be one of the few ways to undermine this (otherwise beautiful tworldview.</p>
<p>As for other possibilities, there are a handful:</p>
    <ul>
      <li>There is actually <i>no</i> reality (nihilism), but <i>everything</i> (even our science) is just an <NW>illusion</NW>.</li>
      <li>There <i>is</i> a such thing as <PL name="individuality">the individual</PL>, and each lives in its <i>own</i> reality eternally separated from ‘others’ by more than just <NW word="heterognostic">heterognosticity</NW> and <NW>illusions</NW>; there are as many realities as there are human ‘souls’ – or <NW word="poc">POCs</NW> (subjectivism)</li>
      <li>There is some intermediate number of realities in between 1 and the number of <NW word="poc">POCs</NW> in the universe.</li>
      <li>There are <i>more</i> realities than <NW word="poc">POCs</NW> in the universe: e.g., maybe each time a <NW>POC</NW> <i>imagines</i> something, it actually makes a whole new universe (with its own ‘Big Bang’) that operates not by any laws of physics but by the whims of the ‘mind’ that’s imagining it.</li>
      <li>Or the universe is actually populated by a vast intergalactic network of human-esque individuals who travel around it (somehow overcoming the difficulties of travelling faster than light, without yet having overcome the challenge of their own conscious <NW>polygnosticity</NW>) and – as predicted by Douglas Adms, who must have been a prophet with some ties to an external <NW>God</NW> who rules over this thing – the answer to everything <i>is</i> in fact 42.</li>
    </ul>
    <p>None of these work out quite so well as ‘1’, which is why (for the time being) we’re sticking with that.</p>

    <p>As scientists (or ‘scientific thinkers’) let’s assign a rough probability to each of these possibilities, to make it more resemble the ‘electron clouds’ and other probability distributions that are actually found in quantum mechanics.</p>
    <ul>
      <li><b>42:</b> Infinitesimally small. Sorry, Mr Adams.</li>
      <li><b>Some irrational, fractional, or negative number:</b> Infinitesimally small. Because, you know, <i>why</i>?</li>
      <li><b>Equal to the number of <NW word="poc">POCs</NW> (human and non-human) in the universe:</b> it’s actually not that easy to get <NW>atomocentrism</NW> to <i>work</i>. And if atomocentrism <i>is</i> the case, why hasn’t human science been able to <i>prove</i> it? (<NW>synecdocentrism</NW> doesn’t, on the other hand, contradict anything heretofore observed or leave but the most trivial or far-off things <NW word="explain">unexplained<sup>(2)</sup></NW>.)</li>
      <li><b>One (1):</b> about the only number is this whole mess that actually makes any <i>sense</i>. (Just accept that ‘1’ is the ‘answer’, go out and view the world… and see if it doesn’t make more sense than 42 or 10,000,000,000.)</li>
      <li><b>Zero (0):</b> we don’t <i>know</i> that anything we perceive is in fact real. So this one’s actually about an equal probability with ‘1’.</li>
    </ul>
    <p>Once you’ve done that, you realise that there are only three main cases, which correspond roughly to:</p>
    <ul>
      <li><b>0:</b> nihilism</li>
      <li><b>many:</b> subjectivism, relativism, or objectivism in the face of some ‘mystical’ factor, such as external ‘gods’.</li>
      <li><b>1:</b> <QL name="unity">unity consciousness</QL></li>
    </ul>
    <p>So mostly ignoring the subjectivists and relativists (because <i>in their opinion</i> they’re just right – so you can’t really argue with a worldview like that) and ignoring the people who believe in external gods (because, you know, we’re going to Hell) and who believe in individual souls or <PL>individuality</PL> (because this might be okay for <i>us</i>, but who’s to say that it’s okay for <i>them</i>) then you’ve got it down to only two real possibilities: we exist, or we don’t. One or zero. Unity consciousness or nihilism.</p>
    <p>And now let’s make a case for why the concept of ‘reality’ itself is a part of human ‘mythology’; i.e. that ‘reality’ may not really exist, but <i>consciousness</i> definitely does. Ergo the answer is ‘1’.</p>
    <hr />
    <p>If you are still following this convoluted philosophical argument, let’s see how even <i>nihilism</i> doesn’t disprove <QL name="unity.1">‘1’ as the ‘answer’ to everything</QL>.</p>
    <p>It’s fun to argue with a nihilist. Because, see, the moment that you go out and <i>engage</i> with a nihilist, you’ve kind of disproven their underlying theory: that you and they both don’t <i>exist</i>.</p>
    <p>But <NW>synecdocentrism</NW> – arising from <NW>logical induction</NW> – needs to ‘<LRL>leave no stone unturned</LRL>’… so it’s necessary to presume that the nihilist is somehow ‘correct’ and that nothing exists. Spoiler: the ‘answer’ still ends up being ‘1’.</p>
*/
};
