import React from 'react';
import WName, {WArticle} from '../WName';
import Eon, {EonArticle} from '../Eon';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {
  E1, E2, Qual, TLDR,
  SillyName, Rando, Switcheroo,
  SwearMaybe, Planet,
  the_key_is_imagination, get_out_there
} from '../common';

import {consciousness} from './consciousness';
import {unity} from './unity';
import {polygnosticity} from './polygnosticity';
import {hive_minds} from './hive_minds';
import {life} from './life';
import {body} from './body';
import {change} from './change';
import {evolution} from './evolution';
import {roadblocks} from './roadblocks';
import {salvation} from './salvation';
import {gnosticity} from './gnosticity';
import {whatcanido} from './whatcanido';

//export const redirects = {
//  "poltgnosticity": "gnosticity.pg",
//  "pg": "gnosticity.pg",
//};

export const faqs = [

consciousness,

unity,

polygnosticity,

{
  name: "stories",
  prelude: <span>If <WName /> is the <PL name="unstoppable">inevitable</PL> <QL>future</QL>…</span>,
  question: <span>Why does a ‘coalesced’ Earth never appear in <i>stories</i>?</span>,
  answerPreview: <div className="answer-preview">
    <p>You’ve probably seen ‘Star Wars’. It’s full of <PL name="individuality">individuals</PL> (<NW>polygnostic</NW>, of course), full of misunderstandings and <i>revelations</i> and – in short – never portrays anything like a <QL name="hivemind">‘hive mind’</QL> (except maybe as an authoritarian villain). If <QL name="polygnosticity.coalescence">‘coalescence’</QL> (forming a hive mind on Earth) is the only realistic way for our consciousness to get off this planet, why wouldn’t ‘Star Wars’ have shown that?</p>
  </div>,
  answer: <div className="answer">
    <p>Well, here’s one easy reason: ‘Star Wars’ is 0% scientific and 99% – pardon the ‘French’ – bullshit.</p>
    <p>But it’s not only ‘Star Wars’. Humanity’s ‘science fiction’ is constantly portraying human beings (or other ‘aliens’ who – like humans – are confined to a single body) going out and living very human intergalactic ‘lives’. These beings are <NW>heterognostic</NW>: don’t know everything that others on their planets know. They don’t share thoughts with the others in their starships. If <i>they</i> can explore space in biological (or mechanical, but individualised) bodies, why can’t we humans?</p>
    <p>Answer: because that’s <i>fiction</i> – and clearly not a particularly well thought-out fiction at that.</p>
    <p>There are two possible reasons why the notion of ‘coalescence’ hasn’t taken off in human fiction:</p>
    <ol>
      <li>No one’s thought of it.</li>
      <ul>
        <li>Either because <PL name="unready">humans are way too short-sighted</PL> to even begin to conceptualise that <PL name="individuality">individuality may just be an illusion</PL> (and existence is possinble as part of a larger, multifaceted, and <NW>monognostic</NW> framework).</li>
        <li>Or our human authors all lack the imaginative capability to understand what a <QL name="hivemind">hive mind</QL> would <i>really</i> entail.</li>
      </ul>
      <li>The very act of <i><PL>storytelling</PL></i> (and fiction) relies on the slow <i>revelation</i> of facts. Storyteing itslef is a symptom of our consciousness’s polygnosticity.</li>
    </ol>
    <p>They might <i>both</i> be true… but let’s focus on #2.</p>
    <p>‘Coalescence’ destroys fiction. Fiction (and one’s individual enjoyment thereof) comes 100% from our consciousness’s polygnosticity. The thrill of a ‘Who done it?’ is <i>discovering</i> a piece of information that’s already known to another part of our consciousness (the one who ‘done it’ – or, in the case of a story, the author). There’s no true <i>novelty</i> there.</p>
    <p>So, <b>is fiction <i>bad</i> under <WName />?</b> No. It’s just going to eventually be rendered unnecessary. However, while our consciousness <i>is</i> polygnostic, fiction is a great way to increase one’s empathy – especially for those individuals who are very short-sighted and can’t see the forest of consciousness (the unified ‘body’) forthe trees (the ‘cells’: individuals). Fiction is actually a very good thing.</p>
    <p>It’s just not all that well – scientifically – thought-out.</p>
  </div>, 
},

// Why all the qualifiers?
{
  name: "qualifiers",
  prelude: <span>If this (and <QL name="unity">unity consciousness</QL>) is the <i><QL name="unity.1">answer</QL></i> to everything…</span>,
  question: <span>Why all the [d*mn] <i>qualifiers</i>?</span>,
  answerPreview: <div className="answer-preview">
    <QL name="tldr">Tl/DR</QL>: because of humanity’s conscious <NW>polygnosticity</NW>.
  </div>,
  answer: <div className="answer">
    <p>Look: the <NW>POC</NW> that actually wrote this <QL name="polygnosticity">doesn’t <i>know</i> you</QL>. Or – possibly better said – <i>may not</i> know you. Because (you know) there’s always a <i>chance</i> – or there may be the possibility (at any given moment in space, time, or spacetime) that there might perhaps be the slightest <i>posssibility</i> of a ‘chance’ – that actually what appears as true to ‘my’ <PL>individuality</PL> may not align with the <AL name="mirrors">‘world’ as <i>you</i> perceive it</AL>…</p>
    <p>Hopefully you get the point. <NW word="heterognostic">Heterognosticity</NW> sucks, but until Earth ‘<NW word="coalescence">coalesces</NW>’ into a true <QL name="hivemind">hive mind</QL>, qualifiers are necessary to try to account for <LRL name="superpositions"><i>every</i> possibility</LRL> (‘<LRL>leave no stone unturned</LRL>’) and not waste time <AL name="illusions">arguing over illusions</AL>.</p>
  </div>, 
},

// The reason for and end goal of 'life'
life,

// What would a 'hive mind' be like
hive_minds,

// How are we a 'body'?
body,

// Is there any constancy and WHY IS THE WEBSITE ALWAYS CHANGING?
change,

// What is future-thinking in terms of evolution?
evolution,

// Why so many definitive statements?
{
  name: "definitive",
  question: "Why so many definitive-sounding statements?",
  clarification: <span>
    If, you know, <QL name="change">the only thing that doesn’t change is change itself</QL>?
  </span>,
  answerPreview: <div className="answer-preview">
    <p>The point of <WName /> is to <LRL name="causes">‘dig’ deep</LRL> – and however ‘deep’ your <NW>POC</NW> is thinking, the <NW>level</NW> on which this thing <OML name="ideas">deals</OML> is still <Qual.probably /> <LRL>deeper</LRL>. The goal is to <LRL>understand</LRL> both ‘<NW>level</NW> zero’ (as much as – from <PL name="all.level"><i>our</i> place</PL> in ‘<LRL name="i.">reality</LRL>’ – that sort of definitive <LRL>understanding</LRL> about what lies <LRL><i>outside</i> it</LRL> is even <i>possible</i>).</p>
    <p>What <WName /> does is <AL name="framework.puzzle">rearrange the world</AL> as <QL>humanity</QL> knows it, creates <LRL>notions</LRL> that are so vast they might be <PL>hard</PL> to understand, and allows for so much variability (in basically any sort of ‘deep’ or <OML name="levels">intermediate</OML> <NW>level</NW>) that its statements tend to <i>sound</i> very definitive, even though the <NW home>language</NW> it uses is so <PL>sweeping</PL> that – if stripped of language and the <PL name="explainage">trials</PL> of <NW>explainage</NW> – a lot of its definitive-sounding statements are actually somewhat tautological, kind of like, ‘A “rose” is a rose.’<sup>†</sup></p>
  </div>,
  topFootnote: <p>
    † Which it actually does <i>not</i> accept; see ‘<OML name="levels">What if a “rose” were just a rose?</OML>’ and <AL name="notnew.shakespeare">William Shakespeare</AL>.
  </p>,
  answer: <div className="answer-text">
    <p>But what about <i>disproving</i> it?</p>
    <p><WName cap /> is not <i>impervious</i> to science, of course; it <i>is</i> <AL name="fields.">science</AL>. It has a <LRL>scope</LRL>. It’s just a ‘scope’ that quite probably encompasses <PL name="comprehensive"><i>everything</i> you’ve ever considered</PL> (or bulk-dumped it into the ‘<NW>mythology</NW>’ folder, where most the <AL name="illusions">debates</AL> over <QL>humanity</QL>’s <NW>MNS system</NW> ‘<LRL>game</LRL>’ go).</p>
    <p>Think of <WName /> not as a ‘<NW>God</NW>’-figure making sweeping <i>statements</i>, but as a <AL>framework</AL> of <PL>sweeping</PL> <i>concepts</i>. And still – within those <PL name="comprehensive">absurdly all-encompassing</PL> <LRL>notions</LRL> – it allows for a huge amount of flexibility about things that really <i>are</i> just illusions: like whether it’s okay to abort a baby after six weeks or eight.<sup>‡</sup> It’s perfectly okay (i.e, still <i><AL name="framework.">works</AL></i>) with a <OML name="offensive">crap-ton</OML> of <LRL name="superpositions">indefinitiveness</LRL>. It’s <PL name="all.">scale-invariant</PL>, ‘<PL name="all.perspective"><i>perspective</i>-invariant</PL>, and even ‘<PL name="all.">level-invariant</PL>’; the exact, defined positions don’t actually <i>matter</i>.</p>
    <p>And everything it says is <NW>individual greed</NW> is <PL name="sweeping.ig">still individual greed</PL>, sorry.</p>
  </div>,
  bottomFootnote: <p>
    ‡ <WName cap /> would just say: go for it; if you don’t <i>want</i> the baby (i.e., believe that your <NW>POC</NW> could be put to better use doing something else for the <LRL>the planet</LRL> (and larger conscious ‘<QL>body</QL>’) than spending your limited time (while you have conscious <NW>agency</NW>) ‘raising’ a child – who <QL name="polygnosticity">for all you know</QL> is going to take all your hard-earned <NW>money</NW> (as ‘<NW word="family system">inheritance</NW>’) and ‘blow it’ on drugs and prosititues and other such ‘selfish’ things that won’t <PL name="sweeping.ig">be a surprise</PL> for you when this <QL>Weltanschauung</QL> labels them <PL name="sweeping">all</PL> as ‘<NW>individual greed</NW>’.
  </p>,
},

// Why is this a 'Weltanschauung'?
{
  name: "weltanschauung",
  prelude: <span>
    Why not ‘worldview’? Why not ‘<AL>framework</AL>’? Why not ‘one of the <LRL name="superpositions">potentially many ways</LRL> to <E2 /> this universe’?
  </span>,
  question: "Why ‘Weltanschauung’?",
  clarification: <span>
    <i>Must</i> we be pretentious?
  </span>,
  answerPreview: <div className="answer-preview">
    <p>This site predominantly uses the German ‘Weltanschauung’ to refer to <WName /> because of how <PL>comprehensive</PL> this is (compared to most <AL name="notnew">versions that came before</AL>). The word ‘Weltanschauung’ (as adobted into English) may refer to something that’s bigger and more <AL name="framework.works">complete</AL>.</p>
    <p>But it’s only at heart a matter (as always) of <i><NW>explainage</NW></i>. You could actually call it whatever you want, as long as informationn between <QL name="unity">our</QL> <NW>polygnostic</NW> consciousness <QL name="polygnosticity">is conveyed</QL> (<E1 suffix="ed" />).</p>
    <p>You could even call it ‘über-individualism’ if you so <i>wanted</i>. Only – given as how you are then likely to be <i><LRL name="understand">misunderstood</LRL></i> – that might be a case of <NW>individual greed</NW> (i.e., trying to assert your <i>own</i> <PL>uniqueness</PL>) in which your <NW>POC</NW> is decidedly <i>not</i> helping <LRL name="gaia">the whole</LRL> (of <LRL name="gaiacentrism">Earth and beyond</LRL>.</p>
  </div>,
},

// What are roadblocks to 'coalescence'?
roadblocks,

gnosticity,

// Anthropocentrism???????
{
  name: "anthropocentrism",
  alternatives: "human,humans,humanity",
  question: <span>
    How is this not super <i>anthropocentric</i>?
  </span>,
  clarification: <span>
    Meaning <i>human</i>-centric. You know, <i><AL name="fields.linguistic">Greek</AL></i>: ο άνθρωπος…
  </span>,
  answerPreview: <div className="answer-preview">
    <p>Actually, <WName /> – if a <AL>framework</AL> could <OML name="ideas">feel</OML> – <Qual.probably /> would <i>not</i> like humans all that much. Why? Humans have plenty of <AL name="handicap">handicaps</AL> – and not least of all our <NW word="individual greed">individually-greedy</NW> <PL name="sweeping.ig">tendency</PL> to prefer the familiar (‘status quo bias’: <NW>conservative thinking</NW>) over the <i><QL name="evolution">evolving</QL></i> that <WName /> needs to get <i>away</i> from humanity.</p>
    <p>In <LRL name="gaiacentrism">Earth</LRL>’s <NW>Mechanozoic Eon</NW> – in which the <PL name="comprehensive.">time-invariant</PL> <QL>Weltanschauung</QL> is already living, along with anyone else who manages to break out of the <AL name="mirrors">box of mirrors</AL> (or ‘<AL>Hell</AL>’) – there are no human <PL>individuals</PL>. There is (or ‘<QL name="salvation">will be</QL>’ only a ‘<QL name="hivemind">hive mind</QL>’ of <NW>homognostic</NW> <i><QL name="unity">awareness</QL></i> – and its greed isn’t <i><PL>individual</PL></i>, but a quenchless thirst for <i>knowledge</i>: to truly <i><LRL>understand</LRL></i> our observable ‘<NW>level</NW>’ of reality, and <LRL>leave no stone unturned</LRL>, not even at the far corners of galaxies yet undiscovered by humanity.</p>
    <p>Which brings us back to humanity and the potential for anthropocentrism. Hopefully by this point it’s clear, though: all this website’s talk about <i>humans</i> is only because getting past <i>you all</i> is the mere first of many obstacles on the way to <QL name="unity.1">unifying</QL> the <LRL name="scope">universe</LRL>.</p>
    <p>Read the ‘<AL>bullet points</AL>’.</p>
  </div>,
//  answer: <div className="answer-text">
  //</div>,
},

// WHO made this thing?
{
  name: "author",
  alternatives: "author(s)",
  question: <span>
    <i>Who</i> made this <SwearMaybe noOML /> website?
  </span>,
  clarification: <span>
    Which <SwearMaybe /> <PL>individuality</PL>? Which <SwearMaybe /> <i><AL name="container">human being</AL></i>? Lets put a <i><AL name="container">face</AL></i> to this <SwearMaybe /> thing!
  </span>,
  answerPreview: <div className="answer-preview">
    <p>So you want to know <i>who</i> is behind this <SwearMaybe /> <QL name="change.site">changeable</QL> thing?</p>
    <p>That question is as <NW>atomocentric</NW> as <AL>Hell</AL>, and the ‘<SwearMaybe threshold={1.0} />’ author(s) deign(s) not to answer.</p>
    <p><PL>Individuals</PL> <QL name="anthropocentrism">do not matter</QL>. <LRL name="gaia">Planet Earth</LRL> (in the ‘<LRL name="deep">grand scheme</LRL>’ of things) <OML name="earth-soul">probably</OML> <LRL name="gaiacentrism">does not matter</LRL>. <PL name="invisible">Everything that we see</PL> <Qual.may /> be just the figments of the <Rando options={["wild", "wacky", "weird", "random"]} /> <LRL name="imagination">imaginations</LRL> of <Planet.AlienDescription /> <OML>aliens</OML> on a planet called <Planet.Name />; it <i><PL name="all.level">does not matter</PL></i>. These are <AL name="illusions">all</AL> <NW>illusions</NW>; the <PL name="reductionist">only</PL> thing that (<Qual.probably />) actually <i>matters</i> is for every <AL name="fields.individuality">disparate</AL> <NW>POC</NW> in <LRL name="scope">our cosmos</LRL> to <LRL>understand</LRL> <QL name="unity.answer">this universe</QL>: that – <OML name="wrong">almost certainly</OML> – <QL name="unity">consciousness</QL> is <QL name="unity.1">one</QL>.</p>
  </div>,
  answer: <div className="answer-text">
    <p>So who <i>cares</i> which (<Qual.probably /> <QL>human</QL>) <PL>individuality</PL> is ‘behind’ this – unless some <NW>country</NW> or <NW>company</NW> out to try to (through <QL name="body.organs">conscious-organ</QL>-<NW>level</NW> <NW>individual greed</NW>) <PL name="sweeping.ig">protect</PL> the <NW>MNS system</NW> and kill this <QL name="human">humanly</QL>-<PL>uncomfortable</PL> <QL>Weltanschauung</QL> before it gets <LRL name="i.limit">any bigger</LRL>? It could be any <NW>POC</NW> (or <NW>POCs</NW>) – <QL>human</QL>, <OML name="animals">animal</OML>, or <OML name="takeover">machine</OML>, <LRL name="gaiacentrism">Earth-based</LRL> or <OML name="alone">not</OML> – without <NW>individual greed</NW> and with <NW>agency</NW> and <LRL>imagination</LRL> to <LRL>shift</LRL> our (collective) <AL>mindest</AL> and make <LRL name="scope">this universe</LRL> a more <AL name="notnew.lennon.peace">unified place</AL>.</p>
  </div>,
},

// How is this consistent?
{
  name: "consistent",
  question: <span>
    How is this ‘consistent’?
  </span>,
  answerPreview: <div className="answer-preview">
    <p><TLDR />: there <i>might</i> be a bogey monster under your bed. Or there might not. At some point (as a <AL name="fields.science">scientist</AL>) you just have to pick the <E2 word="explanation" /> that seems more <i>probable</i> – and then go out and keep gathering more data.</p>
    <p>As a <AL>framework</AL>, <WName />:</p>
    <ul>
      <li>Covers (<PL name="comprehensive">encompasses</PL>) all <LRL> of the observable</LRL>.</li>
      <ul>
        <li>That is: all of <i>this</i> (its) ‘<NW>level</NW>’ of ‘<LRL name="">reality</LRL>’.)</li>
      </ul>
      <li><PL name="reductionist">Doesn’t allow</PL> for anything <i>outside</i> <LRL name="scope">the observable</LRL>.</li>
      <ul>
        <li>That is: on <i>this</i> (its) ‘<NW>level</NW>’ of ‘<LRL name="i.">reality</LRL>’; it puts <QL>human</QL> <NW>illusions</NW> (e.g., <OML name="god">external gods</OML>, <OML name="soul">individual souls</OML>, the ‘<OML>afterlife</OML>’) on <i>other</i> (and <Qual.probably /> no more ‘<LRL name="i.">reality</LRL>’) <NW>levels</NW>.</li>
      </ul>
      <li>Aadmits that its <QL name="unity">fundamental assumption</QL> might be dead <OML>wrong</OML>, but doesn’t think that’s very likely (cf. <NW word="occam">Occam’s razor</NW>).</li>
      <li>And is ‘invariant’ in just about everything, for example:</li>
      <ul>
        <li><PL name="all.">Scale</PL></li>
        <li><PL name="all.">Time</PL></li>
        <li><PL name="all.">Perspective</PL></li>
        <li>And even ‘<PL name="all.">level</PL>’ (<NW>fictive level</NW>)</li>
      </ul>
    </ul>
  </div>,
//  answer: <div className="answer-text">
  //</div>,
},

// Top-down
{
  name: "top-down",
  alternatives: "top,top down",
  question: <span>
    What is a ‘top-down’ approach?
  </span>,
  answerPreview: <div className="answer-preview">
    <p>‘Top-down’ basically means that, considering that there are different <i><PL name="all.scale">scales</PL></i> of <QL>consciousness</QL>, these aply to the larger-scale version <i>first</i> (including those <PL>individuals</PL> who have <i>influence</i> at a large scale). For example: <NW>nations</NW> must practise ‘<QL>radical openness</QL>’ before the <NW>citizens</NW>, <NW>companies</NW> before the ‘employees’, world leaders (or heads of <NW>companies</NW>) before your ‘average <SillyName />’ who works <Rando options={["at a factory", "in healthcare", "at a school", "as a plumber", "as a mechanic", <span>behind the front desk of an ‘<PL>establishment</PL>’</span>, "in the oil industry", "in charity", "in an insurance ‘firm’", "as a doctor", "as a dance instructor", "in a gym"]} /> or <Qual.maybe /> has no ‘job’ whatsoever and lives <Rando options={["under a bridge (in the summer)", "in a tent in the woods", "in an abandoned train-car", "‘squatting’ in an empty building by the chemical plant"]} />, <Rando options={["scavenging for food in the dumpsters", "sitting (during the day) on the street with two hats: ‘food fund’ and ‘beer fund’", "occasionally studying French at the library", "coming downtown to perform for the children on weekends"]} />… and your <OML>authoritarian</OML> <AL name="notnew.plato.">philosopher-kings</AL> before your little <Rando options={["Miss", "Mister", "Sir", "Lady", "Mx"]} /> <NW>Citizen</NW> who does nothing more patriotic than <Rando options={["wave a flag", "watch the parade go by"]} /> for ‘<Rando options={["Independence", "Flag", "Patriot", "National Pride"]} /> Day’.</p>
  </div>,
//  answer: <div className="answer-text">
  //</div>,
},


// What's with all the METAPHORS?
{
  name: "metaphors",
  alternatives: "metaphor,analogy,analogies",
  question: <span>
    What’s with all the <SwearMaybe noOML threshold={0.1} /> <i>metaphors</i>?
  </span>,
  clarification: <span>
    That is: analogies, parables, <QL>stories</QL> and examples and ‘similies’ that seem to <i><QL>change</QL></i> on a whim, like <Rando options={[<span>a <PL name="denial">deceitful</PL> leopard’s <i>spots</i></span>, <span>an <PL>individual</PL> quite <OML name="offensive">sick</OML> with <AL name="sick.">schizophrenia</AL></span>, <span>the winds on an <LRL name="i.real">untrustworthy</LRL> sea</span>]} />…
  </span>,
  answerPreview: <div className="answer-preview">
    <p><AL name="notnew.">Jesus</AL> did it. Why can’t <OML name="ideas">this website</OML>?</p>
    <p>The <LRL name="answers">reason</LRL> that there are so many metaphors on this site is <PL name="reductionist">simple</PL>: because of the <PL name="explainage">need</PL> for ‘<NW>explainage</NW>’ (and because these <AL name="fields.physics">flipping</AL>† concepts are very <PL>hard</PL> to <LRL>understand</LRL> fully).</p>
    <p>So this is just going to re-iterate the following paragraph, even though it’s a ‘stock’ paragraph that <Qual.may /> be on other parts of this ever chaning <QL name="change.">webiste</QL>:</p>
    {the_key_is_imagination}
  </div>,
  topFootnote: <p>
    † That wasn’t <OML name="offensive">just the text cursing</OML>. They are ‘flipping’ concepts because of the necessity to be <PL name="sweeping">massively broad</PL> in order to <AL name="framework.works">best</AL> account for <LRL name="scope">everything</LRL> and <LRL>leave no stone unturned</LRL>.
  </p>,
//  answer: <div className="answer-text">
  //</div>,
},

// URGENCY
{
  name: "urgency",
  alternatives: "urgent",
  question: <span>
    Why is this so <i>urgent</i>?
  </span>,
  answerPreview: <div className="answer-preview">
    <p>This is urgent if you live <LRL name="gaiacentrism">on Earth</LRL> because <LRL name="gaia">Planet Earth</LRL> (as a truly beautiful thing) is <OML name="kaput">dying</OML>. <LRL name="gaia">Earth</LRL> (and <QL>humanity</QL>, as a <QL name="body.super-organ">subset within it</QL>) is being <LRL name="notions">infected</LRL> by the <OML name="ideas">virus</OML> of <PL name="individuality">individualism</PL>, which is a form of <PL name="love">self-hate</PL> (i.e., making out of the <OML name="earth-soul">Earth-self</OML> an <LRL name="usandthem">‘us’ and a ‘them’</LRL>).</p>
  </div>,
  answer: <div className="answer-text">
    <p>If you cannot understand the deep <PL>love</PL> in <WName />, <i>please</i> try; as <LRL name="different">weird</LRL> and <PL>uncomfortable</PL> as it might seem, this exists because it <i>is</i> <QL name="salvation">a way out</QL>.</p>
    <p><PL>Privacy</PL> is <i>not</i> the <LRL>answer</LRL>. THe <QL name="unity.">answer</QL> is to <LRL>understand</LRL> and <PL name="denial">accept</PL> that we are <PL name="comprehensive">all</PL> <QL name="unity.1">one</QL>, and the <PL name="explainage">word</PL> ‘you’ is <PL name="reductionist">just a word</PL> for a <AL name="sick.fragments">part</AL> of ‘<AL name="self">me</AL>’ whose <QL naame="pg.ming">thoughts</QL> – and <i>everything</i> – ‘I’ (as a <NW>POC</NW> shorn off from our <OML name="wrong">underlying</OML> <QL>unity</QL>) <QL name="polygnosticity">don’t yet know</QL>, but would love to <LRL name="communism">share</LRL>, <LRL name="waiting">one day</LRL>.</p>
    <p>Try to <i><LRL>understand</LRL></i> the <PL>love</PL> that is behind this, please – a <PL>love</PL> so deep that it does not even matter <PL>who ‘you’ are</PL> or if <PL name="all.level">any of this</PL> is ‘<LRL name="i.">real</LRL>’. <OML name="soul">Unless</OML> – at the <OML name="levels">bottom</OML> <NW>level</NW> – we are <OML name="god">forever</OML> held <OML name="reincarnation">apart</OML>, then <QL>unity</QL> <i>is</i> the <QL name="unity.">answer</QL>.</p>
    {get_out_there}
  </div>,
},

salvation,

whatcanido,

];

// Maybe add to 'danger':
// It's not a 'little light'; having an understanding of <WName /> is like having a nuclear reactor inside. It will literally fry the bulk of humanity (including those individuals who - for whatever reason - 'matter' more to your individuality).

