import React from 'react';
import ButtonBox from '../ButtonBox';
import Angle from './angles/Angle';
import WName, {WArticle} from './WName';
import AL from './angles/AngleLink';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import OML from './ormaybe/OrMaybeLink';
import LRL from './longreads/LongReadLink';
import NW from './neologisms/NewWord';
import {Outlet, useLocation} from 'react-router-dom';
import {getSubpaths} from './common';
import PagePreview from './PagePreview';
import {getSectionTitle} from '../Menu';

const URL_part = "angles";

const start_sentence = <span>Let’s be real: <WName /> is a really <PL name="hard">tough</PL> thing to <LRL>understand</LRL>.</span>;

const conclusion_without_period = <span>So this website (in an <PL name="explainage">attempt</PL> at ‘<NW>explainage</NW>’) is <OML name="ideas">presenting</OML> this <QL>Weltanschauung</QL> in a <LRL name="motto">vast variety</LRL> of <i>angles</i> – <OML name="ideas">hoping</OML> one of them clicks with your (<NW>heterognostic</NW>) <AL name="handicap">human</AL> ‘<QL name="polygnosticity.">mind</QL>’</span>;

const short_intro = <div className="intro-angles">
  <p>{start_sentence} {conclusion_without_period}.</p>
</div>;

const footnote = <p style={{fontSize: "smaller"}}>† <AL name="notnew.lennon">John Lennon</AL>: ‘<LRL>Imagine</LRL>’</p>

const intro = <div className="intro-angles">
  <p>{start_sentence} A lot of people are (<QL name="qualifiers">probably</QL>) <PL name="unready">just not ready for this</PL>.</p>
  <p><NW>Atomocentric</NW> (and <i><QL>anthropocentric</QL></i>) <NW>mythology</NW> pervades <LRL name="gaiacentrism">Earth</LRL> culture(s)… and the <PL name="sweeping">majority</PL> of <QL>humanity</QL> <NW>polygnostic</NW> consciousness <PL name="denial">would rather live in denial</PL> than face a <PL name="uncomfortable">‘hard’ truth</PL>. <OML name="smarter">Hardly</OML> any <QL>human</QL> ‘<AL name="fields.linguistic">individual</AL>’ <i>with any actual <NW word="agency">potential</NW> to change the ‘status quo’</i> seems to accept that it needs to <QL name="evolution">be changed</QL> – <QL name="urgency">ASAP</QL>.</p>
  <p>{conclusion_without_period}, and you can <LRL name="communism">become one of us</LRL>.<sup>†</sup></p>
  {footnote}
</div>;

export default function Angles(props) {
  const location = useLocation();
  if (props && props.preview) {
    return <PagePreview
      name="angles"
      title={getSectionTitle(URL_part)}
      intro={intro}
    />;
  }
  const subpaths = getSubpaths(location.pathname, URL_part);
  const intro_version = (subpaths.length > 0) ? short_intro : intro;
  return (
    <div className="page">
      {intro_version}
      <hr />
      <Outlet />
      <div className="backbuttons">
        <ButtonBox src={-1}>Back</ButtonBox>
        <ButtonBox src="/menu/angles">All Angles</ButtonBox>
      </div>
    </div>
  );
};
