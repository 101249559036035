import React from 'react';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import OML from '../ormaybe/OrMaybeLink';
import AL from './AngleLink';
import NW from '../neologisms/NewWord';
import {E1, E2, TLDR} from '../common';

// Earth consciousness is a dysfunctional mess (sick 'body').
export const dysfunctional = {
  name: "dysfunctional",
  parentName: <span>our <i>sick</i> conscious body</span>,
  alternatives: "mess,sick",
  prelude: <span>
    Let’s go off the <QL>metaphor</QL> of the conscious ‘<QL>body</QL>’.
  </span>,
  header: <span>
    We are a <i>sick</i> body.
  </span>,
  outside: <span>
    <p>If our consciousness is truly (beyond any <PL name="individuality">illusion of individuality</PL>) a unified <QL>body</QL>, then we are sick. <OML name="kaput">Deadly</OML> sick.</p>    
  </span>,
  preview: <div className="angle-preview">
    <p>Here’s the diagnosis:</p>
    <ul>
      <li>We have an auto-immune disease – which is when the cells of a body start attacking and killing one another, believing the other cells to be foreign. Our collective consciousness has an auto-immune disease that no one has yet
diagnosed, much less begun to treat.</li>
      <li>We also have cancer: the over-proliferation of humanity, and the desire to always make <i>more</i>: more humans, more babies, stay <i>alive</i> (as individuals) longer! Each nation-state wants more for its individual ‘organ’, without realising (or caring) that this is killing the whole.</li>
      <li>And we are <i>mentally</i> ill – with something like DID (dissociative identity disorder, or ‘split personality’): one body split into over 8,000,000,000 ‘personalities’ that aren’t aware of each other’s thoughts (<NW>polygnosticity</NW>).</li>
    </ul>
  </div>,
  text: <div className="angle-text">
    <p>What about the systems that run our individual human lives, and <AL name="identity">keep us separate from one another</AL>? In this metaphor, these systems are viruses that are infecting our cells: clouding their consciousness with <NW>mythology</NW> (maybe with a <AL name="sleeping">soporific effect</AL>) and causing the ‘cells’ to turn against one another. The money system and nation-state system are other diseases that our <QL name="unity">combined consciousness</QL> needs to purge from our body.</p>
    <p>In short, we are all cells of a very sick <QL>body</QL>. It’s a metaphor, obviously – but it’s a pretty damn good metaphor, pardon the English. It <QL name="unity">sums up the state of our world</QL>. Who knows why no other ‘cell’ of the body is willing to see it like this… but, of course, if you <i>do</i> accept this, <PL name="uncomfortable">it isn’t all pleasant</PL>; it ruins a lot of the notions (and <NW>illusions</NW>) that we’ve been taught to value by the money system, the nation-state system, the family system, and others.</p>
  </div>,
  sublist: [

  // We are fragments
  {
    name: "fragments",
    alternatives: "fragment",
    prelude: <span>
      What if ‘you’ are not complete?
    </span>,
    header: <span>
      ‘Individuals’ are really just <i>fragments</i>
    </span>,
    outside: <span>
      You may be an indivisible <i>individual</i>, but who says (thinking non-<NW word="conservatism">conservatively</NW>) you can’t eventually group some <i><AL name="linguistic">άτομα</AL></i> <QL name="hivemind">together</QL>?
    </span>,
    preview: <div className="angle-preview">
      <p>Our <NW>atomocentric</NW> culture puts all the focus on the <i><PL name="individuality">individual</PL></i>: you are an <i>individual</i>; you are good <i><QL name="evolution">just the way you are</QL></i>. You are <i><PL name="equality">equal</PL></i> to every other ‘individual’… and you shouldn’t even <i>work</i> on escapig that <NW>illusion</NW> – becaise you can never be more than your <i>‘self’</i>.</p>
      <p>Okay, there’s no polite way to put this: bullshit. <NW>Synecdocentrism</NW> is calling bullshit.</p>
    </div>,
    text: <div className="angle-text">
      <p>Actually, our <QL name="unity"><i>consciousness</i> is unified</QL>, <PL name="individuality"><i>individuality</i> is the illusion</PL>, our <NW>polynosticity</NW> is the problem – and we <i>should</i> (all) be looking for ways to <QL name="polygnosticity">get around it (our polygnosticity)</QL>.</p>
      <p>The angle of conscious fragments asks you to accept <QL name="unity">unity consciousness</QL> as a baseline. (Acepting unity consciousness doesn’t yet change what ‘you’ (as an individual) <i>have to</i> do with your individual life; it’s just a <NW>synecdocentric</NW> (as opposed to <NW>atomocentric</NW>) <i>approach</i>.)</p>
      <p>Okay, let’s <PL name="unready">go out on  wild limb</PL> and say you actually manage to get that far.</p>
      <p>If you’re accepting unity consciousness, maybe you can start to see Earth – rather than merely a house for the <NW>entropy</NW> of the <NW>Phanerozoic Eon</NW> – as a <QL name="body">single conscious ‘body’</QL> (of which individual humans are the ‘cells’).</p>
      <p>Now we’ll bring out the <PL>uncomfortable</PL> parts: words like ‘should’. As a part of the body, you <i>should</i> help the <i>whole</i> body – and not just the part of it over which you have direct control!</p>
      <p>Each man or woman for his or her self was the Phanerozoic Eon. Been there, done that. The whole point of the <NW>Mechanozoic</NW> is that <i>consciousness</i> (not beholden as biology to the mere randomness of natures quantum fluctuations) does not <i>have</i> to rely on things like ‘natural selection’. <QL name="evolution">Darwin is off the hook.</QL></p>
      <p>The key thing to understand is that <b>the conscious ‘body’ is bigger than <i>you</i></b>. You cannot control it. All you can do is <QL name="whatcanido">live your individual life</QL> with the goal of helping the whole beautiful body – and <QL name="whatcanido.beanexample">be an example</QL>.</p>
    </div>,
  },

  // Auto-immune
  {
    name: "autoimmune",
    alternatives: "auto-immune,autoimmune disease,auto-immune disease",
    prelude: <span>
      Why would another ‘<QL name="body.">cell</QL>’ in your <QL>body</QL> be <i>foreign</i>?
    </span>,
    header: <span>
      Earth’s conscious ‘body’ has an <i>auto-immune disease</i>.
    </span>,
    outside: <span>
      It’s the best way to <E2 /> tribalism, the <NW>nation-state system</NW>, and <i>wars</i>.
    </span>,
    preview: <div className="angle-preview">
      <p>An ‘auto-immune’ disease is when the immune cells of your body attack and kill other perfectly healthy cells, believing them to be foreign.</p>
      <p>And once you accept <LRL name="gaiacentric">Earth</LRL> as a single and united ‘<LRL name="gaia">organism</LRL>’, it just takes a quick look around to see that this is <i>exactly</i> what is happening on this planet.</p>
    </div>,
    text: <div className="angle-text">
      <p>How might some human <PL name="individuality">individuals</PL> get it into their <OML name="stupid">pea-sized little noggins</OML> that killing other humans (who were <i>not</i> (necessarily) <PL>privacy</PL>-obsessed <OML name="privacy">cryptography advocates</OML>). </p>
    </div>,
  },

  // Cancer
  {
    name: "cancer",
    prelude: <span>
      Is ‘pro-abortion’ even a word?
    </span>,
    header: <span>
      Earth’s conscious ‘body’ has <i>cancer</i>.
    </span>,
    outside: <span>
      Humans, pleae stop making more humans. <LRL name="gaia">Earth</LRL> does <i>not</i> need more <NW>heterognostic</NW>, disagreeing, squabbling ‘<PL name="individuality">individuals</PL>’ each needing his or her five minutes in the spotlight to <E1 /> his or her ‘<AL>self</AL>’.
    </span>,
    preview: <div className="angle-preview">
      <p>A lot of things are going to push people’s ‘buttons’, but this may be one of the worst: because (as any human will say) who doesn’t like <i>babies</i>?</p>
      <p>But our body has too many <i><QL name="body.">cells</QL></i>. Far too many. And there’s no way to stop this proliferation except <OML name="sterilisation">brute-force imposition</OML>.</p>
      <p>This <QL name="anthropocentrism">human</QL> excess is bad enough if the cells all adhered to a single control centre (‘brain’), had <i>one</i> locus of consciousness (<NW>monognostic</NW>), and didn’t keep <i><PL name="privacy">secrets</PL></i> from one another… but of course (in the <LRL name="gaia">Earth-body</LRL> under <PL name="neoliberals">neoliberalism</PL>) everyone has the ‘<OML>right</OML>’ to his or her own <i><QL name="polygnosticity.subjectivity">opinion</QL></i>.</p>
      <p>Let’s just hope (for the sake of not brutally scarring our <QL name="unity">shared</QL> <LRL name="gaiacentrism">Earth</LRL> <LRL name="gaia">consciousness</LRL>) that we find a version of metaphorical ‘chemotherapy’ that doesn’t involve <OML name="privacy">murdering most everyone</OML> who won’t ‘<AL name="sleeping">wake the f£€$ up</AL>’.</p>
<p>[Under construction]</p>{/*
      <p>Let’s try to <i>answer</i> that, actually. How about the parents, who have to deal with them <i>every day</i> (and not just when they’re being ‘cute’)? If parents could press a button to magically transform their squabbling infant into a full ‘person’ with thoughts and understanding and the joys and aches of being human, would some of them do it?</p>*/}
    </div>,
    text: <div className="angle-text">
    </div>,
  },

  // DID
  {
    name: "did",
    prelude: <span>
      What would you call me if I insisted that my one <QL>body</QL> was home to <AL name="sick.cancer">8,000,000,000</AL> ‘<PL name="individuality">individuals</PL>’ who often don’t ‘<AL name="illusions">get along</AL>’?<sup>†</sup>
    </span>,
    header: <span>
      Conscious polygnosticity has a medical name: dissociative identity disorder (DID).
    </span>,
    headerFootnote: <p>
      † (Other than ‘<OML name="offensive">crazy</OML>’.)
    </p>,
    preview: <div className="angle-preview">
      <p>DID is a fascinating subject in <QL>human</QL> psychology… because if an ‘<PL>individual</PL>’ has it, then their body <i>truly</i> houses many separate ‘personalities’ – and these personalities do <i>not</i> know what happens to the body when they are not ‘at the wheel’ (or consciously <i>aware</i>).</p>
      <p>In short: the various ‘<AL name="identity">identities</AL>’ are <NW>heterognostic</NW> <AL name="dysfunctional.">fragments</AL> of consciousness. And this seems like one of the (rare) cases in which the word ‘<PL>individual</PL>’ (in a <QL name="anthropocentrism">human context</QL>) is <i>not</i>synonymous with <NW>POC</NW> – because each of these ‘personalities’ really <i>does</i> act like its own (<NW>heterognostic</NW>) <NW>POC</NW>.</p>
    <p>The more one learns about DID, the more it really does seem like a perfect <QL name="metaphor">analogy</QL> for what consciousness (which, <OML name="field">whatever it is</OML>, clearly ‘<PL name="all.level">exists</PL>’) has got <LRL name="gaiacentrism">on Earth</LRL>.</p>
    </div>,
    text: <div className="angle-text">
      <p>DID is – <QL name="qualifiers">as far as we know</QL> – the result of childhood <i>trauma</i>. If you apply this to <LRL name="gaiacentrism">Planet Earth</LRL> as a <LRL name="gaia">whole</LRL> (as the <PL name="comprehensive.">scale-independent</PL> <QL>Weltanschauung</QL> is about to do) then ask yourself: ‘Would Earth have had any <i>trauma</i> in its conscious infancy?’ Consider the whole of <QL>human</QL> <OML name="specialisation">specialised</OML> <i>society</i>, and <LRL>answer</LRL> that.</p>
      <p>Let’s assume that you aren’t <OML>stupid</OML>, and now have an <E2 word="explanation" /> for why Earth is currently <AL>sick</AL> with DID.<sup>‡</sup></p>
      <p>Now how to ‘cure’ our <AL>sick</AL> <LRL name="gaia">planet</LRL>?</p>
      <p>The recommended cure for a <QL>human</QL> ‘<PL>individual</PL>’ who is presenting with several <NW>heterognostic</NW> ‘personalities’ (<NW word="poc">POCs</NW>) is to use <i>therapy</i> to help the ‘individual’ – i.e., the ‘<QL>body</QL>’ that contains all these ‘<AL name="identity">identities</AL>’ who don’t always even <i><QL name="gnosticity">know</QL></i> one ‘another’ – <i>integrate</i> all of these personalities into the same (larger) ‘<QL name="pg.">mind</QL>’.</p>
      <p>And this is the beauty of the <NW>synecdocentric</NW> <QL>Weltanschauung</QL>’s <PL name="comprehensive.">scale-independence</PL>: it’s possible to pretty much take that whole <LRL>notion</LRL>, which both <E2 suffix="s" /> this syndrome extremely well and recommends a <i>cure</i> in a <QL>human</QL>… and up-scale it to not only <E2 /> but also to <i>recommend</i> a course of action for the planet as a whole.</p>
      <p>(Yes, this is more of an <LRL name="scope">observational</LRL> and <E2 word="explanatory" /> <AL>framework</AL> than anything <LRL>prescriptive</LRL>{/*, so take any <QL name="definitive">suggestion</QL> with a <OML name="gaia">grain of salt</OML> */}… but there <i>is</i> an <QL name="urgent">urgency</QL> to <AL name="sleeping">waking</AL> at least those <NW word="poc">POCs</NW> who hold any <QL name="top">sway</QL> on this <PL name="equality">unequally</PL>-controlled <LRL name="gaia">planet</LRL>)</p>
      <p>Okay, fine: even <OML name="gaia">accepting</OML> the ‘<LRL>Gaia hypothesis</LRL>’, DID is – granted – a bit different between a single biological body (where the various ‘personalities’ <QL name="qualifiers">probably</QL> <i>can</i> combine and share thoughts, even if initially they don’t) and <LRL name="gaia">Planet Earth</LRL>, wherein conscious <NW>polygnosticity</NW> is biologically enforced. At least <PL name="comprehensive.time">for now</PL> (before Earth <i><QL name="evolution">evolves</QL></i>). But if <i>you</i> – as a currently-<NW>heterognostic</NW> conscious <AL name="dysfunctional.">fragment</AL> – stop overly <i><AL name="identity">identifying</AL></i> with the <i><AL>container</AL></i> in which your <NW>POC</NW> is placed, recognise that the <LRL name="gaiacentrism">whole planet</LRL> of which <LRL name="gaia">you are a part</LRL> is a <NW>POC</NW> of the larger <QL name="unity"><i>universal</i> consciousness</QL>, and the only thing that holds us back from ‘<NW>coalescence</NW>’ (and <QL>world peace</QL>) is our conscious <NW>polygnosticity</NW>, the <NW>illusion</NW> of <PL>individuality</PL>, our <LRL>notion</LRL> of <LRL name="usandthem">‘us’ and ‘them’</LRL> (which is <PL name="sweeping.ig">of course</PL> <NW>individual greed</NW>… then share those insights with your local politicians or whatever, and <AL name="notnew.lennon.war">war is over</AL> as soon as we all – truly – can accept this.</p>

{/* that we are all just cogs in the <AL>system</AL> (and <PL name="sweeping">everything</PL> is a <PL name="uncomfortable.">system</PL>), and no <PL name="individuality">unit</PL></i> is <PL>special</PL> but the <i>whole</i> of our conscious <QL name="unity.1">universe</AL>: <NW>God</NW>).</p> */}


    </div>,
    bottomFootnote: <p>
      ‡ And no, it’s not just the atom bomb, and (<OML name="earth-soul">probably</OML>) has little to do with <QL name="anthropocentrism">humanity</QL>’s vigorous <PL name="nature">extraction of the planet’s ‘resources’</PL>. It’s the <LRL>notion</LRL> in the ‘<QL name="pg.">mind</QL>’ of ‘<PL>Lucifer</PL>’ (the <AL name="religion">Judeo-Christian</AL> ‘Devil’): ‘Non serviam’: I will not serve. It’s the <NW>illusion</NW> of <PL>individuality</PL> (and the <PL>privacy</PL> used to <QL name="pg.privacy">maintain</QL> it). It’s and seeing the ‘<AL>self</AL>’ as something <i>separate</i> from the <LRL name="gaia">whole</LRL>. It’s ‘Christianity’, and ‘Islam’, and countless others. This is Planet Earth’s conscious-‘childhood’ trauma.
    </p>,
  },

  ],
};
