import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, TLDR, Qual, Rando, Switcheroo} from '../../common';

// ‘’ … –

export const eons = {
  name: "eons",
  alternatives: "fault,blame",
//  prelude: <span>
//  </span>,
  header: <span>
    A tale of two ‘eons’.
  </span>,
//  outside: <span>
//  </span>,
  preview: <div className="long-read-preview">
    <p><TLDR />: <LRL name="gaiacentrism">Earth</LRL> is currently in two simultaneous <AL name="fields.">geological</AL> ‘eons’: the <QL>future-thinking</QL> <NW>Mechanozoic Eon</NW>, and the <NW word="conservatism">conservatively-thinking</NW> (i.e., <NW word="agency">unconscious</NW>, or ‘<LRL>reactive</LRL>’) <NW>Phanerozoic Eon</NW>.</p>
    <p>They overlap: a small number of <LRL name="gaia">Earth</LRL>’s ‘<QL name="body.">cells</QL>’ have ‘<AL name="mirror">broken the mirror</AL>’ – i.e., <LRL>undertstand</LRL> the <PL name="all.level">underlying</PL> <QL>unity</QL> of <LRL name="scope">our universe</LRL> – and furthermore manage to <NW>transcend</NW> (as a bigger ‘<AL>self</AL>’) any <NW>individual greed</NW> that <PL name="sweeping.ig">would have held us back</PL>: i.e., can <LRL name="imagination">think</LRL> and <i><NW word="agency">act</NW></i> in a <QL>future-focussed</QL> manner. However, the <PL name="sweeping">majority</PL> of <QL>humans</QL> are still living in the <NW>Phanerozoic Eon</NW>, <AL>sleeping</AL>.<sup>†</sup>
</p>

{/*

    <p><LRL name="gaiacentrism">Earth</LRL is in a period of (rather dramatic) transition: slowly <AL name="sleeping">waking up</AL> to be a <QL name="unity">single conscious</QL> (as <PL name="sweeping">everything</PL> is a ‘<PL>system</PL>’) on the universal stage: <i>part</i> and not opposed to it. But right noe even this planet is not ‘<NW>coalesced</NW>’; <LRL name="gaia">Earth</LRL></p>

 */}
    <p>[Hopefully to be ‘built’ soon.]</p>
  </div>,
//  text: <div className="long-read-text">
  //</div>,
  topFootnote: <p>† And, likely, they don’t <i>want</i> to pull of the covers and face an <QL name="unity.answer">‘answer’ to the universe</QL> in which <LRL name="them">they</LRL> – in their <AL name="handicap">human bodies</AL>, and their <NW>heterognostic</NW> (<QL name="polygnosticity">separated</QL>) ‘<QL name="pg.">minds</QL>’ – aren’t (<PL name="individuality">individually</PL>) <i><PL>special</PL></i>.</p>,
};
