import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const enlightenment = {
  name: "enlightenment",
  prelude: <span>
    {/* Neoliberals: we are walking on eggshells */}
    <PL name="neoliberals">No offence</PL>, but isn’t that (<QL name="qualifiers">maybe, posssibly</QL>) just a <i>wee</i> bit full of oneself?
  </span>,
  header: <span>
    What’s the big fuss about ‘enlightenment’.
  </span>,
  preview: <div className="long-read-preview">
    <p>Why use the term ‘enlightenment’? What is ‘<NW>enlightenment</NW>’, anyway?</p>
    <p>For one, it’s a consequence of <QL name="anthropocentrism">humanity’s</QL> need for ‘<NW>explainage</NW>’ to convey (or <E1 />) any idea.</p>
    <p>[Under construction]</p>
  </div>,
  text: <div className="long-read-text">
  </div>,
};
