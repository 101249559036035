import React from 'react';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual, Rando, Switcheroo} from '../../common';

// ‘’ … –

export const waiting = {
  name: "waiting",
  alternatives: "wait,waits,waited",
  prelude: <span>
    In a <NW>polygnostic</NW> consciousness, sometimes it feels like the individual <NW>POCs</NW> just spend a lot of their time <i>waiting</i>?
  </span>,
  header: <span>
    Welcome to the ‘waiting game’
  </span>,
  outside: <span>
    It’s a <LRL name="causes">symptom</LRL> of a <NW>POC</NW> not taking (or not being <i>able</i> to take) <NW>agency</NW>.
  </span>,
  preview: <div className="long-read-preview">
    <p>Just think of <Switcheroo middle=". Or " values={[<span>shopkeepers in <Rando options={["India", "Russia", "Bulgaria", "Costa Rica"]} /> standing by their outdoor stalls, their ‘wares’ spread in front of them, watching <Rando options={["spiders weaving nets on their tables", "pigeons peck at the ground", "the trees sway in the breeze", "leaves blow at their feet"]} /> (or whatever) while no ‘other’ <NW>POC</NW> stops by to engage with them</span>, <span>a bored delivery driver, <Rando options={["head against the wheel", "arm on the windowframe", "head leaning back on the headrest, eyes closed"]} />, <Rando options={["his ", "her ", ""]} />phone killing its batery on the empty passenger seat</span>, <span>a <Rando options={["young", "middle-aged"]} /> <Rando options={[<PL>individual</PL>, "hockey player", "guitarist", "painter", <AL name="fields.science">scientist</AL>]} /> ‘killing time’ at the foot of the stairs while <Rando options={["his", "her"]} /> <Rando options={["boyfriend", "girlfriend", "wife", "husband", "partner"]} /> finishes up in the shower before their big ‘date night’</span>]} />. Everyone <i>waiting</i>.</p>
    <p><LRL name="motto">Granted</LRL>, the ‘waiting game’ (unlike the ‘<LRL>blame game</LRL>’) it’s not <i>always</i> a bad thing. After all, <PL name="sweeping">every</PL> <AL name="sleeping">alert</AL> <NW>POC</NW> still has the <LRL>imagination</LRL> – and if it’s <OML name="smarter">possible</OML> to develop the <LRL name="notions">theory</LRL> of <QL name="pg.mind.">relativity</QL> while ‘working’ as a <NW>MNS system</NW> ‘patent’ clerk, then having some ‘down time’ to just ‘zone out’ can’t be <i>that</i> bad, no?</p>
    <p>Well, it depends. Because if your (presumably-<LRL name="gaiacentrism">Earthbound</LRL>) <NW>POC</NW> <i>could</i> be doing something more helpful for <QL>humanity</QL> (and <LRL name="gaia">Earth</LRL> as a whole) – which would also be <i>more</i> ‘enjoyable’ (or at least no less tedious) for you – then the <i>fault</i> (because, when it comes to <LRL>notions</LRL>, it <i>is</i> good to play the ‘<LRL>blame game</LRL>’) squarely goes to our conscious <NW>polygnosticity</NW>.</p>
    <p>[Under construction.]</p>
  </div>,
//  text: <div className="long-read-text">
  //</div>,
};
