import React from 'react';
import ItemBox from '../ItemBox';
import OrMaybesList from './OrMaybesList';
import {or_maybes} from './list';
import {useParams} from 'react-router-dom';
import './OrMaybe.css';

export default function OrMaybe(props) {
  const {name} = useParams(); // If coming from react router.
  const overwrite = {
    prelude: "Or maybe…",
  };
  return <ItemBox
    {...props}
    className="or-maybe-box"
    preview={props.preview}
    data={props.data}
    list={!props.data ? or_maybes : null}
    search={(props.name) ? props.name : name}
    propertyName="name"
    alternativesName="alternatives"
    ListComp={OrMaybesList}
    previewName="preview"
    textName="text"
    overwrite={overwrite}
  />;
};
