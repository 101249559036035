import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, TLDR, Qual, Rando, Switcheroo} from '../../common';

// ‘’ … –

export const blamegame = {
  name: "blame game",
  alternatives: "fault,blame",
  prelude: <span>
    But whose <i>fault</i> is it, <i>really</i>???
  </span>,
  header: <span>
    Enough of the ‘blame game’, already.
  </span>,
  outside: <span>
    <PL>Individuality</PL> is just an <NW>illusion</NW>, so does it <i>really</i> <OML>matter</OML>?
  </span>,
  preview: <div className="long-read-preview">
    <p><TLDR />: if something goes <i>wrong</i> (i.e., <OML name="harmony">sub-optimally</OML>), <WArticle /> <WName ist /> doesn’t care if it’s the ‘fault’ of which <i><AL name="handicap">body</AL></i>. They would care: is it the ‘fault’ of which <i><LRL name="notions">idea</LRL></i>?</p>
    <p>The <WName ic /> <AL>framework</AL> doesn’t allow for <PL>individuality</PL>, <AL name="bulletpoints">remember</AL>? Looking at <PL name="ick">everything</PL> as a <AL>system</AL>, the actual thing of import is to dig ‘<LRL>deep</LRL>’ to uncover which <i><OML>ideas</OML></i> caused the <PL name="ick.">system</PL> to go awry (on the ‘<LRL name="notions">battlefield of <i>notions</i></LRL>’).</p>
    <p>But be <PL name="endsjustifymeans">really careful</PL> with that approach; this <AL name="fields.philosophy">quantum-mechanical philosophy</AL> is so <PL name="hard">difficult</PL> to truly <LRL>understand</LRL> that it’s incredibly easy for some <NW word="ig">individually greedy</NW> <QL>human</QL> to <PL name="dangerous">corrupt</PL> it – and then, as much as the ‘blame game’ is (on the whole) <i>not</i> helpgul to our <QL>unity</QL> <QL>consciousness</QL>, this <i>would</i> be that one <PL>individual</PL>’s ‘fault’. </p>
    <p>[The rest is under construction.]</p>
  </div>,
//  text: <div className="long-read-text">
  //</div>,
};
