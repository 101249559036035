import React from 'react';
import Word from './Word';
import PreviewLink from '../PreviewLink';
import './NewWord.css';

export default function NewWord(props) {
  return <PreviewLink
    {...props}
    PreviewComp={Word}
    linkClassName="new-word-link"
    linkURLPrefix="neologisms"
    nameName="word"
  >{props.children}</PreviewLink>;
};
