import React from 'react';
import WName from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual, rand, the_key_is_imagination} from '../common';

export const salvation = {
  name: "salvation",
  prelude: <span>
    It might sound good in theory, but that’s only in <i>theory.</i>
  </span>,
  question: <span>
    What is the real-world way out?
  </span>,
  clarification: <span>
    How can <LRL name="gaia">our</LRL> current <AL>dysfunctional</AL>, disjointed (and <NW>polygnostic</NW>) <QL name="unity">consciousness</QL> <LRL name="gaiacentrism">on Earth</LRL> ever <PL name="all.time">reach a point</PL> when every <QL name="body.cells">part</QL> bucks its <NW>individual greed</NW> and <PL name="sweeping.ig">admits</PL> we’re just all <AL name="dysfunctional.">fragments</AL> of the <QL name="unity.1">same</QL> conscious <QL>body</QL> (of which no <PL>individual</PL> has full <NW word="agency">control</NW>)?
  </span>,
  readMore: <span>
    Spoiler: the answer is in the <i>imagination</i>.
  </span>,
  answer: <div>
    {/* <p><QL name="polygnosticity.">TL;DR</QL>: {the_key_is_imagination}</p>
    <hr /> */}
    <p>Is it possible? Yes. As <PL name="impossible">absurd as it might seem</PL>, ‘<NW>coalescence</NW>’ (and an end to the <NW>illusion</NW> of <PL>individuality</PL>) will <Qual.probably /> <PL name="all.time">one day</PL> be <AL name="fields.biology">physically</AL> possible.</p>
    <p>It’s just (<OML name="stupid">probably</OML>) going to be <PL name="hard">really, <i>really</i> hard</PL> for <PL name="sweeping">many</PL> <AL name="sleeping">drowsy</AL> <NW>POCs</NW> (trapped in <AL name="handicap">human bodies</AL>) to <i><LRL>imagine</LRL></i> something <LRL name="different">so far outside</LRL> the <LRL name="scope">bounds</LRL> of <QL>humanity</QL>’s <QL name="evolution">past experience</QL>, for which <LRL name="them">they</LRL> <Qual.may /> not be <PL name="unready">ready</PL>, about which it’s (honestly) quite <PL name="explainage">difficult</PL> to even tell <i><QL>stories</QL></i> – and that you <PL name="invisible">can’t even see</PL>.</p>
    <p>Are there going to be growing pains? <QL name="qualifiers">Quite probably.</QL></p>
    <p>Are there going to be a <i>lot</i> of growing pains? <NW word="god">Inshallah</NW> no. But – given <QL>humanity</QL>’s <PL name="sweeping.ig">history</PL> of <NW>individual greed</NW> – quite probably, yes.</p>
    <p>How to <OML name="kaput">save</OML> <LRL name="gaia">Earth</LRL>? How?</p>
    <p>Well, in this <QL>Weltanschauung</QL> we already <i>have</i> an ‘<QL name="unity.">answer</QL>’ to <QL>life</QL>, <LRL name="scope">the universe</LRL>, and <PL name="comprehensive">everything</PL>. But how to actually <i>apply</i> it? How to get to a <QL name="evolution.darwin">competition-fuelled</QL> <AL>mindset</AL> to one where the notion of <PL>privacy</PL> is viewed (by the whole) with the same sort of disgust as 2020’s society views <OML>murder</OML>? Is ‘<WName /> <LRL>communism</LRL>’ even <i><PL name="impossible">achievable</PL></i>? <AL name="notnew.lennon.peace"><i>Can</i> we give peace a ‘chance’?</AL></p>
    <p>The <LRL>answer</LRL>: through our <QL name="unity">conscious</QL> <i><NW>agency</NW></i> (‘<OML>free will</OML>’) and our ability to <i><LRL>imagine</LRL></i> a better (i.e., less <AL>sick</AL> <PL>system</PL>). The path to ‘salvation’ (out of the <WName /> ‘<AL>Hell</AL>’) is <PL name="reductionist">just that</PL>: <LRL>imagination</LRL>.</p>
{/*


Once thus ‘<NW>enlightened</NW>’, will you be <i>able</i> to get <i>back</i> to the ‘<NW>level</NW>’ on which  ‘<LRL>game</LRL>’ (in the <NW>Phanerozoic Eon</NW>)?

 of <Qual.mostly />-<LRL>reactive</LRL> <QL>humanity</QL>
*/}

    <p>So <QL name="whatcanido">go out</QL> and cultivate <LRL>imagination</LRL> in <LRL name="them">everyone you know</LRL>: <i><QL name="whatcanido.">teach</QL></i>; and cultivate the <AL name="handicap">ability</AL> to think <span style={{display:"inline-block", rotate: "23deg", position: "relative", zIndex: 0, top: "2.5em", left: "-.35em"}}><span style={{letterSpacing: "2px"}}>out</span><span style={{letterSpacing: "5px"}}>side</span> <span style={{letterSpacing: "9px"}}>the</span> ‘<b><span style={{letterSpacing: "14px"}}>bo</span>x</b>’.</span></p>
    <br />
    <hr />
    <br />
    <p><QL>Humanity</QL>’s salvation is to be <NW word="agency">able</NW> to <LRL>imagine</LRL> an <PL name="extinction">end to humanity</PL> – and not be afraid of what (<OML name="levels">if anything</OML>) <PL name="all.level">comes next</PL>.</p>
  </div>,
  // sublist: [
  //],
};
