import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual} from '../../common';

// ‘’ … –

// Do we get points for EFFORT?
export const sin = {
  name: "sin",
  prelude: <span>
    This isn’t (<QL name="qualifiers">quite</QL>) a <i><AL name="notnew.">religion</AL></i>, but…
  </span>,
  header: <span>
    Is it <WName ically noNW /> ‘bad’ if I really, really <i>try</i>?
  </span>,
  outside: <span>
    What is ‘sin’, really – and do we get points for <i>effort</i>?
  </span>,
  preview: <div className="long-read-text">
    <p><b>‘Trigger’ warning:</b> the <PL>neoliberals</PL> are (<Qual.probably />) <i>not</i> going to <PL name="uncomfortable">like</PL> this one.</p>
    <p><QL name="polygnosticity.">TL;DR</QL>: Beause <WName /> – as <PL name="comprehensive">in-your-face</PL> as it might <i>seem</i> – actually has a limited <QL>scope</QL> (to just the <i>observable</i>) </p>
    <p>In other words, the <i>intent</i> does not justify the means. Only the (actual, achieved) <PL name="endsjustifymeans">ends</PL> do.</p>
    <p>But now – before you completely flip out – let’s qualify that a whole bunch… and hopefully you’ll realise that it’s not as bad as it sounds.</p>
  </div>,
  text: <div className="long-read-text">
    <p>Now our (<QL name="anthropocentrism">humanity’s</QL>) conscious <NW>polygnosticity</NW> may be the cause of <PL name="sweeping">most (and – <i>internally</i> – all)</PL> of our <QL name="polygnosticity">problems</QL>… but when it comes to <i>intent</i>, this <AL name="dysfunctional">sickness</AL> of our shared <QL>body</QL> actually has a weird way of erasing direct <LRL>culpability</LRL> in a <NW>POC</NW> that’s truly <i>trying</i> to help the planet.</p>
    <p>How? There are <QL name="qualifiers">basically</QL> <PL name="sweeping">only</PL> three ways in which something can go <WName ically /> ‘wrong’, as follows:</p>
    <ol>
      <li>A <NW>POC</NW> <i>deliberately</i> does something that they <i>know</i> does not help <LRL name="gaia">the whole</LRL> (for reasons of <NW>individual greed</NW>)</li>
      <li>A <NW>POC</NW> does something that they <i>think</i> – going off the information and knowledge available to them at the time – will help <LRL name="gaia">the whole</LRL>, but it does not help, and actually makes things worse.</li>
      <li>An external event (of which <i>no</i> <NW>POC</NW> is aware) damages <LRL name="gaia">the whole of our consciousness</LRL> (at least <LRL name="gaiacentrism">on Earth</LRL>).</li>
    </ol>
    <p>Thus reduced (because, if you haven’t yet realised, this <QL>Weltanschauung</QL> <i>adores</i> simplicity – as much as ‘<NW>Occam</NW>’) the ‘fault’ of the error – if one insists on playing the ‘<LRL>blame game</LRL>’ – belongs in the first case to one <NW>POC</NW>’s <NW>individual greed</NW> and in the last two to the <LRL name="gaia">Earth-body</LRL>’s conscious <NW>polygnosticity</NW>.</p>
    <p>As far as ‘wrong’ goes in <WName />, the only one that’s truly <i><PL>evil</PL></i> is #1. So as long as your <i><NW>individual greed</NW></i> isn’t causing problems, your <NW>POC</NW> is not necessarily ‘sinning’.</p>
    <p>Okay… but before you wipe that sweat off your forehead, you need to remember that the term ‘<NW>individual greed</NW>’ is (as <PL name="sweeping">many things</PL> in this <QL>Weltanschauung</QL>) far, <i>far</i> more <PL name="comprehensive">encompassing</PL> than it sounds.</p>
    <p>Is it <NW>individual greed</NW> – for example – to not tell someone something because you don’t want to share for the sake of your <PL>privacy</PL>? Well, yes. Now, is it <NW>individual greed</NW> to not <i>request</i> information because you believe that it would be an infringement of the <PL>privacy</PL> of the ‘fault’? Actually, yes: if you never <i>ask</i>, the problem is with <i>your</i> <NW>POC</NW>. (Obviously if they refuse to then <i>answer</i>, the problem is with theirs.)</p>
    <p>This deserves a whole separate article on how to be a <WName ically /> ‘good’ cell in a <AL name="dysfunctional">sick body</AL> that mostly believes it has many separate <i><AL name="identity">identities</AL></i> (and has created the <NW>MNS system</NW> to ensure this separation)… but if you(r <NW>POC</NW>) do(es)n’t even <i>request</i> information that you suspect you might need, and then go(es) out and do(es) something with the <i>intent</i> to help <LRL name="gaiacentrism">Earth</LRL> and end(s) up making the planet <i>worse</i> (e.g. more fragmented, more disagreeing)… then, guess what? That was a <WName ic /> ‘sin’?</p>
    <p>But are all ‘sins’ equal in the ‘eyes’ of this <QL>Weltanschauung</QL>’s ‘<NW>God</NW>’? Of course not; <PL>equality</PL> is (<PL name="uncomfortable">sorry</PL>) a lie, <AL name="bulletpoints">remember</AL>? The ‘badness’ of a sin is directly proportional to how much <i>influence</i> the ‘sinning’ <NW>POC</NW> has on <LRL name="gaia">the planet</LRL>. And it’s really that simple. This <QL>Weltanschauung</QL> demands an <i>eventual</i> total abolition of <QL name="polygnosticity.">privacy</QL>, but from a <QL>top-down</QL> approach: meaning that individuals don’t have to sacrifice all of their <PL>privacy</PL> until <NW>companies</NW> and <NW word="nation-state">nation-states</NW> give up <i>theirs</i>.</p>
    <p>However, one last warning: this is <i>not</i> a capitalistic <AL>framework</AL> – and it attributes a lot of ‘<PL>evil</PL>’ and <NW>individual greed</NW> to the <NW>money system</NW>. One of the <LRL name="goal">middle-term ‘goals’</LRL> of <WName /> is <QL name="qualifiers">probably</QL> ‘<LRL name="communism"><WName ic noNW /> communism</LRL>’ – which means that, in however <LRL name="gaia">globally</LRL>-small a way, even <i><AL name="notnew.lennon.possessions">owning</AL></i> ‘<QL name="polygnosticity.privacy">private</QL>’ property is ‘bad’.</p>
    <p><QL name="qualifiers">Unless maybe</QL> you only ‘own’ it as a symptom of living in a world where <NW word="poc">POCs</NW> do <i>not</i> share thoughts, and some ‘individuals’ have (shamelessly) so much <NW>individual greed</NW> that they would just take something your <NW>POC</NW> could use to contribute to the world better than <i>they</i> can, only to <i>sell</i> it and get <NW word="money system">money</NW> (i.e., increase their ‘own’ individual <i>value</i> in a sytems that does <PL name="equality">disequality</PL> all <WName ically /> <i>wrong</i>.</p>
    <p>This ‘long read’ could really go on for a very, very long time. More may be added later… but hopefully by now you(r <NW>POC</NW>) get(s) just the vaguest sense of <AL name="framwork">this thing</AL>.</p>
  </div>,
};
