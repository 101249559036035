import React from 'react';
import WName, {WArticle} from '../WName';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Rando} from '../common';

// This is ALWAYS anti-establishment.
export const establishment = {
  name: "establishment",
  parentName: "being a disestablishmentarian",
  prelude: <span>
    If you overthrow the ‘establishment’ and then (as all revolutionaries inevitably do) <i>become</i> the establishment, how can you have a <AL>framework</AL> that <i>still</i> wants to overthrow the ‘establishment’?
  </span>,
  header: <span>
    This is a disestablishmentarian framework – <i>whatever</i> the ‘establishment’.
  </span>,
  outside: <span>
    So if you wanted a more settled <i>name</i> for this thing (that doesn’t just <QL name="change.site">change <Rando options={["all the time", "on a whim"]} /></QL>) you could call <WName /> ‘disestablishmentarianism’… and then you could call the <PL>individuals</PL> (and <AL name="fields.biology">extra-individualistic</AL> ‘<QL name="body.">organs</QL>’) that were <i>opposed</i> to it (i.e., <NW word="conservatism">conservative thinkers</NW> and – <PL name="sweeping.ig">of course</PL> – the <NW word="ig">individually-greedy</NW>) ‘antidisestablishmentarians’, and of course <i>their</i> <LRL name="notions">philosophy</LRL> would be ‘antidisestablishmentarianism’ (which is, at the <OML name="end">end of the road</OML>, just a very long synonym for <NW>conservatism</NW>).
  </span>,
  preview: <div className="problem-preview">
    <p><TLDR />: it isn’t against any particular ‘establishment’. It’s against the <i><LRL>notion</LRL></i> of the ‘establishment’. This is <WName />, and it doesn’t <LRL name="game">play by the old rules</LRL>.</p>
    <p>Because here’s the thing: in all of <LRL name="gaiacentrism">Earth</LRL>’s <QL>human</QL> history, ‘<AL name="notnew.marx">revolutionaries</AL>’ have always ‘settled’ after making just a small change.<sup>†</sup> That is, they effect a ‘revolution’ to turn (or ‘revolve’) the wheel of <QL>humanity</QL>’s ‘cart’ of progress – if you(r <NW>POC</NW>) can <LRL>imagine</LRL> (as a bit of an extended <QL>metaphor</QL>) <LRL name="gaia">Planet Earth</LRL> riding on a cart trying to reach its hopes and dreams in the clouds up in the sky.</p>
    <p><WName cap /> is saying, ‘Look, those clouds are up in the <i>sky</i>. You can revolve the wheels of your cart as many times as you want, but – going the way that you’re going – you aren’t going to ever <i>get</i> there.’ And then it’s getting <i>off</i> of the cart and starting to build an aeroplane.</p>
  </div>,
  topFootnote: <p>
    † Or, at least, ‘small’ in the <WName ic /> sense, because they’ve all stayed more or less on <OML name="alone">Earth</OML>, and <i>all</i> inside the heliosphereg… with the exception of the (mercifully-not-<NW word="atomocentric">atomocentrically</NW>-named) ‘Voyager’ spacecraft, both of which were sent by <QL>humanity</QL>’s <i>scientists</i> working to increase <LRL name="gaia">Earth</LRL>’s <i><LRL>understanding</LRL></i> of the <LRL name="scope">cosmos</LRL> – and so (at least now) are not as beholden to the <NW>MNS system</NW>, because if <QL>humans</QL> started <AL name="illusions">fighting</AL> about who <i><AL name="notnew.lennon.">owns</AL></i> that data, <i><AL name="self">everyone</AL></i> loses. <i><AL name="fields.">Science</AL></i> may be (next to <LRL name="imagination">creativity</LRL>) our <QL>salvation</QL>, so this <QL>Weltanschauung</QL> doesn’t really put the ‘Voyager’ spacecraft in the same category as the ‘establishment’ that it’s constantly looking to dismantle.
  </p>,
  text: <div className="problem-text">
    <p>So when <WName /> says, ‘Down with the establishment!’ this isn’t just to put a <i>new</i> ‘establishment’ in its place. This is saying, ‘Down with the <i><LRL>notion</LRL></i> that anything can ever be “established”, or settled.’ The disestablismentarianism of <WName /> is a complete <LRL>shift</LRL>: suggesting that <AL name="handicap">human-based</AL> <QL>consciousness</QL> <AL name="notnew.lennon.peace">stop</AL> its fighting over <AL name="illusions">illusions</AL> (i.e., enough of the <LRL>game</LRL>) and accept instead the <QL name="pg.mind.">relativity</QL> (though not <QL name="pg.">subjectivity</QL>) of each separate <NW>POC</NW> for as long as it <i>cannot</i> <LRL name="communism">share its thoughts</LRL> with <QL name="hivemind">the rest of us</QL>.</p>
    <p>In summary: as a <WName ist />, take nothing for granted; always seek to <LRL>understand</LRL> <PL name="comprehensive">everything</PL> <LRL>deeper</LRL>. Devolop <AL name="framework.">working</AL> <E2 word="explanations" /> for <LRL name="scope">everything that you observe</LRL> (as a <AL>system</AL>; <OML name="universe-soul">our universe</OML> is only a <PL name="ick.">system</PL>). <QL name="whatcanido">Try your best</QL> (with the <QL name="polygnosticity">information you have</QL>) to help <QL name="body">the whole</QL> (your larger ‘<AL>self</AL>’, as a <NW>POC</NW>: <AL name="dysfunctional.fragments">piece</AL> of the <QL name="unity">unity consciousness</QL>). And <LRL name="motto">never stop turning stones</LRL>.</p>
  </div>,
//  sublist: [


//  ],
};
