import React from 'react';
import ButtonBox from '../../ButtonBox';
import {intro, newWords, intro2, otherWords, coda} from './wordlists';
import {listToWords} from '../Neologisms';

export default function AllWords(props) {
  const buttonLink = (props.all) ? "" : "/all";
  const showing = (props.all) ? "Showing all words" : "Only showing critical words";
  const showingClass = (props.all) ? "all" : "only-critical";
  // The button points to the OPPOSITE of the current state of props.all
  const buttonText = (props.all) ? "only critical" : <b>all</b>;
  const buttonClass = (props.all) ?"only-critical" : "all";
  return <div className="all-words">
    <h2 className={showingClass+"-header"}>{showing}</h2>
    <ButtonBox src={"/menu/neologisms"+buttonLink} className={buttonClass+"-button"}>
      Show {buttonText} words
    </ButtonBox>
    {listToWords(newWords, props.all)}
    <hr />
    {intro2}
    {listToWords(otherWords, props.all)}
    {coda}
  </div>;
};
