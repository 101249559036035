import React from 'react';
import ButtonBox from '../ButtonBox';

// Function to find an item in a list using a specific propertyName.
// searchItem is a value of propertyName (possibly layered, like "me.you")
export const findInList = (
  // These MUST be passed into the function in the following order:
  list,
  searchItem,
  propertyName="name",
  alternatives=null
) => {
  if (!list || !searchItem) {
    return {error: "Neither data nor search term and list provided."};
  }

  // Checking that the search item is a STRING
  // (and not say a JSX component)...
  if (searchItem === null || !searchItem.toLowerCase) {
    console.log('weird search item', list, searchItem, propertyName, alternatives);
    return {error: "The search item appears to not be a string."}
  }
  // The .split(".") is in case there are layered elements
  // Potential matches (i.e. [question].name) must all be lower case.
  // Using period as a delimiter becasue a slash didn't work.
  const searchList = searchItem.toLowerCase().split(".");
  var data, parentName = null;
  var parent = [];

  // Double loop in case of nested elements
  // (if there was a period (.) in the searchItem, searchList.length > 1)
  // Search the searchList in sequence.
  for (var i=0;i<searchList.length;i++) {
    let curSearch = searchList[i];
    let curItem = null;
    // Looking for the current item in the list.
    for (var j=0;j<list.length;j++) {
      let match = list[j][propertyName];
      // Always lower-case everything.
      if (match.toLowerCase) {match = match.toLowerCase();}
      if (curSearch == match) {
        curItem = list[j];
        break;
      }
      // If there are alternatives, search alternatives:
      // (Alternatives should all be presented in lowercase.)
      if (
        !alternatives ||
        !list[j][alternatives] ||
        !list[j][alternatives].split
      ) {
        continue;
      }
      const alts = list[j][alternatives].split(",");
      for (let k=0;k<alts.length;k++) {
        if (curSearch == alts[k]) {
          curItem = list[j];
          break;
        }
      }
      if (curItem) {break;}
    }
    // If we went through the whole list and didn't find it...
    if (!curItem) {
      return {
        error: "Error: " + searchItem + " (" + curSearch + ") not found."
      };
    }
    // If there are more search terms, we are going into a 'deeper' level.
    if (i+1 < searchList.length) {
      if (!curItem.sublist) {
        return {error: "There is no 'sublist' in element " + curSearch + "."};
      }
      // Keep track of parent elements so we can link back to them.
      // Join them with periods.
      parent.push(curSearch);
      // Allow the written name to be different than that used in the URL.
      // We only WRITE the name of the most direct parent -- no grandpas!
      // (So here an array isn't necessary.)
      parentName = (curItem.parentName) ? curItem.parentName : curSearch;
      // Step into the next list.
      list = curItem.sublist;
    }
    else data = curItem;
  }
  if (data && parentName) {
    data.coda = <div className="parent-pointer">
      <ButtonBox src={"../" + parent.join(".")}>
        Read more about {parentName}
      </ButtonBox>
    </div>;
  }
// console.log(parent, parentName, data);
  return data;
}
