import React from 'react';
import Angle from './Angle';
import PreviewLink from '../PreviewLink';
import './AngleLink.css';

export default function AngleLink(props) {
  return <PreviewLink
    {...props}
    PreviewComp={Angle}
    linkClassName="angle-link"
    linkURLPrefix="angles"
  />;
};
