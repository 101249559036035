/* Shuffle the 'bullet points' */

import React, {useState, useContext} from 'react';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import AL from './AngleLink';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import NW from '../neologisms/NewWord';
import {E1, E2, shuffle} from '../common';

/* Side function to randomise the way it says that X causes all Y
 * Meter: do-do-DOO-do-do */

// Using a global variable because (screw it) I'm lazy.
var currentCauseSynonym = 0;

const causeSynonyms = [
<span>is the <LRL name="symptoms">root</LRL> of all</span>,
<span>can <E2 /> every</span>,
<span>is the <LRL name="symptoms">reason</LRL> for</span>,
];

function Causes(props) {
  currentCauseSynonym += 1;
  currentCauseSynonym %= causeSynonyms.length;
  // You can use a Set called 'not' to avoid using certain options.
  while (props.not && props.not.has && props.not.has(currentCauseSynonym)) {
    currentCauseSynonym++;
  }
  return causeSynonyms[currentCauseSynonym];
}

/* Potential 'bullet points' */

// The FIRST and LAST are always well-defined:
const first = <li><i><PL>Individuality</PL></i> is <AL name="fields">just</AL> an <NW>illusion</NW>.</li>;
const last = <li><i><LRL name="motto">Creativity</LRL></i> will be <AL name="self">our</AL> <QL>salvation</QL>.</li>;

// Not sure why there aren't just in the other array
// with a threshold of 1.
// These ones will always be in there:
const steadyPoints = [

  // Equality
  <li><i><OML name="soul">Equality</OML></i> is (<AL name="fields.physics">likely</AL>) a <PL name="equality">lie</PL>.</li>,

  // Identity
  <li><i><AL>Identity</AL></i> is <PL name="reductionist">only</PL> a <LRL name="shift">prison</LRL>.</li>,

];

// These ones will sometimes appear in the list, sometimes not:
// (They have a probability; if undefined it's 50%.)
const flittingPoints = [

  // Subjectivity
  {
    threshold: 0.3,
    point: <li><i><PL name="denial">Subjectivity</PL></i> is (<OML name="levels">deeply</OML>) an <QL name="polygnosticity.subjectivity">issue</QL>.</li>,
  },

  // Polygnosticity
  {
    threshold: 0.25,
    point: <li><i><NW>Polygnosticity</NW></i> <Causes /> <QL name="polygnosticity">problems</QL>.</li>,
  },

  // Cupidity (individual greed)
  {
    threshold: 0.6,
    point: <li><i><NW word="individual greed">Cupidity</NW></i> <Causes /> ‘<PL>evil</PL>’.</li>,
  },

  // Simplicity
  {
    threshold: 0.25,
    point: <li><i><NW word="occam">Simplicity</NW></i> is the <AL name="framework.works">best</AL> <E2 word="explanation" />.</li>,
  },

  // Urbanity
  {
    threshold: 0.15,
    point: <li><i><PL name="ick.rude">Urbanity</PL></i> <LRL name="understand">becomes</LRL> an <QL name="pg.privacy">impediment</QL>.</li>,
  },

  // Reactivity
  {
    threshold: 0.4,
    point: <li><i><LRL>Reactivity</LRL></i> can <OML name="goal">halt</OML> <QL>evolution</QL>.</li>,
  },

/*
  // Fungibility
  {
    threshold: 0.15,
    point: <li><i><PL>Fungibility</PL></i> <OML name="soul">may be</OML> for all <QL name="anthropocentrism">people</QL>.</li>,
  },
*/

  // 
  {
    threshold: 0.6,
    point: <li><i><QL>Unity</QL></i> is the <AL name="framework.">working</AL> <LRL>answer</LRL>.</li>,
  },

  // Interconnectivity
  {
    threshold: 0.25,
    point: <li><i><QL name="unity.1">Interconnectivity</QL></i> <PL name="all.level">transcends</PL> every ‘<NW>level</NW>’.</li>,
  },

  // Complicity
  {
    threshold: 0.35,
    point: <li><i><PL name="uncomfortable">Complicity</PL></i> will <QL name="roadblocks">ensure</QL> we stay ‘<AL>sleeping</AL>’.</li>,
  },

  // Gnosticity
  {
    threshold: 0.5,
    point: <li><i><QL>Gnosticity</QL></i> can <E2 /> the whole <PL>system</PL></li>,
  },

];

export default function BulletPoints(props) {

  let middle = [...steadyPoints];
  flittingPoints.forEach((item) => {
    let threshold = 0.5;
    if (item.threshold) {threshold = item.threshold;}
    if (Math.random() < threshold) {
      middle.push(item.point);
    }
  });

  let shuffledMiddle = shuffle(middle)
  return <ol style={{fontSize: "larger"}}>
    {first}
    {middle}
    {last}
  </ol>;
};
