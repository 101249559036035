import React from 'react';
import WName from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual} from '../common';

// ‘’

export const whatcanido = {
  // What can I do?
  name: "whatcanido",
  prelude: "If the world has so many problems…",
  question: <span>What can I (as an individual) <i>do</i> about it?</span>,
  clarification: <span>Especiallly given that the technology to ‘<NW>coalesce</NW>’ (and think as one ‘<QL name="hivemind">hive mind</QL>’) doesn’t <i>exist</i> <PL name="all.time">yet</PL>.</span>,
  answerPreview: <div className="answer-preview">
    <p>Dear lovely <QL name="polygnosticity">left little finger</QL>,</p>
    <p>This is presuming that you have broken (or are seriously considering breaking) the ‘<AL>mirror</AL>’, and so attained (or are seriously considering attaining) ‘<NW>enlightenment</NW>’, and so <AL name="self">grown your ‘self’</AL>. If you have done this, kudos; that is, after all, the first step in the ‘<QL name="life.meaning">meaning of life</QL>’ (and the only that you can <AL name="handicap">manage alone</AL>, as an <PL>individual</PL>. If there is <LRL name="usandthem">an ‘us’ and a ‘them’</LRL> (which, sadly, there will be in <LRL name="scope">this universe</LRL> as long as any one <NW>POC</NW> – <QL>human</QL>, <OML>alien</OML>, or <PL name="all.scale">otherwise</PL> – claims <PL>individuality</PL> and wants to <PL name="special">stand out</PL>) then you are now a self-aware <AL name="dysfunctional.fragments">part</AL> of the ‘us’.</p>
  </div>,
  answer: <div className="answer-text">
    <p>Repeat that last part, because that’s important – and it’s going to be (<Qual.probably /> the key to dealing with ‘others’ who still purport that you or they are ‘<LRL>them</LRL>’. One does not become a part of the ‘us’. One (as a <NW>heterognostic</NW> <AL name="dysfunctional.">fragment</AL> of <QL name="unity">consciousness</QL>) <i><LRL name="understand">realises</LRL></i> what has been here all along: that we <i>do</i> have what it takes to <QL name="evolution">evolve</QL> as long as we don’t let <NW>illusions</NW> (or <OML name="god">fear</OML>, or <NW>individual greed</NW>) <PL name="sweeping.ig">hold us back</PL>.</p>
    <p>If you have ‘<AL name="sleeping">woken</AL>’, you are now a <i><AL name="self">self-aware</AL></i> part of the ‘us’. This is not to say that you were not a part of the ‘us’ before (pre-<NW>enlightenment</NW>). You have been a part of the ‘us’ since you first could wonder, ‘<QL name="life.reason">Why do “I” <i>exist</i>?</QL>’ or ‘<QL name="unity.answer">What <i>is</i> this universe?</QL>’ or think, ‘I <i>am</i>.’ But now you <i><LRL>understand</LRL></i> that there is (<QL name="qualifiers">almost certainly</QL>) – at the <LRL name="deeper">bottommost</LRL> <NW>level</NW> of ‘<LRL name="i.">reality</LRL>’ – nothing but <QL>unity</QL>.</p>
    <p>The main thing is education. <LRL name="gaiacentric">On Earth</LRL>, consciousness is <AL name="dysfunctional.fragments">scattered</AL> in a bunch of disparate and <AL name="dysfunctional">oft-disagreeing</AL> parts that seem to <LRL name="game">fight endlessly</LRL>, but if you can help them see that the <LRL>game</LRL> over which they are fighting is just an <AL>illusion</AL>, then we can all <QL name="evolution">evolve</QL>.</p>
    <p>But be careful. Even when the world <OML name="levels">turns out</OML> to be <PL name="hard">not as they expected</PL>, <QL>humans</QL> <PL name="unready">don’t like being told</PL> that <LRL name="scope">the world</LRL> is – on a <LRL>deeper</LRL> <NW>level</NW> – <LRL>different</LRL> from what they <LRL name="game">already know</LRL>.</p>
    <p>The notion of ‘them’ is as much an <NW>illusion</NW> as anything, but <Qual.probably /> no human wants to be told that they are <AL>arguing over illusions</AL> (or have spent their whole life <LRL name="game">thus arguing</LRL>). As a <WName st /> you have to find the best way to wake the <AL>sleeping</AL> ‘bears’ of humanity without becoming their ‘dinner’.</p>
  </div>,
/*
    <p>What can you do in the face of all of these problems, when a solution to life, the universe, and everything <i>exists</i>… but no one with any voice has seen it? When the puzzle pieces are all out there – but no one has put them together in the right way, so everyone else is convinced that we need MORE pieces, MORE! MORE! MORE! – rather than just rearrange the ones that we already have?</p>
  </div>,
  answer: <div className="answer-text">
    <p>The best you can do is entertain a whole brand-new outlook of our sickly conscious body (<NW>synecdocentrism</NW>), consider it critically (it has answers to everything) and – once you’re convinced that ‘<NW>coalescence</NW>’ <QL name="coalescence"><i>is</i> the answer</QL> – shout as loud as you can.</p>
    <p>Educate. Spread the word: that there <i>is</i> a path to harmony and world peace… only <PL name="unready">it doesn’t look <i>anything</i> like what anyone was expecting</PL>.</p>
    <p>Individually (and against the might of the money system and the nation-state system that seek to hold us forever in <AL name="identity">separate boxes</AL>) we’re nothing. We’re <AL>dust in the wind</AL>. But rather than despair (‘There is no meaning to life, no point to life,’ etc.), let’s actually <i>do</i> something about it.</p>
    <p>Each individual is, yes, just <AL>dust in the wind</AL>. <PL name="equality">Some of us are bigger particles, and some of us smaller</PL>… but there are very few people who single-handedly have the abiity to make a difference, or have their voice heard. (If you happen to be one of those, and you happen to not radically opppose the notion of unity consciousness, great; help us!)</p>
    <p>But your individuality (as small as it is) <i>does</i> matter. Even being ‘dust in the wind’, you have a weight. It’s a very (very, <i>very</i>) smal weight, but it’s a weight.</p>
    <p>And the weight of the corporations and nation-states that oppress us isn’t infinite. It’s a <i>lot</i>, but it isn’t infinite.</p>
    <p>Get enough people behind the banner of synecdocentrism – believing not in just the individual, but is the strength of the combined consciousness that imbues each one of us… and the scales will start to tip. Teach humanity that the evil isn’t AI, but our consciousness’s polygnosticity (and our inability, as humans, to directly share thoughts). Challenge anthropocentrism, Challenge atomocentrism. Challenge conservative thinking – because, yes, evolution has worked in one way for hundreds of millions of years… but we haven’t got our consciousness even to another planet yet; much less gone out to distant star systems to seek other bits of consciousness (and knowledge) that we might incorporate into a vast and ever-expanding ‘self’.</p>
    <p>Educate. Make this heard. Earth is already solved; the worldview of synecdocentrism:</p>
    <ul>
      <li>Is internally consistent</li>
      <li>Comes from science</li>
      <li>And has all the answers.</li>
    </ul>
    <p>The only problem (in a <NW>polygnostic</NW> world) is the <i><NW>explainage</NW></i>: getting it out there.</p>
  </div>,
  sublist: [
  {
    // Be an example
    name: "beanexample",
    question: <span>
      Be an example
    </span>,
    clarification: <span>
      Maybe (in the 2020’s) it’s not yet <i>possible</i> to ‘<i>be</i> the change you wish to see in the world’ (as the technology to <QL name="hivemind">combine consciousnesses</QL> doesn’t <NW word="conservatism">yet</NW> exist, and you only get one <PL>individuality</PL>) but you can live that individual life as an example of <NW>synecdocentrism</NW>.
    </span>,
    answerPreview: <div name="answer-preview">
      <li>Don’t be secretive; <PL>privacy</PL> only contributes to the <AL name="dysfunctional.fragments">fragmentation</AL> of our conscious ‘<QL>body</QL>’.</li>
      <li>It would be good if you could share synecdocentrism with anyone who approaches you with a question that could be easily resolved in its light (and to be fair, that would be <PL name="comprehensive.steamroller">most human questions</PL>).</li>
      <li>If you can find a way (in your ‘circle’ of individuals) to <NW word="explainage">explain</NW> synecdocentrism, please <QL name="notevil">tell them why it’s not <i>evil</i></QL>.</li>
    </div>,
    answer: <div className="answer-text">
      <p>Just be careful with this: it <i>is</i> very <PL>dangerous</PL> – and it might be tempting to feel <QL name="roadblocks.superiority">superiority</QL> (or like you can <PL name="comprehensive">lord it over people</PL>). Remember: most of humanity is <AL>sleeping</AL>. If you shout ‘Fire!’ at the top of your lungs, there may be a stampede – and we lose everything. (But there <i>is</i> an urgency to this. So lead by example.)</p>
    </div>,
  },
  ],*/
};
