import React from 'react';
import WName from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual} from '../../common';

// ‘’ … –

export const answers = {
  name: "answers",
  alternatives: "answer,questions,question",
  prelude: <span>
    Enough of the <i>just not knowing</i>.
  </span>,
  header: <span>
    There are more answers than questions here.
  </span>,
  outside: <span>
    It’s basically the <LRL name="different">opposite</LRL> of what most people have (<Qual.probably /> <PL name="establishment">learned</PL> to expect from <AL name="fields.">philosophy</AL>.
  </span>,
  preview: <div className="long-read-preview">
    <p>‘But… But…’ you say (<Qual.maybe />), <Qual.possibly /> staggering under the weight of your own <PL name="denial">disbelief</PL>, <Qual.perhaps /> thinking that if this <QL>Weltanschauung</QL> had to make some <PL name="uncomfortable">absurd-seeming</PL> <QL>definitive</QL>-sounding statement, it <OML name="ideas">could have</OML> at least <i>tried</i> to pick something more <i><OML name="sillyplanet">believable</OML></i>.</p>
    <p>And yet there <i>are</i> answers here. Ask it things like, ‘<QL name="life.meaning">What is the meaning of life?</QL>’ and it will (without hesitation) <OML name="things">churn out</OML> an answer.</p>
    <p>If it feels like the answers are always <i><QL name="change">changing</QL></i>, that’s only at the <NW>level</NW> of the <OML name="levels">superficial</OML> (i.e., the things that the <QL>Weltanschauung</QL> <PL name="sweeping.">disregards</PL> as <LRL name="deep">fundamentally</LRL> <PL>just</PL> <NW>illusions</NW>). The answers just <LRL name="superpositions">leave a lot of room</LRL> for <OML home>various other possibilities</OML> at the <NW>level</NW> of <i><AL>illusions</AL></i>.</p>
  </div>,
  text: <div className="long-read-text">
    <p><WName /> has more answers than questions because it (as a <AL>framework</AL>) is <LRL name="causes">digging</LRL> really, really <LRL>deep</LRL> and <PL name="ick.systems">systematically</PL> <AL name="framework.puzzle">re-arranging the puzzle pieces</AL> (that are <LRL name="scope">already here</LRL>) until they fit together in <NW home>terms</NW> that may seem <PL name="sweeping">ridiculously broad</PL> (for <PL name="sweeping.ig">example</PL>, ‘<NW>individual greed</NW>’) but don’t need to be ‘shored up’ by any <NW>mythology</NW> to <E2 /> <QL name="unity.answer">everything</QL> (<PL name="all.level">at least</PL> at our <NW>level</NW>), leaving only <i>cracks</i> (i.e., <QL name="pg.mind.know">unknowns</QL>) for things that are <NW word="illusions">illusory</NW> anyway, so to their exact determination <WName /> is ‘<PL name="all">invariant</PL>’.</p>
    <p>[under construction]</p>
  </div>,
};
