import React from 'react';
import ItemBox from '../ItemBox';
import {newWords, otherWords} from './wordlists';
import {useParams} from 'react-router-dom';
import './Word.css';

export default function Word(props) {
  var {word} = useParams(); // If coming from react router.
  var {search} = props;
  if (!search) {search = (props.word) ? props.word : word;}
  return <ItemBox
    className="word-box"
    preview={false}
    data={props.def}
    list={!props.def ? newWords.concat(otherWords) : null}
    search={search}
    nameName="word"
    propertyName="word"
    alternativesName="match"
    displayName="display"
    headerName="word"
    outsideName="shortdef"
    previewName="etymology"
    textName="def"
  >{props.children}</ItemBox>;
};
