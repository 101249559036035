import React from 'react';
import ButtonBox from '../ButtonBox';
import Word from './neologisms/Word';
import NW from './neologisms/NewWord';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import AL from './angles/AngleLink';
import OML from './ormaybe/OrMaybeLink';
import LRL from './longreads/LongReadLink';
import {E1, E2} from './common';
import {Outlet, Link} from 'react-router-dom';
import './Neologisms.css';

export const listToWords = (list, showAll) => list.map((w, i) => {
  if (!w.important && !showAll) {return null;}
  else return <Word def={w} key={i} />;
});

export const intro = <p className="intro-words">There are (in the vein of <AL name="notnew.">Shakespeare</AL>) a lot of ‘new’ words here… partly because <NW>synecdocentrism</NW> just (<QL name="qualifiers">probably</QL>) isn’t <LRL name="different">anything</LRL> that’s <PL name="unready">on anyone’s ‘radar’</PL>, but also because of <QL name="anthropocentrism">humanity</QL>’s [inset swear word] <NW>polygnostic</NW> consciousness – and <QL name="polygnosticity">inability to communicate</QL> except by <E1 suffinx="ing" /> things through the [insert non-English swear word] medium of <i>language</i>. So the <QL>Weltanschauung</QL> made up (or repurposed) a bunch of English words. Let’s define them:</p>

export default function Neologisms() {
  return (
    <div className="page">
      {intro}
      <Outlet />
      <div className="backbuttons">
        <ButtonBox src={-1}>Back</ButtonBox>
        <ButtonBox className="only-critical-button" src="/menu/neologisms">
          Important words
        </ButtonBox>
        <ButtonBox className="all-button" src="/menu/neologisms/all">
          All words
        </ButtonBox>
      </div>
    </div>
  );
};
