import React from 'react';
import ButtonBox from '../ButtonBox';
import WName, {WArticle} from './WName';
import AL from './angles/AngleLink';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import NW from './neologisms/NewWord';
import LRL from './longreads/LongReadLink';
import OML from './ormaybe/OrMaybeLink';
import {E1, E2} from './common';
import {Outlet, useLocation} from 'react-router-dom';
import {getSubpaths} from './common';
import PagePreview from './PagePreview';
import {getSectionTitle} from '../Menu';

const URL_part = "ormaybe";

const short_intro = <p>If <WName /> had a ‘motto’ (other than <i><PL>individuality</PL> is just an <NW>illusion</NW></i> – and the other ‘<AL>bullet points</AL>’) it would be this: ‘<LRL name="motto">leave no stone unturned</LRL>’.</p>;

const intro = <div className="intro-or-maybe">
  {short_intro}
  <p>What this means is that <WArticle /> <WName ist /> must be able to hold <LRL name="superpositions">a bunch of competing possibilities</LRL> simultaneously in the ‘mind’ with no <i>judgement</i> – even the possibility that this all might be dead <OML>wrong</OML>… and then be able to <i>settle</i> onto the one that <i><AL name="framework.works">works</AL></i> the best (i.e. is the best <E2 word="explanation" />).</p>
  <p>Another way to say it: it’s logical <i><NW>induction</NW></i>. Another way to say it: it’s the ‘scientific method’. Another way to say it: it’s making (or trying to make) the <QL name="anthropocentrism">human</QL> mind work like a <AL name="disciplines.physics">quantum computer</AL>.</p>
  <p>Or maybe. But <i><QL name="salvation">creativity will be our salvation</QL></i>. Just consider all things, and <NW word="god">inshallah</NW> your <NW>POC</NW> will eventually be able to <AL name="mirrors">break the mirror</AL>, escape <AL name="identity">our society’s prison</AL>, and <AL name="sleeping">wake up</AL>, cf. ‘<QL name="life.meaning">What is the meaning of life?</QL>’.</p>
</div>;

export default function OrMaybes(props) {
  const location = useLocation();
  if (props && props.preview) {
    return <PagePreview
      name={URL_part}
      title={props.displayName}
      intro={intro}
    />;
  }
  const subpaths = getSubpaths(location.pathname, URL_part);
  const intro_version = (subpaths.length > 0) ? 
    <div className="short-intro">{short_intro}</div> : intro;
  return (
    <div className="page">
      {intro_version}
      <hr />
      <Outlet />
      <div className="backbuttons">
        <ButtonBox src={-1}>Back</ButtonBox>
        <ButtonBox src="/menu/ormaybe">All possibilities</ButtonBox>
      </div>
    </div>
  );
};
