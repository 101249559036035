import React from 'react';
import Question from './Question';
import PreviewLink from '../PreviewLink';
import './QuestionLink.css';

export default function QuestionLink(props) {
  return <PreviewLink
    {...props}
    PreviewComp={Question}
    linkClassName="question-link"
    linkURLPrefix="faq"
  />;
};
