import React from 'react';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const understand = {
  name: "understand",
  alternatives: "understands,understanding,understood",
  prelude: <span>
    It starts to feel a bit like ‘<NW>explain</NW>’: almost like a ‘bad’ word.
  </span>,
  header: <span>
    What does it <i>really</i> mean to ‘understand’ something?
  </span>,
  outside: <span>
    It has to do – this time – not (<PL name="sweeping.ig">as often</PL>) with <NW>individual greed</NW> but with the sub-<AL name="disciplines">field</AL> of ‘<QL>gnosticity</QL>’.
  </span>,
  preview: <div className="long-read-preview">
    <p><LRL name="gaiacentrism">On Earth</LRL>, as a <LRL name="gaia">unified and conscious animal</LRL> (albeit a fragmented one), our immediate (<OML name="goal">if not ‘end’</OML>) <QL>goal</QL> is to <i>understand</i> <LRL name="scope">this world</LRL>… and <i>yours</i> – as a <NW>heterognostic</NW> <AL name="dysfunctional.fragments">piece</AL> of our <QL name="unity">(shared) consciousness</QL> (<NW>POC</NW>) – should be to <i>understand</i> <NW>synecdocentrism</NW>.<sup>†</sup></p>
    <p>However, what does it really mean to really (as an <PL>individual</PL> ‘<QL name="polygnosticity.">mind</QL>’, or even as a ‘<QL name="hivemind">hive mind</QL>’) <i>understand</i>?</p>
  </div>,
  topFootnote: <p>
    † Or just live a simple life (not trying to be on the ‘<QL>top</QL>’), accept that other ‘<QL name="polygnosticity.mind">minds</QL>’ <i><QL name="gnosticity">know</QL></i> more than you (and ‘understand’ <LRL name="scope">this universe</LRL> better), and <OML name="authoritarianism">submit</OML> to the <i>true</i> (i.e., non-<PL>private</PL>, <QL name="openness">radically open</QL>) <NW word="synecdocentrism">synecdocentrists</NW> as <AL name="notnew.plato.kings">philosopher-kings</AL> – trusting that world leaders who have <i><QL name="polygnosticity.privacy">nothing to hide</QL></i> will manage <LRL name="gaiacentrism">this rock</LRL> better than the <NW>money system</NW> with its obsession over <PL name="equality">artificially-derived</PL> <PL>individual</PL> <i>value</i>.
  </p>,
  text: <div className="long-read-text">
    <p>The notion of ‘understanding’ is a weird one that’s hard to – <QL name="definitive">definitively</QL> – <i>define</i>… so let’s start by <E1 suffix="ing" /> what it means to <i>not</i> understand something:</p>
    <ul>
      <li>To harbour any <PL>beliefs</PL> about it that run contrary to the accumulated (<LRL name="scope">observed</LRL> evidence.</li>
      <li>To lack a <AL>framework</AL> with which to <E2 /> something.</li>
      <li>To not be <i>able</i> to <E2 /> some observed data point(s) with said <AL>framework</AL>: ‘It is because it is.’)</li>
      <li>To have any <NW>mythology</NW> within said <AL>framework</AL> that <E2 suffix="s" /> things in a way that cannot be <AL name="field.science">scientifically</AL> <i>proven</i>.</li>
      <li>To harbour any <PL>beliefs</PL> about it that run contrary to the accumulated (<LRL name="scope">observed</LRL>) evidence or any <i>new</i> evidence that might come along.</li>
    </ul>
    <p style={{color: "red"}}>[Under conscruction still]</p>

  </div>,
};
