import React from 'react';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual} from '../common';

export const gnosticity = {
  name: "gnosticity",
  alternatives: "g",
  prelude: <span>
    There’s a whole sub-field of <AL name="fields.">science</AL> <i>inside</i> <WName />.
  </span>,
  question: <span>
    What is ‘gnosticity’?,
  </span>,
  clarification: <span>
    Does it really <E2 /> <QL name="qualifiers">just about</QL> <PL name="comprehensive">everything</PL>?
  </span>,
  answerPreview: <div className="answer-preview">
    <p>Gnosticity (as a <AL name="fields.">science</AL>, or at least a ‘pseudoscience’) arises from looking at <PL name="ick.systems">even</PL> <i><QL>consciousness</QL></i> as a <AL>system</AL>.</p>
    <p>When dealing with <QL>consciousness</QL>, the way that knowledge (and <LRL>understanding</LRL>) is passed around is very important (especially to <E2 /> the difficulties that <QL>humanity</QL> – as a <NW>polygnostic</NW> <QL name="polygnosticity">subset</QL> within the whole <QL>body</QL> – <AL name="dysfunctional">seems to have</AL>). To pave the way to a <QL>future</QL> of <OML>harmony</OML> (and a triumph of <NW>agency</NW> over <LRL>reactivity</LRL>) where our <QL>consciousness</QL> doesn’t just go <i><OML>kaput</OML></i>, it is important to think about <QL>conscious</QL> <LRL>understanding</LRL> non-<NW word="conservatism">conservatively</NW>. Hence this whole sub-field on the transfer of <i><QL name="pg.mind.know">knowledge</QL></i>.</p>
  </div>,
  answer: <div className="">
    <p>The main <WName ic /> ‘<NW home>neologisms</NW>’ arising from gnosticity are:</p>
    <ul>
      <li><NW>Monognostic</NW>: the <QL name="pg.">mind</QL> of any ‘<QL name="body.">cell</QL>’ in the conscious <QL>body</QL>. Could be an <PL>individual</PL> <AL name="handicap">human being</AL> (without <AL name="sick.">DID</AL> or another ‘<AL>identity</AL> disorder’), could be a whole ‘<NW word="coalescence">coalesced</NW>’ <LRL name="gaia">planet</LRL> (<OML name="alone">Earth</OML> or <OML name="aliens">any other</OML> that has aligned its <QL>conscious</QL> <LRL name="i.power">forces</LRL> to defeat <LRL>reactivity</LRL>).</li>
      <li><NW>Polygnostic</NW>: <E2 suffix="s" /> why <QL>humanity</QL> does not just <OML name="harmony">decide to get along</OML>, but <QL name="polygnosticity">instead</QL> <PL name="all.perspective">sees</PL> <LRL name="usandthem">an ‘us’ and a ‘them’</LRL> harbours <i>fear</i> of the ‘them’ – which <PL name="sweeping.ig">manifests</PL> as <NW>individual greed</NW> (itself a <LRL>symptom</LRL> of <LRL>eactivity</LRL>).</li>
      <li><NW>Heterognostic</NW>: basically any <QL>conscious</QL> ‘<AL name="dysfunctional.fragments">fragment</AL>’ in a <NW>polygnostic</NW> <PL name="ick.">system</PL>, i.e., a <LRL>symptom</LRL> of the (highly <QL name="polygnosticity">problematic</QL><sup>†</sup>) condition of <QL>conscious</QL> <NW>polygnosticity</NW>.</li>
      <li><NW>Isognostic</NW>: being part of the same ‘<QL name="pg.">mind</QL> – at <PL name="all.scale">whatever scale</PL>: for example, in most <QL>humans</QL> the two hemispheres of the brain are isognostic (i.e., think together), while most human conjoined twins are <i>not</i>. (They would be <NW>heterognostic</NW>.)</li>
      <li><NW>Homognostic</NW>: In the <LRL name="scope">universe</LRL> at this <NW>level</NW> – as known to <QL>humanity</QL>… and so to <WName />, which <LRL>notion</LRL> comprises <AL name="handicap">exclusively</AL> <i><QL>human</QL></i> thinkers (diversity problem, anyone?) – this is a synonym to ‘<NW>isognostic</NW>’. On <LRL name="imagination">imaginary</LRL> <NW>fictive levels</NW> (<i>above</i> us) this would be the case if two ‘<PL>individuals</PL>’ both had the same knowledge base but chose to act on it differentlhy. It could also be the case if you were trying to ‘<LRL name="motto">leave no stone unturned</LRL>’ and considered everything you <i>could</i> do from your present position. Obviously all of those fictional versions of ‘you’ have the same initial knowledge <i>base</i>, but go in different directions.</li>
      <ul>
        <li>But are they ‘<LRL name="i.">real</LRL>’, or is this just <LRL>nihilism</LRL>?</li>
        <li>You don’t have to worry about this one. <Qual.probably cap/>.</li>
      </ul>
    </ul>
    <p>Without caring overly what <QL>consciousness</QL> really <i>is</i>, that <E2 suffix="s" italic /> a whole bunch of how it manifests in <LRL name="scope">our universe</LRL> and <NW>levels</NW> <i>above</i> us (<LRL name="imagine">imagined</LRL> by us).</p>
  </div>,
  bottomFootnote: <p>
    † Have you (or anyone you know) ever tried to treat a patient with <AL name="sick.">DID</AL>? Have you tried to convince another <NW>POC</NW> to <AL name="self">expand its sense of ‘them’</AL> when that <NW>POC</NW> maybe didn’t <i>want</i> to? Some people with ‘delusions’ will ‘miss’ the ‘others’ who talk to them when they are ‘deluded’; the ‘others’ keep them company, and maybe they haven’t learned (or are afraid, or are <OML name="stupid">not <i>able</i></OML>) to <QL name="salvation">rely on</QL> their <LRL>imagination</LRL>.
  </p>,
  sublist: [
  {
    // Agnosticity
    name: "agnosticity",
    alternatives: "agnostic,agnosticism",
    question: <span>
      <i>Agnosticity</i> is <WName ically noNW /> ‘bad’.
    </span>,
    clarification: <span>
      But our conscious ‘<QL>body</QL>’ has to put up with a <AL>hell</AL> of a lot of it.
    </span>,
    answerPreview: <div name="answer-preview">
      <p>The prefix ‘a-’ (called – <QL name="gnosticity.polymath">as if you cared</QL> – the ‘alpha privative’) is <AL name="fields.linguistics">linguistically</AL> derived from Greek and refers to the <i>abscence</i> of the thing it modifies.</p>
      <p>So ‘agnostic’ means – literally – ‘not knowing’.</p>
      <p>And if you haven’t <LRL name="understanding">figured it out</LRL> yet, <WName /> <i>really</i> <QL name="goal">doesn’t like not knowing</QL>.</p>
      <p>[Under construction]</p>
    </div>,
//    answer: <div className="answer-text">
//    </div>,
  },

  {
    // What's a 'polymath'
    name: "polymath",
    question: <span>
      What’s a ‘polymath’.
    </span>,
    clarification: <span>
      And <i>why</i> do we care?
    </span>,
    answerPreview: <div name="answer-preview">
      <p><QL name="polygnosticity.">TL;DR</QL>: a ‘polymath’ is someone who dabbles in a wide <i>breadth</i> of subjects, and so may not have <i>expertise</i> (‘deep’ understanding) in any.</p>
      <p>The word ‘polymath’ derives entirely from Ancient Greek – as many things in this <QL>Weltanschauung</QL>; but, hey, the <AL name="notnew.plato.neoplatonism">Ancient Greeks</AL> had a pretty darn good (albeit predominantly ‘<AL name="fields.physics">classical</AL>’) grasp on ‘<AL name="notnew.plato">philosophy</AL>’ – which is itself an Ancient Greek word. But for ‘polymath’: ‘poly-’ means ‘many’, as in ‘polyamory’ or ‘<NW>polygnosticity</NW>’, and ‘math’ is referring to <i>learning</i> (and not just <AL name="fields.">mathematics</AL>).</p>
      <p>So what is a ‘polymath’, exactly? It’s a single <NW>POC</NW> (<QL name="anthropocentrism">human</QL> ‘<PL>individual</PL>’, or maybe a ‘<QL name="hivemind">hive mind</QL>’) that has knowledge (or <LRL>understanding</LRL>) in many different ‘<AL>disciplines</AL>’.</p>
    </div>,
    answer: <div className="answer-text">
      <p>Does a functional (i.e., not <i><AL>dysfunctional</AL></i>) conscious ‘<QL>body</QL>’ need a <i>lot</i> of them? Probably not. Think of the specialisation of labour. How much more <i>efficient</i> is a society (or <NW>nation-state</NW>, <NW>company</NW>, insect colony, <AL name="fields.biology">multi-cellular organism</AL>, or honestly <QL name="qualifiers">just about</QL> anything) when its members (or ‘<QL name="body.">cells</QL>’ don’t all have to do the <i>same</i> repetitive tasks like cooking, washing, cleaning… or basically a good chunk of <LRL name="gaiacentrism">Earth’s</LRL> recent history for any <NW>POC</NW> popped into an anatomically-female body (and – <i>aaagh!</i> – the <i><NW>entropy</NW></i> of that)? Being (or aspiring to be) a ‘polymath’ means that you may have general <i>knowledge</i> (or surface-level <LRL>understanding</LRL>) in a whole bunch of different <AL>fields</AL>, and <i>expertise</i> in none.</p>
      <p>That said (because we’re <LRL name="motto">turning the ‘stone’</LRL> now) there is <QL name="qualifiers">quite a high probability</QL> that <PL name="sweeping"><i>most</i> (if not all)</PL> <QL>human</QL> ‘<QL name="body.">cells</QL>’ have grossly <OML name="specialisation">over-specialised</OML> – and so <LRL name="gaiacentrism">Earth’s</LRL> (<LRL name="gaia">shared</LRL>) <QL>body</QL> actually <i>needs</i> some polymaths… or viewers, from far out, of the ‘big picture’: <QL name="metaphors">analogously</QL> (though with no sense of <QL name="roadblocks.">superiority</QL>) ‘brain cells’.</p>
      <p>But (<QL name="qualifiers">maybe</QL>) what you want to know is this: are <i>you</i> a polymath? Go and read the ‘<OML home>or maybe</OML>’ speculation on <OML>specialisation</OML>, and if your <NW>POC</NW> managed to <LRL name="understand">follow</LRL> that – which glances (cursorily) on <AL>disciplines</AL> as diverse as <AL name="fields.">biology</AL>, <AL name="fields.">physics</AL>, and <AL name="fields."></AL>, not to mention all the <QL>metaphor</QL>) – then ‘you’ could well be a ‘polymath’, or at least one in the making. If that’s you, first stop overly <i><AL name="identity">identifying</AL></i> with the (admittedly probably quite smart) body that <i>houses</i> your <NW>POC</NW>, try to truly <LRL>understand</LRL> this <QL>Weltanschauung</QL> (along with the inherent <LRL name="superpositions">uncertainty</LRL> within) and then – as long as you aren’t holding onto faith in some <OML name="god">external ‘God’</OML> or <i>immortal</i> individual ‘<OML>soul</OML>’ – go and fill out your application<sup>†</sup> to be a <AL name="notnew.plato.kings">philosopher-king</AL>.</p>
    </div>,
    bottomFootnote: <p>
      † A spoof on the <NW>money system</NW>; <WArticle /> <WName ic /> <LRL word="communism">communist</LRL> doesn’t believe in the <NW>MNS system</NW> <LRL>notion</LRL> of needing to ‘apply’ for things.
    </p>,
  },

  // Is this site not just re-inventing the wheel?
  {
    name: "re-inventing the wheel",
    alternatives: "re-inventing,re-invent,re-invents,wheel",
    prelude: <span>
      This site doesn’t link with anything outside, doesn’t use APIs or import any modules (even to helo build its structure), or otherwise doesn’t (appear to) even <i>try</i> to incorporate itself into the rest of <QL>humanity</QL>’s digital existence.
    </span>,
    question: <span>
      Isn’t this site just re-inventing the wheel?
    </span>,
    answerPreview: <div className="answer-preview">
      <p>‘Re-inventing the wheel’ (or an automobile, or a rocketship, or any of the various ‘<NW word="nation">national</NW> <PL>secrets</PL>’ or ‘<NW word="company">corporate</NW> <PL>secrets</PL>’ <QL>humanity</QL>’s ‘<NW>MNS system</NW>’) is – needless to say – <WArticle /> <WName ically /> ‘<PL name="evil">bad</PL>’ thing.</p>
      <p>So then why would the ‘<WName />’ website be doing it?</p>
      <p>Two main reasons:</p>
      <ol>
        <li>A lot of them require <i><NW>money</NW></i>.</li>
        <li>The ones that don’t require <i><NW>money</NW></i> (‘open-source’) occasionally have ‘bugs’, or push an update that has ‘bugs’, or push an update that then breaks something somewhere unexpected on the website… and – even if they have ‘support’ (which may just be an <PL>individual</PL> working on ‘hobby’ projects from a ‘coffee shop’) – one then runs into the <QL name="pg">woes</QL> of <QL>humanity</QL>’s conscious <NW>polygnosticity</NW>: the <QL name="author">website-creator</QL> has <i>no idea</i> what was changed, and so there the website-creator must either request <E1 word="explanations" italic /> (via ‘<NW>explainage</NW>’, <i>search</i> for those <E1 word="explanations" /> (its own form of ‘re-inventing the wheel’: <i><QL name="wheel.">research</QL></i>), or debugging becomes an altogether tedious process that the website-creator could potentially not even solve alone if the ‘bug’-causing component is in the ‘source’ code and the included module is either 1) not ‘<LRL name="communism">open-source</LRL>’, at which case the website-creator has to again sick to ‘<NW>explainage</NW>’ in order to <E1 /> the <i>problem</i> to whoever manages it (and only hope that a) they <LRL>understand</LRL>, and b) actually <i>do</i> something about it, or 2) the <NW>POC</NW> who discovered the bug has to go in ant tinker with the module source code, which a) is unfamiliar (requiring time spent to <i>learn</i> it (personally), or b) extremely difficult, becuase the ‘bugged’ website-creator does not (personally) <i>know</i> the programming language in which the thing is written, or doesn’t personally <i>know</i> how to use Github, or doesn’t (personally) <i>know</i>…</li>
      </ol>
      <p>Hopefully  by now you – even if you don’t know an object prototype from an <AL name="fields.cs">instance</AL> – can see the point.</p>
    </div>,
/*    answer: <div className="answer-text">
      <p>The notion of</p>
      <p>This <QL>Weltanschauung</QL> – liking <i><LRL>analysis</LRL></i> – can provide <PL name="sweeping.categorisation">several main reasons</PL> why this (unfortunate) event may occur, <LRL name="gaiacentrism">at least on Earth</LRL>. (And – though you probably could have guessed it – they all boil down to the <PL name="sweeping.handful">same few concepts</PL>, <PL>all of which derive (at the base <NW>level</NW>) from the problem of our conscious <QL>polygnosticity</QL</NW>.</p>

    </div>,*/
  },

  ],
};
