//import React from 'react';

/************************************************
 * Randomises the name of the 'Mechanozoic' Eon *
 ************************************************/

// Options:
const eon_options = [
  {
    name: "Mechan",
    word: "μηχανή", 
    meaning: "machine",
    transcription: "‘meh-khan-EE’ – ‘kh’ like ‘ch’ in ‘loch’ or ‘Chuppah’",
  },
/*  {
    name: "Synecdo",
    word: "συνείδηση", 
    meaning: "consciousness",
    transcription: "‘seen-EE-thee-see’",
  },*/
  {
    name: "Prothes",
    word: "πρόθεση",
    meaning: "intent",
    transcription: "‘PRO-theh-see’",
  },
  {
    name: "Skopim",
    word: "σκόπιμη",
    meaning: "deliberate", 
    transcription: "‘SKO-pee-mee’",
  },
];

// Global variable to pick it out of the array:
var chosenEon = null;
function choose() {
  // Make more complex later
  return 0;




  if (Math.random() < 0.5) {return 0;}
  return Math.floor(Math.random()*eon_options.length);
}

// Component for displying the etymology:
export function EonEtymology(props) {
  if (chosenEon === null) {
    chosenEon = choose();
  }
  let ourWord = eon_options[chosenEon];
  if (!ourWord) return "[Error fetching word]";
  return <span>Greek <i>{ourWord.word}</i> ({ourWord.transcription}: {ourWord.meaning}) and <i>ζωή</i> (‘zo-EE’: life)</span>;
}

/* OUTPUT THE SELECTED NAME FOR THE 'EON' */
export function eonName(end="") {
  if (chosenEon === null) {
    chosenEon = choose();
  }
  let firstPart = eon_options[chosenEon].name;
  if (!firstPart) {
    console.log("error fetching name", chosenEon, firstPart);
    return "[error fetching name]";
  }
  return firstPart + "ozoic" + end;
}

// For the first 'era' within it (using 'Eo'):
export function eraName(end="") {
  if (chosenEon === null) {
    chosenEon = choose();
  }
  let firstPart = eon_options[chosenEon].name;
  if (!firstPart) {
    console.log("error fetching name", chosenEon, firstPart);
    return "[error fetching name]";
  }
  firstPart = firstPart.toLowercase();
  return "Eo" + firstPart + "ic" + end;
}
