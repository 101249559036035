import React from 'react';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, Qual, SillyName} from '../common';

import {consciousness} from './consciousness';
import {causes} from './causes';
import {levels} from './levels';
import {illusions} from './illusions';
import {abilities} from './abilities';
import {forward} from './forward';
import {others} from './others';

// LEAVE NO STONE UNTURNED

export const or_maybes = [

...consciousness,

...causes,

...levels,

// 'Rights', individual soul, external God, earth-soul
...illusions,

// alone, aliens, robots (with ig)
...others,

// smarter, stupid, bad, offensive, specialisation
...abilities,

// Potnetial paths forward: murder, sterilisation, authoritarianism, kaput
...forward,

];



