import React from 'react';
import Box from './Box'; 
import {useNavigate} from 'react-router-dom';
import PL from './pages/problems/ProblemLink';
import OML from './pages/ormaybe/OrMaybeLink';
import LRL from './pages/longreads/LongReadLink';

export default function E404(props) {
  const navigate = useNavigate();
  return <div className='error'>
    <Box
      prelude="Error ‘404’"
      header=<span>This <PL name="individuality">page</PL> doesn’t seem to <LRL name="i.real">exist</LRL>.</span>
      outside=<span><OML home>Try</OML> the <button onClick={()=>navigate(-1)}>back</button> button.</span>
    />
  </div>;
}
