import React from 'react';
import {problemList} from './list';
import ProblemsList from './ProblemsList';
import {intro} from './list';
import './AllProblems.css';

export default function AllProblems(props) {
  return <div className="all-problems">
    {intro}
    <ProblemsList list={problemList} />
    <hr />
  </div>;
};
