import React from 'react';
import {wName} from '../../wName';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Qual, Rando} from '../common';

// ‘’ – …

// What is consciousness?
export const consciousness = {
  name: "consciousness",
  alternatives: "conscious",
  question: <span>
    What is <i>consciousness</i>, anyway?
  </span>,
  clarification: <span>
    It’s kind of hard to have {(wName().substr(0, 4) === "syne") ? <span>‘<NW word="synecdocentrism">consciousness-centrism</NW>’</span> : <span>‘consciousness-centrism’<sup>†</sup></span>} without answering this one.
  </span>,
  headerFootnote: (wName().substr(0, 4) === "syne") ? null : <p>
    † On some days this thing <OML name="ideas">calls itself</OML> ‘synecdocentrism’ (or ‘synecdesocentrism’), from the Greek word ‘συνείδηση’: <i>consciousness</i>. However, right now it’s calling itself ‘<WName noNW />’.
  </p>,
  answerPreview: <div className="answer-preview">
    <p><TLDR />: <LRL name="gaia">Earth</LRL> consciousness (slowly ‘<AL name="sleeping">waking</AL>’ to the <LRL name="i.">power</LRL> of its own <NW>agency</NW><sup>‡</sup>) <Qual.may /> not entirely <i>know</i> yet but – if you can use your consciousness to <i><LRL>imagine</LRL></i> it – it clearly <i><LRL name="i.real">exists</LRL></i>.</p>
    <p>But what <i>is</i> consciousness, really. Does it stay the same all the time, or does it <OML name="evolve">change and <i>evolve</i></OML>?</p>
    <p>You might think that <WName /> – as a <QL>Weltanschauung</QL> that seems to <AL name="fields.physics">relish</AL> in <QL>change</QL> and <LRL name="superpositions">indetermination</LRL> – would be all over this <LRL>notion</LRL> of consciousness as inherently unstable.. You <OML name="will">might</OML> <i>think</i> that… but you’d be – if not <QL name="definitive">difinitively</QL> <i>wrong</i> – then <Qual.maybe /> just prey to <QL>anthropocentrism</QL>.</p>
    <p>Wait, what? Anthropo… *<NW>explainage</NW> <PL name="explainage">fail</PL>* <i><OML name="offensive">WTF</OML> is even going </i>on<i> here???</i></p> 
    <p><TLDR /> take two: consciousness is <Qual.probably /> less like a <QL name="change">growing</QL> and <QL name="evolution">evolving</QL> <i><NW>POC</NW></i> (even an <LRL name="gaia">Earth-sized</LRL> one, not necessarily <NW>heterognostic</NW> from anything <OML name="aliens">else</OML> conscious that <OML name="alone">may be out there</OML> and of <PL name="all.scale">any size</PL>: even a ‘<NW>POC</NW>’ that is a ‘<NW>coalesced</NW>’ version of the <LRL name="scope">whole <i>universe</i></LRL>) than a ‘fundamental force’ of nature (<PL name="all.level">at least</PL> at this <NW>level</NW>).</p>
  </div>,
  topFootnote: <p>
    ‡ Assuming that said <NW>agency</NW> can actually <OML name="will">effect change</OML> in the <LRL name="scope">cosmos</LRL> (at its own <PL name="all.time">time-bound</PL> <NW>level</NW>) – that being part of the initial presumption of <WName />, and <OML name="goal">without which</OML> there isn’t much of a <i><QL name="life.meaning">point</QL></i> to <AL name="self">our</AL> awareness.
  </p>,
  readMore: <span>
    But… wait. This explanation<sup>(2)</sup> doesn’t seem <i>complete</i>. What about… (Click the link to read more.)
  </span>,
  answer: <div className="answer-text">
    <p>If your <NW>POC</NW> <PL name="hard">survived</PL> that second <E1 word="explanation" />, let’s try to <LRL>understand</LRL> the need to avoid <QL>anthropocentrism</QL> when <OML name="ideas">considering this</OML>. Some (<AL name="handicap">human</AL>) <AL name="notnew">past thinkers</AL> have considered the notion of the ‘collective unconscious’ (and to be an <NW word="atomocentrism">atomocentrist</NW>, Carl Jung comes to mind). Hopefully you can see that past theories of a ‘collective unconscious’ were just accounting for (<E2 suffix="ing" />) the <i><QL>human</QL></i> consciousness – which (<OML name="alone">we hope</OML>) is only a <Qual.probably /> very small <i><AL name="dysfunctional.fragments">part</AL></i> of the <LRL name="scope">knowable universe</LRL> (and <PL name="all.level">potentially beyond</PL>). Hence the <QL>anthropocentrism</QL> of assuming that consciousness <i>itself</i> is changoing and <OML name="evolution">evolving</OML> just because <i><AL name="handicap">human</AL></i> consciousness is <QL name="evolution">evolving</QL>.</p>
    <p>It is <Qual.probably /> safe to assume (as the <PL name="reductionist">simplest</PL> <E2 word="explanation" />, cf. ‘<NW word="occam">Occam’s razor</NW>’) that the <LRL name="deep">deepest</LRL>-<NW>level</NW> force of consciousness <LRL name="scope">in our observable universe</LRL> – similar to electromagnetism or gravity – does not <OML name="evolution">change</OML>.</p>
    <p>But wait. Don’t <i><AL name="fields.science">scientists</AL></i> believe that early on – at some fraction of a fraction of a second very early in the formation of our universe (namely, about 10<sup>-43</sup> seconds: the <NW word="atomocentric">atomocentrically</NW>-named ‘Planck time’<sup>*</sup>) – the ‘fundamental forces’ were unified? Meaning that (and whether or not they <OML name="forms">exist ‘a priori’</OML> being at this point <PL name="all.level">rather irrelevent</PL>) what we today (in this configuration of space) observe as separate forces (gravity, electromagnetism, etc.) were at one point the same thing. Didn’t the way that these ‘fundamental forces’ <i>present</i> – the presentation seeming to be the only thing about which this ‘<PL name="all.level">level-invariant</PL>’ <QL>Weltanschauung</QL> gives a crap – <i>change</i>?</p>


    <p>Was that a lot of <LRL name="superpositions">uncertainty</LRL>? Welcome to <WName />.</p>
  </div>,
  bottomFootnote: <p>
    * Though if you <i>must</i> be an <NW word="atomocentrism">atomocentrist</NW> (as – looking <NW word="conservatism">conservatively</NW> at <QL>human</QL> history – seems to be a requirrement in <AL name="fields.">science</AL>, particularly <AL name="fields.">physics</AL>) then you could’ve picked far worse; this guy – just over 100 years ago – ‘discovered’ quantum physics.
  </p>
/*  sublist: [
  {
    // 
    name: "",
    question: <span>
    </span>,
    clarification: <span>
    </span>,
    answerPreview: <div name="answer-preview">
      <p>Building itself (if slowly).</p>
    </div>,
  },

  ],
*/
};
