import React, {useState} from 'react';
import WName, {WArticle} from './WName';
import Eon from './Eon';
import PL from './problems/ProblemLink';
import AL from './angles/AngleLink';
import QL from './faq/QuestionLink';
import NW from './neologisms/NewWord';
import OML from './ormaybe/OrMaybeLink';
import LRL from './longreads/LongReadLink';
// import Box from '../../Box';

// Shortcut for making the 'explain' superscripts
export function Explain(props) {
  let word = (props.word) ? props.word : "explain";
  if (props.suffix) {word += props.suffix;}
  if (props.italic) {
    word= <i>{word}</i>;
  }
  if (props.bold) {
    word = <b>{word}</b>;
  }
  if (props.style) {
    word = <span style={props.style}>{word}</span>;
  }
  return <NW word="explain">{word}{props.sense && <sup>{props.sense}</sup>}</NW>;
}
function makeExplainer(sense) {
  return (props) => {
    const suffix = (props.suffix) ? props.suffix : props.children;
    return <Explain {...props} sense={sense} />
  }
}

export const E1 = makeExplainer("1");
export const E2 = makeExplainer("2");

export const TLDR = (props) => <QL name="pg.tldr">TL;DR</QL>;

// Object of components using the 'qualifiers' QL:
export const Qual = {
  probably: (props) => <QL name="qualifiers">
    {(props.cap) ? "Probably" : "probably"}
  </QL>,
  maybe: (props) => <QL name="qualifiers">
    {(props.cap) ? "Maybe" : "maybe"}
  </QL>,
  may: (props) => <QL name="qualifiers">
    {(props.cap) ? "May" : "may"}
  </QL>,
  might: (props) => <QL name="qualifiers">
    {(props.cap) ? "Might" : "might"}
  </QL>,
  possibly: (props) => <QL name="qualifiers">
    {(props.cap) ? "Possibly" : "possibly"}
  </QL>,
  basically: (props) => <QL name="qualifiers">
    {(props.cap) ? "Basically" : "basically"}
  </QL>,
  perhaps: (props) => <QL name="qualifiers">
    {(props.cap) ? "Perhaps" : "perhaps"}
  </QL>,
  likely: (props) => <QL name="qualifiers">
    {(props.cap) ? "Likely" : "likely"}
  </QL>,
};

export const the_key_is_imagination = <p>The <PL name="hard">key</PL> is to <LRL>understand</LRL> that <i><WName /> is an</i> <LRL name="imagination">imaginative</LRL> <i>exercise</i>; our (<QL name="unity.answer">collective</QL>: as a <LRL name="gaia">planet</LRL> and <OML name="aliens">potentially</OML> <PL name="all.scale">off it</PL>) <QL>salvation</QL> is the <LRL name="i.limit">ability</LRL> to <i><AL name="notnew.lennon">imagine</AL> a less <AL>dysfunctional</AL> <LRL name="different">status quo</LRL></i> and the <NW>agency</NW> to <PL name="privacy">enact it</PL> on our <NW>level</NW> of ‘<LRL name="i.">reality</LRL>’. That – and <QL name="whatcanido">education</QL>: spreading this thing (like a <OML name="ideas">virus</OML>, or like a <AL>religion</AL> if you <PL name="endsjustifymeans">have to</PL>) – is the way to <LRL name="imagination.power">build</LRL> that <QL name="unity.answer">single</QL> <AL name="Framework.works">coherent</AL> <QL>body</QL> of <QL>conscousness</QL> that <LRL name="scope">our universe</LRL> (<Qual.probably />, as <OML name="alone">far</OML> as <AL name="self">we</AL> <QL name="pg.mind.">know</QL> <LRL name="gaiacentrism">on Earth</LRL>) doesn’t <LRL name="reactivity">yet have</LRL>, but <QL name="urgency">desperately need</QL>.</p>;

export const get_out_there = <p><QL name="whatcanido">Get out there</QL> and let every ‘<LRL name="them">other</LRL>’ know that you are a <WName ist /> – and won’t <NW name="agency">stand</NW> to keep <QL name="life">living</QL> (in <i>this</i> <QL name="life.meaning">life</QL>, at <i>this</i> <NW>level</NW>) in ‘<AL>Hell</AL>’ and <LRL>waiting</LRL> for some <NW word="ig">individually-blissful</NW> <OML>afterlife</OML> or <LRL name="scope">outside</LRL> ‘<QL>salvation</QL>’ from some <NW word="mythology">mythical</NW> <OML>God</OML>. <WName /> is both a <AL>framework</AL> and a <PL name="comprehensive">whole</PL> <LRL name="different">new world order</LRL>. Buck your <PL name="ick">fear</PL>, and <AL name="notnew.lennon">join</AL>, and <PL name="courage">dare</PL> to <LRL>imagine</LRL>.</p>;

export const but_your_choice = <p>But <NW>POCs</NW> have <OML>free will</OML> (<Qual.probably />), no? So it’s your <NW word="agency">choice</NW>. <AL name="sleeping">Wake up</AL> and <AL name="notnew.lennon">join</AL> <WName />, or <PL name="denial">deny this</PL> in order to keep <LRL name="notions">believing</LRL> in your <PL name="uncomfortable">comforting</PL> <NW>mythology</NW> of the <OML name="god">external God</OML> and <PL>individual</PL> <OML>soul</OML> (or the <PL name="neoliberal">neoliberal</PL> <i><OML>Earth-soul</OML></i>), <OML name="ideas">believing</OML> that <LRL name="usandthem">you are the ‘us’</LRL> and the rest of <LRL name="scope">the universe</LRL> (be it <PL>AI</PL>, those <NW>POCs</NW> who don’t <LRL name="communism">share</LRL> your <AL>religion</AL>, those who <QL name="polygnosticity">want to rob you</QL> of your <i><PL>privacy</PL></i>, <LRL name="superpositions">whatever</LRL>) is <QL name="unity.answer">the ‘them’</QL>. Do that, and either <LRL name="gaia">Planet Earth</LRL> – as a <PL name="all.">scale-invariant</PL> ‘<QL name="body.">cell</QL>’ in the (<OML name="wrong">presumably</OML>-<QL name="unity">universal</QL>) <QL>conscious</QL> ‘<QL>body</QL>’ – goes <i><OML>kaput</OML></i>, or the <i><AL name="notnew.plato.kings">thinking</AL></i> (i.e., non-<AL>sleeping</AL>, non-<LRL>reactive</LRL>) <AL name="dysfunctional.fragments">parts</AL> of this <LRL name="gaia">living</LRL> and <QL name="evolution">evolving</QL> <LRL name="gaiacentrism">planet</LRL> who are <i>using</i> their <QL>conscious</QL> <NW>agency</NW> will <PL>just</PL> build the <Eon Eon /> <PL name="fungibility">without you</PL>.</p>;

export const best_presumption = <span>Of course <NW>synecdocentrism</NW> this <QL name="qualifiers">might</QL> <i>not</i> be the ultimate (<AL name="notnew.plato.neoplatonism">Neoplatonic-style</AL>) <i>truth</i>. There’s <OML>nihilism</OML>, and the possibility that <NW>synecdocentric</NW> <QL>Weltanschauung</QL> is just <i>all</i> <OML>wrong</OML>. But discounting <i>those</i> <LRL name="superpositions">possibilities</LRL> (and taking this <AL>framework</AL> as a decent <AL name="systems">systems analysis</AL> of <LRL name="gaia">our conscious planet</LRL>, using only what we have <AL name="framework.puzzle">already discovered</AL>) the truth is that <QL name="anthropocentrism">humanity</QL> has not had something quite this <PL>comprehensive</PL> (but still <i><AL name="framework.">working</AL></i>) before.</span>;

export const we_need_a_shift = <span><NW>Synecdocentrism</NW> first necessitates an entire <LRL>shift</LRL> in <LRL name="gaia hypothesis">Earth’s collective thinking</LRL> (about <AL name="self">Earth-<i>self</i></AL>).</span>;

// This is the passage above with an addendum on the way 'out':
export const imagine_a_shift = <p>{we_need_a_shift} And the only way to get there – from our <i>current</i> (mid-2020’s: entirely <NW>polygnostic</NW>, mostly <LRL name="gaiacentrism">Earth-based (Earth-<i>trapped</i>)</LRL>, and <AL>dysfunctional</AL>) position in spacetime – will be through the <i><QL name="salvation">imagination</QL></i>. (Cf. the ‘<AL>bullet points</AL>’.)</p>;

export const money_system = <span>The <NW>money system</NW> is a <NW>fictive level</NW> of its own: at this point <NW>money</NW> has no <i>real</i> ‘value’ anymore; it’s just a quantifier (of <PL name="equality">inequality</PL>) that will cause some ‘<LRL name="them">other</LRL>’ to do something ‘<QL name="hivemind.altruism">altruistically</QL>’ for <i><AL name="self">your</AL></i> <NW>POC</NW> because <i><LRL name="them">they</LRL></i> want <i>your</i> (<NW word="levels">fictive</NW>) ‘value’ for their <i><AL name="notnew.lennon.">own</AL></i>. But it’s all <AL name="mirrors">smoke and mirrors</AL>; and the whole thing will fall apart as soon as the bulk of <QL>humanity</QL> <AL name="sleepig">wakes up</AL> and learns that the <QL name="life.meaning">meaning of life</QL> is <i>not</i> to collect <PL name="individuality">personal</PL> ‘value’.</span>;

export function getSubpaths(path, check) {
  const splitpath = path.split('/');
  for (var i=0;i<splitpath.length;i++) {
    if (splitpath[i] == check) {
      break;
    }
  }
  if (i+1<splitpath.length && splitpath[i+1].length > 0) {
    return splitpath.slice(i+1, splitpath.length);
  }
  else return [];
}

export function rand(start=0, end=1, whole=true) {
  if (start == end) {return start;}
  let num = (Math.random() * (end-start)) + start;
  return (whole) ? Math.round(num) : num;
}

// For use to generate an obviously prime number for examples on the site.
const primes = [7,11,13,17,19,23,27];
export function PrimeNumber(props) {
  return primes[rand(0, primes.length-1, true)];
}

export function shuffle(array, useCopy=true) {

  // To avoid modifying an external array
  // Not sure it's necessary
  if (useCopy) {
    let copy = [...array];
    array = copy;
  }

  let currentIndex = array.length;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    let randomIndex = rand(0, currentIndex-1, true);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

// Shuffle an array but first pick a 'top' element.
export function shuffleSettingTop(array, topNum) {
  let copy = [...array];
  // Remove the element chosen to be on top:
  copy.splice(topNum, 1);
  // Shuffle the rest:
  copy = shuffle(copy, false);
  // Push the 'top' element to spot 0
  // (from the original, unchanged array):
  copy.unshift(array[topNum]);
  // Return a copy (leaving the original unchanged)
  return copy;
}

// Make a random 'name'.
const sillyNames = ['Joe', 'Suzy', 'Mohammed', 'Fatima', 'Raj', 'Deepa', 'Ada', 'Ivan', 'Giorgio', 'Dmitri', 'Stephania', 'Beatrix', 'Li', 'Yong', 'Yoko'];
// Allow different names for different purposes
// (each of which will persist until the page is reloaded)
var chosenSillyName = {};
export function SillyName(props) {
console.log(props);
  let id = props.id;
  if (!id) {
    id = "std";
  }
  if (!chosenSillyName[id]) {
    chosenSillyName[id] = sillyNames[rand(0, sillyNames.length-1, true)];
  }
  //if (props.persist) {
    return chosenSillyName[id];
  //}
//  else return sillyNames[rand(0, sillyNames.length, true)];
}

// This can be used as a component
// or just to randomly pick something from an array
// (if it's passed to 'Rando' in an object as 'options'):
export const Rando = ({options}) => {
  if (!options || !options.length) {
    return "[Error: bad options array inputted]";
  }
  return options[rand(0, options.length-1)];
}

// Make up a silly planet name when this is first called
// and then keep it throughout.
var sillyPlanetName = null;
function makeSillyPlanetName() {
  const beginning = Rando({
    options: ['G', 'B', 'K', 'S', 'P', 'C', 'F', 'A', 'I', 'U', 'Qu']
  });
  const second = Rando({
    options: [
      Rando({
        options: ['oqu', 'equ', 'ex', 'ox']
      }),
      Rando({
        options: ['r', 'l', 'h', 'n', 'v', 'w']
      }),
    ]
  });
  const vowel = Rando({
    options: ['a', 'e', 'i', 'o', 'u', 'ou', 'ai', 'ea']
  });
  const end = Rando({
    options: ['f', 'n', 'l', 'm', 'z', 'g', 'j']
  });
  return beginning + second + vowel + end + end;

}

// Description of the alien
// that will stay until the page reloads
// (i.e. persist, as our individuality most likely does not)
var alienIndividualColour = null;
var alienIndividualShape = null;
function determineAlienIndividualColour() {
  alienIndividualColour = Rando({options: 
    ["red", "blue", "orange", "silver", "turquoise"]
  });
  return alienIndividualColour
}
function determineAlienIndividualShape() {
  alienIndividualShape = Rando({options:
    ["blobby", "spiky", "bouncy"]
  });
  return alienIndividualShape;
}

export const Planet = {
  Name: (props) => {
    if (!sillyPlanetName) {sillyPlanetName = makeSillyPlanetName();}
    return sillyPlanetName;
  },
  Colour: (props) => {
    if (!alienIndividualColour) {
      alienIndividualColour = determineAlienIndividualColour();
    }
    return alienIndividualColour;
  },
  Shape: (props) => {
    if (!alienIndividualShape) {
      alienIndividualShape = determineAlienIndividualShape();
    }
    return alienIndividualShape;
  },
  AlienDescription: (props) => {
    return [<Planet.Colour />, " ", <Planet.Shape />];
  },
  Aliens: (props) => {
    return [<Planet.AlienDescription />, " ", <OML>aliens</OML>];
  }
}

// Switch up examples (randomise the order)
// You can pick a 'middle' joiner.
export function Switcheroo({middle, values, omit}) {
  if (!values || !values.length) {
    return "Switcheroo component error: bad 'values' array.";
  }
  let scrambled = shuffle(values);
  // Throw away the requested number of values:
  if (omit && Number(omit) > 0) {
    for (let leftToOmit=Number(omit);leftToOmit>0;leftToOmit--) {
      scrambled.pop();
    }
  }
  let display = [];
  for (let i=0;i<scrambled.length;i++) {
    display.push(scrambled[i]);
    if (middle && i+1<scrambled.length) {
      display.push(middle);
    }
  }
  return display;
}

export function SwearMaybe(props) {
  let threshold = (props && props.threshold) ? props.threshold : 0.5;
  if (Math.random() >= threshold) {return null;}
  let options = (props && props.options) ? props.options :
    ["[bleeping]", "bloody", "damn", "stupid", "crazy"];
  if (props.noOML) {return <Rando options={options} />;}
  return <OML name="offensive">
    <Rando options={options} />
  </OML>;
}

// A component that cycles through values given to it,
// changing AS the reader is reading.
// Subsequent changes are after a random tme period.
// Max and min times can be given, or a range of 500ms-1500ms is used.
export function Cycle(props) {
  const [values, setValues] = useState(props.values);
  const [cur, setCur] = useState(rand(0, values.length-1, true));
  if (!values || !values.length) {
    return "[No values array given to 'Cycle' prop.]";
  }
  const next = (props.inOrder) ? 
    (cur + 1) % values.length : rand(0, values.length-1, true);
  const min = (props.min) ? props.min : 500;
  const max = (props.max) ? props.max : 1500;
  const wait = rand(min, max, true);
  window.setTimeout(()=>setCur(next), wait);
  return values[cur];
}

// Outputs whole numbers based on a mix of rand functions and time
// As parameters pass
export function randWithTime({
  start=0,
  limit=null,
  monthMultiplier=0,
  dateMultiplier=0,
  dayMultiplier=0,
  hourMultiplier=0,
  minuteMultiplier=0,
  secondMultiplier=0,
  randomness=0,
  startValue=0,
}) {
  if (!limit) {
    console.log("Error: no 'limit' given to 'randWithTime' function.");
    return 0;
  }
  // If less than 'randomness' threshold do not use the current time:
  if (Math.random() < randomness) {
    return rand(start, limit, true);
  }
  // Current time:
  let d = new Date();
  let total = startValue;
  if (monthMultiplier) {total += d.getMonth()*monthMultiplier;}
  if (dateMultiplier) {total += d.getDate()*dateMultiplier;}
  if (dayMultiplier) {total += d.getDay()*dayMultiplier;}
  if (hourMultiplier) {total += d.getHours()*hourMultiplier;}
  if (minuteMultiplier) {total += d.getMinutes()*minuteMultiplier;}
  if (secondMultiplier) {total += d.getSeconds()*secondMultiplier;}
  return total % limit;
}

