import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, we_need_a_shift} from '../../common';

// ‘’ … –

export const shift = {
  name: "shift",
  prelude: <span>
    The only rule: absolutely no <NW>conservative thinking</NW> allowed.
  </span>,
  header: <span>
    This necessitates a complete (Earth-wide) <i>shift in thinking</i>.
  </span>,
  outside: <span>
    Which <LRL name="gaia hypothesis">Earth-wide</LRL> shift is easier <i><QL name="salvation">imagined</QL></i> than accomplished. <PL name="hard">A <i>lot</i></PL> easier imagined than actually accomplished.
  </span>,
  preview: <div className="long-read-preview">
    <p>It’s weird, <PL>uncomfortable</PL>, <LRL>different</LRL>. <PL name="sweeping">Many</PL> human ‘individuals’ are expected to just flat-out <i>disagree with</i> (reject, or <i><PL name="denial">deny</PL></i>) this <LRL name="order">new world order</LRL> – probably due to its rejection of just about every human <NW>mythology</NW> (such as that of the <PL name="individuality">individual ‘soul’</PL>). {we_need_a_shift}</p>
    <p>And that’s just the beginning…</p>
    <p>[Under construction]</p>
  </div>,
  text: <div className="long-read-text">
  </div>,
};
