import React from 'react';
import WName, {WArticle} from '../WName';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, Qual, SillyName, Rando} from '../common';

export const illusions = [

// If 'rights' were real
{
  name: "rights",
  alternatives: "right",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if ‘rights’ were real?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Well, this whole <NW>synecdocentric</NW> <QL>Weltanschauung</QL> could be <OML>wrong</OML> (and there <i>is</i>, in fact, an <OML name="god">external ‘God’</OML>… but, short of that, there’s about no chance of that. Becuase this is <LRL name="gaiacentrism">Earth</LRL>, and what you see is what you get. No one has definitively proven the existence of an <QL name="roadblocks.">external God</QL> to a <NW word="synecdocentrism">synecdocentrist</NW> yet.</p>
    <p>Learn what the <AL name="fromework.works">working</AL> <AL>framework</AL> means when it says ‘<NW>illusions</NW>’.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// Maybe 'Forms' DO exist.
{
  name: "forms",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if ‘Forms’ were <i>real</i>?
  </span>,
  outside: <span>
    <i>Most</i> of <AL name="notnew.">Plato</AL>’s idea mesh well with <NW>synecdocentrism</NW>. This one, however – depending on how you view the nature of ‘<LRL>reality</LRL>’ – may not.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Once you admit that there’s an <QL name="objectivism">objective reality</QL> (outside each <NW>POC</NW>’s <QL name="polygnosticity.subjectivity">perception</QL>), it only seems natural (as per <NW word="occam">Occam’s razor</NW>: most simple) to presume that humanity’s agreed-upon perception of objects (say, roses) and their attributes (say, ‘beautiful’ or ‘good-smelling’) are also <i>real</i>, no?</p>
    <p>Well, either we take a sort of <QL name="democracy">democratic</QL> approach and assume that if the <i>majority</i> of humanity agrees on something (e.g., that this is a ‘rose’, and that roses smell ‘good’), it must be ‘real’… or we have to dig down really, <i>really</i> deep into the nature of ‘<LRL>reality</LRL>’, stop <AL name="illusions">arguing over (surface-‘<NW>level</NW>’) illusions</AL>, deal with a <LRL name="superpositions">cr*p-ton of uncertainty</LRL> (because it’s not yet <i>possible</i> for our <AL name="dysfunctional">messy</AL> and scattered and <NW>polygnostic</NW> conscious ‘<QL>body</QL>’ to definitively <i>prove</i> the <QL name="atheism">non-existence</QL> of a <NW word="mythology">mythical</NW> <QL>external god</QL>) – and <QL name="qualifiers">qualify</QL> a bunch of stuff for this reason.</p>
    <p><QL name="salvation">Imagining</QL> both scenarios through to their most probable ends, the one in which <QL name="anthropocentrism">humans</QL> are nothing more than humans doesn’t actually <AL name="framework.">work</AL> as well as the initially-maybe-slightly-more-complicated supposition that perception is just a symptom of conscious <NW>polygnosticity</NW>.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// If there's an external God.
{
  name: "god",
  alternatives: "external god",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if there <i>were</i> an external God?
  </span>,
  outside: <span>
    Then probably every <NW word="synecdocentrism">synecdocentrist</NW> is going to ‘Hell’ (and an <NW>atomocentric</NW> one, not <AL name="hell">the synecdocentric one</AL> that we’re already in).
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Can we ever definitively <i>disprove</i> the notion of an <QL>external God</QL>? No, we can’t.</p>
    <p>Can we ever definitively prove that this whole world we perceive isn’t just a <OML>story</OML> told in the <NW word="atomocentric">atomocentrist</NW>’s ‘Heaven’ by one (winged) ‘angel’ to another to <E1 /> (because their <NW word="poc">POCs</NW>, being separate ‘<OML name="soul">souls</OML>’, will be eternally <NW>heterognostic</NW>) how badly <QL name="anthropocentrism">humanity</QL> (who were made in the ‘image’ of this external God, but who are decidedly not <i><AL name="dysfunctional.fragments">pieces</AL></i> of ‘Him’) might screw it all up if we lose <i>faith</i>? No, we can’t.</p>
    <p>All we’ve got (as a <LRL name="gaia hypothesis">now-conscious planet</LRL>, if a <AL name="sleeping">slowly-waking</AL> one at that) is <AL name="fields.">science</AL>, <AL name="fields.">logic</AL>, and <NW word="occam">Occam’s razor</NW>.</p>
  </div>, 
  // Not sure we’ll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// Reincarnation?
{
  name: "reincarnation",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if reincarnation actually <i>happened</i>?
  </span>,
  outside: <span>
    Does <NW>synecdocentrism</NW> have to be opposed to <AL name="notnew.buddha">buddhism</AL>?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>It would be an interesting variation of the notion of the individual <OML>soul</OML>: the individual ‘soul’ <i>would</i> exist and it <i>would</i> persist after an individual’s body expired (at the individual’s ‘death’) to get re-housed in a <i>different</i> body, possibly of a different <QL name="anthropocentrism">species</QL>, possibly on a different (<NW>atomocentric</NW>) <i><LRL name="gaiacentrism">planet</LRL></i>. Now, is that <PL name="individuality">individualistic</PL>? Actually, all of that is well outside the <LRL>scope</LRL> of the <QL>Weltanschauung</QL> – and doesn’t affect it one bit.</p>
    <p>Basically, using the <NW>synecdocentric</NW> <NW home>terminology</NW>, an <AL name="fields.cs">instance of consciousness</AL> (<NW>POC</NW>) would manifest as separate from the whole but – as opposed to the <NW>synecdocentric</NW> purview, in which yor perception of ‘you’ as separate is <PL name="individuality">only an emergent symptom</PL> of the physical (biological) body that houses your consciousness – your individuality <i>does</i> exist on some lower ‘<NW>level</NW>’ – though this isn’t actually the <i>lowest</i> ‘<NW>level</NW>’, and so as a discrete ‘self’, torn off from the whole (e.g., in <NW>synecdocentric</NW> ‘<AL>Hell</AL>’) ‘you’ are going to keep coming back until you ‘<NW word="enlightenment">enlighten</NW>’ <AL name="self">yourself</AL>, realise that your <PL>individuality</PL> is just an <NW>illusion</NW> (and the other ‘<AL>bullet points</AL>’) and stop <AL name="illusions">arguing over said illusions</AL>, to instead <LRL>shift</LRL> to a <QL name="unity">unity consciousness</QL> <AL name="framework">mindset</AL> (even if <PL name="invisible">you can’t see the damn thing</PL>) wherein – and only then will your repeating <NW>POC</NW> escape the cycle of death and rebirth and reunite with ‘<NW>God</NW>’ in ‘<AL name="notnew.buddha">Nirvana</AL>’.</p>
    <p>Who’s to say if it’s really a <i>helpful</i> presumption to make – because we (as humanity, as a <LRL name="gaia">whole living planet</LRL>) need to correct course <i><QL name="urgency">now</QL></i>, and not wait for our <i>deaths as individuals</i> in order to do so. But if it works best (for your <NW>POC</NW>) to imagine that there <i>is</i> some sort of individual ‘soul’, this is about the only way (in the <NW>synecdocentric</NW> <AL>framework</AL>) that you can have one: as an intermediate ‘<NW>level</NW>’ between <AL name="framework.works">the observed</AL> and the <AL name="notnew.plato.neoplatonism">Neoplatonic-style</AL> (bottom-line) <i>truth</i>.</p>
    <p>Which might give <i>you</i> not just one lifetime, but several to get past your <i>own</i> <NW>individual greed</NW>… but <LRL name="gaia">our living planet</LRL> is still dying, and the <NW>MNS system</NW> is still what’s holding our ‘<QL name="body.">cells</QL>’ apart. <QL name="whatcanido">What</QL> are you going to do <i><QL name="whatcanido">now</QL></i>?</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// if there's an individual soul
{
  name: "soul",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if there <i>were</i> an individual ‘soul’?
  </span>,
  outside: <span>
    It’s not <i>quite</i> the same as <OML name="wrong">questioning</OML> <QL name="unity">unity consciousness</QL>.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>There are several ways in which there (<QL name="qualifiers">theoretically</QL>) could be an individual ‘soul’, and it’s important (while in the <LRL name="shift">mindset</LRL> of leaving ‘<LRL name="motto">no stone unturned</LRL>’) to account for them all:</p>
    <ol>
      <li>There is also an <OML name="god">external ‘God’</OML>.</li>
      <li><PL>Individuality</PL> is <i>not</i> an <NW>illusion</NW> – which makes this <QL name="unity">unity-consciousness</QL>-based <QL>Weltanschauung</QL> entirely <OML>wrong</OML>.</li>
      <li>Or there is a ‘soul’ at some <i>intermediate ‘<NW>level</NW>’</i> – because if ‘<NW>levels</NW>’ <i><OML name="levels">exist</OML></i>, why not <LRL name="scope">more</LRL> than just the observable and the ‘true’ reality; cf. <AL name="notnew.plato.">Neoplatonism</AL> – but at the bottom ‘<NW>level</NW>’ there is still no <PL>individuality</PL> (but the <NW>illusion</NW> of it).</li>
    </ol>
    <p>The only one that would not necessitate any changes to the current <AL>framework</AL> of <NW>synecdocentrism</NW> is the third – because this <QL>Weltanschauung</QL>, as <PL name="comprehensive">all-encompassing</PL> as it may <i>seem</i>, actually limits its <LRL>scope</LRL> to the observable (and any <NW>logical induction</NW> that can be made from the observed). In other words, if there <i>is</i> <OML>reincarnation</OML> – and the <i>same</i> <NW word="poc">POCs</NW> come back time and again until they get to ‘<AL name="notnew.buddhism">Nirvana</AL>’ – it changes synecdocentrism not at all.</p>
    <p>If your <NW>POC</NW> can’t understand <i>that</i>, you still don’t <LRL>understand</LRL> the <NW>synecdocentric</NW> <AL name="framwork">mindset</AL>.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// if there's an 'afterlife'
{
  name: "afterlife",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if there’s an afterlife?
  </span>,
  outside: <span>
    <OML name="offensive">Mamma</OML>, I don’t wanna <i>cease to exist</i>!<sup>†</sup>
  </span>,
  headerFootnote: <p>
    † If this were <WArticle /> ‘<WName ic /> story’ (in which <LRL name="them">characters</LRL> don’t act <i><PL name="all.level">realistically</PL></i> as much as do absurd things to make a <i><QL name="salvation">point</QL></i> to the reader), then the response of ‘Mamma’ would <Qual.probably /> be something like this: ‘You don’t “<LRL name="i.real">exist</LRL>” in the first place, <Rando options={["daughter", "son", "child"]} />. Your perception of your own <PL>individuality</PL> is only an <NW>illusion</NW> brought about by your <LRL name="prison">imprisonment"</LRL> in a <AL name="handicap">human body</AL>, but you (just like grandpa, just like me) are <PL name="reductionist">only</PL> <AL name="dysfunctional.fragments">a wee part</AL> of the <QL>conscious</QL> <OML>universe-soul</OML>, which we call “<NW>God</NW>”.’
  </p>,
  preview: <div className="or-maybe-preview">
    <p>If there were some persistence of the <PL>individual</PL> beyond death, that update to the <WName ic /> <LRL name="scope">world-understanding</LRL> (and if not to the <WName ic /> <LRL>understanding</LRL> on <i><LRL name="scope">this</LRL></i> <NW>level</NW>, then at least to that of the <PL>individual</PL>who so <i>persisted</i>) would <i>not</i> – actually (necessarily) – disprove <WName />. This would merely move the persisted <PL>individual</PL> one <NW>level</NW> down the ladder: getting <i>closer</i> to the ‘end’, but with no guarantees that consciousness (on whichever level the <NW>POC</NW> now stands</p>
    <p>This is what <WName /> means by ‘<PL name="all.level">level-invariance</PL>’.</p>
  </div>, 
  text: <div className="or-maybe-text">
    <p>If this is all too <LRL name="different">weird</LRL> for you, try this <LRL>thought-experiment</LRL>:</p>
    <ol>
      <li>Pick a character from a story you know who <i>died</i> (or otherwise permanently left their <NW>fictive level</NW> of <LRL name="i.">reality</LRL>).</li>
      <li>Now ‘resurrect’ that character into your <i>own</i> story (e.g., <LRL>imagine</LRL> that <PL name="individuality">character</PL> <Rando options={[<span><Rando options={["slowly", "groggily", ""]} /> waking up in a bed over which you – as a <Rando options={["nurse", "caretaker", "friend"]} /> or some such – are <Rando options={["patiently", "carefully", "", "", ""]} /> attending</span>, <span>emerges (looking confused) from a <Rando options={["river", "lake", "lagoon-"]} />side cave by which you are <Rando options={["washing your clothes and haning them up to dry", "exercising", "painting a watercolour"]} /></span>]} />).</li>
      <ol type="a">
        <li>Obviously you have to imagine that you and the character speak the same language; if your character had lived in Ancient Grece, either imagine that you can now speak flawless Ancient Greek or they can now speak flawless English. Please do not let <NW>explainage</NW> be a <PL name="explainage">barrier</PL> in this thought-experiment.</li>
      </ol>
      <li><b>This is important</b>: imagine them onto <i>your</i> <NW>level</NW> of <LRL name="i.">reality</LRL>.</li>
      <ol type="a">
        <li>Now obviously this is in your <LRL>imagination</LRL> – so the actual <NW>level</NW> is one <i>above</i> you – but you can <LRL>imagine</LRL> an exact replica of your reality that is indistinguishable except for the presence of this character, and the facts that (if you were folloeing this though experiment literally, word for word) you appear to frequent whatever place it had you at engaged in whatever activity this ting had you doing.</li>
      </ol>        
      <li>Ask a few questions, and see how the character responds. (You can ask, for example, how it felt to die, or if they know what time it is, or if they have any idea that their entire past <QL>life</QL> was <OML name="story">inside of a story</OML>.)</li>
      <ol type="a">
        <li>You can write this down, or just do it in your <LRL>imagination</LRL>.</li>
      </ol>
    </ol>
    <p>And <i>voila</i>: you have just ‘resurrected’ some <PL>individual</PL> from the dead.</p>
    <p>Doesn’t mean that the <LRL name="i.real">world</LRL> into which that <PL>individual</PL> was <i>resurrected</i> (i.e., your <LRL>imagination</LRL>) is not as <NW word="illusions">illusory</NW> as the (fictive) one they inhabited before. Doesn’t mean there aren’t still <OML name="levels">potentially many more</OML> ‘<NW>levels</NW>’ before the bottom – and at some point below us (below however many <NW>levels</NW> through which your perception of <PL>individuality</PL> persists) you(r <NW>POC</NW>), the character you copied, the <i>original</i> version of that character, and the (<Qual.probably /> <AL name="handicap">human</AL>) <i>author</i> are not all just <QL name="unity.1">one</QL>.</p>
  <p>This is the <PL name="all.level">level-invariance</PL> of <WName />.<sup>‡</sup></p>
  </div>, 
  bottomFootnote: <p>
    ‡ And if you(r <NW>POC</NW>) actually <PL name="hard">managed</PL> to follow and <LRL>understand</LRL> that, you(r <NW>POC</NW>) may be well on the way to (<PL name="individuality">personally</PL>) making the <LRL name="shift">jump</LRL> a <AL name="framework.">working</AL>, <WName ic /> world-<LRL>understanding</LRL>.
  </p>,
  // There should not be a 'sublist'
},

// What if Earth had a soul?
{
  name: "earth-soul",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if <i>Planet Earth</i> had a ‘soul’?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>This very easily (when viewed <NW word="synecdocentric">synecdocentriclly</NW>) <PL name="reductionist">boils down</PL> to two <LRL name="superpositions">possibilities</LRL>:</p>
    <ol type="1">
      <li>The underlying <NW>synecdocentric</NW> concept of <QL>unity</QL> is <OML>wrong</OML>, and while there may not be <PL>individuality</PL> on the <NW>level</NW> of <QL>human</QL> ‘beings’, there most definitely <i>is</i> an individual <OML>soul</OML> on the <i>planetary</i> <NW>level</NW>; there are now several possibilities:</li>
      <ol type="a">
        <li>On <LRL name="gaiacentrism">Earth</LRL>, all of <AL name="fields.">biology</AL> still must ‘<NW>coalesce</NW>’ into a super-powerful <NW>monognostic</NW> <QL name="hivemind">hive mind</QL>… but then – rather than <AL name="notnew.lennon.war">come in ‘peace’</AL> (in a <QL name="unity">shared</QL> search for <LRL>understanding</LRL> at the <NW>level</NW> of our <i>universe</i>) – we start to duke it out with our <OML>alien</OML> neighbours in what’s essentially a <PL name="all.scale">galactic-sized version</PL> of <LRL name="gaiacentrism">Earth</LRL>’s <NW>Phanerozoic Eon</NW>; thinnk ‘Star Wars’ or any other <NW>atomocentric</NW> <i><QL>story</QL></i> you might have ever seen.</li>
        <li>Or, actually, <LRL name="gaiacentrism">Earth</LRL> is fine as it is, and even if we are <OML>alone</OML> we do <i>not</i> need to get off the planet. (<LRL name="motto">Maybe</LRL> we’re just <LRL>waiting</LRL> for the coming of <OML>God</OML>.) In the <LRL name="waiting">mean time</LRL>, we just need to not <i><OML name="kaput">kill</OML></i> <LRL name="gaia">the planet</LRL> – which means <NW word="conservatism">thinking conservatively</NW> and <i>not</i> destroying the <NW>Phanerozoic</NW> (and ‘Proterozoic’, ‘Archaeozoic’) Eon’s ‘<PL>nature</PL>’ or otherwise <QL name="evolution">evolving</QL> beyond the <AL name="fields.biology">biological</AL>. (And <NW>synecdocentrism</NW> and the ‘<NW>Mechanozoic Eon</NW>’ can go to <AL>Hell</AL>; <LRL name="gaia">Earth</LRL> is <i>quite</i> fine <AL>sleeping</AL> under the covers of the <NW>MNS system</NW>, its <AL>illusions</AL>, and the promise of the <PL>equality</PL> of the immortal <OML>soul</OML>. So thanks but <PL name="ick">no thanks</PL>.)</li>
      </ol>
      <li>Or – to avoid just ‘<QL name="g.wheel">re-inventing the wheel</QL>’ – we assume that the <PL name="comprehensive.scale">scale-invariance</PL> (and <PL name="all.level">‘level’-invariance</PL>) of the <NW>synecdocentric</NW> <QL>Weltanschauung</QL> applies to the notion of the individual ‘<OML>soul</OML>’ as well: so if <PL>individuality</PL> is just an <NW>illusion</NW> on a <QL>human</QL> <NW>level</NW>, it will be so on a <LRL name="gaiacentrism">plantary</LRL> <NW>level</NW>, a star-system <NW>level</NW>, a <i>galactic</i> <NW>level</NW>… or, in short, anything that’s less than the <QL name="unity">whole</QL> of the <LRL name="scope">observable universe</LRL> (and <OML name="story">may even</OML> stretch <PL name="all.level">beyond</PL> that).</li>
    </ol>
    <p>As a <AL name="fields.">logic</AL>-based <AL>framework</AL>, let’s ask <NW>Occam</NW> about this.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if the universe had a soul?
{
  name: "universe-soul",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if <i>the universe</i> had a ‘soul’?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>If it did, the ‘universe-soul’ would probably be <QL name="unity">unity consciousness</QL> and everything still <AL name="framework.">works</AL> fine… unless you are positing intermediate <NW>levels</NW> <i><OML name="forms">between</OML></i> our <NW>POCs</NW> and the <AL name="notnew.plato.neoplatonism">Neoplatonic</AL> ‘<QL name="unity.1">One</QL>’, in which case you just have to presume that our entire universe is (on some <i>lower</i> <NW>level</NW>, i.e.: if we were all in a <OML>story</OML> the place where our universe would <i>not</i> be <QL name="unity.answer">unified</QL> with <PL name="comprehensive">everything</PL> is at the <NW>level</NW> of the <i>author</i>) essentially just a single <NW>POC</NW> (<NW>heterognostic</NW> from – on <i>that</i> <NW>level</NW> – any ‘<LRL name="them">others</LRL>’) and – (in whichever <AL name="container">body or form</AL> your <QL>consciousness</QL> on that <NW>level</NW> is housed) unless something on that lower <NW>level</NW> runs contrary to the <WName ic /> presumption of <QL name="unity">unity consciousness</QL> enough to prove it <OML>wrong</OML> –  you just treat your whole (now unified) universe as an <PL>individual</PL> at the lower <NW>level</NW> and <i>repeat</i>,<sup>†</sup> cf. ‘<QL name="life.meaning">What is the meaning of life?</QL>’</p>
  </div>,
  topFootnote: <p>
    † Yay, recursion. Who knew that <AL name="fields.cs">computer science</AL> would actually (one day) be good for something?
  </p> 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

];



