import React from 'react';
import ButtonBox from '../ButtonBox';
import {short_intro} from './problems/list';
import {Outlet} from 'react-router-dom';
import AllProblems from './problems/AllProblems';

export default function PotentialProblems() {
  return (
    <div className="page">
      {short_intro}
      <Outlet />
      <div className="backbuttons">
        <ButtonBox src={-1}>Back</ButtonBox>
        <ButtonBox src="/menu/problems">All potential problems</ButtonBox>
      </div>
    </div>
  );
};
