import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const gaiacentrism = {
  name: "gaiacentrism",
  prelude: <span>
    Why the fixation on <i>Earth</i>?
  </span>,
  header: <span>
    Isn’t this a bit ‘Gaia-centric’?
  </span>,
  outside: <span>
    Because there are <i>other</i> planets, other star systems… and even other <i>galaxies</i> that <QL name="qualifiers">could maybe</QL> have <OML name="aliens">some form of consciousness</OML> in them.
  </span>,
  preview: <div className="long-read-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: the <QL>Weltanschauung</QL> isn’t gaiacentric, but it’s <AL name="handicap">trapped</AL> on Earth right now (and in <QL name="anthopocentrism">humanity</QL>) so it has to deal with Earth <i><QL name="urgent">first</QL></i>. (It’s a matter of <i>triage</i>.)</p>
    <p>‘Gaia’ means the <i>Earth</i>: as a unified and living whole <AL>system</AL>.</p>
    <p><NW>Synecdocentrism</NW> accepts the ‘<LRL>Gaia hypothesis</LRL>’ (that <E2 suffix="s" /> Earth the whole as an ‘organism’). That said, this is a <AL>framework</AL> that exists outside of time and place – maybe not just a <QL>Weltanschauung</QL> as much as a <i>Universumschauung</i> (if you can excuse the attempt at a German <NW home>neologism</NW>) – and <NW>synecdocentrism</NW> is decidedly <i>not</i> ‘gaiacentric’.</p>
  </div>,
   text: <div className="long-read-text">
    <p>However, this is only the <i>dawn</i> (i.e., very <i>very</i> early days) of the <NW>Mechanozoic Eon</NW> – ‘Eomechanic’ could be the name for the <i><AL name="fields.geology">era</AL></i> – and (<NW word="god">inshallah</NW>) the <LRL>shift</LRL> from an unconscious ‘evolution’ of ‘<QL name="evolution.darwin">natural selection</QL>’ fuelled by competition (cf. the <NW>money system</NW>) to a future-based <QL>evolution</QL> fuelled by <i>collaboration</i>: in which we realise that this <QL name="unity.1">one</QL> universe is (<QL name="qualifiers">probably</QL>) all that we have, and the only way to stop <NW>entropy</NW> (assuming that’s our <OML>goal</OML>) is for every piece of conscious energy to work <i>together</i>.</p>
    <p>Hopefully <QL name="anthropocentrism">humanity</QL> is not <OML>alone</OML>. Hopefully <i><LRL name="gaia">Earth</LRL></i> is not <OML>alone</OML>. But we <QL name="gnosticity.agnostic">don’t know yet</QL> – and so the best way forward (for now) is just to assume the <i>simplest</i> <E2 word="explanation" /> (cf. <NW word="occam">Occam’s razor</NW>): that Earth the whole (like each <PL>individual</PL> ‘<QL name="body.">cell</QL>’) is <i>not</i> <PL>special</PL> – and that the universe is full of consciousness waiting to incorporate ours so that we can all one day be one universe and (<OML name="goal">probably</OML>) take control of this thing from an ‘unobserved’ state of ‘random’ fluctuations and indeterminate <LRL>superpositions</LRL>.</p>
    <p>So, from an Earth-based <PL name="individuality">human-individual</PL> perspective… Earth’s (<LRL name="gaia hypothesis">living</LRL>) consciousness FIRST has got to get <i>off</i> of Earth before it can even contemplate joining the rest of the (decidedly non-gaiacentric) universe.</p>
  </div>,
};
