import React from 'react';
import {Link} from 'react-router-dom';
import './ButtonBox.css';

class ButtonBox extends React.Component {
  render() {
    const {header, text, footer, src, style, className} = this.props;
    const addClassName = (className) ? " " + className : "";

    let button = null;
    if (src) {
      button = <Link to={src}>
        <button> {/* onClick={()=>{window.location.hash = "#top";}}>*/}
          {text || this.props.children}
        </button>
      </Link>;
    }
    else {
      button = <button onClick={this.props.onClick}>
        {text || this.props.children}
      </button>;
    }

    return <div className={"buttonbox"+addClassName} style={style}>
      {header && <div className="buttonbox-header">
        {header}
      </div>}
      {button}
      {footer && <div className="buttonbox-footer">
        {footer}
      </div>}
    </div>;
  }
}

export default ButtonBox;
