import React from 'react';
import Question from './Question';

export default function FAQList(props) {
  const {list} = props;
  if (!list) {return null;}
  return list.map((q, i) => <div key={i} className="faq-box">
    <hr />
    <Question data={q} listItem />
  </div>);
};
