import React from 'react';
//import {family} from './reads/family';
import {oneorzero} from './reads/oneorzero';
import {shift} from './reads/shift';
//import {trumpcard} from './reads/trumpcard';
//import {nobelprizes} from './reads/nobelprizes';
import {motto} from './reads/motto';
import {deep} from './reads/deep';
import {superpositions} from './reads/superpositions';
import {different} from './reads/different';
import {answers} from './reads/answers';
import {gaiacentrism} from './reads/gaiacentrism';
import {gaiahypothesis} from './reads/gaiahypothesis';
import {understand} from './reads/understand';
import {disagreement} from './reads/disagreement';
import {communism} from './reads/communism';
//import {systems} from './reads/systems';
import {sin} from './reads/sin';
import {scope} from './reads/scope';
import {enlightenment} from './reads/enlightenment';
import {game} from './reads/game';
import {reactivity} from './reads/reactivity';
import {imagination} from './reads/imagination';
import {waiting} from './reads/waiting';
import {symptoms} from './reads/symptoms';
import {notions} from './reads/notions';
import {blamegame} from './reads/blamegame';
import {usandthem} from './reads/usandthem';
import {eons} from './reads/eons';

// These are more detailed explainages of some of the key concepts:

export const long_reads = [
// The traditional nuclear family is finished.
//family,
// Why 1, why not ZERO? (Does ANYTHING really 'exist'?)
oneorzero,
// This necessitates an entire shift in thinking.
shift,
// Synecdocentrism trumps ALL 'opinion'.
//trumpcard,
// Nobel prizes
//nobelprizes,
// 'Leave no stone unturned'
motto,
// Superpositions
superpositions,
// Wherever 'you' are, this is one level deeper
deep,
// Much of humanity can be explained by reactivity vs. conscious AGENCY
reactivity,
// There are more answers than questions here
answers,
// Earth is a living 'organism'
gaiahypothesis,
// Is this 'gaiacentric'?
gaiacentrism,
// It's completely different (probably) from anything
different,
// What does it really mean to UNDERSTAND something?
understand,
// Disagreement is probably just because you don't understand.
disagreement,
// Synecdocentric 'communism' means that we share EVERYTHING
communism,
// What is 'sin' really, and does INTENT justify the means?
sin,
// What is the SCOPE of synecdocentrism?
scope,
// What is 'enlightenment'?
enlightenment,
// 'Life' is all a game we're playing, and we fight over the 'rules'.
game,
// The most critical 'puzzle piece' is probably the 'imagination'
//////////////////////////////////////////
// Need: how you can IMAGINE yourself lo level 'zero'.
//////////////////////////////////////////////////
imagination,
// The 'waiting game
waiting,
// This exists on a different 'level'.
notions,
// Treating the CAUSES and not just the symptoms
symptoms,
// The 'BLAME GAME'
blamegame,
// 'Us' and 'them' mentality
usandthem,
// Two simultaneous 'eons'
eons,
];


