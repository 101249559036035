import React from 'react';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual, Rando, Switcheroo} from '../../common';

// ‘’ … –

export const usandthem = {
  name: "us and them",
  alternatives: "usandthem,them,others,other",
  prelude: <span>
    Either you’re <i>with</i> us, or you’re <i>against</i> us.
  </span>,
  header: <span>
    Humans seem to need to see an ‘us’ and a ‘them’.
  </span>,
  outside: <span>
    And the only one that is <Qual.probably /> <i><LRL name="oneorzero">real</LRL></i> (at <NW>level</NW> ‘<LRL name="deep">zero</LRL>’) is the ‘us’.
  </span>,
  preview: <div className="long-read-preview">
    <p>It’s a mindset. Just like <NW>synecdocentrism</NW> is a <AL>mindset</AL>. If there is a ‘<QL name="life.">meaning</QL>’ to an <PL>individual</PL>’s life, it would be to <NW>transcend</NW> the ‘ego’ (‘<AL>self</AL>’) and <LRL>shift</LRL> to a pattern of thinking where <PL name="sweeping">most everything</PL> is <LRL name="superpositions">still undefined</LRL> exept for the rock at the base: the <OML name="wrong">presumption</OML> of <QL name="unity">unity consciousness</QL>: that the bottom-<NW>level</NW> ‘<LRL>answer</LRL>’ – <PL name="all.level">regardless</PL> of <OML name="reincarnation">what’s in-between</OML> – is ‘<QL name="unity.">1</QL>’.</p>

{/*
 But by saying, rather than ‘cogito ergo sum’, ‘cogitamus ergo sumus</i>’…


    <p>Defining a ‘them’ is to define your ‘self’ by exclusion:  </p>
    <p>So if this website ever sounds like it’s defining ‘<QL>humans</QL>’ or ‘<NW word="atomocentrism">atomocentrists</NW>’ as some <i>other</i>, that’s just a failure of <NW>explainage</NW>. The <NW>synecdocentric</NW> <QL>Weltanschauung</QL> doessn’t <i>permit</i> a ‘them’. There is only ‘us’.</p>
    <p>And that’s part of why the word ‘other’ often appears in quotes when discussing ‘other’ <NW>POCs</NW>: the ‘otherhood’ is only an <i>emergent phenomenon</i> from <QL>humanity’s</QL> biological <AL name="handicap">inability</AL> to directly <QL name="hivemind">share thoughts</QL>), and nothing that is ‘real’.


    <p>And no, it doesn’t matter how ‘inclusive’ you are, because if you don’t <LRL>understand</LRL> that <i>everyone</i>
However, being ‘inclusive’ in accepting every ‘<QL name="body.">cell</QL>’ this is very different from the <PL>neoliberal</PL> sense of the word. And being ‘inclusive’ past a certain point is actually <i>bad</i> (for the <LRL name="gaia">whole</LRL>)</p>
    <p>But what to do about the <NW word="conservatism">conservative thinkers</NW> and <NW word="ig">individually-greedy</NW> ‘<PL name="evil">bastards</PL>’ who don’t want to (and won’t) give up their <PL>privacy</PL>? (Other than, you know, <OML name="sterilisation">force-sterilising</OML> and <OML name="murder">murdering</OML> them.)</p>


Think of it like a <AL name="fields.">biological</AL> <i>virus</i>. A <QL>human</QL> <PL>individual</PL> can be ‘infected’ with a bad <i>idea</i>. Probably – when they are thus infected – you don’t want to be around them, or don’t want them to be around ‘other’ people. Ideas are exactly the same: they can ‘infect’ a 

Is it possible that a whole (<NW>monognostic</NW>) <QL name="hivemind">hive mind</QL> could be <NW word="ig">individually greedy</NW>?
<NW>Synecdocentrism</NW>
The breakdown is this:
<li>Consciousness exists. What it is we don’t (<i>yet</i>, with Eomechanic-Era science</AL>) but we also (for the moment, cf. <PL name="all.time">time-invariance</PL>) do not <i>need</i> to know.
*/}
  </div>,
//  text: <div className="long-read-text">
  //</div>,
};
