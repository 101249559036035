import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

// Superpositions SHOULD HAVE A LOT OF OML
export const superpositions = {
  name: "superpositions",
  alternatives: "superposition",
  prelude: <span>
    Warning: you need to know at least basic quantum mechanics.
  </span>,
  header: <span>
    View the unknown as a yet-undefined <i>superposition</i>.
  </span>,
  outside: <span>
    If you use the standpoint (from physics) of <i>observers</i>, then anything that your <NW>POC</NW> doesn’t expicitly <i>know</i> is just in a superposition of every possible state: not unknown but <i>undefined</i>; a whirlwind of probabilities.
  </span>,
  preview: <div className="long-read-preview">
    <p>So <i>sharing</i> information – particularly by <i>combining</i> <NW word="poc">POCs</NW>: first into <QL name="hivemind">hive minds</QL> and eventually in <LRL name="geocentrism">Earth’s</LRL> ‘<NW>coalescence</NW>’ – is a way to begin to <i>collapse</i> the massive and diverse wave function that is our universe.</p>
    <p>This ties into <NW>entropy</NW>, and maybe (eventually) making a universe-wide entropy reversal. (See <NW word="heatdeath">the eventual heat death of the universe</NW>.)</p>
    <p>[The rest is under construction]</p>
  </div>,
  text: <div className="long-read-text">
  </div>,
};
