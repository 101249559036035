import React from 'react';
import WName, {WArticle} from '../WName';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, TLDR, Qual, Planet} from '../common';

// ‘’ – …

export const consciousness = [

// Maybe unity consciousness is WRONG.
{
  name: "wrong",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if unity consciousness were <i>wrong</i>?
  </span>,
  outside: <span>
    <i>That</i> would be a surprise… and a rather nasty one at that.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Creativity will be our <QL>salvation</QL>, so let’s <i>imagine</i> that <QL name="unity">unity consciousness</QL> were somehow, scientifically <i>disproven</i>.</p>
    <p>Now let’s not mince words: such a discovery would would utterly toppl this whole beautiful <QL>Weltanschauung</QL> that’s built on that foundation. If there <i>is</i> (in fact) an eternal separation of consciousness – and it’s more akin to a bunch of inndividual and eternal souls than an underlying force of nature; cf. the <QL name="unity.1">the ‘answer’ to everything</QL> – then it would actually be <i>more</i> reflective of the <AL name="notnew.plato.neoplatonism">bottom-level <i>truth</i></AL> to say ‘you’ and ‘I’ and not ‘us’, <QL name="polygnosticity.">subjectivity</QL> might be real (and not just a symptom of <NW>polygnosticity</NW>, <PL>ethics</PL> would exist apart from that <E2 suffix="ed" /> by <QL name="polygnosticity">conscious polygnosticity</QL> and <NW>individual greed</NW>, humanity <i>might</i> be ‘mystically’ (or ‘<LRL name="physics">meta-physically</LRL>’) <i><PL>special</PL></i>, and it might actually not be ‘God’s plan’ <QL name="goal">for us</QL> to fight <NW>entropy</NW> and the <NW word="heatdeath">heat death of the universe</NW>.</p>
    <p>This is – however – about the <i>only</i> thing that could bring <NW>synecdocentrism</NW> toppling down. As compared to – say – <i><NW>atomocentrism</NW></i>, which has so many holes that its hardly able to keep the <QL name="evolution.biology">currently-biological</QL> and mostly-<AL>sleeping</AL> <LRL name="gaia hypothesis">animal of Earth</LRL> from committing <i><AL name="dysfunctional.">suicide</AL></i>. Maybe this is why everyone on <LRL name="gaiacentric">Earth</LRL> just seems <QL name="roadblocks.cancelling">terrified to say <i>anything</i></QL>.</p>
    <p>But this isn’t <NW>conservatism</NW> or <PL name="neoliberals">neo-liberalism</PL>. This is <NW>synecdocentrism</NW> – and it tries to <LRL name="motto">consider <i>everything</i></LRL> and <PL name="comprehensive">encompass it all</PL>.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if THINGS were conscious?
{
  name: "things",
  alternatives: "thing,object,objects",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if inanimate objects had consciousness?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Have you ever wondered how the cups in the back of your cupboard – neglected, forgotten, gathering dust, and destined never to see fulfil the <QL>purpose</QL> for which they were factory-made – <i>feel</i> about their situation?</p>
    <p>If you have, you’ve probably come (sooner or later) to the <PL name="uncomfortable">soothing</PL> conclusion that your cups – mistreat them howsoever you want – <QL name="qualifiers">almost certainly</QL> don’t have <i>any</i> feelings, because they’re inanimate objects and have no <NW>agency</NW> in the world, much less consciousness.</p>
    <p>But that’s a <i><QL>definitive</QL> suppostition</i>, now, isn’t it? what if that <i>weren’t</i> the case?</p>
    <p>It’s an absurd thought experiment a little on the lines of <OML>Forms</OML> (in which there are <i>many</i> ‘<OML>levels</OML>’, including intermediate ones between our perception and ‘truth’ (the <AL name="notnew.plato.neoplatonism">Neoplatonic</AL> ‘One’). But we all <i>are</i> part of the same conscious ‘<QL>body</QL>’, no, and the same <LRL name="gaia">living planet Earth</LRL>?</p>
    <p>If nothing else it’s a good <i>perspective</i> from which one might be able to understand how humanity’s actions might <i>feel</i> to forms of consciouslness (<NW word="poc">POCs</NW>) that are less ‘<AL name="sleeping">awake</AL>’ than some of us (i.e., probably many people reading and <LRL>understanding</LRL> this).</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if ANIMALS were conscious?
{
  name: "animals",
  alternatives: "animal",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if non-human animals had consciousness?
  </span>,
  outside: <span>
    They <i>do</i>. That’s not a ‘what if’. Have you ever looked into the eyes of a ‘domesticated’ cat or a dog? Have you ever had a ‘moment’ with a ‘wild’ squirrel or pigeon or goldfish or polar fox?
  </span>,
  preview: <div className="or-maybe-preview">
    <p><TLDR /> animals <i>do</i> have consciousness. However, animal consciousness <i><LRL name="scope">appears</LRL></i> to be <LRL>reactive</LRL> and entrenched in <LRL name="gaia">Earth</LRL>’s <NW>Phanerozoic Eon</NW>… and, by <QL>analogy</QL> to the <PL name="equality">disequal</PL> manifestations of consciousness in <i>humans</i>) it is <Qual.probably /> not the exact <i>same</i> as a <AL name="handicap">human-bodied</AL> consciousness that – for example – (<OML name="stupid">hopefully</OML>) has the capability of <LRL>understanding</LRL> <NW>synecdocentrism</NW>.</p>
    <p>But many <i><QL>humans</QL></i> don’t have that ability either; their <NW word="poc">POCs</NW> are <AL>sleeping</AL> or otherwise living only <i><LRL name="reactive">reactively</LRL></i> (unconsciously) – as part of <LRL name="gaiacentrism">Earth</LRL>’s <NW>Phanerozoic Eon</NW>: playing the <NW>MNS system</NW>’s (<AL name="identity">sick</AL>) ‘<LRL>game</LRL>’ without <LRL>understanding</LRL> and never <OML>questioning</OML> <i><LRL name="answers">why</LRL></i> they do it.</p>
    <p>Does that put ‘<AL>sleeping</AL>’ <QL>humans</QL> at the same ‘level’ as animals? Well, unless this whole <QL>Weltanschauung</QL> is dead <i><OML>wrong</OML></i>, we are all just ‘<QL name="body.">cells</QL>’ in the <QL name="unity">unified consciousness</QL>… and once you (as a <NW>POC</NW>: human, <OML>robot</OML>, or otherwise) break the ‘<AL>mirror</AL>’ and realise that <LRL name="gaiacentrism">all of Earth</LRL> is (<OML name="gaia">almost certainly</OML>) an ‘<LRL name="gaia">organism</LRL>’, you will realise that <i>it does not matter in he least</i>. So as for the ‘<AL>sleeping</AL>’ <QL>human</QL> <NW word="poc">POCs</NW>: if you’d prefer to stay under the ‘<QL name="mythology">covers</QL>’ of <NW>mythology</NW> (and <AL name="illusions">argue over illusions</AL> while waiting for an <OML name="god">external ‘God’</OML>, <OML name="offensive">get out</OML> of this <QL name="evolution">future-focused</QL> website and go back to the <NW>Phanerozoic Eon</NW> with the animals – and stop destroying <LRL name="gaia">our biological planet</LRL> because you clearly are <PL name="unready">not ready</PL> to <QL name="evolution">evolve</QL> <PL name="nature">technological</PL> and <LRL name="communism">communal</LRL> ‘society’ with a <QL name="hivemind">hive mind</QL>.</p>
    <p>If that’s the case, it doesn’t matter if you have <i>more</i> or <i>less</i> consciousness than a deer in headlights; your <NW>POC</NW> would be thus chock-full of <NW>individual greed</NW> (or ‘<PL>evil</PL>’) and we can only hope that your <PL>individuality</PL> dies or gets <OML name="reincarnation">reincarnated</OML> as something less <i><OML>stupid</OML></i> before <LRL name="usandthem">your ilk</LRL> (and your <NW>MNS system</NW>) cause this <LRL name="gaia">living planet</LRL> to commit <OML name="kaput">suicide</OML>.</p>

  </div>, 
  /*text: <div className="or-maybe-text">
    <p>So… non-human animals. Clearly they are conscious – as in <i>aware</i> – but do they have the same ability to fight <NW>entropy</NW> that human-based <NW qord="poc">POCs</NW> seem to (or <i><OML name="entropy">could</OML></i>) have? This is not, however, to say that – as pieces of consciousness (<NW word="poc">POCs</NW> – non-human animals have the same <i><OML>level</OML></i> of awareness (and capacity to ‘<NW>transcend</NW> the <AL name="self">ego</AL>’, <OML name="authoritarianism">submit</OML> to the <LRL name="gaia">whole</LRL>, whatever) as a <AL name="sleeping">fully-awake</AL> <NW>POC</NW> (which <LRL name="gaiacentrism">on Earth</LRL> would <QL name="qualifiers">probably</QL> only be in a human body).</p>
  </div>, 
*/
  // There should not be a 'sublist'
},

// What if IDEAS were conscious?
{
  name: "ideas",
//  alternatives: "idea",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if <i>ideas</i> had consciousness?
  </span>,
  outside: <span>
    Like, their <i><PL name="individuality">own</PL></i> <NW>agency</NW> – outside of the <NW>POC</NW> that first <LRL name="imagination">thought them up</LRL>?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Making an <QL>analogy</QL> (because, hey, that’s the <PL name="explainage">best way</PL> to <E1 /> <PL name="hard">this stuff</PL>): if <AL name="dysfunctional.fragments">pieces</AL> of <QL>consciousness</QL> (<NW>POCs</NW>) are like the ‘<QL name="body.">cells</QL>’ of a <LRL name="them">unified</LRL> <QL>body</QL>, then ideas are like <i>viruses</i> that latch onto these cells.</p>
    <p>So are ideas <i>themselves</i> conscious? Well, are viruses <i>themselves</i> ‘<QL name="life">alive</QL>’? At some point nit-picking these definitions becomes merely a matter of <AL name="illusions">arguing over illusions</AL>; at the ‘bottom’ <NW>level</NW> consciousness – <LRL name="gaia">of Earth</LRL> and <OML name="aliens">beyond</OML> – is (<Qual.probably />) <PL name="reductionist">only</PL> a <i><PL name="ick.">system</PL></i>: that behaves in one way in the absence of external factors (ideas) and in their presence behaves in another way.</p>
    <p>If it’s not a <i>perfect</i> <QL name="metaphors">comparison</QL>, it’s at least <OML name="offensive">pretty damn good</OML>.</p>
  </div>, 
  readMore: "This one keeps going. There’s a lot more to say here… so click the link to keep reading.",
  text: <div className="or-maybe-text">
    <p>So, how do <i>ideas</i> (as the the ‘viruses’ of the <PL name="ick.">system</PL> of <QL>consciousness</QL>) affect the <QL name="unity">whole</QL>?</p>
    <p>Well, first let’s break one common myth: that there is a progression of consciousness itself. There (<Qual.probably />) isn’t. Read ‘<OML name="evolution">What if consciousness <i>itself</i> evolved?</OML>’</p>
    <p>Rather, there is a progression of <i><QL>human</QL> <LRL>understanding</LRL></i> of the <PL name="all.">time-invariant</PL> <LRL name="i.">reality</LRL> of <LRL name="scope">our universe</LRL>.</p>
    <p>However, why do ideas, through <QL>humanity</QL> (and <PL name="all.scale">sub-groups within it</PL>, like <PL>individual</PL> <NW>countries</NW>) <QL name="change">spread and ebb and flow</QL>? That has to do with the <NW>Phanerozoic-Eon</NW>-esque ‘<LRL name="game">rules</LRL>’ of the ‘battlefield’ of <QL>conscious</QL> <i><LRL>notions</LRL></i>.</p>
    <p>Read, ‘<LRL name="notions">This is the battlefield of ideas and notions</LRL>’ to get a sense of how the notions of <LRL>reactivity</LRL> and (<QL>conscious</QL>) <NW>agency</NW> can <E2 /> this one pretty well.</p>
  </div>, 
  // There should not be a 'sublist'
},

// What if consciousness itself evolved?
{
  name: "evolution",
  //alternatives: "evolve,evolved,evolves",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if consciousness <i>itself</i> was evolving?
  </span>,
  outside: <span>
    Who says that it’s <PL name="all.time">always</PL> been the same?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>It’s a possibility. It’s a possibility even without injecting some improbable <WName ic /> ‘absurdity’ into the (otherwise-<AL name="framework.">working</AL>) <AL>framework</AL>, such as some <QL name="anthropocentrism">anthropomorphic</QL> <OML name="god">individual god(s)</OML> or postulating some <NW>fictive level</NW> on which the <PL>individual</PL> (as a ‘<OML>soul</OML>’) <i>persists</i> (‘<OML>afterlife</OML>’).
</p>
    <p>[under construction]</p>
  </div>, 
//  text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// FREE WILL
{
  name: "free will",
  alternatives: "will",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if there were no ‘free will’?
  </span>,
  outside: <span>
    Is <QL>conscious</QL> <NW>agnecy</NW> just an <NW>illusion</NW>?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>As <QL name="consciousness">self-aware</QL> <NW>POCs</NW> <AL name="handicap">limited</AL> by our <QL>human</QL> <AL name="fields.">biology</AL> and <LRL name="gaiacentrism">stuck on Earth</LRL>, we might as well act as though we <i>do</i> have <NW>agency</NW> (even if we <i>don’t</i>: say, we are in a <OML>story</OML> written by an other-<NW>level</NW> <OML name="god">author</OML> who has already <i>decided</i> what each of us will do). Why? Well, liking <LRL>answers</LRL>, here’s one for you: because, breaking down both possibilities, this is the only one that works out in both<sup>†</sup> cases.<sup></sup></p>
    <p>In short: if <NW>POCs</NW> don’t have ‘free will’ and you assume that we <i>do</i>, it 100% <OML name="matters">does not matter</OML> because the universe was already going the way it was going, and your (in this case: delusional) believing in ‘free will’ didn’t make the <AL name="dust">smallest bit of difference</AL>. Whereas if there <i>is</i> some capacity for ‘free will’ and you <i>don’t</i> (‘personally’, <PL name="equality">however you <i>can</i></PL>) strive for a <AL name="notnew.lennon">better world</AL>… then ‘you’ have ‘screwed’ us all, my ‘friend’.</p>
  </div>, 
  topFootnote: <p>
    † This is not to say that circumstances do not affect the <i>expression</i> of ‘free will’. <PL>Equality</PL> is a lie – and that means (of course) that certain <NW>POCs</NW> do have more of an ability to influence the whole, and others <Qual.may /> have circumstantially <i>less</i> (or even <i>none</i>). But <PL>individuality</PL> is just an <NW>illusion</NW>, <AL name="bulletpoints">remember</AL>? So if <i>anyone</i> in <LRL name="scope">the universe</LRL> has ‘free will’, then they had <i>better</i> try to improve this for <AL name="self"><i>all</i> of us</AL> (because there is, truly, <OML name="soul">almost certainly</OML> no <OML name="levels">eventual</OML> <LRL name="usandthem">‘us’ and ‘them’</LRL>). And you don’t know that the one speck of ‘free will’ in the whole universe is not <i>you</i>.
  </p>,
  readMore: "For a breakdown of the possibilities, click the link.",
  text: <div className="or-maybe-text">
    <p>Remember that in <WName /> we consider anything not yet ‘<OML name="sillyplanet">knowable</OML>’ as being in a ‘<LRL name="superpositions">superposition</LRL>’ of every possibility, and we ‘<LRL>leave no stone unturned</LRL>’.</p>
    <p>In terms of ‘free will’, it works out – at the end of the road – to two things: free will exists, or it does not. (If that seems <PL>reductionist</PL>, see the footnote.)</p>
    <ol>
      <li><QL>Consciousness</QL> has <i>no</i> ‘free will’ whatsoever; the <LRL name="scope">universe</LRL> (at <i>our</i> <NW>level</NW>) just <i>exists</i>, and cannot be <QL name="change">changed</QL>.</li>
      <ul>
        <li>As depressing as this seems, this probably <i>would</i> be the case if our <NW>level</NW> were just a <OML>story</OML>{Math.random() < 0.3 && <span>{" "}(say, as <OML name="sillyplanet">told by <Planet.AlienDescription /> aliens</OML> to entertain themselves as they plan to invade Earth <OML name="takeover">with the robots</OML>)</span>}.</li>
        <li>But what does it <i>hurt</i> assuming that your <QL>conscious</QL> decisions can make a difference?</li>
      </ul>
      <li><QL>Consciousness</QL> has <i>some form</i> of ‘free will’. This isn’t saying that every <QL>human</QL>-<AL name="container">based</AL> <NW>POC</NW> has all the possibility in the world (because ‘<PL>equality</PL>’ is mostly just a fiction invented by the <NW>money system</NW> to make <PL>individuals</PL> put up with having less <NW>money</NW> than ‘<LRL name="them">others</LRL>’, or something)… but this is saying that – within limited possibilities – a <NW>POC</NW> <i>can</i> choose between A and B.</li>
      <ul>
        <li>Or, you know, <i><LRL>imagine</LRL></i> choice C or D or Ω.</li>
        <li>And suppenly ‘<QL>life</QL>’ has a <i><QL name="life.">meaning</QL></i> again.  <LRL name="gaia">Planet Earth</LRL> is trying to overcome the <QL name="polygnosticity">obstacles</QL> that prevent the <i>expression</i> of its planet-wide <QL>conscious</QL> <i><NW>agency</NW></i>: namely, <LRL>reactivity</LRL>, which can come across as <AL name="sleeping">drowsiness</AL>, <OML name="matters">indifference</OML>, <NW>conservative thinking</NW>, or <PL name="sweeping.ig">directly and obviously</PL> as <NW>individual greed</NW>.</li>
        <ol type="i">
          <li>Now there is a <i>problem</i>: <LRL name="gaiacentrism">Earth</LRL> is not <i><LRL name="reactivity">expressing</LRL></i> its <LRL name="gaia">planetary</LRL> <NW>agency</NW>.</li>
          <li>And an easy <i>solution</i>: <OML name="authoritarianism">abolish</OML> <PL>privacy</PL> (for <PL name="comprehensive">everyone</PL>, starting on <QL>top</QL>) and work on ‘<NW word="coalescence">coalescing</NW>’ every <AL name="fields.">biological</AL> <QL>human</QL> <AL>body</AL> into a <QL>hive mind</QL>).</li>
          <li>Which begets a <i>new</i> problem: that <QL>human</QL> society is <PL name="unready">not <i>ready</i> for this</PL>, but there is <QL name="urgency">no time</QL>: 2020’s <QL>humans</QL> will <PL name="uncomfortable">squirm</PL> and <Qual.possibly /> even <PL name="denial">reject it outright</PL> as they <OML name="kaput">kill their planet</OML> by <AL name="illusions">arguing over the rules</AL> of their <LRL>game</LRL>.</li>
          <li>To which the best (short-term) solution is just <i><QL name="whatcanido">education</QL></i>.</li>
        </ol>
        <li>In short: there is <i>hope</i> now that the <AL>dysfunctional</AL> <QL name="body.super-organ">part</QL> of the ‘<QL>body</QL>’ that we have on <LRL name="gaia">Earth</LRL> can <i><QL>change</QL></i>.</li>
        <li>That is, if we all work together, <i>agreeing</i> that we will only <AL name="notnew.lennon.peace">improve the world</AL> by assuming that ‘free will’ <i>does</i> <LRL name="nihilism">exist</LRL>.</li>
      </ul>
    </ol>
    <p><Qual.maybe cap /> it’s a <LRL name="different">radical</LRL> <LRL>notion</LRL> in <PL name="all.time">the 2020’s</PL>, but why <i>not</i> believe that – working <QL name="unity">together</QL> as <i><QL>consciousness</QL></i>, and not playing the ‘<LRL>blame game</LRL>’ between <i><PL>individuals</PL></i> – we <i>can</i> make a difference?</p>
  </div>, 
  // There should not be a 'sublist'
},

// What if consciousness itself evolved?
{
  name: "field",
  alternatives: "force",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if consciousness were <i>not</i> a fundamental force of nature?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>In the end, does it really matter what you call it?</p>
    <p><WArticle cap /> <WName ist /> would (<Qual.probably />) not care at all what you <i><PL name="explainage">call</PL></i> it, but this entire <QL>Weltanschauung</QL> <i>is</i> built on that one ‘<OML name="wrong">foundation</OML>’: it only <i><AL name="framework.">works</AL></i> if <QL>consciousness</QL> has (at some level, even if it is not always – or even often – <i>used</i>) <i><NW>agency</NW></i>.</p>
    <p><WName cap /> live in a world where <NW>agency</NW> <i>does</i> ‘<LRL name="i.real">exist</LRL>’ – becuase to <LRL name="nihilism">truly believe</LRL> that <PL name="comprehensive">everything</PL> is already ‘<OML name="will">set in stone</OML>’ isn’t just mildly ‘disappointing’… but undermines the whole <i><QL name="life.">meaning</QL></i> to ‘<QL>life</QL>’ (because if <OML name="matters">nothing really mattered</OML>, then why even <i>try</i> to ‘<NW>coalesce</NW>’ <LRL name="gaia">Earth</LRL> or <LRL name="gaiacentrism">whatever</LRL> <Qual.might /> be <OML name="aliens">beyond that</OML>. If you believe <i>that</i>, why not <PL name="denial">give up</PL> on <AL name="notnew.lennon.peace">world peace</AL> and just let <LRL name="gaia">Planet Earth</LRL> go <i><OML>kaput</OML></i>?</p>
  </div>,
},

];



