import React, {useState} from 'react';
import wName, {WEtymology} from './wName';
import WName from './pages/WName';
import AL from './pages/angles/AngleLink';
import QL from './pages/faq/QuestionLink';
import PL from './pages/problems/ProblemLink';
import NW from './pages/neologisms/NewWord';
import LRL from './pages/longreads/LongReadLink';
import OML from './pages/ormaybe/OrMaybeLink';
import {E1, E2, rand, Rando} from './pages/common';
import {Link} from 'react-router-dom';
import './Header.css';

const taglineOptions = [
{
  line: <span>
    <AL name="framework">It</AL>’s a <PL name="comprehensive">whole</PL> <LRL name="shift">new</LRL> <QL>Weltanschauung</QL>.<sup>*</sup>
  </span>,
  footnote: "* Worldview (German)",
},
{
  line: <span>
    <AL name="framework">It</AL>’s<sup>*</sup> the <NW word="copernicus">Copernicus of the 21<sup>st</sup> century</NW>.
  </span>,
  footnote: <span>* <i>It</i>. The <i><OML name="ideas">theory</OML></i>. Not any <QL>human</QL> <i>individual</i>; there’s no <PL>individuality</PL> here.</span>,
},
{
  line: <span>
    <PL>AI</PL> is not the <QL name="roadblocks">enemy</QL>.
  </span>,
  subline: <span>
    Our <QL name="polygnosticity">problem</QL> (on <LRL name="gaiacentrism">Earth</LRL>) is conscious <NW>polygnosticity</NW> and our ‘<PL name="evil">enemy</PL>’ (as <QL>humanity</QL>) is <i><NW>individual greed</NW></i>.
  </span>,
},
{
  line: <span>
    Don’t fear <LRL name="different">newness</LRL>.
  </span>,
  subline: <span>
    Those who don’t <QL name="evolution">evolve</QL> go <OML name="kaput">extinct</OML>.
  </span>,
},
{
  line: <span>
    ‘<QL>Life</QL>’, the ‘<LRL name="scope">universe</LRL>’, and <i><PL name="comprehensive">everything</PL>…</i>
  </span>,
  subline: <span>
    …all simply <E2 suffix="ed" italic />.
  </span>,
},
{
  line: <span>
    Take a <AL name="fields.physics">quantum-mechanical</AL> approach to <i>philosophy</i>.
  </span>,
  subline: <span>
    The resulting <AL>framework</AL> (<QL name="qualifiers">probably</QL>, when <LRL name="understand">understood</LRL>, if it’s not just <i><OML>wrong</OML></i>) actually <AL name="framework.works">manages</AL> to <E2 /> <LRL name="gaia">Earth</LRL> and <QL name="qualifiers">pretty much</QL> <PL name="comprehensive">everything else</PL>.
  </span>,
},
{
  line: <span>The <QL name="anthropocentrism">human</QL> ‘<QL name="polygnosticity.">mind</QL>’ is so <i><AL name="handicap">limited</AL></i>…</span>,
  subline: <span>
    But (<QL name="qualifiers">for now</QL>) it’s <QL name="hivemind">all</QL> that an <PL>individual</PL>’s got.
  </span>,
},
{
  line: <span>The ‘<AL name="framework.">puzzle pieces</AL>’ are nothing (<QL name="qualifiers">much</QL>) <i><AL name="notnew">new</AL></i>.</span>,
  subline: <span>
    <WName cap /> is just <i><PL name="comprehensive">rearranging</PL></i> them.
  </span>,
},
];

function changeTaglineNum(num, setTaglineNum) {
  var randNum = Math.floor(Math.random()*1000); // 0-999
  // > 1 (so it will change) && < length (so you won't loop full circle)
  var randInc = 1 + randNum % (taglineOptions.length - 1);
  setTaglineNum((num + randInc) % taglineOptions.length);
}

function Header() {
  var r = 0;
  if (Math.random() < 0.7) {
    r = rand(0, 999) % taglineOptions.length;
  }
  const [taglineNum, setTaglineNum] = useState(r);
  const tagline = taglineOptions[taglineNum];

  let whyRandom = (Math.random() < 0.2);
  return <div id='header'>
    <h1>
      <Link
        to="/"
        style={{color: "inherit"}}
        onClick={()=>changeTaglineNum(taglineNum, setTaglineNum)}
      >
       <WName cap noNW />
      </Link>
    </h1>
    <p className="etymology">
      <WEtymology />
    </p>
    <h2>
      {tagline.line}
    </h2>
    {tagline.subline && <p className="subline">{tagline.subline}</p>}
    <div className='header-bottom'>
      {tagline.footnote && <p className='footnote'>{tagline.footnote}</p>}
      {whyRandom && <p className="why-random">
        <QL name="change.site">Why does this website <Rando options={["always", "often", "randomly", "occasionally", "intermittently", "unpredictably (unless maybe it’s Thursday)"]} /> <i>change</i>?</QL>
      </p>}
    </div>
  </div>;
}

export default Header;
