import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual} from '../../common';

// ‘’ … –

// Leave no stone unturned
export const motto = {
  name: "motto",
  alternatives: "leave no stone unturned,unturned",
  prelude: <span>
    If <WName /> (as a <PL>comprehensive</PL> <E2 word="explanation" /> of the <LRL name="scope">visible universe</LRL>) had to have a motto, it would be ‘leave no stone unturned’.
  </span>,
  header: <span>
    <WName cap noNW /> is leaving no stone ‘unturned’.
  </span>,
  outside: <span>
    <p>This is just <AL name="fields.">logic</AL>.</p>
  </span>,
  preview: <div className="long-read-preview">
    <p>This is <NW word="induction">inductive</NW> <AL name="fields.">logic</AL> – which means that it’s necessary to gather a whole <i>lot</i> (i.e., ‘<OML name="offensive">shit-ton</OML>’) of data… and then <NW word="induction">induce</NW> a <AL name="framework.">working</AL> <E2 word="explanation" /> that can account for everything in its <LRL>scope</LRL> with <PL name="comprehensive">no holes</PL>, which only breaks down if you inject some non-<NW>Occam</NW>-friendly <OML name="god">improbable absurdities</OML> into it.</p>
    <p>All that is what the <WName ic /> <QL>Weltanschauung</QL> has essentially (<AL name="systems">analytically</AL>, ‘<PL name="ick.system">heartlessly</PL>’) already done.</p>
  </div>,
  text: <div className="long-read-text">
    <p>The way to truly ‘leave no stone unturned’ cannot be to brute-force lift <i>every</i> ‘stone’. That would take far too long, there is far too much variability in nature; maybe this is the <WName ic/>-<PL name="all.">scale</PL> version of the [<NW>atomocentric</NW>-<QL>human</QL>-<PL>individual</PL>’s-<NW word="mns system">MNS-system</NW>-<i><AL name="identity">name</AL></i>] uncertainty principle of <AL name="fields.">physics</AL>.</p>
    <p>So a <AL name="handicap">human-trapped</AL> <NW>POC</NW> must take some shortcuts: to build <AL name="framework">concepts</AL> that are <PL name="sweeeing">big enough</PL> they can encompass <LRL name="superpositions">all possibilities</LRL>. Here are the steps:</p>
    <ol>
      <li>Gather a whole bunch of data: by living a <QL>human</QL>-based ‘<QL>life</QL>’ yourself, by <i>observing</i> (or reading about) how ‘<LRL name="them">others</LRL>’ live them, or by asking these ‘<LRL name="them">others</LRL>’ and then truly trying to <i><LRL>understand</LRL></i> them (though with ‘<LRL name="them">others</LRL>’ and with your ‘<AL>self</AL>’ you have to filter out, <PL name="sweeping.ig">of course</PL>, any <NW>individual greed</NW>).</li>
      <li>Deconstruct (analyse) said data into patterns or ‘trends’. (These shoukld try to account both for the <i><LRL name="scope">observed</LRL></i> data and also be <PL name="sweeping.handful">robust</PL> enough to account for the many ‘stones’ that ‘you’ (as your separated <NW>POC</NW>) either did not have time to life or <i>cannot</i> lift for reasons like <QL>humanity</QL>’s insistence on <PL>privacy</PL>.)</li>
      <li><NW word="induction">Induce</NW> an <E2 word="explanation" /> that can account for <PL name="comprehensive">everything</PL> that has been observed <i>up to this point</i> (i.e., all the ‘<QL name="pg.mind.">known</QL>’ data points). In the case of <WName />, this is <QL name="unity">unity consciousness</QL>.</li>
      <li><LRL>Imagine</LRL> things <i>beyond</i> the observed, and try (with the patterns discovered in step 2) to account for these (<QL name="qualifiers">possible</QL>) eventualities.</li>
      <li>It is not possible to account for them <i>all</i>, because sometimes they contradict <i>each other</i> – like the <LRL>notions</LRL> of some <OML>external God</OML> and <OML name="levels">nihilism</OML>… so use ‘<NW word="occam">Occam’s razor</NW>’ liberally.</li>
    </ol>
    <p>What results is a <AL>framework</AL> that is <AL name="framework.works">accounts</AL> for <PL name="comprehensive">everything</PL> within its <LRL>scope</LRL> and has a fair amount of ‘invariability’ to <LRL name="imagination">imaginatively</LRL> extend (e.g., in <PL name="all.">time</PL> or <PL name="all.">scale</PL> or even not caring what is ‘<LRL name="i.">real</LRL>’) <i>beyond</i> its scope.</p>
    <p>Then lift more ‘stones’, and more ‘stones’, and yet <i>more</i> ‘stones’… and you will either confirm – time and again – the hypothesis, or it will be proven <OML>wrong</OML>.</p>
    <p><WName cap /> has yet to encounter anything that it cannot (and with just a <PL name="sweeping.">handful</PL> of <E2 word="explanations" />) <E2 />.</p>
  </div>,
};
