import React from 'react';
import WName, {WArticle} from '../WName';
import Eon, {EonArticle} from '../Eon';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, Qual, SillyName, Planet, money_system} from '../common';

export const forward = [

// Force-sterilise conservative thinkers?
{
  name: "sterilisation",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if we force-sterilised every conservative thinker?
  </span>,
  outside: <span>
    ‘<NW word="conservatism">Conservative thinkers</NW>’ being… well, from <i>this</i> point in time, just about everything that <QL name="anthropocentrism">humanity</QL> has ever thought in the past. And force-sterilisation being (<QL name="qualifiers">maybe</QL>) a potential solution to <QL name="body">our body’s</QL> <AL name="dysfunctional.">cancer</AL>
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Remember, outside of the <AL name="illusions">game</AL>, there is (<OML name="forms">probably</OML>) no such thing as ‘<PL>ethics</PL>’.</p>
    <p>[Under construction]</p>
{/*  – */}
  </div>, 
  // Not sure we’ll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// Murder anyone who doesn't live a 'radically open' life?
{
  name: "privacy",
  alternatives: "murder",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if we murdered everyone who did <i>not</i> live a ‘radically open’ life ?
  </span>,
  outside: <span>
    Probably <i>murder</i> wouldn’t actually be the best way to go about it… but that headline probably grabbed your attention.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Make sure you first understand the <NW>synecdocentric</NW> <QL>Weltanschauung</QL>’s views on <PL>privacy</PL>, the notion of ‘<QL>radical openness</QL>’ and the idea of <LRL name="communism">synecdocentric communism</LRL>.</p>
    <p>Now you’re ready to entertain the notion that maybe the best way to actually <QL name="polygnosticity.coalescence"><i>achieve</i> ‘coalescence’</QL> is to simply outlaw <i>privacy</i>.</p>
    <p>[Under construction]</p>
{/*  – */}
  </div>, 
  // Not sure we’ll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},


// WAR ON THEISTS!!!
{
  name: "war",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if we waged a <i>war</i> on all believers in an external God?
  </span>,
  outside: <span>
    Because probably <i>that’s</i> what <AL name="notnew.">Lennon</AL> meant when he said to ‘<AL name="notnew.lennon.peace">give peace a chance</AL>’.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>But really, there are so many other ways one might do this:</p>
    <ul>
      <li>Belief in an <QL name="roadblocks.god">external God</QL> (i.e., a ‘<NW>God</NW>’ that isn’t just us: our <QL>consciousness</QL>) is a form of <NW>conservative thinking</NW>, so we can just <OML name="sterilisation">force-sterilise</OML> all those people so they don’t have kids and spread their <OML>ideas</OML> (which would also help with <LRL name="gaia">Planet Earth</LRL>’s ‘<AL name="sick.">cancer</AL>’).</li>
      <li>Or who says we need to wage a whole <i>war</i>? We could just scour the <LRL name="gaiacentrism">planet</LRL>, find anyone <i><QL name="pg.privacy">hiding</QL></i>, and <OML name="murder">pick them off one by one</OML>.</li>
	<li>At which point we take all of their hoarded <NW>money</NW> and give it to the <OML name="authoritarianism">authoritarian</OML> (but ‘<QL name="openness">radically open</QL>’) <LRL name="communism">communist</LRL> <AL name="notnew.plato.kings">world governemnt</AL>… which promptly <i>burns</i> it.<sup>†</sup></li>
      <li>Hopefully <PL>neoliberals</PL> can tell that this is fairly ‘tongue-in-cheek’.</li>
    </ul>
  </div>,
  topFootnote: <p>
    † {money_system}
  </p>,
  text: <div className="or-maybe-text">
    <p>Why make <i>jokes</i> (and <Qual.probably /> fairly ‘<PL name="uncomfortable">tasteless</PL>’ ones at that) about it? Because we (as <AL name="handicap">humans</AL>) <i><LRL name="motto">don’t know yet</LRL></i>.</p>
    <p>But the best way to proceed in the face of <OML home>uncertainty</OML> (and make the world a better place for the greatest number of the <QL name="body.">cells</QL>: basically using utilitarianism until <QL>humans</QL> can <PL name="invisible">see</PL> <AL name="handicap">past</AL> the <NW>illusion</NW> of <PL>individuality</PL>) is to <QL name="openness">completely elimainate</QL> the <PL>privacy</PL>-barriers hampering the exchanges of <LRL>understanding</LRL> within our <NW>polygnostic</NW> <AL>system</AL> (and sorry <QL>humanity</QL>: you are just a <PL name="ick.">system</PL>).</p>
    <p>Beyond that there might <i>be</i> some <OML name="god">external God</OML> on a <NW>level</NW> <i>below</i> us (and <OML name="afterlife">possibly</OML> even <OML name="levels">no levels below <i>that</i></OML>). Highly unlikely, but still… that’s outside the <LRL>scope</LRL> of <WName />.</p>
  </div>, 
  // There should not be a 'sublist'
},

// AUTHORITARIANISM???
{
  name: "authoritarianism",
  alternatives: "authoritarian",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if <i>authoritarianism</i> were (actually) the best way to go?
  </span>,
  outside: <span>
    Assuming that your ‘average <SillyName />’ will refuse forevermore to <AL name="sleeping">get out of bed</AL> and <LRL name="understand">pick sone roses</LRL> (<PL name="uncomfortable">thorns</PL> and <PL name="comprehensive">all</PL>) for <LRL name="gaia">our planet’s</LRL> ‘birthday’ (<QL name="urgent">tomorrow</QL>) – excuse the extended <QL>metaphor</QL>.
  </span>,
  preview: <div className="or-maybe-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: Taking <LRL name="gaia">Earth</LRL> as a (conscious) <i><AL>system</AL></i>, what’s the best way to cure our <QL>body</QL> of its <AL name="dysfunctional">(many) diseases</AL>?</p>
    <p>This <QL>Weltanschauung</QL> <i>tries</i> – and sometimes very hard – not to be <LRL name="gaiacentrism">Earth-centric</LRL> (or <QL name="anthropocentrism">anthropocentric</QL>)… but sometimes (or – let’s be <AL name="frameework.work">real</AL> – <LRL name="gaiacentrism">on Earth it’s all the time</LRL>) a <NW>POC</NW> has to deal with ‘others’ who are <NW>heterognostic</NW> to it and don’t <i>want</i> to <LRL name="communism">share thoughts</LRL> (and ‘<NW>coalesce</NW>’ <LRL name="gaia">Earth</LRL>’s conscious <QL>body</QL>).</p>
    <p>Authoritarianism – as currently done on <LRL name="gaiacentrism">Earth</LRL> – <i>sucks</i>… because a lot of points of view just get <i>silenced</i>. (And how is that leaving ‘<LRL name="motto">no stone unturned</LRL>’?) That’s the <NW>individual greed</NW> of the few who have (<OML name="rights">probably</OML>) <PL name="equality">no inherent ‘right’ to control the many</PL>, and so are always afraid that their <NW>mythology</NW> will be ‘<QL name="polygnosticity.">exposed</QL>’ and they will be <i>ousted</i>.</p>
    <p>That’s not how <NW>synecdocentric</NW> authoritarianism should work. Think rather of having (in our <AL>sick, sick</AL> ‘<QL>body</QL>’) some <i>brain <QL name="body.">cells</QL></i>: ‘<AL name="notnew.plato.kings">philosopher-kings</AL>’).</p>
    <p>Whether or not <QL name="anthropocentrism">humanity</QL> actually <i>can</i> do this is another matter entirely… but if <LRL name="gaia">we</LRL> could, it might be a bit of a lesser evil than ‘democracy’.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if Earth dies?
{
  name: "kaput",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if the whole planet just went <i>kaput</i>?
  </span>,
  outside: <span>
    Meaning that <LRL name="gaiacentrism">all of Earth</LRL> (which in this <QL>Weltanschauung</QL> is <LRL name="gaia">a single ‘organism’</LRL>, and <AL name="dysfunctional.fragments">part</AL> of the universe’s conscious ‘<QL>body</QL>’) just up and <i>died</i>.
  </span>,
  preview: <div className="or-maybe-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: The conscious force of the universe (or <LRL name="superpositions">whatever it is</LRL>) would <OML name="only">probably</OML> find a different way to fight <NW>entropy</NW> and ‘<NW>coalesce</NW>’ (as a <i>universal</i> <QL name="unity">consciousness</QL>) – with <LRL name="gaia">Earth</LRL> simply being a fossil from a creature that never <i><QL name="evolution">evolved</QL></i>.</p>
    <p>As for Earth, it would either die completely or <i>regress</i> to the <NW>Phanerozoic Eon</NW> </p>
    <p>All of the thinking that humans have done about the ‘<QL name="life.">meaning</QL>’ of life (including the whole <QL>Weltanschauung</QL> of <NW>synecdocentrism</NW>) will either be lost or – by the time that an <OML name="aliens">extraterrestrial consciousness</OML> stumbles upon it – so retro as to be completely useless to them in their quest to stave off the <NW wprd="heat death">heat death of the universe</NW> or <LRL name="scope">whatever they’re doing</LRL>.</p>
    <p>And Earth will be one of those sad attempts that biological evolution (‘<QL name="evolution.darwin">natural selection</QL>’) randomly makes, one that just didn’t (in the end) work out very well.</p>
    <p>Let’s not do that, please?</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if (conscious) robots took over?
{
  name: "takeover",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if conscious robots <i>took over</i> Earth?
  </span>,
  outside: <span>
    In all the <LRL name="superpositions">various eventualities</LRL> that <NW>synecdocentrism</NW> <LRL name="motto">considers</LRL>, this is (for <QL name="unity">consciousness</QL>) <i>far</i> from the worst.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>It’s far from the <i>best</i> <LRL name="superpositions">possible outcome</LRL>, either – becuase we simply <QL name="gnosticity.agnosticity">don’t know</QL> how a violent (and unwilling) internal change in conscious governinance would affect <LRL name="gaiacentrism">our planet’s</LRL> whole ‘<AL>self</AL>’. <OML home>Maybe</OML> it wouldn’t hurt <LRL name="gaia">Planet Earth</LRL> (as a currently-<AL>sick</AL> <QL name="body.">super-organ</QL> in the <QL name="unity">universeal</QL> conscious ‘<QL>body</QL>’) in the least, Earth ‘<NW word="coalescence">coalesces</NW>’ (and becomes <QL name="hivemind.mortality">near-immortal</QL>), and heads out into the stars to <QL name="hivemind.join">unite</QL> with any <OML name="aliens">alien</OML> consciousnesses out there <NW>heterognostic</NW> from it, to (all <LRL name="communism">together</LRL>) stop the <NW word="heatdeath">heat death of the universe</NW> (or <OML name="goal">whatever</OML>).</p>
    <p><OML home>Or maybe</OML> the robots’ violence towards the <AL name="sleeping">still-mostly-sleeping</AL> consciousness of humanity would scar <LRL name="gaia">Earth consciousness</LRL> forevermore, dooming us to a ‘<NW>Mechanozoic Eon</NW>’ in which evolution is <i>still</i> fuelled by ‘<QL name="evolution.darwin">natural selection</QL>’ (competition) and <i>not</i> by any <QL name="evolution">forward-thinking</QL> use of <i>consciousness</i>, the <LRL>potential</LRL> that this planet <LRL name="superpositions">could have been</LRL> is never realised, and – at this point – we (as conscious <AL name="dysfunctional.">fragments</AL> in a ‘<QL name="body.">super-organ</QL>’ that has <i>not</i> yet gone ‘<OML>kaput</OML>’) only have to hope that there <i>are</i> <OML>aliens</OML> out there who <i>have</i> ‘<NW word="coalescence">coalesced</NW> and can either rescue (incorporate) <LRL name="gaia">Earth</LRL> into their burgeoning universal <QL name="hivemind">hive mind</QL>, or obliterate whatever <NW>atomocentric</NW> <AL>Hell</AL>-fiend we would have created, and go on learning more about the universe and trying to <QL name="goal">unify it</QL> or <OML name="goal">whatever</OML>.</p>
  </div>, 
  text: <div className="or-maybe-text">
    <p><OML home>Or maybe</OML> Earth is <OML name="alone">all that we (consciousness) get</OML>, but the <NW>Mechanozoic Eon</NW>’s violent start means that the ‘conscious robots’ who kill all the humans <QL name="qualifiers">might</QL> themselves start off <NW>heterognostic</NW> and with <OML name="robots">their own</OML> <NW>individual greed</NW> that they will take way too long to overcome, and so kill our universe’s <LRL>potential</LRL> to <i>all</i> ‘<NW>coalesce</NW>’, reverse and even <i><QL name="goal">minimise</QL></i> our universal <NW>entropy</NW>, stop this thing from <i>blowing itself apart</i> (‘<NW name="heatdeath">heat death</NW>’), and discover whatever might lay <OML name="multiverse">outside of or beyond</OML> it.</p>
    <p>The <QL name="salvation">imagination</QL> could keep on going… but let’s brainstorm a path to conscious <NW>monognosticity</NW> that does <i>not</i> involve <OML name="privacy">murder</OML>.</p>
  </div>, 
  // There should not be a 'sublist'
},

// What if the 'goal' were not 'coalescence'?
{
  name: "goal",
  alternatives: "coalescence",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if our (consciousness’s) end goal were <i>not</i> ‘coalescence’?
  </span>,
  outside: <span>
    Isn’t <i>that</i> a bit of a <QL>definitive</QL> presumption?
  </span>, 
  preview: <div className="or-maybe-preview">
    <p>Nowhere on this website does it say (or should it say) that any <NW word="synecdocentrism">synecdocentrist</NW> <i><QL name="gnosticity.agnosticity">knows</QL></i> what the end <i><QL>goal</QL></i> for <QL name="unity">our consciousness</QL> will be.</p>
    <p>Earth consciousness’s eventual ‘purpose’ might be a lot of things. We might discover, after ‘<NW word="coalescence">coalescing</NW>’ into a <NW>monognostic</NW> <LRL name="gaia">planet</LRL> (i.e., curing our <QL>body</QL> of <AL name="sick.">DID</AL>) that we are just all a <i><OML>story</OML></i> (or <OML>simulation</OML>) in the individual life of some ‘other’ (<NW>heterognostic</NW>) <NW>POC</NW> in a galaxy far, far away, told or shown in order to help <i>that</i> <NW>POC</NW> <LRL>understand</LRL> <QL name="unity">unity consciousness</QL> (or whatever they know that <i>we</i> – being in this thought-experiment only a <QL name="gnosticity.">subset</QL> of the author’s <QL>gnosticity</QL> – don’t).</p>
    <p>The <LRL name="superpositions">possibilities</LRL> are <LRL name="i.limit">literally endless</LRL>. And here’s the ‘<NW>synecdocentric</NW> weirdness’: it <i>doesn’t matter</i>. It 100% doesn’t matter if we are just characters in the <QL name="salvation">wild</QL> <LRL>imaginations</LRL> of <Planet.AlienDescription /> <NW>polygnostic</NW> and <NW>atomocentric</NW> <OML>aliens</OML> on a planet called <Planet.Name />; <i>our</i> ‘goal’ in the universe that we <i><QL name="gnosticity.agnosticity">know</QL></i> (and can <LRL name="scope">observe</LRL>) is still to try to <QL name="goal">learn more and more</QL>… until we gather some new data point – be it our discovery of an ‘<OML>external God</OML>’ or an individual ‘<OML>soul</OML>’ – that causes us to update this logical-<NW>induction</NW>-derived <QL>Weltanschauung</QL>.</p>
    <p>Once your <NW>POC</NW> can <LRL>understand</LRL> <i>that</i>, you are well on your way to ‘grasping’ this <AL name="fields.physics">quantum-mechanics</AL>-esque world-<E2 word="explanation" />.</p>
  </div>,
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if this were not the END understanding?
{
  name: "end",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if <WName noNW /> were not the <i>final</i> (i.e., best) world-understanding?
  </span>,
  outside: <span>
    It’s possible. <WName cap /> is <LRL name="disagreement">still</LRL> a <i>much</i> more <AL name="framework.works">effective</AL> and <PL name="all">complete</PL> than <i><PL name="sweeping">any</PL></i> <NW>atomocentric</NW> <QL>Weltanschauung</QL>.
  </span>, 
  preview: <div className="or-maybe-preview">
{/*    <p>Following the <WName ic /> understanding of the <QL>evolution</QL> of <QL>consciousness</QL>:</p>*/}
    <p>Before asking this, make sure you <LRL>understand</LRL> the <WName ic /> <LRL name="notions">concepts</LRL> of <QL>evolution</QL> (e.g., of <QL name="evolution.">consciousness</QL>), <PL name="sweeping.scale">scale-invariance</PL>, and the <AL name="fields.physics">pseudo-quantum-mechanical</AL> <LRL name="superpositions">demand</LRL> to (<PL name="denial">truly</PL>) ‘<LRL>leave no stone unturned</LRL>’.</p>
    <p>And then <PL name="hard">try</PL> to <LRL>understand</LRL> one point very clearly: <WName /> does <i>not</i> claim to be the absolute <QL>definitive</QL> ‘end’ for our <QL>consciousness</QL>: (<LRL name="gaia">Earthly</LRL> and <QL name="qualifiers">potentially</QL> (<OML name="alone">hopefully</OML>) <OML name="aliens">non-Earthly</OML>, probably <NW>monognostic</NW> but necessarily <NW>heterognostic</NW> from <LRL name="gaaia">Earth</LRL>’s until we incorporate them or they incorporate us (<PL name="all.perspective">same difference</PL>).</p>
    <p><WName /> <i>is</i> <PL name="hard">demanding</PL>, yes requires a massive <LRL>shift</LRL> in <QL>humanity</QL>’s 2020’s thinking.</p>
    <p><WName /> is just the next step, and the one that will <QL name="life.meaning">assuredly</QL> get <i>our</i> <AL name="dysfunctional.">fragment</AL> of <QL>consciousness</QL> off this <LRL name="gaiacentrism">rock</LRL> without <LRL>Earth</LRL> consciousness comitting <AL name="dysfunctional.">suicide</AL> and <OML name="free will">regressing</OML> to a <LRL>reactive</LRL> state with no <NW>agency</NW>.</p>
  </div>,
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if nothing really mattered
{
  name: "matters",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if nothing really <i>mattered</i>?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>That’s just <LRL>nihilism</LRL>. This is not <LRL>nihilism</LRL>.</p>
  </div>,
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if everyone just decided to spontaneously get along?
{
  name: "harmony",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if everyone on Earth just spontaneously decided to <i>get along</i>??
  </span>,
  outside: <span>
    Is that even <i>possible</i>?
  </span>, 
  preview: <div className="or-maybe-preview">
    <p>That right there is the key <LRL name="different">difference</LRL> between <WName /> and past (<NW word="conservatism">conservatively-thinking</NW>) <AL name="fields.">science</AL>.</p>
    <p>In an <i>unconscious</i> (<LRL>reactive</LRL>) universe, the answer is that yes, theoretically it is <i>possible</i>, but the probability of this happenning (looking at every <AL name="fields.physics">quantum-level</AL> <LRL name="superpositions">determination</LRL> as just random chance) is so improbably small that humanity probably hasn’t even <AL name="fields.maths">invented a number</AL> to <E1 /> it yet.</p>
    <p>However, if you assume that <QL>consciousness</QL> (somehow, the exact mechanics <LRL name="superpositions">to be ‘discovered’</LRL> <PL name="all.time">later</PL>) has the ability to <i>influence</i> those otherwise-random ‘fluctuations’… then your thinking has <i><QL name="evolution">evolved</QL></i> (and welcome to the ‘<Eon Eon />’, by the way), and your <NW>POC</NW> no longer lives in a universe where everything is determined by random chance, but in one where the <LRL>imagination</LRL> (and <QL>conscious</QL> <NW>agency</NW>) are very real factors.</p>
    <p>To <LRL>shift</LRL> the <QL name="unity">whole</QL> of <LRL name="gaiacentrism">Planet Earth</LRL> to this more helpful and less <AL name="sick">self-sabotaging</AL> mode of thinking, get out there, <QL name="whatcanido">start spreading this</QL> like a <OML name="ideas">virus</OML> (being careful that <AL name="notnew.lennon.possessions">those who already ‘have’</AL> don’t <PL name="dangerous">corrupt</PL> it with <NW>individual greed</NW>, <PL name="sweeping.ig">as they did</PL> with the message of <AL name="notnew.">Jesus</AL>), and let’s get out there – as <QL name="unity.answer">one</QL> <LRL name="gaia">planet</LRL>, <QL name="unity.1">one</QL> ‘<QL name="body.">super-organ</QL>’ of <QL>consciousness</QL> – and show the <LRL>notion</LRL> of <NW>entropy</NW> that <i><NW>agency</NW></i> is the boss of <i>this</i> <NW>level</NW> of <LRL name="scope">our unierse</LRL>.</p>
    <p>Okay?</p>
  </div>,
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},


];

