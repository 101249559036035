import React from 'react';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, Qual, SillyName} from '../common';

export const abilities = [

// Maybe you are smarter that the author
{
  name: "smarter",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if <i>you</i> are snarter than the author(s) of this website?
  </span>,
  outside: <span>
    Like, <i>way</i> smarter. (I mean, just look at all the typos and broken links on this thing.)
  </span>,
  preview: <div className="or-maybe-preview">
    <p>There’s always a possibility. There’s always a possibility (and, to be honest, an <QL name="qualifiers">extremely good, almsot-certain possibility</QL> in <i>some</i> <AL>field</AL>) that your <PL name="individuality">individual</PL> <NW>POC</NW> (or <AL name="fields.cs">instance of consciiousness</AL>, ‘<QL name="body.">cell</QL>’, etc.) is ‘smarter’ than <QL name="author">whoever wrote this</QL> (one or many ‘<PL name="fungibility">people</PL>’ – assuming that the author of this isn’t <i><AL>stupid</AL></i> and <LRL>understands</LRL> that a ‘<QL name="hivemind">hive mind</QL>’ thinks <QL name="hivemind.intelligence">better</QL> than a <NW>heterognostic</NW> ‘<QL name="polygnosticity.">mind</QL>’ on its own).</p>
    <p>So, using <AL name="fields.maths">mathematical</AL> (<AL name="fields.logic">logical</AL>) <PL name="language">notation</PL> – if only to try to make this look more like a ‘<PL name="comprehensive.level">real</PL>’ <AL name="disciplines.">science</AL> – we can assume that ∃<i>f</i> (<i>f</i> being some <AL>field</AL> of <LRL>knowledge</LRL>, and the symbol ‘∃’ stipulating <i><LRL name="oneorzero">existence</LRL></i>) in which <i>you</i>(r <NW>POC</NW>) do(es) ‘better’ the <QL>author(s)</QL> of this thing.</p>
    <p>That said, <i>f</i> <QL name="qualifiers">probably</QL> is <i>not</i> thinking <NW word="synecdocentrism">synecdocentrically</NW> – or at least not on the <OML>level</OML> of <i>this</i> <QL>Weltanschauung</QL>, which is <QL name="definitive">truly trying</QL> to ‘<LRL>leave no stone unturned</LRL>’.</p>
    <p>Or there’s something that this <AL>framework</AL> – even as a very minimalist one, which <LRL name="sin">tries</LRL> to avoid <QL>definitive</QL> statements and <LRL name="superpositions">balance <i>all</i> possibilities</LRL> – might have overlooked… or some <AL name="dysfunctional.">fragment</AL> of humanity’s <NW>individual greed</NW> that might have crept in here.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// Maybe the bulk of humanity is just too stuid to understand this
{
  name: "stupid",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if the bulk of humanity were just <i>incapable</i> of ever understanding this?
  </span>,
  outside: <span>
    It’s the flip side of <LRL name="superpositions">weighing</LRL> that you might be way <i><OML>smarter</OML></i> than any <PL>individual</PL> currently <AL name="framework">dealing with</AL> <NW>synecdocentrism</NW>.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>Not to <QL>just</QL> <OML name="offensive">offend</OML>, but it’s something that – while <LRL name="motto">leaving no stone ‘unturned’</LRL> – must be <i><LRL name="superpositions">considered</LRL></i>. Could it be not only that <QL>humanity</QL> does not <i>want</i> to <LRL>imagine</LRL> that we would have a better world without <PL>individuality</PL>, but that many humans are <i>physically incapable of this act of <LRL>imagination</LRL></i>?</p>
    <p>A sad thihng to consider, especially as <i>creativity will be our <QL>salvation</QL></i>. Or, maybe better said, <i>would have been</i>.</p>
    <p>Let’s let <QL>humanity</QL> at this and pray they don’t corrupt it with a <LRL name="understand">misunderstanding</LRL> that <Qual.may /> result from a failure of the <LRL>imagination</LRL>. (Or it would be a <PL name="explainage">failure</PL> of ‘<NW>explainage</NW>’.) </p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// What if some people were just BAD for the body?
{
  name: "bad",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if some people were just <i>bad</i>?
  </span>,
  outside: <span>
    (For <LRL name="gaia">Earth</LRL>’s whole conscious ‘<QL>body</QL>’.)
  </span>, 
  preview: <div className="or-maybe-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: <NW>synecdocentrism</NW> (as a <AL>framework</AL>) exists on a ‘<OML>level</OML>’ that’s <i><LRL>deeper</LRL></i> than the <NW>illusion</NW> of <PL>individuality</PL> (i.e., outside of the ‘<AL name="mirrors">box of mirrors</AL>’ of <NW>atomocentrism</NW>), so it doesn’t actually <i>care</i> about <QL>human</QL> individuals. It cares about <i><PL>ideas</PL></i>.</p>
    <p>And there <i>are</i> ‘bad’ people in <NW>synecdocentrism</NW>. That’s not a surprise, nor a secret; just see ‘<PL>evil</PL>’. However, the <QL>Weltanschauung</QL> <E2 suffix="s" italic /> this ‘<PL>evil</PL>’ with the (admittedly <PL>sweeping</PL>) concept of <i><NW>individual greed</NW></i> – and that <NW>individual greed</NW> (not any <QL>human</QL> <PL>individuality</PL>) is the real <NW>synecdocentric</NW> <i>enemy</i>.</p>
    <p>A biological body fights an infection with ‘immune system’ that targets and kills these ‘bad’ cells… though (<QL name="qualifiers">probably</QL> this doesn’t <i>quite</i> work by analogy; this website is <i>not</i> – as much as it can be <QL>definitive</QL> – recommending that anyone who <PL name="belief">believes</PL> that they are a ‘<AL name="notnew.plato.kings">philosopher-king</AL>’ (or <QL name="gnosticity.">polymath</QL>) go out and <OML name="privacy">murder everyone</OML> who refuses to <LRL name="communism">conform</LRL>. Hopefully’s there’s a less violent way forward.</p>
    <p>However, once you see <LRL name="gaia">Planet Earth</LRL> as a <AL>system</AL> (because <PL name="sweeping">everything</PL> is a <PL name="uncomfortable.">system</PL>) you can <E2 /> these ‘bad’ (<NW word="individual greed">individually-greedy</NW>) <QL>humans</QL> – as ‘<QL name="body.">cells</QL>’ in <LRL name="gaia">Earth</LRL>’s ‘<QL name="body.">super-organ</QL>’ – as being (<QL name="metaphors">allegorically</QL> infected vith <QL name="body.">viruses</QL>. We can’t <PL name="denail">deny</PL> (within the admittedly-<PL>sweeping</PL> <LRL name="scope">scope of the observable</LRL>) the obvious.</p>
    <p>If you (the reader perusing this site) <i><PL name="interpersonal">know</PL></i> any of these ‘infected’ <NW word="poc">POCs</NW>, please try to gently <AL name="sleeping">wake</AL> them (or otherwise <AL name="mirrors">break them out</AL> of <PL>denial</PL>) so that <NW word="synecdocentrism">synecdocentrists</NW> don’t have to hijack the <NW>nation-state system</NW> and impose <OML>authoritarianism</OML> to <OML name="privacy">murder</OML> or <OML name="sterilisation">force-sterilise</OML> every ‘bad’ <QL name="body.">cell</QL> (or – <LRL name="gaia">as a planet</LRL> – <OML name="kaput">die</OML> trying).</p>
  </div>,
},

// What if this is just too OFFENSIVE
{
  name: "offensive",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if this website were just too <i>offensive</i> to do any good?
  </span>,
  outside: <span>
    It <OML name="things">hopes</OML> not.
  </span>, 
  preview: <div className="or-maybe-preview">
    <p>Because if all that it manages to do is to <i><PL name="ick.rude">offend</PL></i> the bulk of <QL>humanity</QL> – and not get a single <NW>POC</NW> to actually <AL name="mirror">break out</AL> (of what it – offensively – calls ‘<AL>Hell</AL>’)… then <QL name="qualifiers">mightn’t</QL> you say that (seeing as the <PL name="endsjustifymeans">ends justify the means</PL> under <NW>synecdocentrism</NW>) that this website is actually (<NW word="synecdocentric">synecdocentrically</NW>) ‘<PL>evil</PL>’?</p>
    <p>Yes, you could say that. However this <QL>Weltanschauung</QL> – if a <AL>framework</AL> could <OML name="ideas">feel</OML> – <QL name="qualifiers">must</QL> get very, very tired of dealing with a <AL>dysfunctional</AL> ‘<LRL name="gaia">animal</LRL>’ comprising <AL name="sleeping">groggy, half-awake</AL> <QL name="body.">cells</QL> who spend the bulk of their time <AL name="illusions">arguing</AL> over their make-believe (upper-<NW>level</NW>) <LRL>game</LRL> when the only thing they need to do to ‘<NW>coalesce</NW>’ (and live in the <NW word="mechanozoic">Mechanozoic-Eon</NW> ‘<QL name="hivemind">hive mind</QL>’ <i>utopia</i> of <LRL name="communism">synecdocentric communism</LRL> is to shrug off their <i><NW>individual greed</NW></i>. (<PL name="sweeping.ig">Right</PL>?)<sup>†</sup></p>
  </div>,
  topFootnote: <p>† Though, to be honest, this <E1 word="explanation" /> <Qual.probably /> isn’t helping.</p>
},

// What if Earth has over-specialised
{
  name: "specialisation",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if Earth’s (conscious) ‘body’ were already <i>overly</i> specialised?
  </span>,
  outside: <span>
    Who even <QL name="gnosticity">knows</QL> what that <i>means</i> any more? Call in a <AL name="fields.">linguistic</AL> ‘specialist’.
  </span>, 
  preview: <div className="or-maybe-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: it (<QL name="qualifiers">probably</QL>) <i>is</i>. Because the rampant <PL name="individuality">individualism</PL> (and <QL name="subjectivity">subjectivism</QL>) of <PL>neoliberals</PL> is <i>not</i> working, and Earth needs a few ‘<AL name="notnew.playto.kings">brain cells</AL>’ who can see the <QL name="gnosticity.polymath">big picture</QL> and help guide this whole planetary (and scour it – if not of <OML name="bad">bad <i>people</i></OML> – at least of poisonous (<LRL name="trumpcard">unhelpful</LRL>) <i><PL>notions</PL></i> that infect our thinking like <QL name="body.">viruses</QL>.</p>
    <p>To give an example: if (to be – sorry – a <LRL name="gaiacentrism">gaiacentrist</LRL>) Earth as a planet is a <LRL name="gaia">single organism</LRL> – which <OML name="gaia">why not</OML> presume that it is? – no single <NW>POC</NW> on it fully <LRL>understands</LRL> the whole <LRL name="gaia">machine of Planet Earth</LRL>… and so <QL>human</QL> consciousness (on a <LRL name="gaia">global level</LRL>) is regressing to a state of frighteningly-high <NW>entropy</NW> and <i>acting</i> (on the <LRL name="gaia">whole</LRL>) not as a <i>conscious</i> animal but as a <AL>sleeping</AL> one – meaning that <LRL name="gaiacentrism">Earth</LRL> is presently dominated not by any <NW>Mechanozoic</NW> <QL name="evolution">future-thinking</QL> (or at <i>all</i> long-term-thinking) force, but by the <NW>Phanerozoic</NW> (and Archaeozoic and Proterozoic) Eon forces of <QL name="">natural selection</QL>: the same <NW word="entropy">entropic</NW> (random) forces that have shaped it throughout its past four billion biology-dominated years.</p>
    <p>If your <NW>POC</NW> managed to <LRL name="understand">follow</LRL> that – which glanced (cursorily) on <AL>disciplines</AL> as diverse as <AL name="fields.">biology</AL>, <AL name="fields.">physics</AL>, and <AL name="fields."></AL>, not to mention all the <QL>metaphor</QL>) – then ‘you’ could well be a ‘polymath’, or at least one in the making.</p>
  </div>,
},

];



