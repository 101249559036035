import React from 'react';
import {Link} from 'react-router-dom';
import './Box.css';

class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: (props.startOpen) ? true : false,
    };
  }

  render() {
    const {
      prelude,
      header,
      outside,
      expandText,
      coda,
      codaInside,
      redirect,
      redirectText,
      noborder,
      className,
    } = this.props;
    const inside = (this.props.inside) ?
      this.props.inside : this.props.children;
    const {expanded} = this.state;
    const showCodaInside = (expanded) ? codaInside : null;
    return <div className={"box" + ((className) ? " " + className : "") + ((noborder) ? " noborder" : "")}>
      {prelude && <div className='box-prelude'>
        {prelude}
      </div>}
      {header && <div className='box-header'>
        {header}
      </div>}
      {outside && <div className='box-outside'>
        {outside}
      </div>}
      {inside && expanded && <div className='box-inside'>
        {inside}
      </div>}
      {inside && (!this.state.expanded || this.props.collapsible) && <button
        className='box-collapse-button'
        onClick={() => this.setState({expanded: !expanded})}
      >
        {(expanded) ? "Collapse" : (expandText || "Expand")}
      </button>}
      {redirect && <Link to={redirect}>
        <button
          className='box-collapse-button'
          onClick={() => this.setState({expanded: !expanded})}
        >
          {redirectText || expandText || "Expand"}
        </button>
      </Link>}
      {(coda || showCodaInside) && <div className='box-coda'>
        {coda}
        {showCodaInside}
      </div>}
    </div>;
  }
}

export default Box;
