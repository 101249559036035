import React from 'react';
import Box from './Box';
import './ErrorBox.css';

export default function ErrrorBox(props) {
  var message = props.children;
  if (!message) {message = props.error;}
  return <Box
    className="error-box"
    header="Error"
    outside={message || "Unspecified error."}
  />;
};
