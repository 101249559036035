import React from 'react';
import WName from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const deep = {
  name: "deep",
  alternatives: "levels,deeper",
  prelude: <span>
    If you take absolutely <i>nothing</i> at surface value…
  </span>,
  header: <span>
    <WName cap noNW /> exists one level <i>deeper</i> than wherever you are.
  </span>,
  outside: <span>
    How is that even possible?
  </span>,
  preview: <div className="long-read-preview">
    <p><WName cap /> is an <LRL name="imagination">imaginative</LRL> <QL name="saalvation">challenge</QL>, yes. What that means is that it not only seems to be <PL name="all.level">rather blithe</PL> about what is ‘<LRL name="i.">real</LRL>’, </p>
  </div>,
  answer: <div name="long-read-link">
    <p>Yes, this <QL>Weltanschauung</QL> might all be dead <i><OML>wrong</OML></i>, and <QL>unity</QL> may <i>not</i> be the ‘bottom’ <NW>level</NW>… but it seems like a wiser <OML name="god">leap of faith</OML> to make than <NW>atomocentrism</NW>; no other (non-<WName ic/>) <E2 /> is so <PL>comprehensive</PL> when it comes to really <i><PL name="sweeping.handful">boiling things down</PL></i>, making sense out of this <AL name="fields.science">scientifically</AL>-<LRL name="scope">observable universe</LRL>.</p>
{/*    <p>If your <NW>POC</NW> accepts <WName />, it means that you are swearing off <QL>huamnity</QL>’s <NW>illusions</NW> 
 and there might really be <OML name="forms">things in the world</OML> beyond just the <i><NW>illusions</NW></i> of them. <QL name="definitive">Believe it or not</QL>, <WName /> takes that <PL name="comprehensive">all</PL> into account – while <LRL name="superpositions">balancing it</LRL> with the possibility that all of <PL>ethics</PL> (and most of past <LRL name="anthropocentrism">human</LRL> philosophy) is just a matter of <AL>arguing over illusions</AL>.</p>
    <p>If that <E1 word="explanation" /> didn’t make any sense, that’s <QL name="qualifiers">possibly</QL> because you want to believe that <OML name="levels">a ‘rose’ is just a rose</OML>, and haven’t learned (or <PL name="uncomfortable">don’t <i>want</i></PL>) to think <WName ically />.</p>*/}
    <p>[Building...]</p>
  </div>,
  //text: <div className="long-read-text">
  //</div>,
};
