import React from 'react';
import WName, {WArticle} from '../WName';
import Eon, {EonArticle} from '../Eon';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {
  E1, E2, Qual, TLDR,
  SillyName, Rando, Switcheroo,
  SwearMaybe, Planet,
  the_key_is_imagination
} from '../common';

const subdividing = <p>The sub-classification of various <AL name="">fragments</AL> of the <QL name="unity">whole</QL> (‘<QL>body</QL>’ of <QL>consciousness</QL>) is merely a result of the <PL name="all.scale">scale-invarience</PL> of the <WName ic /> <QL>Weltanschauung</QL>. <PL name="ick.systems">Everything</PL> is a <AL>system</AL>, and the notion of ‘<NW>coalescence</NW>’ (into one universe-wide conscious ‘<QL>body</QL>’) means that <QL>consciousness</QL> (<OML name="harmony">employing</OML> <NW>agency</NW>, which we have to hope <OML name="will">really <i>exists</i></OML>) just has to re-iteratively <OML name="harmony">unify</OML> at <PL name="all.scale">larger and larger scales</PL>, until there isn’t a quark in this soup of our <NW>level</NW> of ‘<LRL>reality</LRL>’ that isn’t <i><LRL name="superpositions">observed</LRL></i> (and <LRL>understood</LRL>) – and <i>then</i>, as an <QL name="hivemind.mortality">immortal</QL> ‘<QL name="hivemind">hibve mind</QL>’, we can <PL name="all.level">worry</PL> about whether we are all just in a <OML>story</OML>.</p>;

// How are we (consciousness) a 'body'?
export const body = {
  name: "body",
  parentName: "how consciousness is a body",
  question: <span>
    How is <i>consciousness</i> a ‘body’?
  </span>,
  clarification: <span>
    And how are <PL>individuals</PL> (i.e., <NW>heterognostic</NW> <AL name="dysfunctional.">fragments</AL> of <QL>consciousness</QL>) ‘<QL name="body.">cells</QL>’?
  </span>,
  answerPreview: <div className="answer-preview">
    <p><LRL>Imagine</LRL> if all the cells in <AL name="handicap">your body</AL> developed their own <AL name="sick.did">personalities</AL>, and <AL name="notnew.lennon.peace">refused</AL> to <OML name="harmony">work alongside one another</OML> (i.e., <PL name="sweeping.ig">developed</PL> <NW>individual greed</NW>). <LRL>Imagine</LRL> if they <QL name="change">started</QL> to <OML name="murder">kill</OML> <LRL name="usandthem">one another</LRL>. <LRL>Imagine</LRL> if <PL name="all.scale">groups</PL> of <AL name="fields.biology">connected cells</AL> – the <QL name="body.">organs</QL> – turned against (and started <PL name="privacy">keeping secrets from</PL>) one another. The <Switcheroo values={[<span><Rando options={["liver", "bladder", "stomach"]} /> says, ‘I’m ceasing relations with the <Rando options={["skin", "eyes", "reproductive organs"]} />,’</span>, <span><Rando options={["lungs", "skeletal muscles", "kidneys"]} /> raise sanctions against the <Rando options={["heart", "brain", "bones"]} /></span>]} middle=" and the " />.</p>
    <p><AL>Sick</AL> like that, you wouldn’t have a body for <QL name="urgency">very long</QL>.</p>
    <p>Yet this is a pretty spot-on <QL>metaphor</QL>for what <QL>humanity</QL> has <LRL name="gaiacentric">on Earth</LRL> at the moment. In the case of <LRL name="gaia">Earth</LRL>, the ‘<QL name="body.">cells</QL>’ are <PL name="individuality">individual humans</PL>, and the ‘<QL name="body.">organs</QL>’ are <i>countries</i> (in the <NW>nation-state system</NW>) or <i>companies</i> (in the <NW>money system</NW>).</p>
    <p>The <QL name="unity">unified body</QL> is the <OML name="aliens">planet-sized</OML> <QL name="body.">super-organ</QL> that we <PL name="invisible">don’t have yet</PL>, but (<OML name="alone">starting from Earth</OML>) <QL name="life.meaning">need to build</QL>, <LRL name="communism">together</LRL>.</p>
  </div>,
  answer: <div className="answer">
    <p>If it’s really so simple, then why hasn’t anyone said it so simply before? Well, they <i><AL name="notnew">have</AL></i>, but that sort of message tends to get shoved aside by the <NW>MNS system</NW> and anything that can make a <i>profit</i>. <LRL>Blame</LRL> (<PL name="sweeping.ig">of course</PL>) <QL name="anthropocentrism">humanity’s</QL> <NW>individual greed</NW> </p>
  </div>,
  sublist: [

  // How are individuals 'cells'?
  {
    name: "cells",
    alternatives: "cell",
    question: "If we are a body, why are individuals ‘cells’?",
    clarification: <span>
      ‘But mamma, I don’t <i>want</i> to be a “cell”!’
    </span>,
    answerPreview: <div className="answer-preview">
      <p>Just like a biological body, the ‘body’ of the <QL name="unity">unified consciousness</QL> comprises a multitude of different <i>types</i> of cells.<sup>†</sup> These cells are (in a working body) decidedly <i>not</i> all the same; each <PL name="equality">serves a different function</PL>. But they all belong to the <LRL name="gaia">same <i>organism</i></LRL> – and, if they end up hurting that larger organism (of which they’re all a part), both the organism <i>and</i> the individual cells aren’t going to last very long. (In <AL name="fields.">biology</AL> it’s called a <i>disorder</i>.)</p>
    <p>The notion of a ‘body’ of consciousness is just zooming out and looking at all of consciousness in this way (and <LRL name="gaiacentrism">Earth</LRL> as a subset).</p>
    </div>,
    topFootnote: <p>
      † In fact, on the level of <PL name="individuality">individuals</PL>-as-cells, each ‘cell’ is so large and complex that no two ‘cells’ of the ‘body’ of consciousness are exactly alike. You’re still all ‘cells’. Human individuals’ larger size just adds a layer of complexity – but it doesn’t alter the underlying metaphor.
    </p>
  },

  // Organs?
  {
    name: "organs",
    alternatives: "organ",
    question: <span>
      If we are a body, why are groups of individuals ‘organs’ when they spend most their time <i>competing</i> with each other?
    </span>,
    clarification: <span>
      Because the organs of a <AL name="fields.">biological</AL> body work <i>together</i>, and <NW>nations</NW>, <NW>companies</NW>, and feuding <NW word="family system">families</NW> <OML name="harmony">mostly</OML> do <i>not</i>.
    </span>,
    answerPreview: <div className="answer-preview">
      {subdividing}
    </div>,
    answer: <div className="answer">
      <p>So this isn’t implying that <NW>nations</NW>, <NW>companies</NW>, etc. actually <i>act</i> (<LRL name="gaiacentrism">on Earth</LRL>) like organs in a <AL name="fields.">biological</AL> (<QL>human</QL>) <AL name="container">body</AL>. It’s saying thats – if our <AL name="sick.fragments">fragment</AL> of the ‘<LRL name="scope">universal</LRL> <QL>conscious</QL> ‘<QL>body</QL>’ (<PL name="all.scale">planet-sized</PL> ‘<NW>POC</NW>’) wants to <OML name="kaput">not die</OML> – they <i><OML name="harmony">should</OML></i> act that way.</p>
      <p>But the ‘<QL name="body.">super-organ</QL>’ of <AL name="systems">Earth</AL> is very <AL>sick</AL>, remember?</p>
      <p>Can you(r <NW>POC</NW>) <QL name="whatcanido">help change that</QL>?</p>
    </div>,
  },

  // SUPER Organs???
  {
    name: "super-organs",
    alternatives: "super-organ",
    question: <span>
      What the hell is a <i>super-organ</i>?
    </span>,
    clarification: <span>
      Has this <QL>metaphor</QL> over-extended itself?
    </span>,
    answerPreview: <div className="answer-preview">
      {subdividing}
    </div>,
    answer: <div className="answer">
      <p>The notion of a ‘super-organ’ is just the <LRL name="gaia">planet-based</LRL> (or <OML name="aliens">galaxy-based</OML>, etc.) equivalent of a single ‘<QL name="body.">cell</QL>’ within the <LRL name="gaia">Earth</LRL>-<AL>system</AL>, in order to avoid <QL>anthropocentrism</QL> and <LRL>gaiacentrism</LRL> (or otherwise thinking that Earth is <PL>special</PL>). It’s called <PL name="all.scale">scale-invariance</PL>, and it’s how <WName /> manages to be so <OML name="offensive">damn</OML> <i><QL>comprehensive</QL></i>.</p>
    </div>,
  },

  ],
};

