import React from 'react';
import Problem from './Problem';
import PreviewLink from '../PreviewLink';
import './ProblemLink.css';

export default function ProblemLink(props) {
  return <PreviewLink
    {...props}
    PreviewComp={Problem}
    linkClassName="problem-link"
    linkURLPrefix="problems"
  />;
};

/*import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {toTop} from '../../Layout';
import Problem from './Problem';
import './ProblemLink.css';

export default function ProblemLink(props) {
  const [problemDiv, setProblemDiv] = useState(null);
  const hideProblem = () => {
    setProblemDiv(null);
  }
  var {name} = props;
  if (!name) {name = props.children;}
  if (!name) {return "Error: no name provided";}
  var display = props.children;
  if (!display) {display = name;}
  return <div style={{display: "inline"}}>
    <span onMouseEnter={(e) => {
      var mouseX = e.pageX;
      var w = window.innerWidth;
      var xMax = w - 430;
      var x = (mouseX > xMax) ? xMax : mouseX;
      if (x<0) {x = 0;}
      setProblemDiv(
        <div
          className="problem-preview-box"
          style={{left: x, top: e.pageY+10, maxWidth: w-x-30}}
        >
          <Problem name={name} preview />
        </div>
      );
    }} onMouseLeave={()=>hideProblem()}>
      <Link
        className="problem-link"
        to={"/menu/problems/" + name}
        onClick={toTop}
      >
        {display}
      </Link>
    </span>
    {problemDiv}
  </div>;
};
*/
