import React from 'react';
import ProblemLink from '../problems/ProblemLink';
import QuestionLink from '../faq/QuestionLink';
import AngleLink from './AngleLink';
import NewWord from '../neologisms/NewWord';
import Creature from './Creature';

// We are children arguing over illusions.
export const arguing_over_illusions = {
  name: "illusions",
  alternatives: "arguing over illusions",
  prelude: <span>
    You can argue about things like ‘rights’ and ‘ethics’ all you want.
  </span>,
  header: <span>
    We are like children arguing over <NewWord>illusions</NewWord>
  </span>,
  outside: <span>
    Does a <Creature /> have one tail or two?
  </span>,
  preview: <div className="angle-preview">
    <p>Things like <ProblemLink>ethics</ProblemLink> (and <ProblemLink>individuality</ProblemLink> itself) are actually just <NewWord>illusions</NewWord> <QuestionLink name="polygnosticity">created by our polygnostic nature</QuestionLink>.</p>
  </div>,
  text: <div className="angle-text">
    <p>Let’s imagine that you have two children playing a game, in which they live in a world inhabited by conscious biological beings – essentially just a <QuestionLink name="stories">re-creation of Earth</QuestionLink> – but these animals aren’t <i>human</i>. They’re <i>aliens</i> – called ‘<Creature plural />’ – and they walk on all fours and have tails. (Other than that they’re basically humans; their consciousness has the same <NewWord>polygnosticity</NewWord> as ours, and they’ve got the same <ProblemLink>individuality</ProblemLink> as humanity.)</p>
    <p>But just how <i>alien</i> are they? Does a <Creature /> have one tail, or <i>two</i>?</p>
    <p>You can (hopefully) see that the answer to this doesn’t actually <i>matter</i> in the least – because <Creature plural /> don’t really exist; they’re just figments of the children’s imaginations. The exact anatomy of a <Creature /> is <i>undefined</i>; in terms of physics, it exists (as much as it really ‘exists’) in a <i>superposition</i> of states. However – because <Creature plural /> don’t refer to anything <i>real</i> in our universe – unlike as in physics, there’s no way to go out and consciously <i>observe</i> a <Creature />… thereby collapsing the waveform.</p>
    <p>Imagine that now there’s a <i>third</i> child playing ‘<Creature plural />’ – who also agrees that <Creature plural /> have two tails. Does that alter the <i>facts</i> of the world? In other words, do we live in scientific <ProblemLink>relativism</ProblemLink>?</p>
    <p>No. The anatomy of <Creature plural /> is still an <NewWord>illusion</NewWord>. The only difference is that now our <NewWord>polygnostic</NewWord> consciousness contemplating <Creature plural /> has now further broken: from two separate fragments, to <i>three</i>.</p>
    <p>Further break up that consciousness of children – into 8,000,000,000+ ‘individuals’, who all fervently <i>don’t</i> agree on the rules of the game we’re all playing – and you’ll begin to get a sense of the <QuestionLink name="polygnosticity">problem</QuestionLink>.</p>
  </div>,
};
