import React from 'react';
import WName, {WArticle} from '../WName';
import Eon from '../Eon';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {
  E1, E2, Qual,
  imagine_a_shift,
  SwearMaybe,
} from '../common';

// This is really hard to understand
export const hard = {
  name: "hard",
  prelude: "This literally just does not make any sense.",
  header: <span>
    <WName noNW cap /> is really <SwearMaybe noOML options={["bloody", "bleeping", "____ing"]} /> <i>hard</i> to understand.
  </span>,
  outside: <span>
    What does it mean by ‘<LRL>understand</LRL>’, anyway.
  </span>,
  preview: <div className="problem-preview">
    <p>The fault (because when talking about things that are not <PL>individuals</PL>, it’s okay and sometimes even helpful to play the ‘<LRL>blame game</LRL>’) is <i><NW>explainage</NW></i>.</p>
    <p><PL name="explainage">Explainage sucks</PL>. Period.</p>
    <p><LRL name="superpositions">Whether or not</LRL> <PL name="comprehensive">every</PL> (or even <i><OML name="smarter">any</OML></i>) <QL>human</QL> <NW>POC</NW> is <OML name="stupid">able</OML> or <PL name="unready">ready</PL> to grapple with <WName /> is a bit beside the point until it can be <E1 suffix="ed" italic />.</p>
    {imagine_a_shift}
  </div>,
  //text: <div className="problem-text">
//  </div>,
};
