import React, {useState, useContext} from 'react';

/* Make a silly name for a make-believe new species. */

// Store the creature's silly name in a global variable.
// (So that it's the same across each component during a single page load.)
var creature = null;

// Functions for generating a kids' name for a fake creature.
const firstLetter = ["b", "d", "f", "g", "m", "n", "p", "s", "t", "w", "z"];
const middleLetter = ["b", "d", "f", "g", "p", "z"];
const lastLetter = ["b", "d", "g", "m", "n", "p", "t"];

const generate = (list, numTimes=1) => {
  let num = Math.floor(Math.random()*list.length);
  let letter = list[num];
  return (letter.repeat) ? Array(numTimes+1).join(letter) : letter.repeat(numTimes);
}

export default function Creature(props) {
  // If not defined, build the name in this structure:
  if (!creature) {
    creature = 
      generate(firstLetter) + 
      generate(["i", "a", "u", "y"]) + 
      generate(middleLetter, 2) + 
      generate(["ly", "or", "en"]) + 
      generate(firstLetter) + 
      generate(["e", "o"]) + 
      generate(middleLetter, 2) + 
      generate(["i", "e", "u"]) + 
      generate(lastLetter);
  }
  // Return just the name as text, with 's' if plural.
  return (props.plural) ? creature + 's' : creature;
};
