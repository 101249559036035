import React from 'react';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR} from '../common';

// It makes huge generalisations
export const sweeping = {
  name: "sweeping",
  parentName: "these sweeping concepts",
  prelude: <span>
    Nobody <PL name="uncomfortable">likes</PL> to be told: ‘everything you do is X, and the only thing that is <i>not</i> X is <i>this</i>’.
  </span>,
  header: <span>
    Synecdocentrism paints with (extremely) broad brush-strokes.
  </span>,
  outside: <span>
    To generalise: <QL name="qualifiers">just about</QL> everything that comes out of this <QL>Weltanschauung</QL> is a sweeping generalisation.
  </span>,
  preview: <div className="problem-preview">
    <p>Just take a gander at the ‘<AL>bullet points</AL>’. How much do you see the words ‘all’ or ‘every’?</p>
    <ul>
      <li><i>All</i> of <QL name="anthropocentrism">humanity’s problems</QL> boil down to our conscious <NW>polygnosticity</NW>.</li>
      <li><i>All</i> human-caused ‘evil’ is a form of <NW>individual greed</NW>.</li>
      <li><i>All</i> of <LRL name="gaiacentric">Earth</LRL>’s past has been controlled by the randomness (or ‘<NW>entropy</NW>’) of the <NW>Phanerozoic</NW> Eon (and the same as we had in the ‘Archaeozoic’ and ‘Proterozoic’ Eons: the whole time that our <LRL name="gaia">living planet</LRL> has been <i>biological</i>), but the way <i>forward</i> is to redefine <QL>evolution</QL> and inject consciousness into it.</li>
      <li><i>All</i> notions of an ‘<OML>external God</OML>’ are <NW>mythology</NW>.</li>
      <li><i>All</i> forms of <PL>privacy</PL> (and any <QL name="polygnosticity.privacy">not-sharing of information</QL>) are ‘<PL>evil</PL>’.</li>
    </ul>
    <p>Hopefully by now you get the point.</p>
  </div>,
  text: <div className="problem-text">
    <p>Except that <NW>synecdocentrism</NW> is less of a <QL>Weltanschauung</QL> of sweeping <QL>definitive</QL> <i><LRL name="prescriptive">advice</LRL></i> than a <AL>framework</AL> of sweeping <i>concepts</i>. The concepts are so vast that they may encompass literally everything your <NW>POC</NW> – <AL name="handicap">forced</AL> to inhabit a <QL>human</QL> body (with its often <NW word="ig">individually-greedy</NW> wants and <i>needs</i>) trapped on <LRL name="gaiacentric">Earth</LRL>, with its <QL name="pg">inability</QL> to directly <LRL name="communism">share</LRL> <i>experiences</i> with any ‘other’ – <i><QL name="pg.">knows</QL></i>.</p>
    <p>[Under construction]</p>0
  </div>,
  sublist: [

  // Is EVERYTHING 'individual greed'?
  {
    name: "individual greed",
    alternatives: "ig",
    prelude: <span>
      How<sup>†</sup> is it helpful to <NW home>coin a term</NW> this damn <i>broad</i> and then call it ‘<PL name="evil">bad</PL>’?
    </span>,
    header: <span>
      This seems to define <i>everything</i> as ‘individual greed’
    </span>,
    outside: <span>
      You (the reader) probably got here because you were reading some other <NW>synecdocentric</NW> <E1 word="explanation" /> and it (suddenly, seemingly out of the blue) pulled in the concept of <NW>individual greed</NW>.
    </span>,
    headerFootnote: <p>
      † It’s helpful to define things (even with <i>extremely</i> broad brush strokes) becuase then you can <E2 /> things; the only demon that you can’t fight is the one that you cannot <i>name</i>. (And yes, <NW>synecdocentrism</NW> has answers to everything; there aren’t ‘rehtorical questions’ here. <QL name="qualifiers">Unless</QL>… would this website deliberately ask one just to be <i>ironic</i>?)
    </p>,
    preview: <div className="problem-preview">
      <p>Okay, now before you say, ‘but <i>everything</i> <LRL name="gaiacentrism">on Earth</LRL> is <NW word="synecdocentric">synecdocenntrically</NW> defined by <NW>individual greed</NW>!’</p>
      <ol>
        <li>That’s not entirely true; as a counter-example take this website: it will run no adverts (and so gain no <NW>money system</NW> <i>profit</i>) and otherwise not help any <PL>individual</PL> in the least (though it might – <NW word="god">inshallah</NW> – help <LRL name="gaia">Earth’s conscious ‘body’</LRL>).</li>
        <ul>
          <li>Though this website <LRL name="eons">technically</LRL> exists in the ‘<NW>Mechanozoic Eon</NW>’, where ‘<QL>evolution</QL>’ is fuelled no more by competition (hence <NW>individual greed</NW>) but by <i>collaboration</i>.</li>
          <li>So you could say ‘Everything <LRL name="gaiacentrism">on Earth</LRL> <i>in the <NW>Phanerozoic Eon</NW></i> is/was <NW>individual greed</NW>’… though you could <QL name="qualifiers">probably</QL> still find an exception.</li>
        <li>(Your choice of ‘is’ or ‘was’ depends on from where and when you are reading this.)</li>
        </ul>
        <li>That’s kind of the <i>point</i> of these <PL>sweeping</PL> <NW>synecdocentric</NW> <NW home>neologisms</NW>: to dig all the way to the <i>bottom</i> of things – and work on solving the root <LRL>causes</LRL>, and not just the <i>symptoms</i>.</li>
      </ol>
    </div>,
    // text: <div className="problem-text">
    // </div>,
  },

  // It seems to explain human society as one of a small handful of things
  {
    name: "handful",
//    alternatives: "",
    header: <span>
      It explains<sup>(2)</sup> ALL of human society with only a handful<sup>†</sup> of explanations<sup>(2)</sup>.
    </span>,
    outside: <span>
      That’s ‘<NW>explain</NW>’ in sense two: the <NW word="synecdocentric">synecdocentrically</NW> ‘good’ one.
    </span>,
    headerFootnote: <p>
      † Namely, three.
    </p>,
    preview: <div className="problem-preview">
      <p><TLDR />: <NW>Synecdocentrism</NW> <E2 suffix="s" /> <i><PL name="comprehensive">everything</PL></i>:</p>
      <ol>
        <li><PL name="sweeping.ig">Predominantly</PL> as <NW>individual greed</NW></li>
        <li>Or as <NW>illusions</NW> (<NW>mythology</NW>), or as ‘<AL name="illusions">arguing over illusions</AL>’, or as <QL>humanity</QL>’s (<NW word="illusions">illusive</NW>) ‘<LRL>game</LRL>’</li>
        <li>Or as a <LRL name="symptoms">symptom</LRL> of <QL name="pg">conscious</QL> <NW>polygnosticity</NW>… with which <E2 word="explanation" /> is also <E2 suffix="s" /> #1 and #2.</li>
      </ol>
      <p>Am I right?</p>
    </div>,
    text: <div className="problem-text">
      <p>But the <QL>Weltanschauung</QL> (once <i><LRL>understood</LRL></i>) is definitively <i>not</i> <QL>definitive</QL>; it allows for a lot of <LRL name="superpositions">ambiguity</LRL> – even the notion that it might be all <OML>wrong</OML>. <NW>Synecdocentrism</NW> (as the <NW>modern-day Copernicus</NW>’) is only the best <NW word="induction">logically-induced</NW> universe-<E2 word="explanation" />: resting on only <i>one</i> baseline assumption (that <QL name="unity">we are all one</QL>) rather than the many <LRL name="scope">presumptions</LRL> (or <AL name="framework.puzzle">holes</AL>) in universe-<E2 word="explanations" /> based on <NW>atomocentrism</NW>.</p>
      <p>If it feels like this <QL>Weltanschauung</QL> can <E2 /> <PL name="comprehensive">everything</PL> with a very small number of <E2 word="explanations" />, that’s (<QL name="qualifiers">hopefully</QL>) not because it’s generalising with <QL>definitive</QL> <LRL>answers</LRL> that don’t bother to <LRL name="motto">turn any ‘stones’</LRL>. It’s because – as an <PL name="comprehensive">all-encompassing</PL> and yet still-<i><AL name="framework.">working</AL></i> <AL>framework</AL> – <NW>synecdocentrism</NW> generalises with <PL>sweeping</PL> <i>concepts</i>.</p>
      <p>That said, <NW>synecdocentrism</NW> (even if it seems hard to believe) <i>does</i> have a limited <LRL>scope</LRL>. It deals with the <i>observable</i>: <AL name="fields.">science</AL>. Outside of that, it acknowledges the <i>existence</i> of things like <NW>fictive levels</NW> (assuming that <OML name="levels">a ‘rose’ is not just a rose</OML>, though <AL name="fields.">science</AL> tells us it’s not), and admits that we might just be in a <OML>story</OML> or <OML>simulation</OML>… and then <PL name="ick.story">blithely doesn’t care</PL> except in the case of the <QL name="roadblocks.god">external ‘God’</QL> or the <QL name="roadblocks.soul">individual ‘soul’</QL>, both of which it dismisses (with ‘<NW>Occam’s razor</NW>’) as highly unlikely.</p>
      <p>If it seems to provide <LRL>answers</LRL> to the same questions as human <AL>religion</AL>, that’s because it <i>does</i> answer those – <LRL name="scope">at least</LRL> on <i>this</i> <NW>level</NW>. It <LRL>answers</LRL> them without any <NW>mythology</NW>, but also not caring much about your <PL>feelings</PL> or any of the other ‘<AL name="handicap">wonders</AL>’ of being <QL>human</QL>. In short: it <i><AL name="framework.">works</AL></i> (beautifully) as a <AL>framework</AL> but if <i>exquisitely</i> <PL>uncomfortable</PL>, likely to <PL name="ick.rude">put off</PL> (<QL name="qualifiers">potentially</QL>) <PL name="unready">not ready</PL> to <AL name="sleeping">wake up</AL>.</p>
      <p>Too bad, <QL>humanity</QL>. You (in a mere few thousand years: a <QL name="metaphor">blink of the eyes</QL> in <AL name="fields.geology">geologic time</AL>) have fouled up <LRL name="gaia">our living planet</LRL> to the point where <i>we have <QL name="urgent">no more time</QL></i>.</p>
      <p>Do we try to reset the <OML name="offensive">damn</OML> thing: the dream of many a <NW word="conservatism">conservative thinker</NW> (e.g. <PL>neoliberals</PL>)? Or do we accept that we screwed up ‘<PL>nature</PL>’ (as <PL name="ick.systems">everything</PL>, a <i><AL>system</AL></i>) – and <QL>humanity</QL> (as well as the <LRL name="eons">rest</LRL> of the <NW>Phanerozoic Eon</NW>) is <PL name="extinction">on its way out</PL>, anyway… and <LRL>shift</LRL> to <QL>future-thinking</QL>?</p>
{/*      <p>Down with the <NW>money system</NW>! Down with the nation-state system</NW>! Up with <LRL name="communism">synecdocentric communism</LRL>.</p>
      <p><PL name="endsjustifymeans">Whatever it takes</PL>.</p> */}
    </div>,
  },


  ],
};
