import React from 'react';
import Box from '../Box';
import WName, {WArticle} from './WName';
import AL from './angles/AngleLink';
import QL from './faq/QuestionLink';
import PL from './problems/ProblemLink';
import NW from './neologisms/NewWord';
import LRL from './longreads/LongReadLink';
import OML from './ormaybe/OrMaybeLink';
import {E1, E2, TLDR, Planet} from './common';
import "./Gist.css";

const elevatorpitch = <Box
  header=<span><TLDR />: Here’s the ‘elevator pitch’</span>
  outside=<span>(Okay, maybe in a <QL name="metaphors">very long ‘elevator’</QL>.)</span>
  coda=<p>† No, it is not <PL name="comprehensive.">time-invariant</PL> in <i>that</i> sense; <QL>humanity</QL>’s <NW>individual greed</NW> could still <OML name="kaput">hew it down</OML> (as a <LRL>notion</LRL>) before it even gets out of this <AL name="dysfunctional">mess</AL> that is <LRL name="gaia">Earth</LRL>’s <QL name="body.super-organ">part</QL> of our <AL name="fields.biology">multicellular</AL> ‘<QL>body</QL>’ and <OML name="aliens">off</OML> <LRL name="gaiacentrism">this rock</LRL>.)</p>
  startOpen
>
  <p>The <i>main</i> <LRL name="different">difference</LRL> between <WName /> and <i><NW>atomocentrism</NW></i> (which is <QL name="qualifiers">most</QL> <PL name="sweeping">everything else</PL>: focus on the <i><QL name="body.cells">unit</QL></i> <PL name="ick.systems">instead</PL> of the <LRL name="gaia">whole</LRL> <i><AL>system</AL></i>) is how <WName /> <E2 suffix="s" /> that unit (the ‘<PL>individual</PL>’).</p>
  <p>In the 2020’s <QL name="qualifiers">much</QL> of <QL>humanity</QL> deals with the ‘<PL>individual</PL>’ as not only <i>indivisible</i> but also <i>non-<AL name="fields.linguistics">combinable</AL></i> (into, say, a ‘<QL name="hivemind">hive mind</QL>’). <NW word="atomocentrism">Atomocentrists</NW> <OML name="levels">perceive</OML> that each <i><AL>body</AL></i> has its <i><AL name="notnew.lennon.">own</AL></i> <QL>consciousness</QL> (awareness): meaning that ‘<AL name="self">you</AL>’ do not <LRL name="communism">share thoughts</LRL> with ‘<AL name="self">me</AL>’. Then it seems to fall into <NW>conservative thinking</NW> and forget that things can <QL>change</QL>.</p>
  <p>In short, <NW>atomocentric</NW> science does not know how to <E2 /> <QL>consciousness</QL> (because, yeah, when you open that box of worms it gets <LRL name="different">weird</LRL>). So mostly it doesn’t try. It artificially constrains itself, allowing <QL>humanity</QL> to develop a whole side-field of ‘<OML name="soul">spirituality</OML>’ that <NW>atomocentric</NW> <AL name="fields.">science</AL> wouldn’t touch with a ten-foot pole.</p>
  <p>Then along comes <WName /> – and it doesn’t bother with ‘poles’ of any length; it sticks its hands all in <QL>human</QL> <NW>mythology</NW> and tears it apart: offering alternative <E2 word="explanations" /> for most things that actually <i><AL name="framework.">work</AL></i>, ever if <QL>humanity</QL> <PL name="unready">isn’t <i>ready</i></PL> to accept them, or just <i><PL name="denial">won’t</PL></i>.</p>
  <p><WName cap /> – as a <AL name="fields.physics">quantum-mechanical</AL>-esque <AL>framework</AL> with a limited but massive ‘<LRL>scope</LRL>’ of the <AL name="fields.science">scientifically</AL> <i><OML>observable</OML></i> – <i>allows</i> for the <i><LRL name="superpositions">possibility</LRL></i> of <PL>individual</PL> death-transcendence (in, say, <OML>reincarnation</OML>), but <i>presumes</i> (through its one <AL name="fields.logic">logically</AL>-<NW word="induction">induced</NW> <QL name="definitive">presumption</QL> of <QL name="unity">unity consciousness</QL>: the <OML name="wrong">rock</OML> on which the <AL name="framework.">working</AL> <QL>Weltanschauung</QL> is built) that <OML name="god">if there <i>is</i></OML> a ‘<NW>God</NW>’ (<AL name="hell">separated</AL> from <i><LRL name="usandthem">us</LRL></i>), that <QL name="roadblocks.god">external ‘God’</QL> is only part of an <OML name="forms">intermediate</OML> ‘<NW>fictive level</NW>’… and at the <LRL name="deep">bottom</LRL>-<NW>level</NW> (‘<LRL>reality</LRL>’) <PL name="comprehensive">all</PL> is <QL name="unity.1">unified</QL>.</p>
  <p>In short: <WArticle /> <WName ist /> does not <i>care</i> what lies potentially <i>beyond</i>. The scope is this <PL name="ick.">system</PL>, and <i>it</i> (the <NW>MNS system</NW>) is <i><AL>dysfunctional</AL></i>. So <WName /> is literallly a new world order: an <i>alternative</i>. It has a <PL name="sweeping">massive</PL> <LRL>scope</LRL> (i.e., <PL name="comprehensive">everything observable</PL>) and is <i>invariant</i> in <PL name="comprehensive.">scale</PL>, <i><PL name="comprehensive.">time</PL></i>, <i><PL name="all.">perspective</PL></i>… and even ‘<PL name="comprehensive.level">“level”-invariant</PL>’ – which means that the <AL>framework</AL> 100% <i>does not change</i> even if it turns out that we (and everything we perceive) are just characters in a <i><OML>story</OML></i> (say, <OML name="sillyplanet">told by <Planet.AlienDescription /></OML> – and <NW>atomocentric</NW> – <i><OML>aliens</OML></i>). Talk about <i>robust</i>, eh?</p>
  <p>Oh yeah, it’s also completely <QL name="anthropocentrism">humanly</QL> <i><PL name="uncomfortable">brutal</PL></i>, <AL name="mirrors">shatters</AL> any <NW>illusions</NW> ‘you’ might have had about being <i><PL>special</PL></i>… and, in short, doesn’t even <i>belong</i> in the <QL name="evolution.darwin">competition-fuelled</QL> <NW>Phanerozoic Eon</NW>; it’s even got its <LRL name="eons">‘own’ corner of spacetime</LRL> and – if it had <NW>agency</NW> – is mostly just <LRL>waiting</LRL> until <QL>humanity</QL> <AL name="sleeping">wakes up</AL> and smells the <OML name="levels">roses</OML> (which <QL name="qualifiers">probably</QL> ‘smell’ like <QL name="unity">unity consciousness</QL>) – but the problem is that <LRL name="gaiacentrism">Earth</LRL> is <QL name="urgency">running out of time</QL>.<sup>†</sup></p>
</Box>;


const gist = <div className='letter'>

{/*
  <p>Almost<sup>†</sup> every <LRL name="different">previous</LRL> world-<LRL>understanding</LRL> has made the naive <QL name="difinitive">assumption</QL> that – just because I don’t <LRL name="communism">share thoughts</LRL> with my neighbour, and <PL name="privacy">maintain</PL> the capacity to have a ‘<QL name="pg.subjectivity">difference of opinion</QL>’ from <Random options={["him", "her", "them"]} /> – <LRL name=‘I’ and ‘my neighbour’ must be two <QL name="polygnosticity">separate</QL> <i><OML name="soul">entities</OML></i>.</p>
  <p>You know, because to <AL name="notnew.lennon">seriously consier</AL> anything <i>else</i> (namely, that <QL>humanity</QL>’s prized <AL name="self">sense of might just be part of a scheme t )
  <p>And along comes the <NW word="copernicus">modern-day Copernicus</NW> (<WName />), saying: let’s <i>not</i> just assume that. Let’s just be frank: <QL>humanity</QL>’s theories (of <QL>life</QL>, the <LRL name="scope">universe</LRL>, and <QL name="unity.answer">everything</QL>) aren’t really <AL name="framework.works">holding up</AL> all <AL name="dysfunctional">that well</AL> <i>with</i> that <OML name="soul">assumption</OML>, are they? Let’s <PL name="uncomfortable">take it out</PL>.</p>
  <p>
This is <AL name="systems">analysing</AL> <LRL name="gaia">our planetary</LRL> <PL>system</PL>, observing <LRL name="scope">the data</LRL>: that <PL name="sweeping">every</PL> – or at least <OML name="stupid">hopefully every</OML> – <QL>human</QL> ‘<PL>individual</PL>’ seems to be able to (somehow: not caring <PL name="all.time">yet</PL> <i><LRL name="superpositions">exactly</LRL></i> how) <LRL name="">imagine</LRL> even things that have not yet <i>happened</i> – at least on <LRL name="nihilism">our</LRL> <NW>level</NW> of ‘<LRL name="i.">reality</LRL>’ – and maybe <OML name="kaput">never <i>will</i></OML>… and <LRL name="motto">asking</LRL>: how to <E2 /> this in the limits set to us by the reality (of physics) that humanity has observed?
 what if <OML>free will</OML> <i><LRL name="nihilism">exists</LRL></i> (as <QL>conscious</QL> <NW>agency</NW>) – which means that our universe <i>can</i> <QL>change</QL> in <QL name="evolution">an intentional manner</QL>? If this is the case, then things do not always have to be as they were in the past (<PL name="all.time">however long</PL> the ‘past’): cf. ‘<NW>conservative thinking</NW>’.</;p>
    <p>This them forces one to make the <LRL name="differeent">opposite</LRL> <OML name="wrong">assumption</OML> from <QL name="qualifiers">most (if not all)</QL> of past <AL name="fields.">physics</AL> (as you can in by many physicists’ <NW>atomocentric</NW> naming of things like Hawking radiation or Schrodinger’s cat): that <PL>individuality</PL> is just an emergent phenomenon and the underlying <E2 word="explanation" italic /> is that our 
    <p>But then – if <QL>consciousness</QL>
. <QL>consciousness</QL> were actually – in some <LRL name="superpositions">still-unknown</LRL> way that we <AL name="handicap">as human cannot directly <i><PL name="invisible">perceive</PL></i> – were, at some <LRL>deeper</LRL> <NW>level</NW> of  <QL name="unity.1">one</QL>?</p>
  <p>What follows is a deconstruction of everything you’ve ever been taught to believe, and a toppling of every past world order… but let’s start small.</p>
  <p className="page-footnote">† Not everyone; granted. <AL name="notnew">This isn’t entirely new.</AL> But <WName /> is a <i><PL>comprehensive</PL></i> <QL>Weltanschauung</QL>, whereas most past <AL name="framework">frameworks</AL> (even of <QL>unity</QL>) haven’t dared to step all over <AL name="fields.">science</AL> <i>and</i> <NW>mythology</NW> <i>and</i> <AL>religion</AL> and presume to <LRL>answer</LRL> (or <LRL name="nihilism">not care about</LRL> <PL name="all.level">overly</PL>) <i><QL> name="unity.answer">everything</QL></i>.</p>

*/}

  <Box
    header=<span>The important thing is to recognise what’s <i>real</i>, and what’s just <NW word="illusions">illusory</NW>.</span>
    outside=<span>And by ‘illusory’, I’m not saying you don’t perceive it. We all <i>perceive</i> optical illusions (and can’t do anything about that; it’s a shortcoming of our imperfect biology). Likewise, we all <i>perceive</i> the illusion of individuality.</span>
  />
  <p>But there it is: <PL name="individuality">your <i>individuality</i> is just an illusion</PL>. Your ‘neighbour’ is not actually part of a separate consciousness from yourself. There is one consciousness. Every conscious ‘individual’ is realy just one vast and <QL name="hivemind.complexity">multidimensional</QL> consciousness plagued by a <i><NW>polygnostic</NW></i> nature that prevents us from <PL name="invisible">directly perceiving</PL> and understanding our underlying unity.</p>
  <hr />
  <Box
    header=<span>
      This is the scientific method of <NW>induction</NW>.
    </span>
    outside=<span>
      Add a healthy does of <NW word="occam">Occam’s razor</NW>, and you’ve got <NW>synecdocentrism</NW>.
    </span>
  >
    <p>It might not seem like synecdocentrism is acually <i>simpler</i> than the alternative (presuming that separate individuals actuallly <i>are</i> separate entities, and <i>not</i> part of some mega-body), but let’s see:</p>
    <p>Every past theory, based on the assumption that separate human bodies actually contain something that’s truly separate, ends up being scientifically <i>incomplete</i>. How? Most past scientific theories stay well out of the so-called ‘metaphysical’ sphere – leaving that to a separate ‘discipline’ (religion): which always pulls in some <NW word="mythology">magic or mythology</NW> to postulate some sort of individual <i>soul</i>, and some sort of God-figure to control it.</p>
    <p>Okay, maybe modern (<NW word="atomocentrism">atomocentric</NW>) science doesn’t directly <i>postulate</i> the individual soul… but it doesn’t do much to work around it, either. It treats our universe almost as if it were two: an ‘observable’ plane (whether directly by human eyes, or through scientific instruments) and an <i>unobservable</i> (‘metaphysical’) plane – on which all of religion is allowed to exist, undetected (and <i>undetectable</i>) by science.</p>
    <p>Suddenly Mr (or is it Ms?) Occam’s not liking this very much. </p>
    <p>Well, Occam, let’s postulate instead a non-atomocentric, non-anthropocentric (human-centred) – non-biocentric (biology-centred) and non-gaiacentric (Earth-centred) – theory that <i>does</i> encompass everything… <i>everything</i> meaning even the formerly ‘metaphysical’. Let’s stipulate a world in which the same physical laws that apply to everything else <i>also</i> apply to our consciousness (and sense of ‘self’). (It’s – honestly – pretty radical.)</p>
    <p>Rather than stipulating the (unprovable) existence of a ‘soul world’, let’s keep it all within the scientifically verifiable. And let’s presume that our <i>consciousness</i> (awareness of the world) is actually one. Presume that the way that you perceive your ‘self’ as an individuality somehow separated from the rest is actually not the way that things <i>must</i> (or even <i>should</i>) be; that’s just a fucntion of our consciousness’s current existence as confined to biological containers (a state known in synecdocentrism as <NW>polygnosticity</NW>).</p>
    <p>Suddenly there’s no need for all the elaborate, non-Occam-friendly suppositions.</p>
  </Box>
  <p>Take <i>this</i> (<NW>synecdocentrism</NW>) as a starting point rather than <NW>atomocentrism</NW> (individual-centrism)… and everything just works.</p>
  <h3>TL/DR:</h3>
  <p>The whole <i>need</i> for a TL/DR (too long, didn’t read) is part of the problem: humanity’s <NW>polygnosticity</NW>. It’s why we need a <i><QL name="hivemind">hive mind</QL></i> to control this conscious <i><QL>body</QL></i> of 8,000,000,000+ ‘individuals’. Not 8,000,000,000+ ‘individuals’ who don’t get along – and sometimes deliberately conceal things from (or even <i>lie</i> to) one another, or mislead each other to further their own <i>individual</i> ends (<NW>individual greed</NW>).</p>
  <p>We’ll only truly have world peace (and a global end to the bloody <NW>Phanerozoic Eon</NW>) once our consciousness reaches a state of <NW>monognosticity</NW> (‘<QL name="polygnosticity.coalescence">coalescence</QL>’).</p>
  <p>But since we <i>are</i> <NW word="polygnosticity">polygnostic</NW> right now – and rely on <i><NW>explainage</NW></i> to transfer information – go back and read the rest if you haven’t already.</p>
{/* (It probably goes to the quantum level: probably there are atoms and molecues that are self-aware and there are atoms and molecues that aren’t. I don’t think we know that yet, but it would be a good thing for science to eventually probe. Being a scientific theory, synecdocentrism will evolve as our understanding increases.) */}
</div>;

function Gist() {
  return (
    <div className="page">
      <h1 id="gist-title">>> The Gist</h1>
      <p id="gist-subtitle">Or: rapid-fire <i><NW>explainage</NW></i></p>
      <p id="gist-warning"><b>Warning:</b> if this is the first time your (<NW>heterognostic</NW>) <i><QL name="pg.mind">awareness</QL></i> (or your ‘<PL>individuality</PL>’, or your <NW>POC</NW>, or just ‘you’) has come across the word ‘<NW>synecdocentrism</NW>’… then sorry to tell you, but you’ve just taken <QL name="metaphors">a dive into the deep end</QL>, mate.</p>
      <hr />
      <h3>Now use the <AL name="bulletpoints">bullet points</AL>, or try to <LRL>understand</LRL> <i>this</i>:</h3>
      {elevatorpitch}
{/*      <hr />
      <p>The rest of this has been removed. It was counter-productive and embarassing ('unripe'). Maybe one day it will be restored... but until then, please try to actually <LRL>understand</LRL> this thing, because it isn't nearly so bad as it might initially <i>seem</i>.</p>
*/}
      {/* gist */}
    </div>
  );
}

export default Gist;
