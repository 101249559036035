import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const game = {
  name: "game",
  alternatives: "rules",
  prelude: <span>
    Does your <NW>POC</NW> like a <QL>metaphor</QL>?
  </span>,
  header: <span>
    Earth can’t agree on the rules of its make-believe <i>game</i>.
  </span>,
  outside: <span>
    But please don’t think that this is <i><OML name="offensive">belittling</OML></i> <QL>humanity</QL>’s problems… because it’s decidedly <i>not</i>. This <QL>metaphor</QL> is just honestly the best way to <E2 /> (or make sense out of) the <AL>dysfunctional</AL> state of things (<i>status quo</i>) that <LRL name="gaia">Earth</LRL>’s currently got.
  </span>,
  preview: <div className="long-read-preview">
    <p>That one sentence basically <E2 suffix="s" /> (or summarises) just about everything that’s wrong with the <LRL name="gaiacentric">Earth</LRL>-<AL>system</AL> under <NW>polygnosticity</NW>. (Remember that <PL name="sweeping">many</PL> <LRL name="gaiacentric">Earth</LRL> <NW word="poc">POCs</NW> are <i><AL>sleeping</AL></i>, and so they are more <LRL>reactive</LRL> than fully <i>conscious</i>, and their <i><NW>agency</NW></i> (in terms of their ability to consciously fight <NW>entropy</NW>) is <QL name="qualifiers">perhaps</QL> more akin to that of <OML>animals</OML> or children.)</p>
    <p>Have you ever played ‘pretend’ and you couldn’t agree with your playmate on the <i>rules</i> of the game you were playing? Or maybe <i>you</i> (<NW>heterognostic</NW> from your playmate) imagined that you were in a forest, and then your playmate makes some comment about the lack of trees on this island… and then – rather than lauch into an intriguing (but <PL name="tangents">‘rabbit hole’-tangentail</PL> discussion on the <PL name="comprehensive.">scale-invariant</PL> <AL name="fields.physics">quantum-mechanical</AL> particularities of <NW>synecdocentric</NW> ‘<LRL>superpositions</LRL>’ – you get in a fight and then leave in a huff after telling your playmates <PL name="denial.closing">you aren’t talking to them any more</PL>.</p>
  </div>,
  text: <div className="long-read-text">
{/*    <p>That is quite literally the problem we have in human society: the whole of <NW>atomocentrism</NW> is an <NW>illusion</NW>


.................

</p>*/}

    <p>Let’s break this down (<AL name="systems">analysis</AL>) step by step… keeping in <QL name="pg.">mind</QL> <PL name="comprehensive">all</PL> the contributing factors:</p>
    <ul>
      <li><QL>Humanity</QL> (and <OML>animals</OML>, and <OML name="plants">the rest of Earth biology</OML>) is consciously <NW>polygnostic</NW>: with each <NW>POC</NW> not <i><QL name="gnosticity">knowing</QL></i> what ‘others’ know.</li>
    </ul>
  </div>,
};
