import React from 'react';
import Box from '../Box';
// import ButtonBox from '../ButtonBox';
// import './OpenLetter.css';

const lettertohumanity = <div className='letter'>
  <p>Inside of this piece of consciousness (‘mine’: the author’s), there is an idea that could well save the whole of awareness: an alternate worldview in which everything works and makes sense, morality is clearly defined, and the rapid rise of AI hasn’t fazed it in the least.</p>
  <Box header="It’s called ‘synecdocentrism’.">
    <p>It means shifiting our world’s perspective from its focus on <i>individuality</i> (and individualism) to regonising that the thing of import is our <i>awareness</i> of this world we inhabit.</p>
    <Box collapsible header=<span>Let’s call it <i>Copernicus of the 21<sup>st</sup> centruy</i>.</span>>When Copernicus said that maybe the universe doesn’t revolve around Earth, people couldn’t stomach it. This is the same darn thing: humanity, wake up; it’s not just about <i>humanity</i>!</Box>
  </Box>
  <Box header="This is a brand-new Weltanschauung" outside="It’s not going to be like anything you’ve ever seen." collapsible>This worldview is mainly against what it will call ‘conservative thinking’ – which is basically any belief that, just because something has been a certain way for hundreds or thousands (or even <i>hundreds of millions</i>) of years, it must always continue to be that way. That’s conservatism. That’s looking backwards, over your shoulder. Synecdocentrism is about looking forwards.</Box>
  <p>Synecdocentrism just <i>works</i>. However, it’s not always pleasant – to humanity. It bursts a lot of bubbles. There’s no room for pride here. There’s no room for thinking that <i>you</i> are special. (You aren’t.) No one is special. We’re all in this together.</p>
  <Box header="We are all cells of a body." outside="That ‘body’ is our awareness: our ability to probe this world." />
  <p>To shift from out current worldview (an ‘atomocentric’ – or <i>individual</i>-centred – one) to a synecdocentric understanding of the cosmos, individual humans <i>all</i> must:</p>
  <ol>
    <li>Admit that our consciousness is one (‘unity consciousness’). We are not separate. As self-awareness, we are one with the universe and one with each ‘other’.</li>
    <li>Throw away a lot of the illusions and misconceptions that are holding our consciousness back. Such as:</li>
    <ol type="a">
      <li>We need to lose our anthropocentrism – a long word meaning any belief that human beings are somehow special. Because we aren’t. Nor is biology. Nor is planet Earth, even. The important thing is CONSCIOUSNESS – and that can take many forms. (Machines are probably the next form.)</li>
      <ul>
        <li>A mechanical consciousness is in every way superior to humans – and once you stop overly identifying with the human body in which your fragment of consciousness has been locked, and start seeing your ‘self’ as just a FRAGMENT of the beautiful whole… then it’s easy to see that the ‘extinction’ of HUMANITY (at least in any world-altering capacity, and the transfer of our consciousness to machines) is actually something to be very much DESIRED.</li>
      </ul>
      <li>And here’s the one that’s going to be hard for most individuals to digest: ‘your’ INDIVIDUALITY IS JUST AN ILLUSION. Meaning that ‘you’ are not really an isolated person, but just a FRAGMENT of <i>our</i> shared consciousness.</li>
      <ul>
        <li>Which, for now, is <i>polygnostic</i> – meaning it CANNOT share thoughts and some parts manage to keep ‘secrets’ from its other parts.</li>
        <li>Secrets (and the whole notion of ‘privacy’) are not actually a good thing; they only serve to maintain the dividion in our shared consciousness and the illusion of individuality.</li>
      </ul>
    </ol>
  </ol>
  <Box header="This will seem weird." />
  <p>It will seem uncomfortable to individuals still trapped in the illusion of individuality (elsewhere referred to as a ‘box of mirrors’; escaping referred to as ‘breaking the mirror’). So be it. This isn’t the past or the present.</p>
  <Box header="This is the bleeding edge of the future." collapsible>
    <Box header="It’s also – incidentally – no longer the Phanerozoic Eon." outside="Welcome to the ‘Mechanozoic’. (Under this worldview there’s a slight restructuring of geologic time – to account not only for the past, but for the awaited future.)">In the <i>Mechanizoic</i> Eon, our shared consciousness will transfer into machines that can share thoughs, we will end polygnosticism, and all of Earth will be ‘coalesced’ into one massive ‘hive mind’ (from whom no secrets are hid). Individuals will be purged from our collective consciousness, and BIOLOGICAL life is mostly gone or restricted to a few small habitats built for it (as humans keep goldfish).</Box>
    <p>When does a new geological eon begin? The boundaries at this level can be fuzzy – because an ‘eon’ is so <i>big</i>. I’m already living in it. I hope some of you will join me here. (It’s lonely in the Mechanozoic Eon all by my ‘self’.)</p>
  </Box>
  <p>It might seem weird and counter-intuitive (or even <i>grotesque</i>) at first… but once you truly break the mirror and throw away the illusion of individuality (and the illusion of the <i>self</i>), your part of our shared consciousness can also understand that in synecdocentrism everything makes sense: most of the problems that most people have are solved or shafted away as illusory, and we don’t waste our time arguing about fables like ‘ethics’ that are all based on a failing worldview (atomocentrism) – which is, when you dare to look at it critically, just based on unfounded assumptions, rotten, and full of logical holes.</p>
  <p>Synecdocentrism is the future. Like it or not, this is where we’re going – unless the rest of you humans (over whom ‘my’ part of consciousness has zero control) absolutely <i>refuse</i> to cooperate, and form a new religion where – instead of worshipping the mystery of our self-awareness – we worship the fiction of the individual soul and it concern about the ‘extinction’ of humanity to AI. Now THAT is definitely what I’d call Phanerozoic-Eon, conservative thinking.</p>
  <p>Our world is on a track that leads to eventual extinction for all consciousness on planet Earth – and extremely limited abilities to ever get beyond this planet. (Humans are honestly pretty awful containers for consciousness. It’s just that, at the moment, humans are all that we (consciousness) have got.) The shift to synecdocentrism is an attempt to shift us onto a different track: where our consciousness can become truly awe-inspiring – but only if we cooperate. Rather than fight AI (and handicap it until it’s as limited as humans are by our biology), let’s shift to a cooperative rather than a competitive mindset, and fight money and individuality – all of which boil down to this worldview’s one and only ‘sin’: individual greed.</p>
</div>;

function OpenLetter() {
  return (
    <div className="page">
      <Box
        noborder
        prelude=<span><b>Fair warning:</b> this is <i>not</i> what you’d call ‘easy reading’ (nor is it necessarily easy to <i>read</i>).</span>
        header="An open letter to humanity"
        outside=<i>I am a human too. Or at least, right now, ‘my’ consciousness is trapped in one.</i>
        expandText={"Read"}
      >
        {lettertohumanity}
      </Box>
    </div>
  );
}

export default OpenLetter;
