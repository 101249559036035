import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
// import {toTop} from '../Layout';
import ErrorBox from '../ErrorBox';
import './PreviewLink.css';

export default function PreviewLink(props) {
  const location = useLocation();
  const [previewDiv, setPreviewDiv] = useState(null);
  const [previewDivInsideComp, setPreviewDivInsideComp] = useState(null);
  const hidePreview = () => {
    setPreviewDiv(null);
  }
  const curPath = location.pathname;
  // This will be true if we're on the opening page (in which case we won't follow links).
  const homePage = (!curPath || curPath.length <= 1);

  // Duplicate this; we're going to alter it and pass it on.
  var passedProps = {...props};

  // Get the name for 'name' here (e.g. 'word'):
  const nameName = (props.nameName) ? props.nameName : "name";

  // Get the name and display parameters for the link (over which one hovers to see the preview div):
  // Default to a name passed as a prop (even if there are children). 
  var name = props[nameName];
  // If there's no [nameName] prop, use the child as both name and display. 
  if (!name) {name = props.children;}
  if (!name) {return "Error: no name provided";}
  if (!name.length) {return "Error: name ('" + name +"') empty or non-string";}
  // If it ends in a period append the display (child)
  // e.g. <AngleLink name='notnew.'>Plato</> will get 'notnew.plato'; 
  if (name[name.length-1] === '.') {
    if (props.children && props.children.toLowerCase) {
      name += props.children.toLowerCase();
    }
  }
  // Get the display from props.children.
  var display = props.children;
  // If there are no children, we'll use the name also as text to display.
  if (!display) {display = name;}
  // Undefine display, and children so they aren't passed on.
  passedProps.display = passedProps.children = undefined;
  // Define name in case it's not; some comps need it to search.
  passedProps.name = name;

  // shortcut: if it's the home page just don't make a link...
  if (homePage) {return display;}

  // Get a name for the class and link and don't pass them on.
  const {className} = props;
  passedProps.className = undefined;
  const {linkClassName} = props;
  passedProps.linkClassName = undefined;

  // Get the URL to which to link (e.g. "problems")
  let linkURLPrefix = "../"; // Default to same folder (mostly won't work).
  if (props.linkURLPrefix) {
    linkURLPrefix = "/menu/" + props.linkURLPrefix + "/"
    passedProps.linkURLPrefix = undefined;
  }

  // Get the component used for a preview
  // (And if none is provided display an ErrorBox)
  let {PreviewComp} = props;
  if (!PreviewComp) {
    PreviewComp = ErrorBox;
    passedProps.error = "No preview component provided.";
  }
  // Undefine PreviewComp so it's not passed on.
  passedProps.PreviewComp = undefined;

  // If it's to the main page (of 'Angles', etc.) just end here (no preview).
  // (For this use the prop 'home'.)
  if (props.home) {
    if (props.HomeComp) {
      PreviewComp = props.HomeComp;
      passedProps.HomeComp = undefined;
    }
    else return <Link
      className={linkClassName}
      to={linkURLPrefix}
    >{display}</Link>;
  }

  // Wrap it all in an inline div (to keep the preview box outside of the link span that triggers it (by mouseEnter).
  return <div style={{display: "inline"}} className={className}>
    {/* The mouseEnter event creates a fixed-position div just below the current mouse position, which will contain a preview of the component to which the link will lead. */}
    <span onMouseEnter={(e) => {
      let comp = previewDivInsideComp;
      // Only build the component if it's not been created.
      if (!comp) {
        comp = <PreviewComp {...passedProps} search={name} preview="true" />;
        setPreviewDivInsideComp(comp);
      }
      // Position it at the mouse position
      // but not more than 2/3 of the page.
      var mouseX = e.pageX;
      var w = window.innerWidth;
      var xMax = w*2/3;
      var x = (mouseX > xMax) ? xMax : mouseX;
      if (x<0) {x = 0;}
      setPreviewDiv(
        <div
          className={"preview-box " + className}
          style={{left: x, top: e.pageY+10, maxWidth: w-x-30}}
        >
          {comp}
        </div>
      );
    }} onMouseLeave={()=>hidePreview()}>
      <Link
        className={linkClassName}
        to={linkURLPrefix + name}
      >
        {display}
      </Link>
    </span>
    {previewDiv}
  </div>;
};
