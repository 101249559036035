import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, TLDR, Qual} from '../../common';

// ‘’ … –

// OLD NOTE
// forgot something about it needing a 'control centre'
// this tied into a bigger concept

// Much of humanity can be explained by reactivity vs. conscious AGENCY
export const reactivity = {
  name: "reactivity",
  alternatives: "reactive",
  prelude: <span>
    Who doesn’t love an <PL name="comprehensive">all-encompassing</PL> ‘<QL>Weltanschauung</QL>’ that makes <PL>sweeping</PL> <QL name="definitive">generalisations</QL> about (<Qual.basically />) <i><LRL name="scope">everything</LRL></i>?<sup>†</sup>
  </span>,
  header: <span>
    A great many things can be explained<sup>(2)</sup> by <i>reacticity</i>.
  </span>,
  headerFootnote: <p>
    † That was <OML name="offensive">sarcasm</OML>.
  </p>,
  preview: <div className="long-read-preview">
    <p><TLDR />: if we accept that <PL name="sweeping">a lot</PL> (<Qual.probably /> the majority) of <QL>humans</QL> are not actively using their conscious <i><NW>agency</NW></i> (i.e., <NW>entropy</NW>-<LRL name="notions">fighting</LRL> ability) – i.e., ‘<AL>sleeping</AL>’ – then a <PL name="sweeping">whole bunch</PL> of <LRL name="gaiacentrism">Earth</LRL>’s <i><AL name="dysfunctional">problems</AL></i> can be <PL name="reductionist">easily</PL> <E2 suffix="ed" italic />. Maybe our <QL name="unity">conscious</QL> ‘<QL>body</QL>’ (at least on <LRL name="gaia">Earth</LRL>) needs some non-<NW word="ig">individually-greedy</NW> <i><OML name="authoritarianiam">brain</OML></i> ‘<QL name="body.">cells</QL>’.</p>
    <p style={{color: "red"}}>[Under conscruction still]</p>
  </div>,
//  text: <div className="long-read-text">
  //</div>,
};
