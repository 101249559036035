import React from 'react';
import PL from '../problems/ProblemLink';
import QL from '../faq/QuestionLink';
import LRL from '../longreads/LongReadLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from './OrMaybeLink';
import {E1, E2, SillyName} from '../common';

// ‘’ – …

export const causes = [

// Maybe the pathriarchy is the direct cause of individuality.
{
  name: "patriarchy",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if the <i>patriarchy</i> were the reason for individuality?
  </span>,
  outside: <span>
    We’re talking about the <E2 word="explanation" /> for one of <QL name="body">our conscious body’s</QL> biggest <QL name="roadblocks">problems</QL>.
  </span>,
  preview: <div className="or-maybe-preview">
    <p>And this isn’t to hate on <i>men</i>… because the patriarchy hurts everybody: it hurts the whole beautiful <QL>body</QL> of which we’re <LRL name="gaia hypothesis">all a part</LRL>. There’s nothing about <i>genders</i> in this; sometimes <i>women</i> are far more ‘patriarchal’ than men, and anyway the <NW word="mythology">fiction</NW> of ‘gender’ just hurts <QL name="anthropocentrism">humanity</QL> as a whole.</p>
    <p>This is just to take a step back, look at some of the basic assumptions that <PL name="sweeping">many people</PL> make, and ask ourselves <i>why</i> we make them.</p>
    <p>For example:</p>
    <ul>
      <li>Why are ‘you’ separate from ‘me’?</li>
      <li>How is ‘your’ business not also ‘mine’?</li>
      <li>Why would you ever do something that was in ‘your’ interest, but not in ‘mine’?</li>
    </ul>
    <p>Obviously, there are <E2 word="explanations" /> for most of these – and even <NW>synecdocentric</NW> <E2 word="explanations" /> (which might be defferent from the <NW>atomocentric</NW> ones – but the underlying curiosity is this: is it only <i>because</i> <LRL name="gaiacentrism">Earth</LRL> has been run by the patriarchy for so long that we just all accept that we all (the cells of the <AL name="dysfunctional">messed-up body</AL>) are eternally <PL name="individuality">separate</PL>?</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// Maybe this is all because the AUTHOR has problems.
{
  name: "author",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if this whole [bleep]ing thing were just because the author(s) is/are [insert derogatory term here]?
  </span>,
  preview: <div className="or-maybe-preview">
    <p>It’s not, really, about any human <i><PL name="individual">individual(s)</PL></i> behind it… but, anyway, that’s why it’s a <NW word="induction">logically inductive</NW> <AL>framework</AL>. You are <i>supposed</i> to question it. So go ahead and <PL name="before">try</PL> to build an alternate <QL>Weltanschauung</QL> (or a non-<PL>arbitrary</PL> way to make <NW>atomocentrism</NW> <AL name="framework.">work</AL>). But first:</p>
    <ul>
      <li>Clear away <i><PL name="comprehansive">all</PL></i> <NW>mythology</NW> (i.e., basically anything you thought you knew about the ‘<AL name="illusions">rules</AL>’ of society’s ‘<LRL>game</LRL>’).</li>
      <li>Clear your <QL name="pg.">mind</QL> of any <NW>conservative thinking</NW>.</li>
      <li>Look <LRL name="scope">only at the <i>observable</i></LRL>.</li>
      <li>But <i><PL name="comprehansive">all</PL></i> of the observable.</li>
      <li>And develop a <AL>working</AL> <E2 word="explanation" /> (other than <NW>synecdocentrism</NW>) of <i><PL name="comprehensive">everything</PL></i>.</li>
    </ul>
    <p>In short: yes, the <QL>author(s)</QL> may have problems. <i><PL name="fungibility">Anyone</PL></i> may have problems being only <QL>human</QL> (or at least <AL name="handicap">trapped in the biological</AL>). This matters not at all to the <PL name="sweeping.">time-invariant</PL>, <PL name="comprehensive">scale-invariant</PL> <QL>Weltanschauung</QL> of <NW>synecdocentrism</NW> – which exists <i>outside</i> of all that, and just <i><AL name="framework.">works</AL></i>.</p>
    <p>If you  need an <NW>atomocdentric</NW> way to <LRL>understand</LRL> it, think <NW>copernicus</NW>.</p>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

// Maybe this is the work of the 'devil'.
// SHOULD PROBABLY (MOSTLY) MOVE TO 'UNCOMFORTABLE'
{
  name: "devil",
  // 'prelude' will always be 'Or maybe…'
  header: <span>
    What if synecdocentrism were the work of the ‘Devil’?
  </span>,
  outside: <span>
    To, you know, lead your <OML name="soul">immortal soul</OML> away from the true (and external) <OML>God</OML>
  </span>,
  preview: <div className="or-maybe-preview">
    <p>It’s a <i><LRL name="superpositions">possibility</LRL></i>. (<LRL name="motto">Leave no stone ‘unturned’</LRL>.)</p>
    <p>But first let’s ask ourselves (remembering <NW word="occam">Occam’s razor</NW>) some questions:</p>
    <ul>
      <li>Which <QL>Weltanschauung</QL> (<NW>atomocentrism</NW> or <NW>synecdocentrism</NW>) better allows those who <i><AL name="notnew.lennon.possessions">have</AL></i> to continue to opress those who ‘have not’, while promising them salvation in some future, speculative state (thinking of the ‘<AL name="notnew.marx">opium of the people</AL>’?</li>
      <li>And in which is it more <i><PL name="uncomfortable">comfortable</PL></i> to live as an ‘<AL name="notnew.lennon.possessions">owner</AL>’ of ‘things’ (<E2 suffix="ing" /> away)?</li>
      <ul>
        <li>In which is it less <i><QL>urgent</QL></i> to <AL name="dysfunctional">fix</AL> <LRL name="gaia">our planet</LRL> <i>now</i>: in <OML name="reincarnation">this life</OML>, not some future one? (<QL>Urgency</QL> = <PL name="uncomfortable">discomfort</PL>.)</li>
        <li>In which do you have to be (<PL name="uncomfortable">uncomfortably</PL>) part of the same ‘<AL>self</AL>’ – over which you have no <NW>agency</NW> – as ‘<NW word="individual greed">individually-greedy</NW> <PL name="evil">bastards</PL> (who mostly have more <NW>money</NW> than you)?</li>
        <li>Versus the one in which you can just think, ‘Those <OML name="bad">bad people</OML> will have their come-uppance in <i>Hell</i>!’</li>
        <li>To which ‘Hell’ <i>you</i> (as an <OML name="soul">immortal</OML> <PL>individual</PL>) are not going, and <i>in which</i> you are decidedly <i>not</i> <AL name="hell">already living</AL>.</li>
      </ul>
      <li>In which <QL>Weltanschauung</QL> (that of an <OML name="god">external</OML> or an <i>internal</i> ‘<NW>God</NW>’) are people purportedly ‘equal’ on some <OML name="forms">higher</OML> ‘<OML>level</OML>’ of the individual ‘<OML>soul</OML>’, but blatantly <i><PL name="equality">unequal</PL></i> in the <NW>money system</NW> that rules <LRL name="gaiacentric">Earth</LRL>?</li>
      <li>And in which <QL>Weltanschauung</QL> does each <NW>POC</NW> (<PL name="denial">admittedly</PL>) have <PL name="equality">different abilities and capabilities</PL>… but <LRL name="gaia">Earth</LRL> is all <QL name="unity.1">one</QL> planet, <AL name="dysfunctional.fragments">part</AL> of the ‘<QL>body</QL>’ of <QL name="unity">consciousness</QL> – and there is no ‘<PL name="individuality">self</PL>’ except the <AL name="self">Earth-self</AL>?</li>
      <li>Etc., etc.</li>
    </ul>
  </div>, 
  // Not sure we'll use this...
  //text: <div className="or-maybe-text">
  //</div>, 
  // There should not be a 'sublist'
},

];



