import React from 'react';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, TLDR, Qual} from '../common';

// AI / robots / 'reality'

export const ai = {
  name: "ai",
  alternatives: "robot,robots,bots,bot",
  parentName: "robots and AI",
  prelude: <span>
    Do you ever just want to talk to a real <i>person</i>?
  </span>,
  header: <span>
    Much of humanity needs to fix its <i>attitude</i> towards AI.
  </span>,
  outside: <span>
    The <QL>future</QL> (like it or <PL name="uncomfortable">not</PL>) is ‘artificial’.
  </span>,
  preview: <div className="problem-preview">
    <p>This is a big one. A big one that’s going to be <QL name="polygnosticity">hard</QL> to <E1 />.</p>
    <p><TLDR />: <QL>Humans</QL> need to care a little bit (or a lot) less about what’s ‘<LRL name="oneorzero">real</LRL>’… and start to focus on <LRL name="scope">what we <i>see</i></LRL>): the problems of , the <QL name="salvation">hope</QL> for ‘<NW>coalescence</NW>’ (<QL>unity</QL>) – at which point whether or not this is all a <OML>simulation</OML> will <PL name="comprehensive.level">not even <i>matter</i></PL>.</p>
    <p>[Under construction]</p>
  </div>,
//  text: <div className="problem-text">
//    <p></p>
//  </div>,
//  sublist: [

/*
  // 
  {
    name: "",
//    alternatives: "ig",
    prelude: <span>
    header: <span>
    </span>,
    outside: <span>
    </span>,
//    headerFootnote: <p>
//    </p>,
    preview: <div className="problem-preview">
    </div>,
    // text: <div className="problem-text">
    // </div>,
  },
*/
/*
  // 
  {
    name: "",
//    alternatives: "ig",
    prelude: <span>
    header: <span>
    </span>,
    outside: <span>
    </span>,
//    headerFootnote: <p>
//    </p>,
    preview: <div className="problem-preview">
    </div>,
    // text: <div className="problem-text">
    // </div>,
  },
*/
/*
  // 
  {
    name: "",
//    alternatives: "ig",
    prelude: <span>
    header: <span>
    </span>,
    outside: <span>
    </span>,
//    headerFootnote: <p>
//    </p>,
    preview: <div className="problem-preview">
    </div>,
    // text: <div className="problem-text">
    // </div>,
  },
*/


//  ],
};
