import React from 'react';
import ItemBox from '../ItemBox';
import LongReadsList from './LongReadsList';
import {long_reads} from './list';
import {useParams} from 'react-router-dom';
import './LongRead.css';

export default function LongRead(props) {
  var {name} = useParams(); // If coming from react router.

  return <ItemBox
    {...props}
    className="long-read-box"
    preview={props.preview}
    data={props.data}
    list={!props.data ? long_reads : null}
    search={(props.name) ? props.name : name}
    propertyName="name"
    alternativesName="alternatives"
    ListComp={LongReadsList}
    previewName="preview"
    textName="text"
  />;
};
