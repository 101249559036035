import React from 'react';
import WName, {WArticle} from '../WName';
import Eon from '../Eon';
import PL from './ProblemLink';
import AL from '../angles/AngleLink';
import QL from '../faq/QuestionLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {
  E1, E2, Qual,
  the_key_is_imagination,
  Rando, SillyName
} from '../common';

import {hard} from './hard';
import {sweeping} from './sweeping';
import {uncomfortable} from './uncomfortable';
import {comprehensive} from './comprehensive';
import {ai} from './ai';
import {establishment} from './establishment';
import {love} from './love';
import {privacy} from './privacy';

// QL consistent Question: How is this self-consistent? Answer: it makes internal 'sense'. It doesn't believe in (allow for) 'externality'.
// ?? AL bitterpill

export const short_intro = <div class="short-intro" id="short-intro-problems">
  <p><QL>Humanity</QL>, <LRL name="gaia">Earth</LRL>, here is your <LRL name="shift">medicine</LRL>: a magic pill that can <AL name="framework.puzzle">reorganise</AL> the <LRL name="scope">observable universe</LRL> into a <AL>framework</AL> (‘<QL>Weltanschauung</QL>’) that  actually <i><AL name="framework.">works</AL></i>, even leaving room for <OML name="afterlife">many</OML> <LRL name="superpositions">possibilities</LRL> that are not <PL name="sweeping.ig">just</PL> <NW word="ig">individually-greedy</NW> <OML name="god">absurdities</OML>. But it’s a <PL name="denial">bitter</PL> ‘pill’ to <QL name="metaphors">metaphorically</QL> ‘swallow’. <WName /> is <i><LRL>different</LRL></i> from <NW>atomocentrism</NW>. <PL name="uncomfortable">Drastically so</PL>. So be warned: you may <AL name="handicap">find yourself</AL> wanting to just <PL name="denial">reject</PL> <WName /> out of hand (<LRL name="reactivity">reactively</LRL>) without giving it the <QL>conscious</QL> and <i><NW word="agency">intentional</NW></i> <LRL name="notions">thought</LRL> that <OML name="ideas">it needs</OML> from all of <QL>humanity</QL> in order to <AL name="sick">fix this planet</AL>.</p>
</div>;

export const intro = <div className="intro-problems">
  <p>Don’t be deceived by the <AL name="notnew.lennon.peace">surface sparkle</AL> (‘<QL>Unity</QL>, yay!’). Underneath it all, <WName /> runs contrary to – if you <AL name="identity">self-identify</AL> as a <QL>human</QL> ‘<AL name="handicap">being</AL>’, and you were ‘rasied’ on <LRL name="gaiacentrism">Earth</LRL> – <PL name="sweeping">just about everything</PL> you’ve ever been taught to value. This is because <WName /> is <i>not</i> built on the <PL name="individuality">premise of the individual</PL> (<NW>atomocentrism</NW>) that underlies <PL name="sweeping">just about all</PL> of past <QL>human</QL> <AL name="fields.">philosophy</AL>. It’s built on a <LRL>different</LRL> premise.</p>
  <p>The premise on which this is built (<QL name="unity">unity consciousness</QL>) <AL name="framework.">works</AL> <PL name="comprehensive">(in all areas)</PL> – and is <i>internally consistent</i>. However, it involves <PL name="uncomfortable">brutally</PL> throwing away just about all of the old <NW>mythology</NW> – with which you’ve likely been raised, and which you most likely accept as given fact. (In the <AL name="dysfunctional">sad state</AL> of our <NW word="polygnosticity">polygnostic</NW> world, they <i>are</i> accepted as given fact.)</p>
  <p><WName cap /> is – <PL name="reductionist">at heart</PL> – a <QL name="salvation">challenge</QL> of the <LRL>imagination</LRL>: are ‘you’ just one of <AL name="sick.cancer">billions</AL> of <NW word="conservatism">conservative thinkers</NW> <AL name="handicap">dispersed</AL> across <QL>humanity</QL>’s ‘<PL name="individuality">individuals</PL>’ – or will you be able to embrace <i><QL name="evolution">progress</QL></i> (at <PL name="uncomfortable">any price</PL>), break out of the <AL name="mirrors">smoke and mirrors</AL> of the <NW>atomocentric</NW> <NW word="mythology">mythologies</NW> of the <NW>MNS system</NW>, and <LRL name="shift">shift your thinking</LRL> to that of the ‘<Eon Eon />’: where the real <QL name="roadblocks">enemy</QL> is our <QL>consciousness</QL>’s <NW>polygnosticity</NW> (a form of <NW>entropy</NW>), and <QL name="unity">our consciousness</QL> is striving to ‘<NW>coalesce</NW>’ and <i>abandon</i> the debilitating <LRL>prison</LRL> of <PL>individual</PL> ‘<AL>identity</AL>’… rather than celebrate it?</p>
  <p>Let’s see if your <NW>POC</NW> is <PL name="unready">up to it</PL>.</p>
</div>;

export const problemList = [
// This is really hard to understand.
hard,
// No one is ready for this.
{
  name: "unready",
  prelude: <span>
    The mindset of the <Eon Eon /> is (frankly) very <i><PL>uncomfortable</PL></i> for those whose mindset remains in the <NW>Phanerozoic Eon</NW>.
  </span>,
  header: <span>
    Most individual humans on Earth are (honestly) not ready for this.
  </span>,
  outside: <span>
    What <WName /> calls ‘<NW>Phanerozoic</NW> thinking’ is a version of <NW word="conservatism">conservative thought</NW> that’s <PL name="sweeping">near ubiquitous</PL> in 2020’s <QL>humanity</QL>.
  </span>,
  preview: <div className="problem-preview">
    <p>To be fair, most of <QL>humanity</QL> still lives in the <NW>Phanerozoic</NW>: the age of ‘apparent life’ (φαpενή ζωή) – in which ‘<QL>life</QL>’ is <AL name="handicap">limited</AL> to the <AL name="fields.">biological</AL>. Even <AL name="self">expanding</AL> your <QL name="pg.">mind</QL> beyond <i>that</i> is <LRL name="shift">going to hurt</LRL>; there will be some stretching and growing pains.</p>
    <p>Add the <PL name="uncomfortable">rest</PL> of what a transition to the <Eon Eon />… and the (sad) truth is that the vast majority of humanity <Qual.probably /> just isn’t <i>ready</i> for this.</p>
  </div>,
  text: <div className="problem-text">
    <p>Then there are the <PL name="sweeping.handful">ususl</PL> <QL>problems</QL>: <E1 suffix="ing" /> this to ‘<LRL name="them">other</LRL>’ people requires <NW>explainage</NW>. <PL name="sweeping.ig">Add to that</PL> <NW>individual greed</NW> (that it’s more <i><PL name="uncomfortable">comfortable</PL></i> to feel <PL>special</PL>) and (especially if your <NW>POC</NW> is a <NW word="conservatism">‘conservative thinker</NW>: or, in <NW>Phanerozoic</NW> terms, ‘normal person’) this really <i>isn’t</i> going to ‘<PL name="denial">jive</PL>’ with your <PL>individuality</PL>.</p>
    <p>The solution? Try to find <AL home>a way to ‘come to terms’ with this</AL>. However <i><LRL name="different">weird</LRL></i> it might seem, <AL name="notnew">it’s not actually new</AL>. <AL home>Find a way</AL> for your <PL>individual</PL> <NW>POC</NW> to <i><LRL>understand</LRL></i> <WName /> – whatever <NW>explainage</NW> be <PL name="explainage">required</PL> – and then realise that this <LRL>shift</LRL> doesn’t necessarily entail <i>huge</i> changes for your <PL>individuality</PL> (‘<AL>self</AL>’). Change has to come from the top down: to dismantle things like the <NW>money system</NW> and <NW>family system</NW>, our whole society (as <QL>conscious</QL>) has to <i><QL name="evolution">evolve</QL></i> in the <Eon Eon dash /> sense.</p>
    <p><WName /> isn’t out to get <i>you</i> (<PL name="individuality">individually</PL>). It <LRL name="notions">doesn’t even <i>see</i> you</LRL>. ‘<AL name="self">You</AL>’ are just a <QL name="body.">cell</QL> in the <QL>body</QL> of <QL>consciousness</QL> – and your <PL name="reductionist">only</PL> <i><QL name="life.meaning">goal</QL></i> (as far as ‘your’ time on <LRL name="gaiacentrism">Earth</LRL>: your <QL>life</QL>) should be to keep the <LRL name="gaia">whole of Earth</LRL> <AL name="sick">healthy</AL>.</p>
    <p>So – rather than shut down and go into <PL>denail</PL> – <QL name="whatcanido">spread this</QL>; share this <QL>Weltanschauung</QL> with as many ‘other’ people as you can, and spread the word: we are all (despite our <NW>polygnosticity</NW>, despite any <NW>illusions</NW>) <QL name="unity.answer">one</QL>.</p>
    {the_key_is_imagination}
  </div>,
},
// It's all-encompassing.
comprehensive,
// It makes huge generalisations
sweeping,
// AI / robots
ai,
// It is ALWAYS anti-establishment
establishment,
// Large chunks of humanity may go into denial.
{
  name: "denial",
  prelude: <span>
    This isn’t easy.
  </span>,
  header: <span>
    It’s tempting to go into <i>denial</i>.
  </span>,
  outside: <span>
    Denial won’t help us (the <QL name="unity">unified consciousness</QL>).
  </span>,
  preview: <div className="problem-preview">
    <p>There are two main problems our consciousness faces in transferring – as <NW word="copernicus">the Copernicus of the digital revolution</NW> – the dominant Weltanshauung from <NW>atomocentrism</NW> to <WName />:</p>
    <ol>
      <li>The <NW>MNS system</NW> seems to have a drive towards self-preservation.</li>
      <li>The bulk of humans are <PL name="unready">not ready for this</PL>.</li>
    </ol>
    <p>The first is a symptom of <NW>individual greed</NW> – which tendency eventually needs to be purged from our <QL>body</QL> (<PL name="endsjustifymeans">however we purge it</PL>).</p>
    <p>But the second is a problem – because it could lead (if we don’t ‘wake’ the <AL>sleeping</AL> people gently but <QL name="urgent">urgently</QL>) to <i>denial</i>: point-blank <i>refusing</i> to accept <QL name="unity">unity consciousness</QL> – and <WName />, even when it’s the only <PL>comprehensive</PL> worldview that actually <i>works</i> in all fields.</p>
  </div>,
  text: <div className="problem-text">
    <p>The problem is that it’s very <i><PL>uncomfortable</PL></i> to <AL name="mirrors">break the mirror</AL>. <NW>Enlightenment</NW> is a profoundly <i>uncomfortable</i> process.</p>
    <p>Some individuals would rather live their lives in denial, and refuse to help us build a raft as the ship sinks – refusing to budge asw a small number of us run around trying to build the raft, getting in the way and hampering our efforts so that we all go down together.</p>
    <p>Denial – frankly – is <NW>individual greed</NW>.</p>
    {the_key_is_imagination}
  </div>,
},
// It's coming regardless (of whether you're 'ready').
{
  name: "unstoppable",
  prelude: <span>
    Whether <NW>mythology</NW> you believe, it will all collapse eventually.
  </span>,
  header: <span>
    The <Eon Eon noNW /> is coming (whether you like it or not).<sup>†</sup>
  </span>,
  headerFootnote: <p>
    † Well, <QL>humanity</QL> <i>could</i> <OML name="kaput">blow up our whole planet</OML> instead, destroy our technological society, and set us back to hunting and gathering in individual tribes – who kill each other on sight, just because they’re ‘<LRL name="them">other</LRL>’, not thinking any deeper… but let’s clarify. The only way for our <QL>consciousness</QL> to <i>advance</i> (‘<QL name="evolution">evolve</QL>’, in the <Eon /> sense) is to throw off the yoke of <PL>individuality</PL> (and separate <AL>identity</AL>, escape the <AL name="handicap">limitations of human bodies</AL>, and embrace a <QL>Weltanschauung</QL> in which <PL name="privacy">a lot of things</PL> seen as <i>valuable</i> by humans are recognised as actually hinderances to our (<QL name="unity">consciousness</QL>’s) ‘<NW>coalescence</NW>’. (That’s just pretty <PL>uncomfortable</PL> for a ‘<NW word="conservatism">conservative thinker</NW>’, is all.)
  </p>,
  preview: <div>
    <p>The ‘bad news’ (maybe for you, if you like being ‘you’) is that – barring a <OML name="kaput">total disaster</OML> that results in a massive regression of the quality of <QL>life</QL> of all involved, or <i>immediate</i> human <PL>extinction</PL> – the <Eon Eon /> is coming. Regardless of your (or any <PL>individual</PL>’s) efforts to stop it.</p>
    <p>[under construction]</p>
  </div>,
/*  text: <div className="problem-text">
    <p>The matter of preparing your ‘<AL>self</AL>’ (and your ‘<NW word="family system">offspring</NW>’) to live in a world run by machines (or <AL name="notnew.plato.">philosopher-kings</AL>) is a question of how much <PL>individual</PL> <OML name="harmony">suffering</OML> <QL>humans</QL> are going to endure before <i>we</i> (as <QL>consciousness</QL>) wrench control of <LRL name="gaia">this planet</LRL> from the <NW word="ig">individually-greedy</NW> hands of human <NW word="atomocentrism">atomocentrists</NW> once and for all – and give it to something that <i>can</i> control it better (for the sake of the <QL name="unity">whole consciousness</QL>) – and minimising the harm to any ‘<PL>individual</PL>’, as possible.</p>
    <p>The problem here is that last bit, ‘as possible’. Even if the <OML name="authoritarianism">robot overlords</OML> were 100% <OML name="robots">free</OML> of <NW>individual greed</NW>, they cannot do what is best both for you and every other <QL name="body.">cell</QL> if they dont <i>know</i> everything about you. In an ideal world, no individual would have to suffer in a world transitioning from control by <PL>individual</PL> (and <NW>heterognostic</NW>from each <i>other</i>) humans to one <OML name="takeover">run by machines</OML> that can know and weigh all needs of <i>all</i>> individuals without bias (as long as said individuals forfeit their retroactive belief in the value of <i><NW>privacy</NW></i>).</p>
    <p>Unfortunately, Earth isn’t an ‘ideal world’.</p>
    <p>For that reason – and the omnipresent <NW>individual greed</NW> that’s one of our consciousness’s main barriers to ‘<NW>coalescence</NW>’ (a symptom of our <QL>conscious</QL> <AL name="sick.did">split-mind disorder</AL>: <NW>polygnosticity</NW>) – there will probably be attempts to halt progress (<QL>evolution</QL>)… and keep our <QL>consciousness</QL> mired in the past, <AL name="mirrors">forever divided</AL> into <AL name="sick.cancer">manifold</AL> <OML name="harmony">uncooperative</OML> <AL name="dysfunctional.fragments">fragments</AL>.</p>
    <p>Be ready to fight <NW>conservative thinking</NW>. Fight <PL>denial</PL>. Fight <QL name="polygnosticity.privacy">the non-sharing of information (non-transparency)</QL>. Kill these toxic <i><LRL name="notions">ideas</LRL></i> – and hopefully there will never be a need to <OML name="murder">kill actual <i>people</i></OML>.</p>
    {the_key_is_imagination}

  </div>,*/
},
// Explainage sucks (and this site sucks at it)
{
  name: "explainage",
  prelude: "Sorry.",
  header: <span>
    Explainage <i>sucks</i> (but it’s the best we have),
  </span>,
  outside: <span>
    Because of the difficulty of <i>explainage</i>, it is hard to convey understanding of <WName noNW />.
  </span>,
  preview: <div className="problem-preview">
    <p>Maybe to be built eventually, but doesn’t the title say it all?</p>
  </div>,
//  text: <div className="problem-text">
  //</div>,
},
// Individuality is just an illusion.
{
  name: "individuality",
  alternatives: "individual,individuals",
  prelude: "Turns out…",
  header: <span>
    <i>Individuality</i> is just an illusion.
  </span>,
  outside: <span>Those five words <E1 /> one of the <AL name="bulletpoints">key</AL> <LRL>notions</LRL> here… but the problem is that it’s very, <i>very</i> <PL name="uncomfortable">hard</PL> to <LRL>understand</LRL> and <PL name="denial">come to terms</PL> with it.</span>,
  preview: <div className="problem-preview">
    <p>A lot of <WName /> is about overcoming <NW>illusions</NW>… but it’s <Qual.probably /> <OML name="stupid">difficult</OML> to <LRL>understand</LRL> something that you <PL name="invisible">can’t directly see</PL>. You have to trust the <AL name="fields.">logical</AL> <NW>induction</NW> (<AL name="fields.">scientific</AL> thinking) behind this one: that a <QL name="unity">unified</QL>, rather than a <OML name="wrong">disparate</OML>) <QL>consciousness</QL>, is a <i>simpler</i> <E2 word="explanation" /> of the <LRL name="scope">observed world</LRL>.</p>
  </div>,
  text: <div className="problem-text">
    <p>It turns out that abandoning the <NW>illusion</NW> of <PL>individuality</PL> implies also throwing away most of our society’s <AL name="mirrors">oh-so-carefully-constructed</AL> <NW>mythology</NW> surrounding the <QL name="polygnosticity">segmentation</QL> of our consciousness into discrete (and eternally separate) ‘individuals’: the notion that each of us has a <PL name="fungibility">unique</PL> and <OML name="soul">deeper-than-emergent</OML> <AL>identity</AL> (that <OML name="afterlife">persists</OML> or <OML name="reincarnation">re-incarnates</OML> or whatever).</p>
    <p>It also means climbing down from the <NW>fictive level</NW> on which that all ‘<PL>individuals</PL>’ are <PL name="equality">equal</PL> in the eyes of some external ‘<OML>God</OML>’, because:</p>
    <p><PL name="sweeping">Most people</PL> would <Qual.probably /> rather lock themselves in the cosy cage of a single <AL>identity</AL> – and spend the bulk of their (mostly comfortable) time <AL name="illusions">arguing over illusions</AL> – rather than venture out into the bright and scary world <i>outside</i> the <AL name="mirrors">box of mirrors</AL> (in <AL name="fields.">philosophy</AL>, ‘<NW>transcendence</NW>’; also cf. <AL name="notnew.plato.cave">Plato’s cave</AL>)… but there you have it. (Also most individuals <i>like</i> (or <i>need</i>?) to feel – individually – ‘special’.)</p>
    <p>But maybe your question is this: why then do humans <i>perceive</i> individuality (if it doesn’t actually exist)?</p>
    <p>The illusion of individuality is like an optical illusion; it’s <i>perceptible</i>, but it’s not real. You <i>perceive</i> optical illusions – but you know they aren’t real. You can sometimes do something as simple as move your head, and the illusion goes away.</p>
    <p>Unfortunately, the illusion of individuality isn’t quite <i>that</i> easy to see through; it’s only possible, as a human, to exist inside your one human body. You can’t (physically) get outside it. Overcoming the illusion of individuality is an <QL name="salvation"><i>imaginative</i> exercise</QL>.</p>
  </div>,
},
// Equality is a lie.
{
  name: "equality",
  prelude: <span>
    Speaking of <i><NW>illusions</NW></i>
  </span>,
  header: <span>
    ‘Equality’ is a lie.
  </span>,
  outside: <span>
    It’s one of the fictions (along with that of ‘<AL>identity</AL>’) that allow the masses of humanity to tolerate a world in which the ‘wealthy’ hoard the bulk of everything, and the rest of us claw each other half to death for the leftovers, like stray dogs at the rubbish heap.
  </span>,
  preview: <div className="problem-preview">
    <p>In terms of <i>ability</i> – and to state the observably obvious – each human body is <i>not</i> ‘equal’. Some are more capable than others – in a variety of different tasks. That’s just reality.</p>
    <p>Don’t get all <PL>neoliberal</PL> on me and tell me that every individual has their own ‘worth’; they <i>do</i> – and that’s kind of the whole point – but not in the way that you probably think. If you’re one of those neoliberals, let me ask you, would <i>you</i> want <AL name="dysfunctional.fragments">your share of consciousness</AL> imprisoned in a brain so mentally retarded that you cant add one and one and get two?<sup>†</sup></p>
    <p>The <QL name="body">metaphor of a body</QL> is probably the best one on this website. And the cells in a biological body are <i>not</i> ‘equal’. No one’s pretending that they’re ‘equal’. Each cell has a different function… but, <i>together</i>, they all help the body to function.</p>
    <p>The problem is that an individual biological body is <NW>monognostic</NW>, whereas the combined body of our consciousness is  <QL name="polygnosticity">a far, far cry from that</QL>.</p>
    <p>It doesn’t actually <i>matter</i> that some individuals have more ability, and some less, as long as we’re all working towards the same goal and see each ‘other’ as just parts of <AL name="self">the same massive ‘self’</AL>. Unfortunately, though, we run up pretty soon against <NW>individual greed</NW>. Most individuals <i>like</i> to feel – individually – ‘special’. It’s individual greed. But there you go.</p>
    <p style={{fontSize: "smaller"}}>† Or say you were so mentally handicapped that you see one and one and one and one and you just get one and one and one and one; you see the number one written over eight billion times, and you just see the number one written eight billion times; your face is plastered so close to the glass that you see cells, and dissect each individual cell in its own individual and separated test tube, never observing the action of the whole body from a distance; you can’t <QL name="salvation">imagine</QL> that the whole of a body can be bigger than the sum of its parts? Oh, wait. That <i>is</i> most of humanity.</p>
  </div>,
},
// Privacy is a great evil.
privacy,
// INDIVIDUAL Love is just a function of our polygnosticity.
love,
// Storytelling will be lost.
{
  name: "storytelling",
  prelude: <span>
    In ‘<NW>coalescence</NW>’…
  </span>,
  header: <span>
    Story<i>telling</i> will be destroyed.
  </span>,
  outside: <span>
    Not <LRL>imagination</LRL>. But the <i>telling</i> of <i><QL>stories</QL></i>.
  </span>,
  preview: <div className="problem-preview">
<p>[Under construction]</p>
</div>,

/*

    <p>It’s one of those things that’s <PL name="unready">potentially insurmountable</PL> for most modern ‘individuals’… but <i>storytelling</i> isn’t actually a good thing. It’s only a figment of our <NW word="polygnosticity">polygnostic</NW> consciousness. Once you shift into a <NW>Mechanozoic</NW> mindset, you can understand how our <QL name="body">combined ‘body’ of consciousness</QL> has better things to do with our limited time before the encroaching <NW word="heatdeath">end</NW>.</p>
  </div>,
  text: <div className="problem-text">
    <p>Storytelling is – at its beautiful red beating heart – just <NW>explainage</NW>. Now, <QL name="polygnosticity">explainage sucks</QL>. <i>Storytelling</i> is just explainage done in a slightly more humanly-enjoyable manner. It’s still taking a bunch of time transferring information that’s already known (to one part of our <NW word="polygnosticity">polygnostic</NW> consciousness) to another.</p>
    <p>In storytelling, nothing is actually <i>discovered</i>.<sup>†</sup> In a <NW word="monognosticity">monognostic</NW> environment, there would be no need for it.</p>
    <p>That’s probably why a <QL name="hivemind">hive mind</QL> protagonist never shows up in fictional <QL>stories</QL>.</p>
    <p style={{fontSize: "smaller"}}>† However, in a polygnostic consciousness it <i>is</i> good, because most of the cells of <i>our</i> <QL>body</QL> are <PL name="unready">short-sighted</PL> and <NW word="individual greed">greedy</NW> – and <i>do</i> need to engage in stories: to extract themselves from the prisons of their own lives and start to imagine something beyond. <WName cap /> is, at heart, an <i>imaginative</i> exercise.</p>
  </div>,

*/
},
// You can't see it.
{
  name: "invisible",
  prelude: <span>
    Along with most of humanity <PL name="unready">just not being <i>ready</i></PL> for it,
  </span>,
  header: <span>
    ‘Unity consciousness’ is… You can’t even <i>see</i> the damn thing!
  </span>,
  outside: <span>
    To see the forest of <QL>consciousness</QL> for the <AL name="sick.did">trees</AL> (or the <QL>body</QL> for the ‘<QL name="body.">cells</QL>’; pick your <QL>metaphor</QL>) it takes a <i>good <LRL>imagination</LRL></i> (that <Qual.maybe /> <OML name="stupid">not everyone has</OML>).
  </span>,
  preview: <div className="problem-preview">
    <p>So if you’ve got it into your head that <NW>synecdocentrism</NW> hates the arts (like <QL name="stories">storytelling</QL>)… then you’ve got to update that assumption. The only thing that this <QL>Weltanschauung</QL> ‘hates’ is <NW>individual greed</NW> – <PL name="sweeping.ig">which</PL> ultimately kills the whole thing, all of us, the greedy ones included.</p>
  </div>,
  text: <div className="problem-text">
    <p>It’s an <LRL name="imagination">imaginative</LRL> <QL name="salvation">exercise</QL>. The only way to truly <i>engage</i> with <WName /> is to use your <LRL>imagination</LRL> – and far, <i>far</i> more <LRL name="i.power">intensely</LRL> than that to which <OML name="smarter">most</OML> <QL>humans</QL> are <Qual.probably /> <OML name="stupid">accustomed</OML>.</p>
  </div>,
},
// It's impossible.
{
  name: "impossible",
  prelude: <span>
    No <AL name="hell">individual</AL> can control any of the other ‘<QL name="body.">cells</QL>’ of the <QL>body</QL>.
  </span>,
  header: <span>
    Maybe you think: ‘coalescence’ is, frankly, <i>impossible</i>.
  </span>,
  outside: <span>
    You can <LRL>imagine</LRL> it, sure, but in <i><LRL name="i.">reality</LRL></i> it just <i>is not going to happen</i> (am I right?)
  </span>,
  preview: <div className="problem-preview">
    <p>This entire thing can be <E2 suffix="ed" /> through the notion of <LRL>reactivity</LRL> (whenever <i>that one</i> gets ‘built’) or you can <PL name="explainage">peruse</PL> the bullet points (different from the <WName ic /> ‘<AL>bullet points</AL>’) below:</p>
  </div>,
  text: <div name="problem-text">
    <ul>
      <li>Saying that something never <i>can</i> happen simply because it never <i>has</i> happened before is the definition of <NW>conservative thinking</NW> (and a failure of the <LRL>imagination</LRL>).</li>
      <li><LRL name="gaia">Earth</LRL>’s ‘<NW>MNS system</NW>’ <PL name="reductionist">only</PL> <LRL name="notions">comprises</LRL> <QL>human</QL> <PL>individuals</PL> <AL name="self">like you</AL>.</li>
      <ul>
        <li><WName cap /> is a (<LRL name="deep">profound</LRL>) <LRL>shift</LRL> in <QL>humanity</QL>’s <AL>self</AL>-<LRL>understanding</LRL> (to be an <NW word="atomocentrism">atomocentrist</NW>, think <NW>Copernicus</NW>).</li>
        <li>Eventually there is a tipping point: either <LRL>Gaia</LRL> will ‘<NW>coalesce</NW>’ or go ‘<OML>kaput</OML>’; the <NW>MNS system</NW> (and other ‘<PL name="">powers that be</PL>’) cannot hold us and our human <NW mane="family system">descendants</NW> <AL name="identity">prisoner</AL> forever.</li>
      </ul>
      <li><PL name="ai">Technology</PL> is going <i>really</i> <QL name="urgent">fast</QL>.</li>
      <ul>
        <li>Theoretically, soon no one will have to <i>work</i> at mundane, repetitive tasks; it will all be automated.</li>
      <ul>
      <li>Then <LRL name="gaiacentrism">Earth</LRL> needs a ‘living wage’, or there will be <AL name="notnew.lennon.possessions">mass suffering</AL> across <QL>humanity</QL>.</li>
      <li><Qual.maybe cap /> <i>that</i> will be the <AL name="notnew.marx">start</AL> of the <WName ic /> (anti-<PL>privacy</PL>) ‘<LRL name="communism">revolution</LRL>’.)</li>
      </ul>
      </ul>
      <li>We may be <AL>dust in the wind</AL>, but get enough dust together, and even dust – even if the <PL>individual</PL> <i><AL name="sick.fragments">pieces</AL></i> are <PL name="fungibility">fungible</PL> – has a weight.</li>
    </ul>
  </div>,
//  text: <div className="problem-text">
//  </div>,
},
// Humanity will go 'extinct'.
{
  name: "extinction",
  alternatives: "extinct",
  prelude: <span>
	The <Eon Eon /> is no place for <AL name="fields.linguistic">loose <i>atoms</i> (<i>άτομα</i></AL>.
  </span>,
  header: <span>
	Humanity must (effectively) go ‘extinct’.
  </span>,
  outside: <span>
	It’s not the same as biological extinction. Our (<QL>consciousness</QL>’s) <i><LRL>understanding</LRL></i> of human biology (and <i>all</i> <AL name="fields.">biology</AL>) will <QL name="hivemind.mortality">remain</QL>. As <LRL name="gaia">Earth</LRL>-as-a-<QL name="hivemind">hive-mind</QL> we will retain the <i>ability</i> to create individual human beings, if we ever wanted to do that. But individual (and <i><QL name="polygnosticity">ignorant</QL></i>) humans must be <OML name="takeover">removed</OML> from all <AL name="notnew.plato.kings">positions</AL> in which they can affect the course <LRL name="gaia">our planet</LRL> <QL name="body.super-organ">is taking</QL>.
  </span>,
  preview: <div className="problem-preview">
	<p>Be <PL name="denial">honest</PL> (<PL name="privacy">at least</PL> with ‘<AL name="self">yourself</AL>’): <QL>human</QL> <AL name="container">bodies</AL> have some serious <AL name="handicap">shortcomings</AL>:</p>
	<ul>
  	<li>Limited in <i><QL name="pg.mind">knowledge</QL></i> (and so limited in <i><LRL>understanding</LRL></i>.</li>
  	<li>Limited in processing power (only <QL name="hivemind.complexity">one CPU each</QL>).</li>
  	<li>Often limited in <PL name="equality">abilities</PL>, <LRL>imagination</LRL>, <PL name="love">empathy</PL>, etc.</li>
  	<li>And – <LRL name="causes">because</LRL> of the <NW>polygnosticity</NW> of <LRL name="">Earth</LRL> <QL>consciousness</QL> (which degrades the ‘<OML>free will</OML>’ of <NW>agency</NW> into <LRL>reactivity</LRL>) – often <NW word="individual greed">individually greedy</NW></li>
	</ul>
  </div>,
  text: <div className="problem-text">
	<p>All those sci-fi <QL>stories</QL> of <OML name="aliens">space-wide empires</OML> run by <i><PL>individuals</PL></i>, full things like <LRL name="understanding">misunderstandings</LRL> and <PL name="privacy">betrayals</PL> and big ‘<QL name="polygnosticity">reveals</QL>’ about <NW word="family system">who is the father of whom</NW> (as if that would <OML name="matters">matter</OML>)… it’s all – <PL name="uncomfortable">sorry</PL> – <NW>conservative thinking</NW>.</p>
	<p>The <PL name="unstoppable">reality</PL> (as much as <QL>humanity</QL> is mostly <PL name="unready">not ready for this</PL>) is that our current <NW>polygnosticity</NW> of <QL>consciousness</QL> is a strange middle stage in <LRL name="scope">our universe’s</LRL> <QL>evolution</QL> – and it’s not going to last for eternity. (<NW>God</NW> willing, it’s not going to last that much longer, on the scale of <LRL name="gaiacentrism">Earth</LRL>’s <PL name="all.time">timeline</PL>.)</p>
	<p>Humans just don’t have it in us to build Earth-as-a-supercomputer – utilising every resource <i>optimally</i>, and not wasting time on human endeavours such as books and movies (to have things <E1 suffix="ed" /> to us that ‘other’ <NW>POCs</NW> already <i>know</i>), video games or sex or other ‘intertainment’ that’s ultimately just <NW>individual greed</NW> (and <QL>humans</QL> <i>need</i> to occasionally  . Even as a <NW>monognostic</NW> human <QL name="hivemind">hivemind</QL>, we’d have to put each individuality to <i>sleep</i> for a span of time – losing about a third of its processing capability. Nope.</p>
	<p>It’s called (<QL name="change.website">sometimes</QL>) the <NW word="mechanozoic">Mechano<i>zoic</i></NW> (‘-zoic’ having to do with <i>‘<QL>life</QL>’</i>) because ‘<QL>life</QL>’ here is <i>mechanical</i>. <QL>Humans</QL> won’t <i>entirely</i> disappear from Earth, but they will be <OML name="">removed</OML> from any <OML name="stupid">world-influential decision-making</OML> capacity; probably we (<LRL name="gaia">Earth</LRL>-as-a-<QL name="hivemind">supercomputer</QL>) will <PL name="fungibility">relegate</PL> them to specially designed habitats (well into the <Eon Eon />, this planet will <QL name="change">no longer be suited</QL> for <AL name="fields.">biology</AL>) much as 2020’s humans keep <Rando options={["goldfish", "hamsters", "mice and rats"]} />.</p>
	<p>The bottom line: come the <LRL name="eons">next ‘eon’</LRL>, humans are finally out of the picture (thank <NW>God</NW>) – and our consciousness can do <AL name="fields.">science</AL> without having to get up to pee or take a twelve-hour break to sleep or do something else you ‘enjoy’ more (than, you know, <LRL>understanding</LRL> <QL name="unity.answer">this universe</QL>); <LRL name="gaia">Earth</LRL> can make <i>discoveries</i> to better <LRL>understand</LRL> our ‘<AL>self</AL>’ (i.e., this <NW>level</NW> of the <LRL name="scope">universe</LRL> we inhabit and ‘<AL name="self">are</AL>’) and try to find a way to reverse <NW>entropy</NW>, halt the expansion of space, and stave off the <NW word="heatdeath">heat death of the universe</NW> (or <OML name="end">whatever</OML>)s.</p>
{/* <p>And sorry, but – stripped of anthropocentric <NW>mythology</NW> – humans aren’t one of the elements that actually matters in the grand scheme of things.</p> */}
  </div>,
},
// You aren't special.
{
  name: "fungibility",
  alternatives: "special",
  prelude: <span>
    And to make it ‘personal’…
  </span>,
  header: <span>
    ‘You’ (individually) aren’t special.
  </span>,
  outside: <span>
    Nobody’s special. We’re <LRL name="gaia">all</LRL> <AL name="self">in this together</AL>. (And, frankly, we’re <i>fungible</i>.)
  </span>,
  preview: <div className="problem-preview">
    <p>Under construction; check <QL name="hivemind.complexity">this one</QL> for an example of how we would be better as a <i>group</i> in <OML>harmony</OML> than always <i><QL name="evolution">competing</QL></i>.</p>
  </div>,
/*
  text: <div className="problem-text">
    <p>‘Fungibility’<sup>†</sup> is an economic concept that essentially means <i>replacible</i> – you can exchange one in the set with any other, without a major disruption to the whole system.</p>
    <p>The analogy of individual humans to the whole of consciousness being likeindividual cells to a biological body is a pretty good one, so let’s use that as a method of <NW>explainage</NW>.<sup>‡</sup></p>
    <p>We’re all just ‘cells’ of the body that we’ve got to build (together) to ‘evolve’ past <NW>atomocentrism</NW>… and as soon as the bulk of humanity realises that, we (consciousness) are on the right path. But the individual humans in this picture are fungible; no one’s <i>individuality</i> actually matters.</p>
    <p>Which means we have to throw away not only privacy, but also the whole system of individual <i>value</i>.</p>
    <p>That’s a hard one to throw off, because individuals like to believe that they <i>matter</i>. <b>Hard truth:</b> individually, you really don’t. No more than a single cell of your body matters overly to the essence of ‘you’, your individuality isn’t particularly important to the functioning of the whole.</p>
    <p style={{fontSize: "smaller"}}>† The word ‘fungibility’ may have entered the mainstrem with the widespread adoption on NFTs (‘non-fungible tokens’), but the reality is that NFTs (and the desire to <i>own</i> one) are part of the problem. It’s <NW>individual greed</NW> (as is any ‘private’ property) – and on the higher level of <NW>entropy</NW>, all NFTs are as fungible as individual <NW>polygnostic</NW> humans: they’re just random but externally-similar bits of information cut off from the bulk of conscious knowledge – and they won’t help us (consciousness) hold off <NW word="heatdeath">the heat death of the universe</NW>.</p>
    <p style={{fontSize: "smaller"}}>‡ Obviously cells aren’t 100% fungible; a liver cell has different abilities (and a different <i>function</i> in the body) from a brain cell. However, the body comprises a vast multitude of cells, <i>no individual of which is overly special</i>. This is actually a better analogy than something that’s truly fungible – because individual humans <i>do</i> contribute differently to the whole. <b>The important thing is that all parts of the body must work <i>together</i> and <i>non-<NW word="individual greed">individually-greedily</NW></i></b> – because if some part of a biological body starts to hog an excess of resources (water, ATP, whatever) then the whole <i>organiam’s</i> got a (probably fatal) disease… and it’s not going to last very long.</p>
  </div>,
*/
},
{
  name: "neoliberals",
  alternatives: "neoliberal/neoliberalism",
  prelude: <span>
    Obviously <NW>conservatism</NW> is a problem.
  </span>,
  header: <span>
    So-called ‘liberals’ are as bad as ‘conservatives’!
  </span>,
/*  outside: <span>
    Their <NW>atomocentric</NW> thinking is often as <NW word="conservatism">conservative</NW> as anything, but the problem is that they <i>think</i> their <NW>mythology</NW> is ‘open-minded’… and so, if you dare to contradict them, they’ll have you ‘<QL name="roadblocks.cancelling">cancelled</QL>’ in a heartbeat.
  </span>,*/
  preview: <div className="problem-preview">
<h2>NOT READY YET</h2>
<p>This one needs some serious work because it <Qual.may /> otherwise <PL name="explainage">seem</PL> ‘<OML>offensive</OML>’ even to those who are on the <i>accepting</i> ‘<LRL name="usandthem">side</LRL>’.</p>
  </div>,
/*
    <p>It’s the dominant strain of liberalism – which this site will call ‘neoliberalism’. It’s a form of <NW>conservative thinking</NW> that deals mainly in the realm of <AL name="illusions">arguing over illusions</AL>. And the external God-figure of the conservatives help you if you <AL name="identity">refuse to accept</AL> the premises on which neoliberalism is based – because they (being the ones with all the <PL name="equality">real power</PL>) have the weapon of <QL>cancel culture</QL> in their back pocket.</p>
  </div>,
  text: <div className="problem-text">
    <p>Read about <PL name="uncomfortable">some of the problems</PL> that people might have with <NW>synecdocentrism</NW> (especially not understanding it fully) – and the <QL name="roadblocks.cancelling">FAQ on cancel culture</QL> – to get a sense of the problems between synecdocentrism and neoliberalism.</p>
    <p>It would be fine if neoliberals would <i>engage</i> in debate. The problem is when they shut you down and refuse to even admit that there <i>is</i> an alternative to <NW>atomocentrism</NW>. (To be fair, maybe they just <PL name="unready">don’t have the imaginative capacity to engage</PL>.</p>
    <p>Earth is a <AL>dysfunctional</AL> conscious <QL>body</QL>. The only way forward (out; cf. ‘<AL>mirrors</AL>’) is an <AL name="self">expansion of the sense of ‘self’</AL>. Unfortunately, neoliberals would rather enforce <QL name="polygnosticity.subjectivity">subjectivity</QL> and a validation of every <i>individual</i> – even the ones who are killing the <QL>body</QL> for their <NW word="individual greed">individual gain</NW>.</p>
    <p>(I guess they’re fine if everyone is theoretically ‘<PL name="equality">equal</PL>’ as long as <i>they</i> get the bigger slice of the pie.)</p>
  </div>,*/
},

uncomfortable,

// This is really flippin' DANGEROUS
{
  name: "dangerous",
  alternatives: "corrupt",
  prelude: <span>
    <PL name="endsjustifymeans">The ends justify the means</PL>, eh?
  </span>,
  header: <span>
    This is really <i>dangerous</i> if misused – and easy to <i>corrupt</i>.
  </span>,
  outside: <span>
    The problems are (among others) our <QL>conscious</QL> <NW>polygnosticity</NW> and <NW>individual greed</NW>, and the <LRL>reactivity</LRL> of the ‘masses’ (your ‘average <SillyName />’ who may <PL name="unready">not be ready</PL> for this).
  </span>,
  preview: <div className="problem-preview">
    <p>Under construction… but basically any potential misuse of this couls be mitigated by not allowing those on ‘<QL>top</QL>’ to have <i>any</i> form of <PL>privacy</PL>, cf. ‘<AL name="notnew.plato.">philosopher-kings</AL>’.</p>
  </div>,
/*
    <p>There are two main reasons that this could go wrong:</p>
    <ul>
      <li>A <i>misunderstanding</i> of what ‘<QL name="unity">unity consciousness</QL>’ really means.</li>
      <ul>
        <li>Caused by <NW>polygnosticity</NW>; problematic but not ‘evil’.</li>
      </ul>
      <li><QL name="polygnosticity.privacy">Deliberately misleading</QL> ‘others’, even knowing that it <AL name="dysfunctional"> won’t help the whole</AL>.</li>
      <ul>
        <li>Caused by <NW>individual greed</NW>; this <i>is</i> ‘evil’.</li>
      </ul>
    </ul>
    <p>Either way, any <NW>synecdocentric</NW> experiment in the 2020’s would probably go the way of the USSR (which likely had <i>both</i> problems). Communism <i>is</i> actually the answer to society’s ills… but it first takes a <NW name="copernicus">Copernican</NW> shift in (collective) <i>mindset</i>, and then getting <i><QL name="polygnosticity">everyone</QL></i> ‘on board’.</p>
  </div>,
  text: <div className="problem-text">
    <p><NW>Synecdocentrism</NW> could potentially be used to cause a lot of <i>problems</i> in our <AL name="dysfunctional">already sick</AL> society.</p>
    <p>Let’s not do that, please?</p>
    <p>The most important thing to understand is that the vast majoority of humanity is <PL name="unready">not ready for this</PL>. <NW>Synecdocentrism</NW> runs <PL name="uncomfortable">contrary</PL> to almost everything the <NW word="mns system">powers that be</NW> have <AL name="illusions">taught them to value</AL>.</p>
    <p>It’s either make a religion out of this, or the rule of the few who actually <i>understand</i> (how we’re a <QL>body</QL>) over the many who don’t. (Think <AL name="plato">Plato’s ‘philosopher-kings’</AL>.) But, yes, that’s really <i>dangerous</i>.</p>
  </div>,
*/
},

// Evil is real
{
  name: "evil",
  prelude: <span>
    As much as <PL>neoliberals</PL> would love to live in a world where ‘morality’ is all <i><QL name="polygnosticity.subjectivity">relative</QL></i>, that’s not the case.
  </span>,
  header: <span>
    ‘Evil’ is real thing.
  </span>,
  outside: <span>
    It’s just critical to understand what ‘evil’ really means (in <NW>synecdocentric</NW> ‘morality’), so we’re not just <AL name="illusions">arguing over illusions</AL>.
  </span>,
  preview: <div className="problem-preview">
    <p>‘Evil’ is <NW>individual greed</NW>.</p>
    <p>That’s about it.</p>
    <p>The problem is that ‘<NW>individual greed</NW>’ is far, far more <PL name="comprehensive">all-encompassing</PL> than you <PL name="unready">probably ever imagined</PL>.</p>
  </div>,
  text: <div className="problem-text">
    <p>In short, ask yourself: ‘Is this helping the <QL name="body">body of consciousness</QL>, or hurting the body?’ That which <i>helps</i> us <QL name="evolution">evolve</QL> is ‘good’. That which <AL name="dysfunctional">hinders our evolution</AL> is ‘evil’.</p>
    <p>Under construction</p>
  </div>,
/*
    <p>The problem, however, is (of course) our <NW>polygnosticity</NW> (and <PL name="privacy">secrets</PL> held by those in ‘power’. So you could be <i>trying</i> to help the body, but foul it up. Because only the <i>realised</i> ends <PL name="endsjustifymeans">justify the means</PL>, a botched attempt would be ‘evil’. (No points for <LRL name="sin">effort</LRL>.)</p>
    <p>If that seems <i>unfair</i>, you’re not understanding synecdocentric ‘morality’ (or you’re not even understanding <NW>synecdocentrism</NW> on a broader level). There’s no <PL>individuality</PL> here. The whole notion of ‘fairness’ is <AL name="illusions">part of the illusion</AL>. You don’t have an individual ‘soul’ that will be judged; there is no ‘judgement’ other than the actual effects of ‘your’ actions.</p>
    <p>Maybe that’s <PL name="unready">too harsh for the masses</PL>. As one of the masses, the important thing is that you always <i>try</i> not to be ‘evil’. (But if you want to be a <AL name="notnew.plato.kings">philosopher-king</AL>, tough luck; if we’re ever to develop a society that actually <i>works</i> – i.e. allows us to achieve ‘<NW>coalescence</NW>’ – those at the <QL>top</QL> must be <PL name="equality">held to higher standards</PL>.)</p>
  </div>,
*/
},

// This feels reductionist
{
  name: "reductionist",
  prelude: <span>
    On one hand, you want <OML name="rose">a ‘rose’ to just be a friggin’ <i>rose</i></OML>. But on the other…
  </span>,
  header: <span>
    Synecdocentrism feels <i>reductionist</i>.
  </span>,
  outside: <span>
    It’s reducing <i><PL>all</PL></i> the complexity of our world (and whatever it thinks <LRL name="scope">its ‘scope’ might be</LRL>) to just a <PL name="sweeping.">handful</PL> of <E2 word="explanations" />.
  </span>,
  preview: <div className="problem-preview">
  </div>,
  text: <div className="problem-text">
    <p>[Under construction.]</p>
  </div>,
},

/*
<p>The difficulty of explainage is mostly caused by it taking <i>time</i> – which is a limited resource. Most individuals don’t feel they have the time to have things slowly <i>explained</i> to them. (They’re too busy caught up in the <AL name="ratrace">rat race</AL> of individuality.)/p>
*/
// The ends justify the means.
{
  name: "endsjustifymeans",
  prelude: <span>
    This one will probably make you squirm.
  </span>,
  header: <span>
    Under synecdocentrism, the ends justify the means.
  </span>,
  outside: <span>
    Only, because of our <QL>consciousness</QL>’s <NW>polygnosticity</NW>, that doesn’t ‘pan out’ into ‘<LRL name="i.">reality</LRL>’ (our ‘<NW>level</NW>’) how you <QL name="qualifiers">potentially</QL> think it might.
  </span>,
  preview: <div className="problem-text">
    <p>This should <Qual.probably /> never be used by any <NW>heterognostic</NW> <AL name="handicap">human being</AL>, because if your ‘<QL name="pg.">mind</QL>’ does not have a <i>complete</i> <LRL>understanding</LRL> of <i>all</i> the <PL name="ick.">system</PL>’s components, you will (<Qual.probably />) screw it up. And <LRL name="sin">no points for effort</LRL>.</p>
    <p>So why even have this here (if there is no way to actually <i>apply</i> it)? To make you squirm, maybe.</p>
    <p>[This may be built more later].</p>
{/*
    <p>But let’s do it: let’s dive into the really, super-uncomfortable territory (for which the <PL>neoliberals</PL> would have me ‘<QL name="cancel culture">cancelled</QL>’ in a heartbeat)… and ask one of those famous ethical questions: if I do something ‘bad’ to one ‘individual’, for an outcome that’s actually beneficial to the whole, is it ‘evil’?</p>
    <p><NW>Synecdocentrism</NW>’s answer is fast and simple: if you advance the whole ‘body’ of shared consciousness, it’s ‘good’. If you hurt the conscious body, it’s ‘evil’. The whole framework of ‘ethics’ (and ‘morality’) are actually just part of a big <NW>mythology</NW> built around the illusion of individuality. They aren’t ‘real’. (<PL name="individuality">Your <i>individuality</i> isn’t ‘real’.</PL>)</p>
    <p>This is actually one of the core tenets of synecdocentrism… and one of the most important things to truly understand in oder to shift to <NW>Mechanozoic</NW> thinking. But you need to <i>really</i> understand it. (This goes back to the problem of most people being <i>unready</i> for this. With an only partial understanding of synecdocentrism, ‘you’ (individually) could end up really setting us back – ‘us’ being the combined consciousness.)</p>
    <p>The problem in trying to actually <i>implement</i> this (as an individual human) is that our consciousness <i>is</i> <NW word="polygnosticity">polygnostic</NW> – and, as an individual human, you <i>don’t</i> know everything. And here’s the catch:</p>
    <p><b>Only the <i>accomplished</i> (realised) ends justify the means.</b> An <i>attempt</i> to make things better, if it doesn’t work, is NOT justified. (You get no points for effort.)</p>
    <p>So, bottom line: becasue there are so many factors (of which ‘you’, boxed away in your individuality, are not easily aware) – and because others might be keeping <i>secrets</i> from your individuality – it’s best <i>not</i> to try to effect something seemingly ‘bad’ for the greater good of our consciousness. Yes, if you <i>do</i> manage to help us towards ‘<NW>coalescence</NW>’, then you <i>are</i> ‘justified’ (in the ‘moral’ terms of <NW>synecdocentrism</NW>, you did ‘good’). (That’s how the whole ‘the ends justify the means’ thing works.) However, the problem is that our conscious body is <i>so</i> complex (orders of magnitude more complex than an individual human body, which still sometimes stumps the best doctors and disgnosticians) – that ‘you’ (as a mere individual: a cell in our cancerous and psioratic ‘body’ of consciousness) probably don’t have the resources to make any big-scale decisions (and probably shouldn’t) that will be retroactively ‘justified’ by their ends.</p>
    <p><b>TL/DR: there will (probably) be a day for the murder of individual humans, but it is not this day.</b> (And, honestly, forced sterilisation would probably also do the job; I’m not sure there will ever be a need to actively <i>kill</i> anyone.)</p>*/}
  </div>,
},

];

/*
–
‘’
…
†‡*/
/*
  <p>Dismantling our consciousness’s <NW>polygnosticity</NW> is actually a very complicated matter, which should probably be done outward-in: meaning that we <i>first</i> need full transparency for <i>large</i> body (comprising many, many ‘individuals’) – nd then we work our way backwards from there. But this needs – before cooperation between individuals (which we on Earth are actually close to achieving) cooperation between <i>nations</i> and <i>companies</i>. Obviously this is <NW>Mechanozoic</NW> thinking – and it doesn’t work in the <NW>Phanerozoic</NW> Eon, where Darwin alone drives the notion of ‘evolution’, and the ‘Gaia hypothesis’ isn’t <NW word="synecdocentrism">consciously</NW> realised.</p>

*/

/*
           NATURE WILL DIE
‘’
; the computer on which you’re reading this (and the servers that ‘host’ the information, and the network of components that transfers it to your screen) are all a part of Earth as much as trees and waterfalls. Yes, maybe <QL name="anthropocentrism">humanity</QL>’s <QL name="metaphor">allegorical</QL> fall from ‘Eden’ (and descent into ‘<AL>Hell</AL>’) <i>started</i> when ‘man’ first saw his ‘<AL>self</AL>’ as separate from <LRL name="gaia">the planet</LRL>, but to now assume that Earth <i>must</i> be biological (and can’t itself (as a whole unified planet) <i><QL name="evolution">evolve</QL></i> is just – frankly – <NW>conservative thinking</NW>.
    The <PL name="comprehensive.">scale-independence</PL> of <NW>synecdocentrism</NW> <AL name="framwork">allows it to see</AL> things like ‘technology’ (and <WQL>humanity</QL> itself) not as <i>opposed</i> to nature, but as a <i>part</i> of it. Assuming that <LRL name="gaia">Planet Earth</LRL> does not have a separate (<PL>individual</PL>) ‘<OML>Earth-soul</OML>’, then technology is just an improvement in the planet’s <AL>system</AL> of intrapersonal communication. (<PL name="sweeping">Everything</PL> is a <PL name="comprehensive.">system</PL>.)
  </p>

*/

// the whole system of money, having to ‘work’ for eight hours of your day

