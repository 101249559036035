import React from 'react';
import WName, {WArticle} from '../../WName';
import Eon from '../../Eon';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual, Rando, Switcheroo, SillyName} from '../../common';

// ‘’ … –

export const notions = {
  name: "notions",
  alternatives: "ideas,notion,idea",
  prelude: <span>
    The ‘rules’ of <i>your</i> ‘<LRL>game</LRL>’ just don’t apply here.
  </span>,
  header: <span>
    This is the ‘battlefield’ of <i>ideas</i> and <i>notions</i>.
  </span>,
/*  outside: <span>
    <WName /> exists on a completely different <NW>level</NW> of ‘<LRL>reality</LRL>’ than human ‘<PL>individuals</PL>’ (and their <LRL name="motto">hypothetical</LRL> <OML name="soul">individual ‘souls’</OML>).
  </span>,*/
  preview: <div className="long-read-preview">
    <p>On the <NW>level</NW> of <WName />, there are no <PL>individuals</PL> (and no individual <OML>soul</OML>); this is well <i>below</i> <OML name="reincarnation">all of that</OML> (and relies on the <LRL>understanding</LRL> that if there <i>is</i> some <PL>individual</PL> ‘<OML>God</OML>’, ‘<Rando options={[<span><OML name="patriarchy">He</OML>’ is</span>, "She’ is", "It’ is", "They’ are"]} /> surely not at the <i><LRL name="deep">bottommost</LRL></i> <NW>level</NW>). To venture <i>here</i> it is not only incredibly <PL>hard</PL> but <QL name="definitive">decidedly</QL> <i>not</i> <PL name="invisible">possible</PL> to do so in a <AL name="handicap">human body</AL>. The only way to find this place is through the <LRL name="i.">power</LRL> of the <LRL>imagination</LRL>. And yet it clearly (self-evdently) <i><LRL name="nihilism">exists</LRL></i>.</p>
    <p><PL name="hard">Confused</PL> yet?</p>
  </div>,
  text: <div className="long-read-text">
    <p>The <NW>level</NW> of <OML>ideas</OML> and notions is a <LRL>different</LRL> (and <Qual.likely /> <PL>uncomfortable</PL>) place for a <NW>polygnostic</NW> <QL name="pg.">mind</QL> to <OML>end</OML> up. For one, the whole concept of <QL>gnosticity</QL> that rules <i>our</i> universe starts to break down; either the idea is <i>thought</i> (by some <NW>POC</NW>, or it is <i>abandoned</i> (i.e., forgotten, or discarded in favour of a new <E2 word="explanation" />), and the <QL>gnosticity</QL> of its thinker does not apear to particularly matter. (You even have weird things where an idea – at least <LRL name="gaiacentrism">on Earth</LRL> – will get lost and then ‘rediscovered’ decades or centuries <i><PL name="all.time">later</PL></i>: effectively <OML name="afterlife">resurrecting</OML> it from the ‘dead’.)</p>
    <p>That said, there <i>do</i> appear to be some similarities between how ideas propogate and how (in the absence of <i><OML name="will">intentionality</OML></i>) ideas manage to propogate… and <i>those</i> <Qual.probably /> have to do with a <Qual.possibly /> even lower-<NW>level</NW> dichotomy between <LRL>reactivity</LRL> and <QL>conscious</QL> <i><NW>agency</NW></i>.</p>
    <p>There is a stark similarity between the ‘evolution’ of <i>ideas</i> and that of the <NW>Phanerozoic Eon</NW>’s <AL name="fields.">biology</AL> – surprising, maybe, until you <LRL>understand</LRL> that the competition-fuelled ‘change’ in <AL name="fields.">biological</AL> ‘<QL>evolution</QL>’ (that isn’t <i>really</i> <QL>change</QL> because it’s all the same <i><PL name="ick.">system</PL></i>, but maybe that’s just <PL name="all.scale">scale-invariance</PL>) is exactly the same as what is powering the (current) evolution of <i><OML>ideas</OML></i>.</p>
    <p>If you apply <QL name="evolution.">natural selection</QL> to the ‘battlefield’ of <OML>ideas</OML>, you can see the parallels (and might understand now why it’s a ‘battlefield’): different ideas, just like different viruses, seem to be more or less successful ‘infecting’ <QL>humanity</QL> and propogating. The ‘successful’ ideas ‘win out’. We are going to call this form of the ‘evolution’ of ideas – because it needs a good <NW>atomocentric</NW> <i><AL name="identity">name</AL></i> – ‘average-<SillyName persist />-ism’.</p>
    <p>So under ‘average-<SillyName persist />-ism’, how does an idea out-compete its fellow ideas and manage to <i>reproduce</i> (i.e., spread)? (And remember that none of this is a <i>conscious</i> process, this is the natural selection of <i>ideas</i> that is almost identical to another <NW word="atomocentric">atomocentrically</NW>-named form of this thing that was proposed a long time ago and <QL name="evolution.darwin">excels within its scope</QL>: i.e., <QL name="conscious">unconscous</QL> <LRL>reactivity</LRL>.)</p>
    <p style={{marginLeft: "20px"}}>Aside: if it seems weird that there can be an unconscious evolution of ideas when <Qual.maybe /> you think that ideas <i>are</i> <QL>consciousness</QL>… well, there can be. When the propogation of ideas follows the same rules as the <NW>Phanerozoic</NW> (and not the ‘<Eon />’) Eon, this reeks of <LRL>reactivity</LRL>. For one, having the <i><LRL name="i.power">ability</LRL></i> to employ conscious <NW>agency</NW> does not mean that most of <QL>humanity</QL> is not just still <AL>sleeping</AL>. The second <E2 word="explanation" /> has to do with <PL>privacy</PL> in our <AL>dysfunctional</AL> <NW>polygnostic</NW> <AL name="handicap">human</AL> <QL>consciousness</QL>.</p>
    <p>For an idea to <i>propogate</i> (survive) under ‘average-<SillyName persist />-ism’ it must fulful several factors:</p>
    <ul>
      <li>It must persist in the <QL name="pg.">mind</QL> of the thinker (<NW>POC</NW>) whose <QL>consciousness</QL> it presently inhabits.</li>
      <ul>
         <li>This can be an <i>unconscious</i> process – and for many ideas this is probably the best way for them to endure, because if the thinker is constantly questioning or second-guessing them (i.e., <LRL name="motto">leaving no stone unturned</LRL>) they are more likely to be exposed as <NW>mythology</NW>.</li>
        <li>The idea must endure, furthermore, without ‘killing’ the bearer – which in this case would include having him or her socially isolated to such a point that the idea itself just becomes a laughingstock and unable to spread.</li>
        <ul>
          <li>Yes, some ideas can spread in that way too, however: as jokes. Though if the point of the ‘average-<SillyName persist />-ian evolution’ of ideas is to be given serious <i>consideration</i>, then being relegated to a joke is a failure of this ‘evolution’.</li>
        </ul>
      </ul>
      <li>Then to spread: it must ‘woo over’ its new host: i.e., be both versatile and <i><PL name="ick">likeable</PL></i> enough that it will be accepted into the <QL name="pg.">mind</QL> of some <NW>heterognostic</NW> ‘<LRL>other</LRL>’ (namely: your ‘average-<SillyName persist />’).</li>
    </ul>
    <p>That’s a little bit ‘tongue-in-cheek’, but not really; it avtually <i>does</i> describe the past evolution of <QL>human</QL> thought pretty well.</p>
    <p>And now get ready for some <WName ic /> weirdness.</p>
    <p>(Yes, we’re just getting there.)</p>
    <p>Because that was ‘average-<SillyName persist />-ism’, which is an <i>unconscious</i> (or <LRL>reactive</LRL>) propogation of ideas. Now we’re going to inject <QL>conscious</QL> <NW>agency</NW> into there.</p>
    <p>This one doesn’t need an <NW>atomocentric</NW> epithet; call it the <WName ic /> (or <Eon />) <QL>evolution</QL> of ideas.</p>
    <p>So ‘average-<SillyName persist />-ism’ was the way that ideas spread <i>unconsciously</i> (as the <OML name="ideas">viruses</OML> of the <QL>consciousness</QL>-as-a-<QL>body</QL> <PL name="ick.">system</PL>). is very similar to the <NW>Phanerozoic Eon</NW> (and <LRL name="gaiacentric">before</LRL>) concept of <i>evolution</i>: <QL name="evolution.">natural selection</QL>, or <LRL>reactivity</LRL>.</p>
  </div>,
};
