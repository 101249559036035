import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

// Does it have a SCOPE?
export const scope = {
  name: "scope",
  prelude: <span>
    Isn’t it supposed to be ‘<PL>comprehensive</PL>’?
  </span>,
  header: <span>
    Is there a ‘scope’ to <WName noNW />?
  </span>,
  outside: <span>
    Is there anything that this supposedly <PL name="comprehensive">all-encompassinhg</PL> <QL>Weltanschauung</QL> <i>can’t</i> ‘see’?
  </span>,
  preview: <div className="long-read-preview">
    <p><QL name="polygnosticity.">TL;DR</QL>: yes; outside of the one presumption it makes (of <QL>unity consciousness</QL>, which <QL name="qualifiers">may be</QL> dead <OML>wrong</OML>, but which arises from <NW>logical induction</NW> and <NW word="occam">Occam’s razor</NW>), it is limited to the experimentally <i>observable and verifiable</i>.</p>
    <p>Remember, <WName /> is at heart a <i>theory</i> (world-<E2 word="explanation" />) that – in order to <AL name="framework.">work</AL> – rejects everything outside the <i>observable</i> (shafting that into <NW>mythology</NW>, or just vaguely considering the <LRL name="superpositions">possibility</LRL> of ‘<OML home>or maybe</OML>)… and as such manages a hell of a lot <i>more</i> than any other non-religious, observation-based <QL>Weltanschauung</QL> </p>
  </div>,
  text: <div className="long-read-text">
  </div>,
};
