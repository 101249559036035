import React from 'react';
import Header from './Header';
import {Outlet} from "react-router-dom";
import './Layout.css';

export const toTop = () => {
  //window.location.hash = "#top";
}

const Layout = () => {
  return (
    <div className="App">
      <Header />
      <a name="top" />
      <Outlet />
    </div>
  );
};

export default Layout;
