import React from 'react';
import WName, {WArticle} from '../../WName';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2} from '../../common';

// ‘’ … –

export const different = {
  name: "different",
  prelude: <span>
    What (really) even <i>is</i> ‘<NW>conservative thinking</NW>’?
  </span>,
  header: <span>
    This is probably unlike <i>anything</i> you've seen before.
  </span>,
  outside: <span>
    (<QL name="qualifiers">Probably.</QL>)
  </span>,
  preview: <div className="long-read-preview">
    <p><NW>Synecdocentrism</NW> isn’t only fairly <i>incompatible</i> with <NW>atomocentrism</NW> but to an atomocentric thinker it would be very <i><PL name="uncomfortable">weird</PL></i>.</p>
    <p>So get ready to throw out your <NW>conservative thinking</NW>. And what is that? Start with anything that might be ‘conservative’. (Tories and republicans come to mind – as well as (frankly) most of Western Europe in the 2020’s.) Now throw out anything that might even qualify as ‘traditional’ (i.e., adhering to <i>tradition</i>). Now throw out anything that might even possibly perhaps (or if one so considered it)…</p>
    <p>Fair warning: it’s (literally) just about everything that you would have ever been taught by <LRL name="gaiacentrism">Earth’s</LRL> majority (<NW>atomocentric</NW>) culture(s).</p>
    <p>[Still under construction]</p>
  </div>,
  text: <div className="long-read-text">


  </div>,
};
