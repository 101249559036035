import React from 'react';
import WName, {WArticle} from '../../WName';
import Eon from '../../Eon';
import PL from '../../problems/ProblemLink';
import QL from '../../faq/QuestionLink';
import AL from '../../angles/AngleLink';
import NW from '../../neologisms/NewWord';
import OML from '../../ormaybe/OrMaybeLink';
import LRL from '../LongReadLink';
import {E1, E2, Qual, Rando, Switcheroo, but_your_choice} from '../../common';

// ‘’ … –

export const symptoms = {
  name: "symptoms",
  alternatives: "symptom,causes",
  prelude: <span>
    Enough of just treating the <i>symptoms</i>.
  </span>,
  header: <span>
    This is identifying the underlying <i>causes</i>.
  </span>,
  outside: <span>
    This <AL>framework</AL> can <E2 italic /> <QL name="qualifiers">just about</QL> <PL name="sweeping">everything</PL>… so if there’s something wrong with <LRL name="gaia">the planet</LRL>, you can sure as <AL>Hell</AL> <OML name="meaning">bet</OML> that there is a <i><LRL name="answers">reason</LRL></i>.
  </span>,
  preview: <div className="long-read-preview">
    <p>Granted, the causes always tend to end up as one of only a very small <PL name="sweeping.">handful</PL> of <LRL>deep</LRL> things: like <NW>individual greed</NW>, or that <PL name="ick">everything</PL> is just an <NW>illusion</NW>, and <QL>humanity</QL> spends the bulk of its time (when not engaged in the ‘<LRL>waiting game</LRL>’) just <AL name="illusions">arguing over these illusions</AL>. The <NW>polygnosticity</NW> of <QL name="pg">our consciousness</QL> is essentially always at play. It can feel <PL>reductionist</PL>.</p>
    <p>But the <QL>Weltanschauung</QL> really doesn’t (frankly, my dear) give a <OML name="offensive">damn</OML> about <PL name="ick.rude">your feelings</PL>. It (as a <LRL>notion</LRL>) is <i><QL name="unity">part</QL></i> of <QL>humanity</QL> (because at least one human – or at least <LRL name="gaiacentrism">Earth-bound</LRL> entity, seeing as <WName ic /> thinking has not yet got <i>off</i> <LRL name="gaiacentrism">this rock</LRL> – has considered it: <i>you</i>). <QL>Humanity</QL> (and <LRL name="gaia">Planet Earth</LRL>) is a <AL name="sick">sick, sick</AL> <PL name="ick.">system</PL>. So this thing (‘<QL>Weltanschauung</QL>’, <LRL>notion</LRL>, <OML name="ideas">conscious</OML> <AL>framework</AL>, <LRL name="superpositions">whatever it is</LRL>) is going to first <i><AL name="systems">analyse</AL></i> (take apart) the whole thing and <i><AL name="dysfunctional">diagnose</AL></i> its problems (at the <LRL name="deep">root</LRL> ‘<NW>level</NW>’).</p>
  </div>,
  text: <div className="long-read-text">
    <p>Which it does <OML name="offensive">pretty damn well</OML>. A <AL>Hell</AL> of a lot better than any of <QL>humanity</QL>’s <NW>mythology</NW> with its <QL name="g.agnosticity">eternally-unknowable</QL> (next-<NW>level</NW>) <OML name="god">external God</OML> or tenacious <PL>individual</PL> <OML>soul</OML>.</p>
    <p>And no – if your <NW>POC</NW> agrees with <AL name="fields.">science</AL> that there’s <OML name="levels">more to the world</OML> than <AL name="handicap">meets the (human) eye</AL> – this isn’t <PL>reductionist</PL>. It’s <NW word="occam">Occam’s razor</NW>.</p>
    <p>That no <QL>human</QL> on <LRL>Earth</LRL> would (<Qual.probably />) <i><PL name="ick">like</PL></i> it isn’t <i>its</i> problem.</p>
    <p><QL>Humanity</QL>, fact check: <PL name="denial">denying</PL> that you’re <AL>sick</AL> isn’t going to cure you.</p>
    <p>Get over your <PL name="ick">qualms</PL> and become a <WName ist /> or go back to charting how Earth moves around the sun with pre-<NW word="copernicus">Copernican</NW> ‘epicycles’.</p>
    {but_your_choice}
  </div>,
};
