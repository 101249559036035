import React from 'react';
import WName, {WArticle} from '../WName';
import QL from './QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import NW from '../neologisms/NewWord';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual, TLDR} from '../common';

export const life = {
  name: "life",
  prelude: <span>
    If you clicked on this one, maybe you’re <i>trying</i> to throw the <QL>Weltanschauun</QL> something that it can’t just simply <i><LRL>answer</LRL></i>.
  </span>,
  question: <span>
    What is life?,
  </span>,
  clarification: <span>
    Because <WName /> doesn’t just throw up its hands when faced with the ‘deep’ stuff. This <QL>Weltanschauung</QL> is ‘<LRL>deeper</LRL>’ than you are. (Even if ‘<PL name="special">you</PL>’ are a <OML name="smarter">genius</OML>.)
  </span>,
  answerPreview: <div className="answer-preview">
    <p>The <E2 word="explanation" italic />: ‘life’ an emergent phenomenon (essentailly an <NW>illusion</NW>) that arises from the <AL>handicap</AL> of being a <NW>POC</NW> trapped inside of a human (or other <LRL name="gaiacentrism">Earth-based</LRL> <AL name="fields.">biological</AL>) mortal and (as of 2024) <QL name="polygnosticity">incurably</QL>-<NW>heterognostic</NW> ‘<AL name="container">body</AL>’.</p>
  </div>,
  answer: <div className="answer-text">
    <p>Yes, it was really that simple.</p>
    <p>Now go on through the sub-list below – and try to really <LRL>understand</LRL> each of those.</p>
  </div>,

  sublist: [

  {
    // The MEANING of life
    name: "meaning",
    question: <span>
      What is the <i>meaning</i> of life?
    </span>,
    clarification: <span>
      This is assuming you want to know the end <i>goal</i>. If you want to know the reason why you <i>exist</i>, read ‘<QL name="life.reason">What is the <i>reason</i> for life?</QL>’
    </span>,
    answerPreview: <div name="answer-preview">
      <p>The ‘end’ goal for a <QL>human</QL> <PL>individual</PL> (which is <PL name="all.time">actually</PL> – on a non-<QL>anthropocentric</QL>, non-<LRL name="gaiacentric">gaiacentric</LRL> <PL name="all.">scale</PL> – fairly <QL name="urgency">immediate</QL>) is for <QL>humans</QL> (as – <QL name="evolution">initially</QL> – <PL>individuals</PL>) to <AL name="sleeping">wake up</AL>, break out of the ‘<AL name="mirrors">box of mirrors</AL>’ (or ‘<AL>Hell</AL>’), <NW>transcend</NW> any <NW>individual greed</NW>. For <QL>humanity</QL> as a ‘<QL name="body.">super-organ</QL>’ the ‘end’ goal is to buck the yoke of the <NW>MNS system</NW> (as a whole Earth: <LRL name="communism">together</LRL> and with no <PL name="privacy">secrets</PL>) and <LRL name="reactivity">take</LRL> conscious <NW>agency</NW> of <LRL name="gaia">Earth</LRL>, and now (effectively treating all of <LRL name="gaia">Planet Earth</LRL> as a massive and complex ‘<PL>individual</PL>’) <PL name="all.scale">repeat from the beginning</PL>.</p>
      <p>When you ask about the ‘end’ goal for our <QL name="unity">consciousness as whole</QL>, that <OML name="goal">may not be clear yet</OML> (<AL name="fields.physics">i.e.</AL>, still in a <LRL>superposition</LRL>-esque <QL name="definitive">indeterminate</QL> state). However, <LRL>understanding</LRL> is <OML name="gaia">almost certainly</OML> good, <QL name="g.">agnosticity</QL> is (<OML name="god">almost certainly</OML>) <i>bad</i>, and if <LRL name="gaia">Planet Earth</LRL> could <QL name="hivemind">think as one</QL> and <QL name="evolution">learn</QL> without the bulk of its sum <QL name="pg.mind">knowledge</QL> having to be re-<E1 suffix="ed" /> to ‘<NW word="family system">generation</NW>’ <PL name="all.time">after ‘generation’</PL> of <AL name="handicap">human ‘beings’</AL>… <i><Qual.probably /></i> that would be a <QL name="salvation"><i>good</i> thing</QL>.</p>
    </div>,
//    answer: <div className="answer-text">
  //  </div>,
  },

  {
    // The REASON for life
    name: "reason",
    question: <span>
      What is the <i>reason</i> for life?
    </span>,
    clarification: <span>
      <OML name="offensive">Mommy</OML>, why do I <i><LRL name="i.real">exist</LRL></i>?’
    </span>,
    answerPreview: <div name="answer-preview">
      <p><TLDR />: <QL>Humans</QL> each have an <PL>individual</PL> ‘life’ because <LRL name="gaia">Planet Earth</LRL> – as a conscious <AL>system</AL>, and <PL name="sweeping">every</PL> <AL name="fields.biology">organism</AL> is a <PL name="ick.">system</PL> – has <AL name="sick.">DID</AL>.</p>
      <p><AL name="handicap">Human-based</AL> <NW>POCs</NW> perceive their ‘<AL name="self">selves</AL>’ (as <QL name="polygnosticity">separate</QL> <PL>individuals</PL>) because of – as you <PL name="sweeping.handful">might expect</PL> – <QL>humanity</QL>’s <QL name="unity">conscious</QL> <NW>polygnosticity</NW>.</p>
      <p>It would <Qual.probably /> feel exactly the same to a <AL name="handicap">human individual</AL> who had <AL name="sick.">DID</AL>: their <LRL name="game">separate ‘personalities’</LRL> would actually each <i>have</i> its own ‘life’ (and they – <Qual.probably /> – wouldn’t <i><PL name="ick">want</PL></i> to <QL name="pg.privacy">share thoughts</QL>).</p>
      <p><AL name="sick.">DID</AL> is still an <NW>illusion</NW>.</p>
      <p>But there’s the short and sweet answer: <LRL name="gaia">Earth</LRL> has <AL name="sick.">DID</AL>.</p>
    </div>,
//    answer: <div className="answer-text">
  //  </div>,
  },

  ],
};
