import React from 'react';
import Box from './Box';
import './Opening.css';

function Opening() {
  const titlePrelude = <span>
    And I’m sorry if this offends your <i>individuality</i>, but…
  </span>;
  const titleHeader = "It’s time for a (radical) shift in perspective:";
  const titleOutside = <span style={{fontSize: "16pt"}}>
    From <span className="badidea">‘you’</span> and <span className="badidea">‘I’</span>…<br />
    to just <span className="goodidea">‘us’</span>.
  </span>;
  return (
    <div className="maintext">
      <Box
        prelude={titlePrelude}
        header={titleHeader}
        outside={titleOutside}
        expandText="Enter"
        redirect="/menu"
      />
    </div>
  );
}

export default Opening;
